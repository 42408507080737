import React from "react";
import profileImg from "../../../../assets/images/profilePlaceholder.svg";
import classes from "./ProfileImage.module.scss";

const ProfileImage = (props) => {
  const shapeClass = props.rectangle ? "rounded" : "rounded-circle";
  return (
    <>
      <img
        src={props.src ? props.src : profileImg}
        className={`${classes.ProfileImage} ${shapeClass}`}
        style={{ height: props.height }}
        alt=""
      />
    </>
  );
};

export default ProfileImage;
