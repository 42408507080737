import React from "react";
import classes from "./Sidebar.module.scss";
import homeIcon from "../../../../assets/icons/sideBarIcons/home.svg";
import homeIconActive from "../../../../assets/icons/sideBarIcons/homeActive.svg";
import mySessionIconActive from "../../../../assets/icons/sideBarIcons/mySession.svg";
import mySessionIcon from "../../../../assets/icons/sideBarIcons/mySessionActive.svg";
import progressIcon from "../../../../assets/icons/sideBarIcons/progressIcon.svg";
import progressIconActive from "../../../../assets/icons/sideBarIcons/progressIconActive.svg";
import settingsIcon from "../../../../assets/icons/sideBarIcons/setting.svg";
import settingsIconActive from "../../../../assets/icons/sideBarIcons/settingActive.svg";
import messageIcon from "../../../../assets/icons/sideBarIcons/message.svg";
import messageIconActive from "../../../../assets/icons/sideBarIcons/messageActive.svg";
import btsIcon from "../../../../assets/icons/sideBarIcons/BTS_grey.svg";
import btsIconActive from "../../../../assets/icons/sideBarIcons/BTS_green.svg";
import logoutIcon from "../../../../assets/icons/sideBarIcons/logout.svg";
import logoutIconActive from "../../../../assets/icons/sideBarIcons/logoutActive.svg";
import clientIcon from "../../../../assets/icons/adminSideBarIcon/clientsIcon.svg";
import clientIconActive from "../../../../assets/icons/adminSideBarIcon/clientIconActive.svg";
import therapistIcon from "../../../../assets/icons/adminSideBarIcon/therapistIcon.svg";
import therapistIconActive from "../../../../assets/icons/adminSideBarIcon/therapistIconActive.svg";
import revenueIcon from "../../../../assets/icons/adminSideBarIcon/revenueIcon.svg";
import revenueIconActive from "../../../../assets/icons/adminSideBarIcon/revenueIconActive.svg";
import queriesIcon from "../../../../assets/icons/adminSideBarIcon/queriesIcon.svg";
import queriesIconActive from "../../../../assets/icons/adminSideBarIcon/queriesIconActive.svg";
import SidebarItem from "./SidebarItem/SidebarItem";
import { connect } from "react-redux";

const Sidebar = (props) => {
  const { currentPath } = props;

  if (props?.type === "admin") {
    return (
      <nav className={`${classes.Sidebar}`}>
        <SidebarItem
          title="home"
          icon={currentPath === "/" ? homeIconActive : homeIcon}
          link="/"
          exact
        />

        <SidebarItem
          title="clients"
          icon={currentPath === "/clients" ? clientIconActive : clientIcon}
          link="/clients?all"
        />

        <SidebarItem
          title="therapists"
          icon={
            currentPath === "/therapists" ? therapistIconActive : therapistIcon
          }
          link="/therapists?all"
        />

        <SidebarItem
          title="sessions"
          icon={
            currentPath === "/sessions" ? mySessionIconActive : mySessionIcon
          }
          link="/sessions?all"
        />

        <SidebarItem
          title="revenue"
          icon={currentPath === "/revenue" ? revenueIconActive : revenueIcon}
          link="/revenue?all"
        />

        <SidebarItem
          title="Questions"
          icon={currentPath === "/questions" ? queriesIconActive : queriesIcon}
          link="/questions?all"
        />

        <SidebarItem
          title="Logout"
          className={classes.logout}
          icon={currentPath === "/logout" ? logoutIconActive : logoutIcon}
          link="/logout"
        />
      </nav>
    );
  }
  return (
    <nav className={`${classes.Sidebar}`}>
      <SidebarItem
        title="home"
        icon={currentPath === "/" ? homeIconActive : homeIcon}
        link="/"
        exact
      />

      <SidebarItem
        title="my sessions"
        icon={
          currentPath.includes("/my-sessions")
            ? mySessionIconActive
            : mySessionIcon
        }
        link="/my-sessions"
      />

      <SidebarItem
        title="messages"
        icon={
          currentPath.includes("/messages") ? messageIconActive : messageIcon
        }
        link="/messages"
      />

      {props.type === "client" && (
        <div className={classes.bts}>
          <a
            href="https://my.mindsum.app/beat-the-stigma"
            className={classes.btsLink}
            target="_blank"
            rel="noreferrer"
          >
            <img src={btsIcon} alt="" />
            <p className={classes.btsText}>
              Beat <br /> the stigma
            </p>
          </a>
        </div>
      )}

      <SidebarItem
        title="settings"
        icon={
          currentPath.includes("/settings") ? settingsIconActive : settingsIcon
        }
        link="/settings"
      />

      {props.type === "therapist" && (
        <SidebarItem
          title="reports"
          icon={
            currentPath.includes("/reports") ? progressIconActive : progressIcon
          }
          link="/reports?all"
        />
      )}

      {props?.type === "therapist" && (
        <SidebarItem
          className={classes.logout}
          title="Logout"
          icon={currentPath.includes("/logout") ? logoutIconActive : logoutIcon}
          link="/logout"
        />
      )}

      {props?.type === "client" && (
        <SidebarItem
          title="Logout"
          icon={currentPath.includes("/logout") ? logoutIconActive : logoutIcon}
          link="/logout"
        />
      )}
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.auth.user["type"],
    profile: state.userProfile.profile,
  };
};

export default connect(mapStateToProps)(Sidebar);
