import React from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import classes from "../MySesTable.module.scss";
import therapistImg from "../../../../../../assets/images/profilePlaceholder.svg";
import { timeBetweenDates } from "../../../../../../shared/utility";

const MySessionsTableRow = (props) => {
  const history = useHistory();
  const { data } = props;
  const { client } = data;

  const onClick = () => {
    history.push("/session/" + data.id + "/overview");
  };

  return (
    <div
      onClick={onClick}
      className={`${classes.tableRow}`}
      style={{ width: "100%", cursor: "pointer" }}
    >
      <div className={`${classes.tableRowDiv} ${classes.therapist}`}>
        <div className={classes.therapistProfileDiv}>
          {client?.attendee.profile_image ? (
            <img
              src={client?.attendee.profile_image}
              className={classes.therapistProfile}
              alt=""
            />
          ) : (
            <img
              src={therapistImg}
              className={classes.therapistProfile}
              alt=""
            />
          )}
        </div>
        <div className={classes.therapist__nameDiv}>
          <p className={classes.name}>
            {client?.attendee?.first_name} {client?.attendee?.last_name}
          </p>
          <p className={`${classes.location} location`}>
            {client?.attendee?.type}
          </p>
        </div>
      </div>
      <div className={`${classes.treatmentSession} ${classes.tableRowDiv}`}>
        {data?.status}
      </div>
      <div className={`${classes.sessionDate} ${classes.tableRowDiv}`}>
        <big>
          <Moment local format="DD MMM YYYY">
            {data?.start}
          </Moment>
        </big>
        <small>
          <Moment local format="HH:mm A">
            {data?.start}
          </Moment>
        </small>
      </div>
      <div className={`${classes.sessionType} ${classes.tableRowDiv}`}>
        {data?.type === "video" && (
          <svg
            className={classes.mySessionVideo}
            xmlns="http://www.w3.org/2000/svg"
            width="32.381"
            height="32.381"
            viewBox="0 0 32.381 32.381"
          >
            <path
              id="Path_4383"
              data-name="Path 4383"
              d="M354.742,354.742a16.192,16.192,0,0,1,25.292,19.847l2.313,6.94a.648.648,0,0,1-.819.819l-6.94-2.313a16.191,16.191,0,0,1-19.847-25.292Zm2.382,15.6a.647.647,0,0,0,.993.547l4.132-2.605v2.436a.648.648,0,0,0,.648.648h11.713a.648.648,0,0,0,.648-.648v-9.067a.648.648,0,0,0-.648-.648H362.9a.648.648,0,0,0-.648.648v2.436l-4.132-2.605a.647.647,0,0,0-.993.547Z"
              transform="translate(-350 -350)"
            />
          </svg>
        )}
        {data?.type === "home" && (
          <svg
            className={classes.mySessionVideo}
            xmlns="http://www.w3.org/2000/svg"
            width="25.873"
            height="37.996"
            viewBox="0 0 25.873 37.996"
          >
            <g id="Group_7168" data-name="Group 7168" transform="translate(0)">
              <path
                id="Path_4726"
                data-name="Path 4726"
                d="M1121.88,1145.165h-2.19a1.345,1.345,0,0,0-1.41,1.265v.676h.932v-.676c0-.121.158-.33.478-.333h2.19c.318,0,.475.213.477.333v.676h.932v-.676A1.343,1.343,0,0,0,1121.88,1145.165Z"
                transform="translate(-1113.511 -1123.969)"
              />
              <path
                id="Path_4727"
                data-name="Path 4727"
                d="M1141.464,1098.354a4.083,4.083,0,1,0-4.088-4.086A4.085,4.085,0,0,0,1141.464,1098.354Z"
                transform="translate(-1125.245 -1090.188)"
              />
              <path
                id="Path_4728"
                data-name="Path 4728"
                d="M1139.868,1123.622l-6.177-9.3a1.97,1.97,0,0,0-1.192-.841l-2.172-.546-.207-.038-.032-.011-1.905-.4a2.346,2.346,0,0,0-.415-.04,2.027,2.027,0,0,0-1.464.627l-6.137,6.508a1.986,1.986,0,0,0-.547,1.379l0,3.315h4.016v-2.525l2.27-2.429-.247,6.206-.451,1.057-.106.224h1.394a3.386,3.386,0,0,1,3.381,3.382v.2l5.67,10.427a1.982,1.982,0,0,0,1.2.962,2.03,2.03,0,0,0,.567.082,1.968,1.968,0,0,0,.958-.249,2.02,2.02,0,0,0,.966-1.194,2.05,2.05,0,0,0,.079-.588,1.975,1.975,0,0,0-.246-.944l-6.2-12.808.443-5.079,3.215,4.849a2.008,2.008,0,1,0,3.343-2.224Z"
                transform="translate(-1114.333 -1103.863)"
              />
              <path
                id="Path_4729"
                data-name="Path 4729"
                d="M1118.071,1152.278H1108.3a2.386,2.386,0,0,0-2.386,2.386v8.015a2.386,2.386,0,0,0,2.386,2.386h9.774a2.386,2.386,0,0,0,2.386-2.386v-8.015A2.385,2.385,0,0,0,1118.071,1152.278Zm.938,10.4a.94.94,0,0,1-.938.938H1108.3a.941.941,0,0,1-.939-.938v-8.015a.943.943,0,0,1,.939-.939h9.774a.942.942,0,0,1,.938.939Zm-4.5-7.386h-2.654v2.051h-2.051V1160h2.051v2.051h2.654V1160h2.05v-2.654h-2.05Z"
                transform="translate(-1105.911 -1128.34)"
              />
            </g>
          </svg>
        )}
        {data?.type === "in_clinic" && (
          <svg
            className={classes.mySessionVideo}
            id="Group_7167"
            data-name="Group 7167"
            xmlns="http://www.w3.org/2000/svg"
            width="32.624"
            height="32.256"
            viewBox="0 0 36.624 33.256"
          >
            <path
              id="Path_4724"
              data-name="Path 4724"
              d="M3355.151,3420.112a6.368,6.368,0,1,0,6.369,6.368A6.375,6.375,0,0,0,3355.151,3420.112Zm4.32,8.253h-2.435v2.434h-3.771v-2.434h-2.435v-3.771h2.435v-2.435h3.771v2.435h2.435Z"
              transform="translate(-3336.839 -3406.848)"
            />
            <path
              id="Path_4725"
              data-name="Path 4725"
              d="M3101.543,3116.446l-17.786-11.214a.524.524,0,0,0-.56,0l-17.786,11.214a.525.525,0,0,0,.28.969h4.162v20.993H3097.1v-20.993h4.162A.525.525,0,0,0,3101.543,3116.446Zm-13.593,14.971a8,8,0,1,1,3.527-6.634A8,8,0,0,1,3087.951,3131.417Z"
              transform="translate(-3065.165 -3105.151)"
            />
          </svg>
        )}
        {data?.type === "phone" && (
          <svg
            className={classes.mySessionVideo}
            xmlns="http://www.w3.org/2000/svg"
            width="32.38"
            height="32.38"
            viewBox="0 0 32.38 32.38"
          >
            <path
              id="Path_4384"
              data-name="Path 4384"
              d="M609.217,602.708a16.191,16.191,0,1,0-5.445,5.445l6.939,2.313a.648.648,0,0,0,.819-.819Zm-6.581-1.831a4.6,4.6,0,0,1-1.337,2.078c-.918.682-2.547.491-4.484-.421a17.694,17.694,0,0,1-9.275-12.062c-.384-2.11-.147-3.732.745-4.442a4.6,4.6,0,0,1,2.352-.758.507.507,0,0,1,.531.259l1.582,3.126a.9.9,0,0,1-.1.994q-.5.6-1,1.207a.1.1,0,0,0-.019.1,21.594,21.594,0,0,0,5.765,7.5.1.1,0,0,0,.106.008l1.424-.659a.9.9,0,0,1,.987.154l2.615,2.332A.506.506,0,0,1,602.635,600.877Z"
              transform="translate(-579.184 -578.123)"
            />
          </svg>
        )}
        <div className={classes.sessionTypeSpan}>
          {data?.type.toString() === "in_clinic" && <span>Clinic</span>}
          {data?.type.toString() === "home" && <span>Home</span>}
          {data?.type.toString() === "video" && <span>Video</span>}
          {data?.type.toString() === "phone" && <span>Phone</span>}

          <span>|</span>
          <span>
            {timeBetweenDates(Date.parse(data?.start), Date.parse(data?.end))}
          </span>
        </div>
      </div>
      <div className={`${classes.sessionFee} ${classes.tableRowDiv}`}>
        {data?.fee} {`(${data?.currency_code})`}
      </div>
    </div>
  );
};

export default MySessionsTableRow;
