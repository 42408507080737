import React, { useEffect, useState } from "react";
import classes from "../ProfileClient.module.scss";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { updateUserProfile } from "../../../../../store/actions";

const PreferencesForm = ({ profile, getProfile }) => {
  const dispatch = useDispatch();
  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    clearErrors,
    register,
  } = useForm({ reValidateMode: "onChange" });

  const [edit, setEdit] = useState(true);
  const [businessType, setBusinessType] = useState(
    profile.business_type ? Number(profile.business_type) : 1
  );
  const [vatRegistered, setVatRegistered] = useState(profile.is_vat_registered);

  const [therapySessionsPrefer, setTherapySessionsPrefer] = useState({
    offers_in_clinic_sessions: profile.offers_in_clinic_sessions,
    offers_home_visit_sessions: profile.offers_home_visit_sessions,
    offers_online_sessions: profile.offers_online_sessions,
    offers_phone_sessions: profile.offers_phone_sessions,
  });

  const [clinicFee, setClinicFee] = useState(profile.clinic_fee);
  const [homeFee, setHomeFee] = useState(profile.home_fee);
  const [audioFee, setAudioFee] = useState(profile.audio_fee);
  const [videoFee, setVideoFee] = useState(profile.video_fee);

  const onTherapySessionPrefChange = ({ target: { name, checked } }) => {
    setTherapySessionsPrefer((prev) => ({ ...prev, [name]: checked }));
    if (errors?.therapySessionsPrefer) {
      clearErrors("therapySessionsPrefer");
    }
  };

  const [clientServe, setClientServe] = useState({
    serves_adults: profile.serves_adults,
    serves_children: profile.serves_children,
    serves_couples: profile.serves_couples,
    serves_young_people: profile.serves_young_people,
  });

  const onClientServeChange = ({ target: { name, checked } }) => {
    setClientServe((prev) => ({ ...prev, [name]: checked }));
    if (errors?.clientServe) {
      clearErrors("clientServe");
    }
  };

  useEffect(() => {
    Object.keys(profile).map((e) => {
      setValue(e, profile[e]);

      if (e.toString() === "skills") {
        setValue(
          "skills",
          profile.skills.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      if (e.toString() === "professional_bodies") {
        setValue(
          "professional_bodies",
          profile.professional_bodies.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      if (e.toString() === "counselling_areas") {
        setValue(
          "counselling_areas",
          profile.counselling_areas.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      if (e.toString() === "therapy_types") {
        setValue(
          "therapy_types",
          profile.therapy_types.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      if (e.toString() === "language") {
        setValue(
          "language",
          profile.language.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      return null;
    });
  }, [profile]);

  const getTherapistTherapyTypes = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/therapy-types";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getLanguages = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/language/list";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getTaherapistSkil = async (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/skills";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getTaherapistProfessionalBodies = async (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/professional-bodies";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getTherapistCounsellingAreas = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/counselling-areas";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#F6F5F9",
      border: "none",
      outline: "none",
      marginLeft: "15px",
      width: "90%",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "12px",
    }),
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: "15px",
        width: "90%",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#62BFAE",
        color: "white",
        borderRadius: "15px",
        padding: "3px 4px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
      fontFamily: "L-SBold",
      fontSize: "10px",
      marginRight: "10px",
      textTransform: "uppercase",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#62BFAE",
      backgroundColor: "#D0ECE6",
      borderRadius: "50%",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#fff",
      },
    }),
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const onSubmit = (formData) => {
    const userProfile = {
      qualifications_training_experience:
        formData.qualifications_training_experience,
      skills: formData.skills,
      professional_bodies: formData.professional_bodies,
      counselling_areas: formData.counselling_areas,
      therapy_types: formData.therapy_types,
      language: formData.language,
      offers_in_clinic_sessions:
        therapySessionsPrefer.offers_in_clinic_sessions,
      offers_home_visit_sessions:
        therapySessionsPrefer.offers_home_visit_sessions,
      offers_online_sessions: therapySessionsPrefer.offers_online_sessions,
      offers_phone_sessions: therapySessionsPrefer.offers_phone_sessions,
      serves_children: clientServe.serves_children,
      serves_young_people: clientServe.serves_young_people,
      serves_adults: clientServe.serves_adults,
      serves_couples: clientServe.serves_couples,
      clinic_fee: clinicFee,
      home_fee: homeFee,
      audio_fee: audioFee,
      video_fee: videoFee,
      default_session_fee: formData.default_session_fee,
      business_type: businessType,
      business_name: formData.business_name,
      is_vat_registered: vatRegistered,
    };
    if (vatRegistered) {
      userProfile["vat_registration_number"] = formData.vat_registration_number;
    }
    dispatch(updateUserProfile(userProfile));
    handleEdit();
  };

  const handleCancle = () => {
    getProfile();
    handleEdit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.preferences}>
        <div className={classes.profileClient__title}>
          <h3 className={classes.profileClient__heading}>My Preferences</h3>
          <div>
            {edit && (
              <div onClick={handleEdit} className={classes.editProfile}>
                Edit
              </div>
            )}

            {!edit && (
              <div className={classes.saveButton}>
                <button type="submit" className={classes.editProfile}>
                  Save
                </button>
                <div
                  onClick={handleCancle}
                  className={`${classes.editProfile} ${classes.cancelBtn}`}
                >
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.preferences__content}>
          <div
            className={classes.basicInfo__addressDiv}
            style={{ marginBottom: "20px" }}
          >
            <p className={classes.addInputLabel}>
              Your qualifications, training and experience
            </p>
            <textarea
              {...register("qualifications_training_experience", {
                required: false,
                maxLength: 1000,
              })}
              type="text"
              rows="5"
              className={classes.profileInput}
              name="qualifications_training_experience"
              disabled={edit}
            />
            {errors?.qualifications_training_experience &&
              errors.qualifications_training_experience.type === "required" && (
                <p className="errorTxt">
                  Your qualifications, training and experience is required
                </p>
              )}

            {errors?.qualifications_training_experience &&
              errors.qualifications_training_experience.type ===
                "maxLength" && (
                <p className="errorTxt">
                  Max length exceeded (Max 1000 characters)
                </p>
              )}
          </div>

          <div className="form-group">
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span> Your skills</span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="skills"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTaherapistSkil}
                    placeholder="e.g. Critical thinking"
                    closeMenuOnSelect={true}
                    defaultOptions
                    isMulti
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.skills && errors.skills?.type === "required" && (
              <p style={{ paddingLeft: "3%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>

          <div className="form-group">
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>Your memberships with professional bodies</span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="professional_bodies"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTaherapistProfessionalBodies}
                    placeholder="e.g. BACP - Individual Member"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.professional_bodies &&
              errors.professional_bodies?.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className="form-group">
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>Counselling areas you deal with</span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="counselling_areas"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistCounsellingAreas}
                    placeholder="e.g. Bipolar disorder"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.counselling_areas &&
              errors.counselling_areas?.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className="form-group">
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>Therapies offered</span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="therapy_types"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistTherapyTypes}
                    placeholder="e.g. Cognitive behavioral therapies"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.therapy_types &&
              errors.therapy_types?.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className="form-group">
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span> Languages you can speak </span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="language"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getLanguages}
                    placeholder="e.g. English"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.language && errors.language?.type === "required" && (
              <p style={{ paddingLeft: "3%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>

          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Types of sessions you offer</span>
          </p>
          <div
            style={{ gridTemplateColumns: "1fr", gap: "8px", width: "80%" }}
            className={`row ml-2 mt-2 mb-3 ${classes.therapyTypeOptions}`}
          >
            <div
              className={` ${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  id="inClinicSwitch"
                  name="offers_in_clinic_sessions"
                  className={classes.styledCheckbox}
                  disabled={edit}
                  checked={therapySessionsPrefer.offers_in_clinic_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="inClinicSwitch"
                >
                  Clinic
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_in_clinic_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  required={
                    therapySessionsPrefer.offers_in_clinic_sessions === true
                      ? true
                      : false
                  }
                  type="number"
                  id="clinicInputId"
                  placeholder="Enter clinic fee"
                  value={clinicFee && clinicFee > 0 && clinicFee}
                  onChange={(event) => setClinicFee(event.target.value)}
                  disabled={edit}
                />
                <div className={classes.typeSessionFee_currency}>
                  {profile.currency_code}
                </div>
              </div>
            </div>

            <div
              className={`  ${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  id="homeVisitsSwitch"
                  name="offers_home_visit_sessions"
                  className={classes.styledCheckbox}
                  disabled={edit}
                  checked={therapySessionsPrefer.offers_home_visit_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="homeVisitsSwitch"
                >
                  Home
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_home_visit_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  required={
                    therapySessionsPrefer.offers_home_visit_sessions === true
                      ? true
                      : false
                  }
                  type="number"
                  id="homeInputId"
                  placeholder="Enter home fee"
                  value={homeFee && homeFee > 0 && homeFee}
                  onChange={(event) => setHomeFee(event.target.value)}
                  disabled={edit}
                />
                <div className={classes.typeSessionFee_currency}>
                  {profile.currency_code}
                </div>
              </div>
            </div>

            <div
              className={` ${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  className={classes.styledCheckbox}
                  id="onlineSwitch"
                  name="offers_online_sessions"
                  disabled={edit}
                  checked={therapySessionsPrefer.offers_online_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="onlineSwitch"
                >
                  Video
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_online_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  required={
                    therapySessionsPrefer.offers_online_sessions === true
                      ? true
                      : false
                  }
                  type="number"
                  id="videoInputId"
                  placeholder="Enter video Fee"
                  value={videoFee && videoFee > 0 && videoFee}
                  onChange={(event) => setVideoFee(event.target.value)}
                  disabled={edit}
                />
                <div className={classes.typeSessionFee_currency}>
                  {profile.currency_code}
                </div>
              </div>
            </div>

            <div
              className={`  ${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  className={classes.styledCheckbox}
                  id="phoneSwitch"
                  name="offers_phone_sessions"
                  disabled={edit}
                  checked={therapySessionsPrefer.offers_phone_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="phoneSwitch"
                >
                  Phone
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_phone_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  required={
                    therapySessionsPrefer.offers_phone_sessions === true
                      ? true
                      : false
                  }
                  type="number"
                  id="phoneInputId"
                  placeholder="Enter phone Fee"
                  value={audioFee && audioFee > 0 && audioFee}
                  onChange={(event) => setAudioFee(event.target.value)}
                  disabled={edit}
                />
                <div className={classes.typeSessionFee_currency}>
                  {profile.currency_code}
                </div>
              </div>
            </div>
            {errors?.therapySessionsPrefer &&
              errors.therapySessionsPrefer?.type === "required" && (
                <p style={{ paddingLeft: "4%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Types of clients you serve</span>
          </p>
          <div className={`row ml-2 mt-2 mb-3 ${classes.therapyTypeOptions}`}>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                id="serves_children"
                name="serves_children"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={clientServe.serves_children}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_children"
              >
                Children
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                id="serves_young_people"
                name="serves_young_people"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={clientServe.serves_young_people}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_young_people"
              >
                Young people
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                className={classes.styledCheckbox}
                id="serves_adults"
                name="serves_adults"
                disabled={edit}
                checked={clientServe.serves_adults}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_adults"
              >
                Adults
              </label>
            </div>

            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                className={classes.styledCheckbox}
                id="serves_couples"
                name="serves_couples"
                disabled={edit}
                checked={clientServe.serves_couples}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_couples"
              >
                Couples
              </label>
            </div>
            {errors?.clientServe && errors.clientServe?.type === "required" && (
              <p style={{ paddingLeft: "4%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>
          {/* <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Your availability</span>
          </p> */}
          {/*           
          <div
            className={`row ml-2 mt-2 mb-3 ${classes.therapyTypeOptions} ${classes.availabilityOptions}`}
          >
            {weekDays.map((weday) => {
              return (
                <div
                  key={weday.id}
                  className={`${classes.therapyTypeWrap} ${classes.rowFlex} mb-1 ml-3`}
                >
                  <label className={`${classes.subLabel} ${classes.topLabel}`}>
                    {weday.day}
                  </label>
                  <div>
                    {profile.availability_day
                      .filter((e) => e.title === weday.day)?.[0]
                      ?.["slots"]?.map((s) => {
                        const st = s?.start_time;

                        return (
                          <div key={s.id} className={classes.selectedTimesDiv}>
                            <p className={classes.selectedTimes}>
                              <Moment local format="hh:mm a">
                                {getFormatedTime(s?.start_time)}
                              </Moment>
                              <Moment local format="hh:mm a">
                                {getFormatedTime(s?.end_time)}
                              </Moment>
                            </p>
                            <button
                              type="button"
                              onClick={() => handleSlotDelete(s)}
                              className={classes.availableAddBtn}
                            >
                              <svg
                                className={classes.svgIcon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="409.898"
                                height="517.767"
                                viewBox="0 0 409.898 517.767"
                              >
                                <path
                                  id="Path_3"
                                  data-name="Path 3"
                                  d="M-1103.2-248.771H-1211.07v-21.573a32.394,32.394,0,0,0-32.361-32.361h-64.72a32.394,32.394,0,0,0-32.361,32.361v21.573H-1448.38a32.393,32.393,0,0,0-32.36,32.361,32.335,32.335,0,0,0,27.247,31.842l43.4,371.023a32.35,32.35,0,0,0,32.134,28.607h204.345a32.349,32.349,0,0,0,32.134-28.607l43.406-371.023a32.335,32.335,0,0,0,27.237-31.842A32.393,32.393,0,0,0-1103.2-248.771Zm-215.736-21.573a10.8,10.8,0,0,1,10.787-10.787h64.72a10.8,10.8,0,0,1,10.787,10.787v21.573h-86.294ZM-1360.6-163.587a10.832,10.832,0,0,1,11.542,9.967l22.965,315.093a10.794,10.794,0,0,1-9.967,11.552c-.27.011-.539.022-.8.022a10.791,10.791,0,0,1-10.755-10l-22.965-315.093A10.787,10.787,0,0,1-1360.6-163.587Zm74.019,325.847V-152.833a10.791,10.791,0,0,1,10.787-10.787A10.791,10.791,0,0,1-1265-152.833V162.26a10.791,10.791,0,0,1-10.787,10.787A10.791,10.791,0,0,1-1286.578,162.26Zm105.571-314.3-22.966,315.093a10.79,10.79,0,0,1-10.754,10c-.259,0-.529-.011-.8-.022a10.8,10.8,0,0,1-9.967-11.552l22.965-315.093a10.851,10.851,0,0,1,11.542-9.967A10.787,10.787,0,0,1-1181.007-152.045Z"
                                  transform="translate(1480.74 302.705)"
                                  fill="#ff5f5f"
                                />
                              </svg>
                            </button>
                          </div>
                        );
                      })}
                  </div>
                  <button
                    style={{ width: "30px", height: "30px" }}
                    onClick={() => handlePopup(weday.day)}
                    type="button"
                    className={classes.availableAddBtn}
                  >
                    <svg
                      className={classes.svgIcon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="820.056"
                      height="768.179"
                      viewBox="0 0 820.056 768.179"
                    >
                      <g
                        id="Group_1"
                        data-name="Group 1"
                        transform="translate(-147 -156)"
                      >
                        <rect
                          id="Rectangle_1"
                          data-name="Rectangle 1"
                          width="816.056"
                          height="764.179"
                          rx="76.512"
                          transform="translate(149 158)"
                          fill="#fff"
                          stroke="#fff"
                          strokeMiterlimit="10"
                          strokeWidth="4"
                        />
                        <path
                          id="Path_2"
                          data-name="Path 2"
                          d="M-2026.65-93.654H-2235.7V-302.7h-99.664V-93.654h-209.051V6.01h209.051V215.062h99.664V6.01h209.051Z"
                          transform="translate(2842.561 583.911)"
                          fill="#62bfae"
                        />
                        <g id="Group_2" data-name="Group 2">
                          <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M-2026.65-93.654H-2235.7V-302.7h-99.664V-93.654h-209.051V6.01h209.051V215.062h99.664V6.01h209.051Z"
                            transform="translate(2830.561 583.822)"
                            fill="#62bfae"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              );
            })}


            {isShowPopup && (
              <TimeInput
                availability={profile.availability_day || []}
                day={isShowPopup}
                handleCancel={handleCancel}
              />
            )}
          </div> */}

          <div className="form-group">
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>
                What is your default session fee?&nbsp;
                <span className={classes.defaultSessInfo}>
                  <svg
                    fill="#000000"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24px"
                    height="24px"
                  >
                    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                  </svg>

                  <small>This will be shown on your profile card</small>
                </span>
              </span>
            </p>

            <div className={classes.sessionFeeDiv}>
              <input
                {...register("default_session_fee", {
                  required: true,
                })}
                type="number"
                className={`form-control ${classes.sessionFeeInput}`}
                name="default_session_fee"
                disabled={edit}
              />
              <div className={`input-group-prepend ${classes.prepend}`}>
                <div className={`input-group-text ${classes.prependInner}`}>
                  {profile.currency_code}
                </div>
              </div>
            </div>
          </div>

          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Your business type</span>
          </p>
          <div
            className={`row ml-2 mt-2 ${classes.therapyTypeOptions} ${classes.mobileRes}`}
          >
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="privateLimitedRadio"
                name="business_type1"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={businessType === 1}
                onChange={() => setBusinessType(1)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="privateLimitedRadio"
              >
                Private limited
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="soleProprietorshipRadio"
                name="business_type2"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={businessType === 2}
                onChange={() => setBusinessType(2)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="soleProprietorshipRadio"
              >
                Sole Proprietorship
              </label>
            </div>

            {errors?.availableOn && errors.availableOn?.type === "required" && (
              <p style={{ paddingLeft: "4%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>
          <div
            className={classes.basicInfo__addressDiv}
            style={{ marginTop: "15px", marginBottom: "30px" }}
          >
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>Your business name</span>
            </p>

            <input
              {...register("business_name", {
                required: true,
              })}
              type="text"
              className={`${classes.profileInput} ${classes.businessName}`}
              name="business_name"
              disabled={edit}
            />
            {errors?.business_name && errors.business_name.type === "required" && (
              <p style={{ paddingLeft: "4%" }} className="errorTxt">
                business name is required
              </p>
            )}
          </div>
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>VAT registered</span>
          </p>
          <div className={`row ml-2 mt-2 mb-2 ${classes.therapyTypeOptions}`}>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="vatRegisteredNoRadio"
                name="vatRegisteredNoRadio"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={!vatRegistered}
                onChange={() => setVatRegistered(false)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="vatRegisteredNoRadio"
              >
                No
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="vatRegisteredYesRadio"
                name="vatRegisteredYesRadio"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={vatRegistered}
                onChange={() => setVatRegistered(true)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="vatRegisteredYesRadio"
              >
                Yes
              </label>
            </div>
          </div>

          {vatRegistered && (
            <div className={classes.basicInfo__addressDiv}>
              <p className={classes.listingTitle}>
                <span className={classes.mRight}>•</span>
                <span>VAT registration number</span>
              </p>

              <input
                {...register("vat_registration_number", {
                  required: vatRegistered,
                })}
                type="text"
                className={`${classes.profileInput} ${classes.businessName}`}
                name="vat_registration_number"
                disabled={edit && vatRegistered}
                placeholder="VAT registration number"
              />
              {errors?.vat_registration_number &&
                errors.vat_registration_number.type === "required" && (
                  <p className="errorTxt">
                    VAT registration number is required
                  </p>
                )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default PreferencesForm;
