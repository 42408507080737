import React from "react";
import NewEnquiriesListItem from "./NewEnquiriesListItem/NewEnquiriesListItem";
import classes from "../../../MySessions/MySessionsTable/MySesTable.module.scss";

const NewEnquiriesList = (props) => {
  return (
    <div className={classes.sessionTable} style={{ marginTop: "15px" }}>
      <div className={classes.sessionTable__head}>
        <div className={classes.sessionTable__head__rowOne}>
          <span> Client </span>
          <span> Date & Time </span>
          <span> Session Type </span>
          <span className={classes.sessionDuration}> Duration </span>
          <span className={classes.sessionNull}> </span>
        </div>
      </div>

      <div className={classes.sessionTable__body}>
        <div className={classes.sessionTable__body__row}>
          {props.sessions.length === 0 && (
            <div className={classes.noSessionDiv}>
              <p className={classes.noSessionText}> No Enquiry </p>
            </div>
          )}

          {props.sessions.length > 0 && (
            <>
              {props.sessions.map((item) => (
                <NewEnquiriesListItem
                  key={item.id}
                  session={item}
                  client={item.client}
                  user={props.user}
                  getSessions={props.getSessions}
                  homeBadgeUpdate={props.homeSession}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewEnquiriesList;
