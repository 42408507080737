import React, { useState } from "react";
import ComponentLoader from "../../../UI/ComponentLoader";
import classes from "../../Login/Login.module.scss";
import eyeIcon from "../../../../assets/icons/eyeIcon.svg";
import eyeHidden from "../../../../assets/icons/eyeHidden.svg";

const SignUpForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onEmailChangeHandler = (event) => {
    setEmail(event.target.value);
    props.onChangeHandler();
  };

  const onPasswordChangeHandler = (event) => {
    setPassword(event.target.value);
    props.onChangeHandler();
  };

  const onFirstNameChangeHandler = (event) => {
    setFirstName(event.target.value);
    props.onChangeHandler();
  };

  const onLastNameChangeHandler = (event) => {
    setLastName(event.target.value);
    props.onChangeHandler();
  };

  const onTermsAgreedChangeHandler = (event) => {
    setTermsAgreed(event.target.checked);
    props.onChangeHandler();
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    props.onSubmitHandler(email, password, firstName, lastName, termsAgreed);
  };

  return (
    <form onSubmit={onSubmitHandler} className={classes.signupForm}>
      <div className={classes.formField}>
        <input
          type="text"
          className={`${classes.firstName} ${
            props.errors && props.errors["first_name"] && "is-invalid"
          }`}
          id="firstNameInput"
          placeholder="First Name"
          onChange={onFirstNameChangeHandler}
        />
        {props.errors && props.errors["first_name"] && (
          <div className="invalid-feedback">{props.errors["first_name"]}</div>
        )}
      </div>
      <div className={classes.formField}>
        <input
          type="text"
          className={`${classes.firstName} ${
            props.errors && props.errors["last_name"] && "is-invalid"
          }`}
          id="lastNameInput"
          placeholder="Last Name"
          onChange={onLastNameChangeHandler}
        />
        {props.errors && props.errors["last_name"] && (
          <div className="invalid-feedback">{props.errors["last_name"]}</div>
        )}
      </div>
      <div className={classes.formField}>
        <input
          type="email"
          className={`${classes.firstName} ${
            props.errors && props.errors["email"] && "is-invalid"
          }`}
          id="emailInput"
          placeholder="Email"
          onChange={onEmailChangeHandler}
        />
        {props.errors && props.errors["email"] && (
          <div className="invalid-feedback">{props.errors["email"]}</div>
        )}
      </div>

      <div className={`${classes.formField} ${classes.passwordField}`}>
        <input
          type={passwordShown ? "text" : "password"}
          className={`${classes.firstName} ${
            props.errors && props.errors["password"] && "is-invalid"
          }`}
          id="passwordInput"
          placeholder="Password"
          onChange={onPasswordChangeHandler}
        />
        {passwordShown ? (
          <img
            onClick={togglePasswordVisibility}
            className={classes.signupEye}
            src={eyeIcon}
            alt=""
          />
        ) : (
          <img
            onClick={togglePasswordVisibility}
            className={classes.signupEye}
            src={eyeHidden}
            alt="/"
          />
        )}

        {props.errors && props.errors["password"] && (
          <div className="invalid-feedback">{props.errors["password"]}</div>
        )}
      </div>
      <div className={classes.termsAgreed}>
        <div className={`form-check ${classes.formCheck}`}>
          <input
            type="checkbox"
            id="termsInput"
            name="prefers_in_clinic_sessions"
            className={`form-check-input ${classes.checkBox} ${
              classes.styledCheckbox
            }  ${props.errors && props.errors["terms_agreed"] && "is-invalid"}`}
            onChange={onTermsAgreedChangeHandler}
          />

          <label
            className={`form-check-label ${classes.labelTxt} ${classes.topLabel} `}
            htmlFor="termsInput"
          >
            I have read and agree to the Mindsum
            <a
              className="ml-1"
              href={
                props.type === "therapist"
                  ? "https://www.mindsum.app/terms-for-therapists"
                  : "https://www.mindsum.app/terms-for-clients"
              }
              rel="noreferrer"
              target="_blank"
            >
              terms
            </a>{" "}
            and{" "}
            <a
              href="https://www.mindsum.app/privacy-cookie-policy"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>
          </label>
          {props.errors && props.errors["terms_agreed"] && (
            <div className="invalid-feedback">
              {props.errors["terms_agreed"]}
            </div>
          )}
        </div>
      </div>

      {!props.loading && (
        <button type="submit" className={classes.signupBtn}>
          Join Mindsum
        </button>
      )}
      {props.loading && <ComponentLoader />}
    </form>
  );
};

export default SignUpForm;
