import React from 'react';
import Chat from '../../../Chats/Chat/Chat';

const MyClientConversation = props => {
    return (
        <div className="row">
            <div className="col">
                <Chat height='400px'/>
            </div>
        </div>
    );
};

export default MyClientConversation;
