import React from "react";
import classes from "../CP.module.scss";
import PaymentMethodsListItem from "./PaymentMethodsListItem/PaymentMethodsListItem";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardSetupForm from "./CardSetupForm/CardSetupForm";
import { STRIPE_PK } from "../../../../../../../shared/constants";

const stripePromise = loadStripe(STRIPE_PK);

const PaymentMethodsList = (props) => {
  return (
    <div className={classes.paymentMethodList}>
      {props.paymentMethods.map((item) => (
        <PaymentMethodsListItem
          key={item.id}
          method={item}
          setAsDefault={props.setAsDefault}
          delete={props.delete}
          loading={props.defaultMethod}
          deleting={props.deleteCard}
        />
      ))}

      <div className={` ${classes.cpDiv}`}>
        <div className="">
          <Elements stripe={stripePromise}>
            <button
              className={` ${classes.addCardBtn}`}
              data-toggle="modal"
              data-target="#addCardModal"
            >
              <div className={classes.addCardInner}>
                <div className={classes.addCardDiv}>
                  <svg
                    className={classes.addCardIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21.379 21.379"
                  >
                    <g
                      id="Group_301"
                      data-name="Group 301"
                      transform="translate(-413.233 -834.048)"
                    >
                      <path
                        id="Path_195"
                        data-name="Path 195"
                        d="M6720.422,10278.048v19.379"
                        transform="translate(-6296.5 -9443)"
                        fill="none"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_196"
                        data-name="Path 196"
                        d="M0,0V19.379"
                        transform="translate(433.612 844.737) rotate(90)"
                        fill="none"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </div>
                <p className={classes.addCardText}> Add Payment Method </p>
              </div>
            </button>
            <CardSetupForm
              getPaymentMethods={props.getPaymentMethods}
              setAsDefault={props.setAsDefault}
            />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodsList;
