import React from "react";
import if_amex from "../../../../../../../../assets/images/if_amex.png";
import if_discover from "../../../../../../../../assets/images/if_discover.png";
import if_jcb from "../../../../../../../../assets/images/if_jcb.png";
import if_mastercard from "../../../../../../../../assets/icons/masterCard.svg";
import if_visa from "../../../../../../../../assets/icons/visaImg.svg";
import hoveredVisaImg from "../../../../../../../../assets/icons/hoveredVisaImg.svg";
import classes from "../../CP.module.scss";
import ComponentLoader from "../../../../../../../UI/ComponentLoader";

const PaymentMethodsListItem = (props) => {
  let brand_img;
  switch (props.method.brand) {
    case "amex":
      brand_img = if_amex;
      break;
    case "discover":
      brand_img = if_discover;
      break;
    case "jcb":
      brand_img = if_jcb;
      break;
    case "mastercard":
      brand_img = if_mastercard;
      break;
    case "visa":
      brand_img = if_visa;
      break;
    default:
      brand_img = if_visa;
      break;
  }

  return (
    <>
      <div className={classes.cardItemDiv}>
        <div className={classes.cardItemTop}>
          {props.method.is_default && (
            <h5 className={classes.defaultText}> Default </h5>
          )}

          {props.method.brand === "visa" && (
            <>
              <img
                src={brand_img}
                alt=""
                className={`${classes.cardTypeImg} ${classes.hideOnHover}`}
              />
              <img
                alt=""
                src={hoveredVisaImg}
                className={`${classes.cardTypeImg} ${classes.hoveredVisaImg}`}
              />
            </>
          )}
          {props.method.brand !== "visa" && (
            <img src={brand_img} alt="" className={classes.cardTypeImg} />
          )}
        </div>

        <div className={classes.cardItemCenter}>
          <div className={classes.expiryDate}>
            {props.method.exp_month}/{props.method.exp_year}
          </div>
        </div>

        <p className={classes.cardNumber}>
          <span>&#8226;&#8226;&#8226;&#8226;</span>&nbsp;
          <span>&#8226;&#8226;&#8226;&#8226;</span>&nbsp;
          <span>&#8226;&#8226;&#8226;&#8226;</span>&nbsp;
          {props.method.last4}
        </p>

        <div className={classes.cardItemBottom}>
          {!props.method.is_default && (
            <div className={classes.cardItemBottom}>
              {props.loading ? (
                <ComponentLoader />
              ) : (
                <button
                  className={classes.cardItemBtn}
                  type="button"
                  onClick={() => props.setAsDefault(props.method.id)}
                >
                  Set as default
                </button>
              )}

              {props.deleting ? (
                <ComponentLoader />
              ) : (
                <button
                  className={classes.cardItemBtn}
                  type="button"
                  onClick={() => props.delete(props.method.id)}
                >
                  Remove
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentMethodsListItem;
