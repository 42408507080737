import React, { useState, useEffect } from "react";
import classes from "./ProfileSettings.module.scss";
import CheckBox from "../../../../UI/checkBoxSlider/CheckBox";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import { clearAndLogout } from "../../../../../shared/utility";
import AlertMessage from "../../../../UI/Alert/AlertMessage";

const ClientProfileSettings = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [privacy, setPrivacy] = useState({
    client_account: "",
    is_profile_visible_for_therapists: true,
    hide_contact_info_from_therapists: false,
    can_therapists_send_me_messages: true,
  });
  const [is_inactive, setIs_inactive] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handlePrivacySettings = async (type) => {
    const token = localStorage.getItem("token");
    const url = `${BASE_URL}/${user.type}/privacy-settings`;

    var config = {
      method: "put",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        ...privacy,
        [type]: !privacy[type],
      },
    };

    await axios(config)
      .then((response) => {
        setPrivacy(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const handleIsInactiveAccount = async () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/inactive/" + user.id;

    var config = {
      method: "put",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        is_inactive: !is_inactive,
      },
    };

    await axios(config)
      .then((response) => {
        setIs_inactive(response.data.is_inactive);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const handleDelteAccount = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem("token");
    const url = BASE_URL + "/delete-account/" + user.id;

    var config = {
      method: "put",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    await axios(config)
      .then((response) => {
        clearAndLogout();
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const getUserPrivacySettings = async () => {
    const token = localStorage.getItem("token");
    const url = `${BASE_URL}/${user.type}/privacy-settings`;

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    await axios(config)
      .then((response) => {
        setPrivacy(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const getUserIsInactive = async () => {
    const token = localStorage.getItem("token");
    const url = `${BASE_URL}/inactive/${user.id}`;

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    await axios(config)
      .then((response) => {
        setIs_inactive(response.data.is_inactive);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  useEffect(() => {
    getUserPrivacySettings();
    getUserIsInactive();
  }, []);

  return (
    <div className={classes.profileSet}>
      <div className="col">
        {/* <div className={`row ${classes.borderBottom} my-3`}>
          <label className={classes.notification}>Notifications</label>
        </div>
        <div className="row">
          <div className={classes.listDiv}>
            <li className="">
              Important information about your account, payments and live
              sessions
            </li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label className={classes.checkBoxLabel} htmlFor="1Switch">
                      Email
                    </label>
                    <CheckBox id="1Switch" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.listDiv}>
            <li className="">
              Get notified when you have an unread message in your dashboard
            </li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label className={classes.checkBoxLabel} htmlFor="3Switch">
                      Email
                    </label>
                    <CheckBox id="3Switch" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.listDiv}>
            <li className="">Reminders about your upcoming sessions</li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label className={classes.checkBoxLabel} htmlFor="5Switch">
                      Email
                    </label>
                    <CheckBox id="5Switch" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.listDiv}>
            <li className="mr-5">
              Email about promotions, features and content from Mindsum
            </li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label className={classes.checkBoxLabel} htmlFor="7Switch">
                      Email
                    </label>
                    <CheckBox id="7Switch" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.listDiv}>
            <li className="">Desktop notifications sound</li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label
                      className={classes.checkBoxLabel}
                      htmlFor="9Switch"
                    ></label>
                    <CheckBox id="9Switch" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> */}
        <div className={`row ${classes.borderBottom} my-3`}>
          <label className={classes.notification}>Privacy</label>
        </div>
        <div className="row">
          {/* <div className={classes.listDiv}>
            <li className="">Hide my contact information from therapist</li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label
                      className={classes.checkBoxLabel}
                      htmlFor="inClinicSwitch"
                    ></label>
                    <CheckBox
                      onChange={() =>
                        handlePrivacySettings(
                          "hide_contact_info_from_therapists"
                        )
                      }
                      checked={privacy.hide_contact_info_from_therapists}
                      id="inClinicSwitch"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          <div className={classes.listDiv}>
            <li className="">Make my profile visible for therapists</li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label
                      className={classes.checkBoxLabel}
                      htmlFor="inClinicSwitch"
                    ></label>
                    <CheckBox
                      onChange={() =>
                        handlePrivacySettings(
                          "is_profile_visible_for_therapists"
                        )
                      }
                      checked={privacy.is_profile_visible_for_therapists}
                      id="inClinicSwitch"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.listDiv}>
            <li className="">
              Therapist should be able to send me a message through Mindsum
            </li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label
                      className={classes.checkBoxLabel}
                      htmlFor="inClinicSwitch"
                    ></label>
                    <CheckBox
                      onChange={() =>
                        handlePrivacySettings("can_therapists_send_me_messages")
                      }
                      checked={privacy.can_therapists_send_me_messages}
                      id="inClinicSwitch"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={`row ${classes.borderBottom} my-3`}>
          <label className={classes.notification}>Account</label>
        </div>
        <div className="row">
          <div className={classes.listDiv}>
            <li className="">Make my account inactive</li>

            <div className="">
              <form className="form-inline">
                <div className={classes.cbFormGroup}>
                  <div className={classes.checkboxDiv}>
                    <label
                      className={classes.checkBoxLabel}
                      htmlFor="inClinicSwitch"
                    >
                      <CheckBox
                        checked={is_inactive}
                        onChange={handleIsInactiveAccount}
                        id="inClinicSwitch"
                      />
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={classes.profileSetBtnDiv}>
          <button
            // onClick={handleDelteAccount}
            onClick={() => setDeleteConfirm(true)}
            className={classes.profileSetBtn}
          >
            Delete my account
          </button>
        </div>
      </div>

      {deleteConfirm && (
        <AlertMessage
          msg="Are you sure you want to delete your account?"
          handleNo={() => setDeleteConfirm(false)}
          handleYes={handleDelteAccount}
        />
      )}
    </div>
  );
};

export default ClientProfileSettings;
