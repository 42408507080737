import { useState, useEffect } from "react";
import classes from "./TimeInput.module.scss";
import enGB from "date-fns/locale/en-GB";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import axios from "axios";
import { BASE_URL } from "../../../shared/constants";
import { addAvailabilitySlot, getUserProfile } from "../../../store/actions";
import { setHours, setMinutes } from "date-fns";

const TimeInput = ({ handleCancel, day, getProfile }) => {
  const profile = useSelector((state) => state.userProfile.profile);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState("");

  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);

  const currentDayData = profile.availability_day.filter(
    (e) => e.title.toLowerCase() === day.toLowerCase()
  );

  const handleSave = async () => {
    setIsLoading(true);
    if (error) {
      setError(() => "");
    }
    if (
      startDate.getTime() === endDate.getTime() ||
      startDate.getTime() > endDate.getTime()
    ) {
      setIsLoading(false);
      setError(() => "Please select the correct time");

      return null;
    }
    // Api to create slot

    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/availability";

    const data = {
      day: day,
      is_available: true,
      start_time: moment(startDate).format("HH:mm:ss"),
      end_time: moment(endDate).format("HH:mm:ss"),
      profile_id: currentDayData.length ? currentDayData[0].id : "anything",
    };

    try {
      await axios
        .post(url, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (currentDayData.length) {
            const availability = profile.availability_day.map((wday) => {
              if (wday.title === day) {
                return {
                  ...wday,
                  slots: [...wday.slots, response.data],
                };
              }
              setIsLoading(false);
              return wday;
            });
            dispatch(
              addAvailabilitySlot({
                ...profile,
                availability_day: availability,
              })
            );
          }

          if (!currentDayData.length) {
            dispatch(getUserProfile());
          }

          handleCancel();
          getProfile();
        });
    } catch (error) {
      setIsLoading(false);
      setError(() => error.response.data.error);
    }
  };

  // const seletedHours = [];

  // currentDayData?.[0]?.slots?.forEach((e) => {
  //   for (
  //     let i = parseInt(e.start_time.slice(0, 2));
  //     i < parseInt(e.end_time.slice(0, 2)) + 1;
  //     i++
  //   ) {
  //     seletedHours.push(setHours(setMinutes(new Date(), 0), i));
  //   }
  // });

  return (
    <div className={classes.timeInputDiv}>
      <div className={classes.timeInputDiv__innerDiv}>
        <p className={classes.topText}>
          {`Select your availability timing for ${day}`}
        </p>

        <div className={classes.firstInputDiv}>
          <DatePicker
            calendarClassName={classes.timeCalendar}
            selected={startDate}
            locale={"en-GB"}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="From"
            timeFormat="HH:mm"
            selectsStart
            startDate={startDate}
            endDate={endDate}
            inline
            fixedHeight
          />
          <DatePicker
            calendarClassName={classes.timeCalendar}
            selected={endDate}
            locale={"en-GB"}
            onChange={(date) => setEndDate(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="To"
            timeFormat="HH:mm"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            inline
            fixedHeight
          />
        </div>

        <p className={classes.errorMessage}> {error} </p>

        {isLoading ? (
          <div className={`${classes.loader} ${classes.btnDiv}`}>
            <span className={classes.loader__spin}></span>
          </div>
        ) : (
          <div className={classes.btnDiv}>
            <button type="button" onClick={handleSave}>
              Save
            </button>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeInput;
