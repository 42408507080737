import React from "react";
import { Link } from "react-router-dom";
import classes from "./Login.module.scss";
import clientImage from "../../../assets/images/loginImg.jpg";
import clientVideo from "../../../assets/video/Mindsum_login.mp4";
import logo from "../../../assets/images/mindsum-logo.svg";
import LoginForm from "./LoginForm/LoginForm";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Alert from "../../UI/Alert/Alert";
import FooterLink from "../../footerLink/FooterLink";

const Login = (props) => {
  const onChangeHandler = () => {
    props.onResetState();
  };

  const onSubmitHandler = (email, password) => {
    props.onLogin(email, password);
  };

  let errorMessage = null;
  if (props.error) {
    errorMessage = <Alert level="danger" message={props.error} />;
  }
  return (
    <div className={classes.login}>
      <div className={classes.firstDiv}>
        <div className={classes.welcomeSec}>
          <a href="https://www.mindsum.app/">
            <img className={classes.mindsumLogo} src={logo} alt="" />
          </a>
          <h1 className={classes.welcome}> Welcome </h1>
          <p className={classes.signIn}> Sign In to continue </p>
        </div>
        <div className={classes.loginDiv}>
          {errorMessage}
          <LoginForm
            onSubmitHandler={onSubmitHandler}
            onChangeHandler={onChangeHandler}
            loading={props.loading}
          />

          <Link className={classes.forgetPassword} to="/forgot-password">
            Forgot password?
          </Link>
        </div>

        <div className={classes.loginFooter}>
          <div className={classes.content}>
            <p className={classes.notReg}> Not registered yet?</p>
            <div className={classes.btnDiv}>
              <Link className={classes.joinBtn} to="/signup-as-therapist">
                <div className={classes.joinLink}> join as a therapist </div>
              </Link>

              <Link className={classes.joinBtn} to="/signup-as-client">
                <div className={classes.joinLink}> book a therapist </div>
              </Link>
            </div>

            <div className={classes.loginFooterLink}>
              <FooterLink />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.secondDiv}>
        <video
          muted
          autoPlay
          loop
          src={clientVideo}
          playsInline
          poster={clientImage}
          className={classes.loginVideo}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password) => dispatch(actions.login(email, password)),
    onResetState: () => dispatch(actions.authResetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
