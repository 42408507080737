import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  error: null,
  loading: false,
  profile: null,
  navigate: false,
};

const getUserProfileStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    profile: null,
  });
};

const getUserProfileSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    profile: action.profile,
  });
};

const getUserProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    profile: null,
  });
};

const updateUserProfileStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    profile: null,
    navigate: false,
  });
};

const updateUserProfileSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    profile: action.profile,
    navigate: true,
  });
};

const updateUserProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    profile: null,
    navigate: false,
  });
};

const updateUserProfileResetNavigate = (state, action) => {
  return updateObject(state, {
    navigate: false,
  });
};

const addUserExternalWebsiteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    profile: null,
  });
};

const addUserExternalWebsiteSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    profile: action.profile,
  });
};

const addUserExternalWebsiteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    profile: null,
  });
};

const addAvailabilitySlot = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    profile: action.profile,
  });
};
const deleteAvailabilitySlot = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    profile: action.profile,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE_START:
      return getUserProfileStart(state, action);
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return getUserProfileSuccess(state, action);
    case actionTypes.GET_USER_PROFILE_FAIL:
      return getUserProfileFail(state, action);
    case actionTypes.UPDATE_USER_PROFILE_START:
      return updateUserProfileStart(state, action);
    case actionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return updateUserProfileSuccess(state, action);
    case actionTypes.UPDATE_USER_PROFILE_FAIL:
      return updateUserProfileFail(state, action);
    case actionTypes.UPDATE_USER_PROFILE_RESET_NAVIGATE:
      return updateUserProfileResetNavigate(state, action);
    case actionTypes.ADD_USER_EXTERNAL_WEBSITE_START:
      return addUserExternalWebsiteStart(state, action);
    case actionTypes.ADD_USER_EXTERNAL_WEBSITE_SUCCESS:
      return addUserExternalWebsiteSuccess(state, action);
    case actionTypes.ADD_USER_EXTERNAL_WEBSITE_FAIL:
      return addUserExternalWebsiteFail(state, action);
    case actionTypes.ADD_AVAILABILITY_SLOT:
      return addAvailabilitySlot(state, action);
    case actionTypes.DELETE_AVAILABILITY_SLOT:
      return deleteAvailabilitySlot(state, action);

    default:
      return state;
  }
};

export default reducer;
