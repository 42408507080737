import React, { useEffect, useState } from "react";
import classes from "../ProfileClient.module.scss";
import CheckBox from "../../../../UI/checkBoxSlider/CheckBox";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { updateUserProfile } from "../../../../../store/actions";

const PreferencesForm = ({ profile, getProfile }) => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
  } = useForm({ reValidateMode: "onChange" });

  const [edit, setEdit] = useState(true);

  const [therapySessionsPrefer, setTherapySessionsPrefer] = useState({
    prefers_in_clinic_sessions: profile.prefers_in_clinic_sessions,
    prefers_home_visit_sessions: profile.prefers_home_visit_sessions,
    prefers_online_sessions: profile.prefers_online_sessions,
    prefers_phone_sessions: profile.prefers_phone_sessions,
  });
  const onTherapySessionPrefChange = ({ target: { name, checked } }) => {
    setTherapySessionsPrefer((prev) => ({ ...prev, [name]: checked }));
    if (errors?.therapySessionsPrefer) {
      clearErrors("therapySessionsPrefer");
    }
  };

  const [shouldTherapistBeInMyCity, setShouldTherapistBeInMyCity] = useState(
    profile.should_therapist_be_in_my_city
  );

  const [needsFinancialAssistance, setNeedsFinancialAssistance] = useState(
    profile.needs_financial_assistance
  );

  useEffect(() => {
    if (profile?.counselling_areas_looking_for.length > 0) {
      setValue(
        "counselling_areas_looking_for",
        profile.counselling_areas_looking_for.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }
    if (profile?.therapy_types_looking_for.length > 0) {
      setValue(
        "therapy_types_looking_for",
        profile.therapy_types_looking_for.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }

    if (profile?.language.length > 0) {
      setValue(
        "language",
        profile.language.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }
  }, [profile]);

  const getTherapistTherapyTypes = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/therapy-types";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getTherapistCounsellingAreas = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/counselling-areas";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getLanguages = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/language/list";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#F6F5F9",
      border: "none",
      outline: "none",
      marginLeft: "15px",
      width: "90%",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "12px",
    }),
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: "15px",
        width: "90%",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#62BFAE",
        color: "white",
        borderRadius: "15px",
        padding: "3px 4px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
      fontFamily: "L-SBold",
      fontSize: "10px",
      marginRight: "10px",
      textTransform: "uppercase",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#62BFAE",
      backgroundColor: "#D0ECE6",
      borderRadius: "50%",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#fff",
      },
    }),
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const onSubmit = (formData) => {
    let checkedPrefer = 0;

    Object.keys(therapySessionsPrefer).map((e) => {
      if (therapySessionsPrefer[e] === false) {
        checkedPrefer++;
      }
      return null;
    });

    if (checkedPrefer === 4) {
      setError("therapySessionsPrefer", { type: "required" });
    } else if (checkedPrefer <= 3) {
      const userProfile = {
        prefers_in_clinic_sessions:
          therapySessionsPrefer.prefers_in_clinic_sessions,
        prefers_home_visit_sessions:
          therapySessionsPrefer.prefers_home_visit_sessions,
        prefers_online_sessions: therapySessionsPrefer.prefers_online_sessions,
        prefers_phone_sessions: therapySessionsPrefer.prefers_phone_sessions,
        should_therapist_be_in_my_city: shouldTherapistBeInMyCity,
        counselling_areas_looking_for: formData.counselling_areas_looking_for,
        therapy_types_looking_for: formData.therapy_types_looking_for,
        language: formData.language,
        needs_financial_assistance: needsFinancialAssistance,
      };
      dispatch(updateUserProfile(userProfile));
      handleEdit();
    }
  };

  const handleCancle = () => {
    if (profile?.counselling_areas_looking_for.length > 0) {
      setValue(
        "counselling_areas_looking_for",
        profile.counselling_areas_looking_for.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }
    if (profile?.therapy_types_looking_for.length > 0) {
      setValue(
        "therapy_types_looking_for",
        profile.therapy_types_looking_for.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }

    if (profile?.language.length > 0) {
      setValue(
        "language",
        profile.language.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }

    getProfile();
    handleEdit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.preferences}>
        <div className={classes.profileClient__title}>
          <h3 className={classes.profileClient__heading}>My Preferences</h3>
          <div>
            {edit && user.type === "client" && (
              <div onClick={handleEdit} className={classes.editProfile}>
                Edit
              </div>
            )}

            {!edit && (
              <div className={classes.saveButton}>
                <button type="submit" className={classes.editProfile}>
                  Save
                </button>
                <div
                  onClick={handleCancle}
                  className={`${classes.editProfile} ${classes.cancelBtn}`}
                >
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.preferences__content}>
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Types of therapy sessions you prefer?</span>
          </p>
          <div className={`row ml-4 mt-2 ${classes.therapyTypeOptions}`}>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                id="inClinicSwitch"
                name="prefers_in_clinic_sessions"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={therapySessionsPrefer.prefers_in_clinic_sessions}
                onChange={onTherapySessionPrefChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="inClinicSwitch"
              >
                Clinic
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                id="homeVisitsSwitch"
                name="prefers_home_visit_sessions"
                className={classes.styledCheckbox}
                disabled={edit}
                checked={therapySessionsPrefer.prefers_home_visit_sessions}
                onChange={onTherapySessionPrefChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="homeVisitsSwitch"
              >
                Home
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                className={classes.styledCheckbox}
                id="onlineSwitch"
                name="prefers_online_sessions"
                disabled={edit}
                checked={therapySessionsPrefer.prefers_online_sessions}
                onChange={onTherapySessionPrefChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="onlineSwitch"
              >
                Video
              </label>
            </div>

            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                className={classes.styledCheckbox}
                id="phoneSwitch"
                name="prefers_phone_sessions"
                disabled={edit}
                checked={therapySessionsPrefer.prefers_phone_sessions}
                onChange={onTherapySessionPrefChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="phoneSwitch"
              >
                Phone
              </label>
            </div>

            {errors?.therapySessionsPrefer &&
              errors.therapySessionsPrefer?.type === "required" && (
                <p style={{ paddingLeft: "4%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className={classes.therapistInCity}>
            <label className={classes.labelOne} htmlFor="basedInYourCitySwitch">
              <span className={classes.mRight}>•</span>
              <span>Do you prefer the therapist to be based in your city?</span>
            </label>
            <div style={{ marginTop: "5px" }}>
              <CheckBox
                id="basedInYourCitySwitch"
                checked={shouldTherapistBeInMyCity}
                disabled={edit}
                onChange={(event) =>
                  setShouldTherapistBeInMyCity(event.target.checked)
                }
              />
            </div>
          </div>

          <div className="form-group">
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>Any specific therapies you are looking for?</span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="therapy_types_looking_for"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistTherapyTypes}
                    placeholder="e.g. cognitive therapies"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.therapy_types_looking_for &&
              errors.therapy_types_looking_for?.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className={classes.specificTherapies}>
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>
                Are you looking for a therapist with expertise in a specific
                mental condition?
              </span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="counselling_areas_looking_for"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistCounsellingAreas}
                    placeholder="e.g. bipolar disorder"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.counselling_areas_looking_for &&
              errors.counselling_areas_looking_for?.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className={classes.specificTherapies}>
            <p className={classes.listingTitle}>
              <span className={classes.mRight}>•</span>
              <span>Languages you can speak</span>
            </p>

            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <Controller
              name="language"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getLanguages}
                    placeholder="e.g. English"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    isDisabled={edit}
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.language && errors.language?.type === "required" && (
              <p style={{ paddingLeft: "3%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>

          <div className={classes.therapistInCity}>
            <label
              className={classes.labelOne}
              htmlFor="financialAssistanceSwitch"
            >
              <span className={classes.mRight}> • </span>
              <span>Do you need financial assistance for therapies? </span>
            </label>
            <div style={{ marginTop: "5px" }}>
              <CheckBox
                id="financialAssistanceSwitch"
                checked={needsFinancialAssistance}
                disabled={edit}
                onChange={(event) =>
                  setNeedsFinancialAssistance(event.target.checked)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PreferencesForm;
