import React, { Fragment } from "react";
import { connect } from "react-redux";
import ClientPaymentSettings from "./ClientPaymentSettings/ClientPaymentSettings";
import TherapistPaymentSettings from "./TherapistPaymentSettings/TherapistPaymentSettings";

const PaymentSettings = (props) => {
  return (
    <Fragment>
      {props.user.type === "client" && <ClientPaymentSettings />}
      {props.user.type === "therapist" && <TherapistPaymentSettings />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PaymentSettings);
