import React, { Fragment, useEffect } from "react";
import classes from "../FP.module.scss";
import svgImg1 from "../../../../assets/images/fp1.svg";

import ProgressBar from "../../../UI/ProgressBar/ProgressBar";

const ForgotPasswordForm = (props) => {
  let emailInput = null;
  useEffect(() => {
    emailInput.focus();
  }, []);

  return (
    <Fragment>
      <div className={classes.svgImgDiv}>
        <img className={classes.svgImg} src={svgImg1} alt="" />
        <p className={classes.paraText}>Please enter your email address. </p>
        <p className={classes.paraText}>
          We will send you a password reset link.
        </p>
      </div>

      <form onSubmit={props.onSubmit} className={classes.forgotPasswordForm}>
        <div className="">
          <input
            ref={(input) => {
              emailInput = input;
            }}
            type="email"
            className={classes.resetInput}
            id="emailInput"
            placeholder="Registered Email"
            value={props.email}
            onChange={(event) => props.onChangeHandler(event.target.value)}
          />
        </div>
        {props.loading ? (
          <ProgressBar />
        ) : (
          <button type="submit" className={classes.resetPasswordBtn}>
            Reset Password
          </button>
        )}
      </form>
    </Fragment>
  );
};

export default ForgotPasswordForm;
