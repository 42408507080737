import React, { Fragment } from "react";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import logo from "../../../assets/images/mindsum-logo-new.svg";
import classes from "./RP.module.scss";
import FooterLink from "../../footerLink/FooterLink";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Alert from "../../UI/Alert/Alert";
import ProgressBar from "../../UI/ProgressBar/ProgressBar";
import { Link } from "react-router-dom";

const ResetPassword = (props) => {
  const onChangeHandler = (password) => {
    props.onResetState();
  };

  const submitHandler = (password) => {
    const code = new URLSearchParams(props.location.search).get("code");
    props.onResetPassword(password, code);
  };

  const form = (
    <ResetPasswordForm
      onSubmit={submitHandler}
      onChangeHandler={onChangeHandler}
      loading={props.loading}
    />
  );

  let content = form;

  if (props.error) {
    content = (
      <Fragment>
        <Alert level="danger" message={props.error} />
        {form}
      </Fragment>
    );
  } else if (props.message) {
    content = (
      <Fragment>
        <Alert level="success" message={props.message} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Link
            to="/login"
            className="btn btn-secondary"
            onClick={props.onResetState}
          >
            Login
          </Link>
        </div>
      </Fragment>
    );
  } else if (props.loading) {
    content = <ProgressBar />;
  }

  return (
    <>
      <div className={classes.resetPasswordLogo}>
        <a href="https://www.mindsum.app/">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className={classes.resetPassword}>
        <div className="">
          <div className={classes.topComp}>
            <h3 className={classes.rpHeader}>Reset password?</h3>
            {content}
          </div>
        </div>
        <div className={classes.bottomComp}>
          <FooterLink />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.resetPassword.loading,
    error: state.resetPassword.error,
    message: state.resetPassword.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (password, code) =>
      dispatch(actions.resetPassword(password, code)),
    onResetState: () => dispatch(actions.requestPasswordResetCodeResetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
