import React, { useEffect, useState } from "react";
import classes from "./Settings.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PaymentSettings from "./PaymentSettings/PaymentSettings";
import ClientProfileSettings from "./ProfileSettings/ClientProfileSettings";
import TherapistProfileSet from "./ProfileSettings/TherapistProfileSet";
import FooterLink from "../../../footerLink/FooterLink";

const Settings = (props) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    if (props.location.pathname === props.match.url + "/payment") {
      setCurrentTab("payment");
    } else if (props.location.pathname === props.match.url + "/profile") {
      setCurrentTab("profile");
    } else {
      history.replace("/settings/payment");
    }
  }, [props]);

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    history.replace("/settings/" + tab);
  };

  return (
    <div className={classes.settings}>
      <div className={`${classes.bcgSettings} bcg`}>
        <div className={`settingsHead ${classes.settingsTab}`}>
          <div className="navItem">
            <a
              className={`navLink ${classes.active} ${
                currentTab === "payment" && "activeTab"
              }`}
              href="#"
              onClick={(event) => handleTabClick(event, "payment")}
            >
              Payment Settings
            </a>
          </div>

          <div className="navItem">
            <a
              className={`navLink ${classes.active} ${
                currentTab === "profile" && "activeTab"
              }`}
              href="#"
              onClick={(event) => handleTabClick(event, "profile")}
            >
              Profile Settings
            </a>
          </div>
        </div>
        <div className={`tab-content ${classes.resHeight}`}>
          <div className={` tab-pane ${currentTab === "payment" && "active"}`}>
            <PaymentSettings />
          </div>
        </div>

        <div className={`tab-content ${classes.resHeight}`}>
          <div className={` tab-pane ${currentTab === "profile" && "active"}`}>
            {user.type === "therapist" ? (
              <TherapistProfileSet />
            ) : (
              <ClientProfileSettings />
            )}
          </div>
        </div>
      </div>
      <div className={classes.settingsFooter}>
        <FooterLink />
      </div>
    </div>
  );
};

export default Settings;
