import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./Home.module.scss";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import animationData2 from "../../../../assets/Welcome.json";
import UpcomingSessions from "./UpcomingSessions/UpcomingSessions";
import NewEnquiries from "./NewEnquiries/NewEnquiries";
import FooterLink from "../../../footerLink/FooterLink";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import axios from "axios";
import { BASE_URL } from "../../../../shared/constants";
import moment from "moment";
import qs from "qs";
import { clearAndLogout } from "../../../../shared/utility";

const Home = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [currentTab, setCurrentTab] = useState(
    location.search.includes("new_enquiries")
      ? "new_enquiries"
      : "upcoming_sessions"
  );
  const [banner, setBanner] = useState(false);
  const [session, setSession] = useState([]);

  const handleTabClick = (event, tab) => {
    event.preventDefault();

    setCurrentTab(tab);
  };

  useEffect(() => {
    if (!localStorage.getItem("show")) {
      setBanner(() => true);
      setTimeout(() => {
        setBanner(() => false);
      }, 2000);

      localStorage.setItem("show", true);
    }
  }, []);

  useEffect(() => {
    if (props.user.type === "therapist") {
      getDataLength();

      const intervalId = setInterval(getDataLength, 30000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  const getDataLength = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          from: moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
          to: moment(new Date())
            .startOf("day")
            .utc()
            .add(1, "year")
            .format("YYYY-MM-DD HH:mm:ss"),
          status: "tentative",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        setSession(response.data.count);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const handleNewEnquireTab = (event) => {
    event.preventDefault();
    handleTabClick(event, "new_enquiries");

    history.replace({
      pathname: "/",
      search: "new_enquiries",
    });
  };

  const handleUpcomingTab = (event) => {
    event.preventDefault();
    handleTabClick(event, "upcoming_sessions");

    history.replace({
      pathname: "/",
      search: "upcoming_sessions",
    });
  };

  return (
    <div className={classes.home}>
      {banner && (
        <div className={classes.welcomeBanner}>
          <div className={classes.welcomeBannerDiv}>
            <div className={classes.lottieDiv}>
              <lottie-player
                className={classes.lottie}
                src={animationData2}
                autoplay
                mode="normal"
              />
            </div>
            <h4 className={classes.welcomeText}>{props.user.first_name}</h4>
          </div>
        </div>
      )}

      <div className={`bcg ${classes.home__innerDiv}`}>
        {props.user.type === "therapist" && (
          <div className={classes.homeTab}>
            <div className={`settingsHead`}>
              <div className="navItem">
                <a
                  style={{ cursor: "pointer" }}
                  className={`navLink ${classes.active} ${
                    currentTab === "upcoming_sessions" && "activeTab"
                  }`}
                  onClick={handleUpcomingTab}
                >
                  Upcoming sessions
                </a>
              </div>

              <div className="navItem">
                <a
                  style={{ cursor: "pointer" }}
                  className={`navLink ${classes.active} ${
                    currentTab === "new_enquiries" && "activeTab"
                  }`}
                  onClick={handleNewEnquireTab}
                >
                  New enquiries
                  {session > 0 && (
                    <span className={classes.NewEnquiriesBadge}>
                      <span className={classes.NewEnquiriesNumber}>
                        {session}
                      </span>
                    </span>
                  )}
                </a>
              </div>
            </div>
          </div>
        )}

        {currentTab === "upcoming_sessions" ? (
          <UpcomingSessions user={props.user} />
        ) : (
          <NewEnquiries user={props.user} getDataLength={getDataLength} />
        )}
      </div>

      <div className={classes.homeFooter}>
        <FooterLink />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserProfile: () => dispatch(actions.getUserProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
