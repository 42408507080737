import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    externalWebsiteTypes: null
};

const getExternalWebsiteTypesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        externalWebsiteTypes: null
    });
};

const getExternalWebsiteTypesSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        externalWebsiteTypes: action.externalWebsiteTypes
    });
};

const getExternalWebsiteTypesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        externalWebsiteTypes: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EXTERNAL_WEBSITE_TYPES_START: return getExternalWebsiteTypesStart(state, action);
        case actionTypes.GET_EXTERNAL_WEBSITE_TYPES_SUCCESS: return getExternalWebsiteTypesSuccess(state, action);
        case actionTypes.GET_EXTERNAL_WEBSITE_TYPES_FAIL: return getExternalWebsiteTypesFail(state, action);
        default: return state;
    }
};

export default reducer;
