import React, { Fragment, useEffect } from "react";
import * as actions from "../../../store/actions";
import logo from "../../../assets/images/mindsum-logo-new.svg";
import verifyImg from "../../../assets/images/verify-email.svg";
import { connect } from "react-redux";
import ComponentLoader from "../../UI/ComponentLoader";
import Alert from "../../UI/Alert/Alert";
import classes from "./VerifyEmail.module.scss";
import FooterLink from "../../footerLink/FooterLink";
import { Link } from "react-router-dom";

const VerifyEmail = (props) => {
  const { onVerifyEmail } = props;

  useEffect(() => {
    const page = new URLSearchParams(props.location.search).get("page");
    const code = new URLSearchParams(props.location.search).get("code");
    if (page === "payment") {
      props.onRequestEmailVerificationCode();
    } else {
      onVerifyEmail(code);
    }
  }, [onVerifyEmail]);

  let content = <ComponentLoader />;
  if (!props.loading) {
    if (props.error) {
      content = (
        <Fragment>
          <Alert
            level="danger"
            customClass={classes.errorMsg}
            message={props.error}
          />

          {props.isAuthenticated && (
            <button
              className={classes.verifyEmailBtn}
              onClick={props.onRequestEmailVerificationCode}
            >
              Send another email verification code
            </button>
          )}
        </Fragment>
      );
    } else if (props.message) {
      content = (
        <Fragment>
          <Alert
            level="success"
            customClass={classes.successMsg}
            message={props.message}
          />
          <Link to="/" className={classes.goHomeBtn}>
            <div>Go Home</div>
          </Link>
          <p style={{ textAlign: "center" }}>
            Please check your spam folder in case <br />
            you don’t see the verification email in your inbox.
          </p>
        </Fragment>
      );
    }
  }
  return (
    <>
      <div className={classes.verifyEmailLogo}>
        <a href="https://www.mindsum.app/">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className={classes.verifyEmail}>
        <div className={classes.verifyEmailTop}>
          <h4 className={classes.verifyEmailText}> Verify Email </h4>
          <div className={classes.verifyImgDiv}>
            <img src={verifyImg} alt="" />
          </div>
          {content}
        </div>
        <div>
          <FooterLink />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.verifyEmail.loading,
    error: state.verifyEmail.error,
    message: state.verifyEmail.message,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onVerifyEmail: (code) => dispatch(actions.verifyEmail(code)),
    onRequestEmailVerificationCode: () =>
      dispatch(actions.requestEmailVerificationCode()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
