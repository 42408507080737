import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import CheckBox from "../../../../UI/checkBoxSlider/CheckBox";
import classes from "./StepTwoTherapistForm.module.scss";
import { useForm, Controller } from "react-hook-form";

import AsyncSelect from "react-select/async";

const StepTwoForm = (props) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
  } = useForm({ reValidateMode: "onChange" });

  const [therapySessionsPrefer, setTherapySessionsPrefer] = useState({
    prefers_in_clinic_sessions: props.profile.prefers_in_clinic_sessions,
    prefers_home_visit_sessions: props.profile.prefers_home_visit_sessions,
    prefers_online_sessions: props.profile.prefers_online_sessions,
    prefers_phone_sessions: props.profile.prefers_phone_sessions,
  });

  const onTherapySessionPrefChange = ({ target: { name, checked } }) => {
    setTherapySessionsPrefer((prev) => ({ ...prev, [name]: checked }));
    if (errors?.therapySessionsPrefer) {
      clearErrors("therapySessionsPrefer");
    }
  };

  const [shouldTherapistBeInMyCity, setShouldTherapistBeInMyCity] = useState(
    props.profile.should_therapist_be_in_my_city
  );

  const [needsFinancialAssistance, setNeedsFinancialAssistance] = useState(
    props.profile.needs_financial_assistance
  );

  const getTherapistCounsellingAreas = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/counselling-areas";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getLanguages = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/language/list";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  useEffect(() => {
    if (props?.profile?.counselling_areas_looking_for.length > 0) {
      setValue(
        "counselling_areas_looking_for",
        props.profile.counselling_areas_looking_for.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }
    if (props?.profile?.therapy_types_looking_for.length > 0) {
      setValue(
        "therapy_types_looking_for",
        props.profile.therapy_types_looking_for.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }

    if (props?.profile?.language.length > 0) {
      setValue(
        "language",
        props.profile.language.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }
  }, []);

  const getTherapistTherapyTypes = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/therapy-types";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const onSubmit = (formData) => {
    let checkedPrefer = 0;

    Object.keys(therapySessionsPrefer).map((e) => {
      if (therapySessionsPrefer[e] === false) {
        checkedPrefer++;
      }
      return e;
    });

    if (checkedPrefer === 4) {
      setError("therapySessionsPrefer", { type: "required" });
    } else if (checkedPrefer <= 3) {
      const userProfile = {
        prefers_in_clinic_sessions:
          therapySessionsPrefer.prefers_in_clinic_sessions,
        prefers_home_visit_sessions:
          therapySessionsPrefer.prefers_home_visit_sessions,
        prefers_online_sessions: therapySessionsPrefer.prefers_online_sessions,
        prefers_phone_sessions: therapySessionsPrefer.prefers_phone_sessions,
        should_therapist_be_in_my_city: shouldTherapistBeInMyCity,
        counselling_areas_looking_for: formData.counselling_areas_looking_for,
        therapy_types_looking_for: formData.therapy_types_looking_for,
        language: formData.language,
        needs_financial_assistance: needsFinancialAssistance,
      };
      props.onUpdateUserProfile(userProfile);
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#F6F5F9",
      border: "none",
      outline: "none",
      marginLeft: "15px",
      width: "90%",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "12px",
    }),
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: "15px",
        width: "90%",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#62BFAE",
        color: "white",
        borderRadius: "15px",
        padding: "3px 4px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
      fontFamily: "L-SBold",
      fontSize: "10px",
      marginRight: "10px",
      textTransform: "uppercase",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#62BFAE",
      backgroundColor: "#D0ECE6",
      borderRadius: "50%",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#fff",
      },
    }),
  };

  return (
    <div className={classes.formDiv2}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formTwo}>
        <h5 className={classes.title}>Your preferences</h5>
        <p className={classes.subTitle}>
          You can always change your preferences under your profile
        </p>
        <div className={`${classes.therapyType}`}>
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>
            <label className={classes.labelOne}>
              Types of therapy sessions you prefer
            </label>
            <br />
          </div>

          <div className={`row ml-4 mt-2 ${classes.therapyTypeOptions}`}>
            <div className={` ${classes.therapyTypeWrap}`}>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="inClinicSwitch"
                  name="prefers_in_clinic_sessions"
                  className={classes.styledCheckbox}
                  checked={therapySessionsPrefer.prefers_in_clinic_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="inClinicSwitch"
                >
                  Clinic
                </label>
              </div>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="homeVisitsSwitch"
                  name="prefers_home_visit_sessions"
                  className={classes.styledCheckbox}
                  checked={therapySessionsPrefer.prefers_home_visit_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="homeVisitsSwitch"
                >
                  Home
                </label>
              </div>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <div className="form-group">
                <input
                  type="checkbox"
                  className={classes.styledCheckbox}
                  id="onlineSwitch"
                  name="prefers_online_sessions"
                  checked={therapySessionsPrefer.prefers_online_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="onlineSwitch"
                >
                  Video
                </label>
              </div>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <div className="form-group">
                <input
                  type="checkbox"
                  className={classes.styledCheckbox}
                  id="phoneSwitch"
                  name="prefers_phone_sessions"
                  checked={therapySessionsPrefer.prefers_phone_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="phoneSwitch"
                >
                  Phone
                </label>
              </div>
            </div>
          </div>
          {errors?.therapySessionsPrefer &&
            errors.therapySessionsPrefer?.type === "required" && (
              <p style={{ paddingLeft: "4%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
        </div>

        <div className={classes.therapistInCity}>
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>
            <label className={classes.labelOne} htmlFor="basedInYourCitySwitch">
              Do you prefer the therapist to be based in your city?
            </label>
          </div>

          <div>
            <CheckBox
              id="basedInYourCitySwitch"
              checked={shouldTherapistBeInMyCity}
              onChange={(event) =>
                setShouldTherapistBeInMyCity(event.target.checked)
              }
            />
          </div>
        </div>

        <div className="form-group" style={{ marginTop: "2%" }}>
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>
            <label
              htmlFor="therapiesOfferedInput"
              className={`mb-0 ${classes.labelOne} `}
            >
              Any specific therapies your are looking for?
            </label>
          </div>
          <small className="form-text text-muted mb-2 ml-3">
            Type and select from the list
          </small>
          <div className={classes.controllerDiv}>
            <Controller
              name="therapy_types_looking_for"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistTherapyTypes}
                    placeholder="e.g. cognitive behavioral therapies"
                    closeMenuOnSelect={true}
                    isMulti
                    defaultOptions
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.therapy_types_looking_for &&
              errors.therapy_types_looking_for?.type === "required" && (
                <p style={{ paddingLeft: "4%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>
        </div>

        <div className="form-group" style={{ marginTop: "4%" }}>
          <div>
            <div className={classes.titleFlex}>
              <div className={classes.listStyle}> </div>
              <label
                htmlFor="counsellingAreasInput"
                className={`mb-0 ${classes.labelOne}`}
              >
                Are you looking for a therapist with expertise in a specific
                mental health condition?
              </label>
            </div>
            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <div className={classes.controllerDiv}>
              <Controller
                name="counselling_areas_looking_for"
                rules={{ required: true }}
                control={control}
                render={({ field }) => {
                  return (
                    <AsyncSelect
                      {...field}
                      cacheOptions
                      loadOptions={getTherapistCounsellingAreas}
                      placeholder="e.g. bipolar disorder"
                      closeMenuOnSelect={true}
                      isMulti
                      defaultOptions
                      styles={colourStyles}
                    />
                  );
                }}
              />
              {errors?.counselling_areas_looking_for &&
                errors.counselling_areas_looking_for?.type === "required" && (
                  <p style={{ paddingLeft: "4%" }} className="errorTxt">
                    Please select at least one option
                  </p>
                )}
            </div>
          </div>
        </div>

        <div className="form-group" style={{ marginTop: "4%" }}>
          <div>
            <div className={classes.titleFlex}>
              <div className={classes.listStyle}> </div>
              <label
                htmlFor="languageInput"
                className={`mb-0 ${classes.labelOne}`}
              >
                Languages you can speak?
              </label>
            </div>
            <small className="form-text text-muted mb-2 ml-3">
              Type and select from the list
            </small>

            <div className={classes.controllerDiv}>
              <Controller
                name="language"
                rules={{ required: true }}
                control={control}
                render={({ field }) => {
                  return (
                    <AsyncSelect
                      {...field}
                      cacheOptions
                      loadOptions={getLanguages}
                      placeholder="e.g. English"
                      closeMenuOnSelect={true}
                      isMulti
                      defaultOptions
                      styles={colourStyles}
                    />
                  );
                }}
              />
              {errors?.language && errors.language?.type === "required" && (
                <p style={{ paddingLeft: "4%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
            </div>
          </div>
        </div>

        <div className={classes.therapistInCity} style={{ marginTop: "4%" }}>
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>

            <label
              className={classes.labelOne}
              htmlFor="financialAssistanceSwitch"
            >
              Do you need financial assistance for therapies?
            </label>
          </div>
          <div>
            <CheckBox
              id="financialAssistanceSwitch"
              checked={needsFinancialAssistance}
              onChange={(event) =>
                setNeedsFinancialAssistance(event.target.checked)
              }
            />
          </div>
        </div>

        <div className={classes.needsBtnDiv}>
          <button type="submit" className={classes.needNextBtn}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepTwoForm;
