import React, { useState } from "react";
import logo from "../../../../assets/images/mindsum-logo.svg";
import heroImg from "../../../../assets/images/profileBuilderImg.jpg";
import classes from "../CreateProfile.module.scss";
import FooterLink from "../../../footerLink/FooterLink";
import { updateObject } from "../../../../shared/utility";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import ComponentLoader from "../../../UI/ComponentLoader";

const StepZeroTherapist = (props) => {
  const [loading, setLoading] = useState(false);

  const handleSkip = () => {
    setLoading((prev) => true);
    const updatedUser = updateObject(props.user, {
      is_signup_complete: true,
    });
    props.onUpdateUser(updatedUser);
  };

  return loading ? (
    <ComponentLoader />
  ) : (
    <div className={classes.createProfile}>
      <div className={classes.firstDiv}>
        <div className={classes.logoDiv}>
          <img src={logo} className={classes.mindsumLogo} alt="" />

          {props.isAuthenticated &&
            (!props.user.is_signup_complete ||
              !props.user.is_profile_approved) && (
              <p style={{ fontSize: "20px", margin: "0", marginTop: "10px" }}>
                <Link to="logout">Logout</Link>
              </p>
            )}
        </div>
        <div className={classes.introDiv}>
          <h3 className={classes.name}> Welcome {props.firstName} </h3>
          <p className={classes.intro}>
            Mindsum is the platform where certified mental health professionals
            and therapists can provide you counselling and therapies. To ensure
            we can provide you the right support and connect you with the right
            professionals, please provide information about you and your needs.
          </p>
          <p className={classes.intro}>
            You can always add the information later under “PROFILE” tab.
          </p>
          <div className={classes.proceedText}>
            <Link to="/about-you" className={classes.proceedBtn}>
              <span className={classes.proceed}>Proceed</span>
            </Link>
          </div>
          <button onClick={handleSkip} className={classes.skipNow}>
            Skip for now
          </button>

          <p className={classes.recommText}>
            (We strongly recommend to complete your profile to view best matched
            therapists)
          </p>
        </div>
        <div className={classes.createProfileFooter}>
          <FooterLink />
        </div>
      </div>

      <div className={classes.secondDiv}>
        <img src={heroImg} className={classes.heroImg} alt="" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.auth.user.first_name,
    user: state.auth.user,
    profile: state.userProfile.profile,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUser: (updatedUser) => dispatch(actions.updateUser(updatedUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepZeroTherapist);
