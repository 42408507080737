import React, { Fragment, useEffect, useState } from "react";
import classes from "./Balance.module.scss";
import { BASE_URL } from "../../../../../shared/constants";
import axios from "axios";
import ComponentLoader from "../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../shared/utility";

const Balance = (props) => {
  const [balances, setBalances] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBalances();
  }, []);

  const getBalances = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/reports/therapist-balance";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setBalances(response.data);
        setLoading(false);
        
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const payout = (currency) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/payment/payout-to-therapist";

    axios
      .post(
        url,
        {
          currency: currency,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        getBalances();
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  return (
    <>
      {loading && <ComponentLoader />}

      {!loading && balances && (
        <div className={classes.balance}>
          <div className={classes.balance__header}>
            <h3 className={classes.balance__header__title}>
              Available Balance
            </h3>
          </div>

          {balances.available.map((balance) => (
            <div key={balance.currency} className={classes.balanceDiv}>
              <div className={classes.amountDiv}>
                <h4 className={classes.amount}>
                  {balance.amount}&nbsp;{balance.currency}
                </h4>
              </div>
              {balance.payable && (
                <div className={classes.payoutBtnDiv}>
                  <button
                    className={classes.payoutBtn}
                    onClick={() => payout(balance.currency)}
                  >
                    Pay Out
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Balance;
