import classes from "./Footer.module.scss";
import { useSelector } from "react-redux";

const FooterLink = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className={classes.footerLinks}>
      <a
        className={classes.footerLink}
        href="https://www.mindsum.app/about"
        rel="noreferrer"
        target="_blank"
      >
        About
      </a>
      <div className={classes.brRight}></div>

      <a
        className={classes.footerLink}
        href="https://mindsum.freshdesk.com/support/solutions/80000201126"
        rel="noreferrer"
        target="_blank"
      >
        Help
      </a>
      <div className={classes.brRight}></div>
      <a
        className={classes.footerLink}
        href={
          user?.type === "therapist"
            ? "https://www.mindsum.app/terms-for-therapists"
            : "https://www.mindsum.app/terms-for-clients"
        }
        rel="noreferrer"
        target="_blank"
      >
        terms
      </a>
      <div className={classes.brRight}></div>
      <a
        className={classes.footerLink}
        href="https://www.mindsum.app/privacy-cookie-policy"
        rel="noreferrer"
        target="_blank"
      >
        Privacy Policy
      </a>
    </div>
  );
};

export default FooterLink;
