import React from "react";
import classes from "./Message.module.scss";

const Message = (props) => {
  const alertLevelClass = props.ownMessage ? "alert-primary" : "alert-dark";

  return (
    <div className="">
      <React.Fragment>
        {props.ownMessage && <div className="" />}
        <div className="">
          <div style={{ display: "flow-root" }}>
            <div
              className={` ${alertLevelClass} ${classes.Alert} ${
                props.ownMessage && classes.Own
              } mb-1`}
            >
              {props.message.text}
            </div>
          </div>
        </div>
        {!props.ownMessage && <div className="" />}
      </React.Fragment>
    </div>
  );
};

export default Message;
