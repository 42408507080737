import React, { useEffect, useState } from "react";
import ProfileImage from "../../../../UI/ProfileImageAndBasicInfo/ProfileImage/ProfileImage";
import classes from "../ProfileClient.module.scss";
import { useDispatch } from "react-redux";
import { updateUser, getUserProfile } from "../../../../../store/actions";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../../../../shared/constants";
import axios from "axios";

const BasicInfoForm = ({ profile, getProfile }) => {
  const [edit, setEdit] = useState(true);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ reValidateMode: "onChange" });

  useEffect(() => {
    Object.keys(profile.user).map((e) => {
      setValue(e, profile.user[e]);
      return null;
    });
  }, []);

  const onSubmit = (formData) => {
    dispatch(
      updateUser({
        first_name: formData.first_name,
        last_name: formData.last_name,
      })
    );
    handleEdit();
  };

  const handleCancle = () => {
    Object.keys(profile.user).map((e) => {
      setValue(e, profile.user[e]);
      return null;
    });
    getProfile();
    handleEdit();
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleProfileImageChange = async (event) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + `/user/` + profile.user.id;

    const formData = new FormData();
    formData.append(
      "profile_image",
      event.target.files[0],
      `${profile.user.id}_${event.target.files[0].name}`
    );
    formData.append("first_name", profile.user.first_name);
    formData.append("last_name", profile.user.last_name);
    formData.append("is_signup_complete", true);

    await axios
      .put(url, formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .catch((err) => {});

    getProfile();
    dispatch(getUserProfile());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.basicInfo}>
        <div className={classes.profileClient__title}>
          <h3 className={classes.profileClient__heading}>Basic Information</h3>
          <div>
            {edit && (
              <div onClick={handleEdit} className={classes.editProfile}>
                Edit
              </div>
            )}
            {!edit && (
              <div className={classes.saveButton}>
                <button type="submit" className={classes.editProfile}>
                  Save
                </button>
                <div
                  onClick={handleCancle}
                  className={`${classes.editProfile} ${classes.cancelBtn}`}
                >
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.basicInfo__content}>
          <div className={classes.profileImgDiv}>
            {loading ? (
              <div className={`${classes.loader} ${classes.btnDiv}`}>
                <span className={classes.loader__spin}></span>
              </div>
            ) : (
              <label htmlFor="profileimg" className={classes.profileLabel}>
                <ProfileImage src={profile?.user?.profile_image} />

                <input
                  style={{ display: "none" }}
                  id="profileimg"
                  name="profileimg"
                  type="file"
                  onChange={handleProfileImageChange}
                />

                <div className={classes.imageHovered}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.038"
                    height="20"
                    viewBox="0 0 23.038 23"
                  >
                    <path
                      id="Path_157"
                      data-name="Path 157"
                      d="M936.555-917.72a1.194,1.194,0,0,1,2.388,0v6.147a3.145,3.145,0,0,1-3.135,3.135H920.582a3.145,3.145,0,0,1-3.135-3.135V-926.9a3.145,3.145,0,0,1,3.135-3.135h6.551a1.194,1.194,0,0,1,0,2.388h-6.551a.749.749,0,0,0-.747.747v15.327a.749.749,0,0,0,.747.747h15.226a.749.749,0,0,0,.747-.747v-6.147Zm.695-13.435L940.2-928.2a.973.973,0,0,1,0,1.372l-9.724,9.724-5,.705.653-5.013,9.742-9.742a.973.973,0,0,1,1.372,0Z"
                      transform="translate(-917.447 931.438)"
                      fill="#fff"
                    />
                  </svg>
                </div>
              </label>
            )}
          </div>

          <div className={classes.basicDetail}>
            <div className={`${classes.usernameDiv} ${classes.mobRes}`}>
              <div className={classes.profileInputDiv}>
                <div className={classes.inputWrapper}>
                  <input
                    {...register("first_name", { required: true })}
                    className={classes.profileInput}
                    type="text"
                    name="first_name"
                    disabled={edit}
                  />

                  {errors?.first_name &&
                    errors.first_name.type === "required" && (
                      <p className="errorTxt">First Name is required</p>
                    )}
                </div>

                <div className={classes.inputWrapper}>
                  <input
                    {...register("last_name", { required: true })}
                    className={classes.profileInput}
                    type="text"
                    name="last_name"
                    disabled={edit}
                  />

                  {errors?.last_name &&
                    errors.last_name.type === "required" && (
                      <p className="errorTxt"> Last Name is required</p>
                    )}
                </div>
              </div>
            </div>
            <div className={classes.usernameDiv}>
              <div className={classes.emailWrapper}>
                <input
                  {...register("email", { required: true })}
                  type="text"
                  className={classes.profileInput}
                  name="email"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BasicInfoForm;
