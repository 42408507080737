import React, { Fragment, useEffect, useState } from "react";
import classes from "./TherapistPayment.module.scss";
import { BASE_URL, STRIPE_PK } from "../../../../../../shared/constants";
import axios from "axios";
import { Link } from "react-router-dom";
import ComponentLoader from "../../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../../shared/utility";
import BankAccountSetupForm from "./BankAccountSetupForm/BankAccountSetupForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(STRIPE_PK);

const TherapistPaymentSettings = (props) => {
  const [stripeAccount, setStripeAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState("");

  useEffect(() => {
    getAccount();
  }, []);

  const getAccountLink = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/payment/account-link";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getAccount = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/payment/account";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setStripeAccount(response.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }

        if (
          error?.response?.data?.error ===
          "Permission denied: Please, verify your email to perform this action"
        ) {
          setError(() => "Please, verify your email to perform this action");
        }
      });
  };

  const setExternalAccount = (bank_account_token) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/payment/account/external-account";

    axios
      .post(
        url,
        {
          bank_account_token: bank_account_token,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setStripeAccount(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const onToggle = () => {
    setError(() => "");
  };

  return (
    <Fragment>
      {loading && <ComponentLoader />}

      {!loading && stripeAccount && (
        <div className={classes.therapistPayment}>
          <div className={classes.payoutDetails}>
            {stripeAccount.external_accounts &&
              stripeAccount.external_accounts.data[0] && (
                <div className={classes.payoutDetails__card}>
                  <div className={classes.firstRow}>
                    <div className={classes.firstRow__label}>
                      <p className={classes.labelName}> Holder Name </p>
                      <p className={classes.labelName}> Sort Code </p>
                    </div>

                    <div className={classes.firstRow__nameData}>
                      <p className={classes.dataText}>
                        {
                          stripeAccount.external_accounts.data[0]
                            .account_holder_name
                        }
                      </p>

                      <p className={classes.dataText}>
                        {stripeAccount.external_accounts.data[0].routing_number}
                      </p>
                    </div>
                  </div>

                  <div className={classes.secondRow}>
                    <p className={classes.accountNumber}> Account Number </p>

                    <p className={classes.numberDigit}>
                      <span>&#8226;&#8226;&#8226;&#8226;</span>&nbsp;
                      <span>&#8226;&#8226;&#8226;&#8226;</span>&nbsp;
                      {stripeAccount.external_accounts.data[0].last4}
                    </p>
                  </div>
                </div>
              )}
          </div>

          <div className={classes.payoutBtn}>
            <button
              className={classes.addPayoutBtn}
              data-toggle="modal"
              data-target="#addBankAccountModal"
            >
              Add Payout Details
            </button>
            <Elements stripe={stripePromise}>
              <BankAccountSetupForm
                setExternalAccount={setExternalAccount}
                getAccountLink={getAccountLink}
              />
            </Elements>
          </div>
        </div>
      )}

      {error && (
        <div className={classes.errorPopup}>
          <div className={classes.errorPopup__innerDiv}>
            <div onClick={onToggle} className={classes.crossIconDiv}>
              <svg
                className={classes.crossIcon}
                xmlns="http://www.w3.org/2000/svg"
                width="25.731"
                height="25.731"
                viewBox="0 0 25.731 25.731"
              >
                <g
                  id="Group_226"
                  data-name="Group 226"
                  transform="translate(2559.582 -8520.719) rotate(45)"
                >
                  <line
                    id="Line_21"
                    data-name="Line 21"
                    y2="30.389"
                    transform="translate(4233.354 7819.761)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="3"
                  />
                  <path
                    id="Path_4397"
                    data-name="Path 4397"
                    d="M0,0H30.389"
                    transform="translate(4218.16 7834.955)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </div>

            <p className={classes.errorText}>{error}</p>
            <Link className={classes.verifyBtn} to="/verify-email?page=payment">
              send verification email
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TherapistPaymentSettings;
