import React, { Fragment, useEffect, useState } from "react";
import BasicInfoForm from "./clientProfileForm/BasicInfoForm";
import classes from "./ProfileClient.module.scss";
import FooterLink from "../../../footerLink/FooterLink";
import ComponentLoader from "../../../UI/ComponentLoader";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { BASE_URL } from "../../../../shared/constants";
import { clearAndLogout } from "../../../../shared/utility";

import PersonalInfoForm from "./clientProfileForm/PersonalInfoForm";
import PreferencesForm from "./clientProfileForm/PreferencesForm";
import AlertDialog from "../../../UI/Alert/AlertDialog";

const ProfileClient = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [profile, setProfile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  let { id } = useParams();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = (props) => {
    // setLoading(true);
    setProfile(null);

    const token = localStorage.getItem("token");
    const url = BASE_URL + "/client/" + id + "/profile";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
        if (error.response.data.error) {
          setShowAlert(true);
        }
      });
  };

  const handleDeactiveAccount = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/admin/account/status/" + id;

    var config = {
      method: "put",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        status: !status,
      },
    };

    await axios(config)
      .then((response) => {
        setLoading(false);
        setStatus(response.data.status);
        setSuccessMsg(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAccountStatus = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/admin/account/status/" + id;

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
      // data: {
      //   status: !status,
      // },
    };

    await axios(config)
      .then((response) => {
        setLoading(false);
        setStatus(response.data.is_active);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAccountStatus();
  }, [status]);

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <Fragment>
      {showAlert && (
        <AlertDialog msg="This profile is private" onToggle={handleCancel} />
      )}

      {successMsg && (
        <AlertDialog msg={successMsg} onToggle={() => setSuccessMsg("")} />
      )}

      {!profile && <ComponentLoader />}
      {profile && (
        <div className={classes.profileClient}>
          <div className={`bcg ${classes.ProfileClientInnerDiv}`}>
            <div className={classes.profileClientDiv}>
              {user?.type === "admin" && (
                <div className={classes.approveProfile}>
                  <button
                    onClick={handleDeactiveAccount}
                    type="button"
                    style={{ color: status === true ? "#FF5F5F" : "#62BFAE" }}
                    className={classes.accountActivate}
                  >
                    {status === true ? "Deactivate" : "Activate"} this account
                  </button>

                  {loading && <ComponentLoader />}
                </div>
              )}

              <BasicInfoForm getProfile={getProfile} profile={profile} />
              <PersonalInfoForm getProfile={getProfile} profile={profile} />
              <PreferencesForm getProfile={getProfile} profile={profile} />
            </div>
          </div>

          <div className={classes.profileClient__footer}>
            <FooterLink />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProfileClient;
