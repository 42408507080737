import classes from "../QNA/QNA.module.scss";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import enGB from "date-fns/locale/en-GB";

import qs from "qs";
import moment from "moment";
import Moment from "react-moment";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_URL } from "../../../../../shared/constants";
import FooterLink from "../../../../footerLink/FooterLink";
import ComponentLoader from "../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../shared/utility";

const columns = [
  {
    field: "first_name",
    headerName: "First Name",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`questionClientName`}>
            {params.getValue(params.id, "first_name") || "-"}
          </p>
        </div>
      );
    },
  },
  {
    field: "last_name",
    headerName: "Last Name",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`questionClientName`}>
            {params.getValue(params.id, "last_name") || "-"}
          </p>
        </div>
      );
    },
  },

  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`questionClientEmail`}>
            {params.getValue(params.id, "email") || "-"}
          </p>
        </div>
      );
    },
  },
  {
    field: "signup_datetime",
    headerName: "Join Date",
    type: "dateTime",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <Moment local format="DD MMM YYYY hh:mm A">
          {params.value}
        </Moment>
      );
    },
  },

  {
    field: "is_profile_approved",
    headerName: "Approval Status",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <p className={`questionClientStatus`}>
          {params.value === false ? (
            <span className={`questionClientNotPub`}>Pending</span>
          ) : (
            <span className={`questionClientPublished`}> Approved </span>
          )}
        </p>
      );
    },
  },

  {
    field: "is_active",
    headerName: "Account Status",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <p className={`questionClientStatus`}>
          {params.value === true ? (
            <span className={` questionClientPublished`}>Active</span>
          ) : (
            <span className={`questionClientNotPub`}> Inactive </span>
          )}
        </p>
      );
    },
  },

  {
    field: "is_inactive",
    headerName: "Profile Status",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <p className={`questionClientStatus`}>
          {params.value === true ? (
            <span className={`questionClientNotPub`}>Inactive</span>
          ) : (
            <span className={`questionClientPublished`}> Active </span>
          )}
        </p>
      );
    },
  },
];

const Therapists = () => {
  const contentBodyRef = useRef(null);
  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);
  const history = useHistory();

  const { search } = useLocation();
  const [details, setDetails] = useState([]);

  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const [questions, setQuestions] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const [currentTab, setCurrentTab] = useState(
    (search.includes("all") && "all") ||
      (search.includes("past30days") && "past30days") ||
      (search.includes("past1year") && "past1year") ||
      (search.includes("selectDates") && "selectDates")
  );

  const [startDate, setStartDate] = useState(
    params.from ? new Date(params.from) : null
  );
  const [endDate, setEndDate] = useState(
    params.to ? new Date(params.to) : null
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentTab === "selectDates") {
      contentBodyRef.current.style.height = "59vh";
    } else {
      contentBodyRef.current.style.height = "65vh";
    }
  });

  useEffect(() => {
    if (currentTab === "past30days") {
      getOneMonthData();
    }

    if (currentTab === "past1year") {
      getOneYearData();
    }

    if (currentTab === "all") {
      getAll();
    }
    if (currentTab === "selectDates" && startDate && endDate) {
      getSelectData();
    }
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className={classes.topFlex}>
          <div className={classes.topTabs}>
            <ul
              className={`nav nav-pills ${classes.navPills}`}
              id="myTab"
              role="tablist"
            >
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={`${currentTab === "all" && "adminActiveTab"} ${
                    classes.navLink
                  }`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#all"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "all")}
                >
                  All
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={` ${
                    currentTab === "past30days" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#past30days"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "past30days")}
                >
                  Past 30 days
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={`${
                    currentTab === "past1year" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#past1year"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "past1year")}
                >
                  Past 1 year
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={` ${
                    currentTab === "selectDates" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#selectDates"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "selectDates")}
                >
                  Select dates
                </a>
              </li>
            </ul>
          </div>
        </div>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    setCurrentTab(tab);

    if (tab === "all") {
      history.replace({
        pathname: "/therapists",
        search: "all",
      });

      getAll();
    }

    if (tab === "past30days") {
      history.replace({
        pathname: "/therapists",
        search: "past30days",
      });

      getOneMonthData();
    }

    if (tab === "past1year") {
      history.replace({
        pathname: "/therapists",
        search: "past1year",
      });

      getOneYearData();
    }

    if (tab === "selectDates") {
      setDetails([]);
      history.replace({
        pathname: "/therapists",
        search: "selectDates",
      });

      getSelectData();
    }
  };

  const getAll = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/therapist`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
        params: {
          ordering: "-signup_datetime",
        },
      });

      setQuestions(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const getOneMonthData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/therapist`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
        params: {
          signup_datetime__date__range: `${moment(new Date())
            .startOf("day")
            .utc()
            .subtract(30, "day")
            .format("YYYY-MM-DD")},${moment(new Date())
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD")}`,
          ordering: "-signup_datetime",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });

      setQuestions(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const getOneYearData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/therapist`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
        params: {
          signup_datetime__date__range: `${moment(new Date())
            .startOf("day")
            .utc()
            .subtract(365, "day")
            .format("YYYY-MM-DD")},${moment(new Date())
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD")}`,
          ordering: "-signup_datetime",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });

      setQuestions(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const getSelectData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/therapist`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
        params: {
          signup_datetime__date__range: `${moment(startDate)
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD")},${moment(endDate)
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD")}`,
          ordering: "-signup_datetime",
        },
      });

      setQuestions(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const onPageChange = async (page) => {
    setIsLoading(() => true);
    try {
      const response = await axios.get(
        page > currentPage ? questions.next : questions.previous,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );

      setQuestions(response.data);
      setCurrentPage(page);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.qna}>
      <div className={`bcg ${classes.qna__innerDiv}`}>
        {currentTab === "selectDates" && (
          <>
            <div className={classes.selectDates}>
              <DatePicker
                calendarClassName={classes.firstCalender}
                className={classes.firstInput}
                onChange={(date) => setStartDate(date)}
                selected={startDate}
                placeholderText="Start Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />
              <p className={classes.to}> To </p>

              <DatePicker
                className={classes.secondInput}
                calendarClassName={classes.secondCalender}
                onChange={(date) => setEndDate(date)}
                selected={endDate}
                placeholderText="End Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />

              <button onClick={getSelectData} className={classes.GetReports}>
                Get Report
              </button>
            </div>
          </>
        )}
        <div className={classes.contentDiv}>
          <div ref={contentBodyRef} className={classes.contentBody}>
            <DataGrid
              rows={questions.results}
              onRowClick={(e) => {
                history.push({
                  pathname: `/therapist/${e.id}/profile`,
                  state: { data: e.row },
                });
              }}
              columns={columns}
              components={{
                Toolbar: CustomToolbar,
              }}
              pageSize={50}
              rowCount={questions.count}
              pagination
              paginationMode="server"
              onPageChange={onPageChange}
              loading={loading}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              disableColumnMenu
              rowHeight={80}
            />
          </div>
        </div>
      </div>
      <div className={classes.footerLink}>
        <FooterLink />
      </div>

      {isLoading && <ComponentLoader />}
    </div>
  );
};

export default Therapists;
