import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../../shared/constants";
import axios from "axios";
import enGB from "date-fns/locale/en-GB";
import DatePicker, { registerLocale } from "react-datepicker";
import qs from "qs";
import classes from "../../Reports/Reports-detail/ReportDetail.module.scss";
import moment from "moment";
import ComponentLoader from "../../../../UI/ComponentLoader";
import FooterLink from "../../../../footerLink/FooterLink";
import { clearAndLogout } from "../../../../../shared/utility";
import AlertDialog from "../../../../UI/Alert/AlertDialog";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Moment from "react-moment";

const statuses = [
  "available_to_transfer",
  "pending",
  "cancelled",
  "refunded",
  "transferred",
];

const handleReportData = (data) => ({
  id: data.id,
  charge_status: `${data.charge_status}`,
  last_charge_attempt_dt: `${data.last_charge_attempt_dt}`,
  payment_amount: `${data.payment_amount} ${data.payment_currency_code}`,
  charge_attempts: `${data.charge_attempts}`,
  therapist_session: `${data.therapist_session}`,
  stripe_balance_transaction_id: `${data.stripe_balance_transaction_id}`,
});

const RevenueDetails = (props) => {
  const contentBodyRef = useRef(null);

  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);

  const history = useHistory();

  const { user } = useSelector((state) => state.auth);
  const { search } = useLocation();

  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const [details, setDetails] = useState([]);
  //   const [clientId, setClientId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    (search.includes("all") && "all") ||
      (search.includes("past30days") && "past30days") ||
      (search.includes("past1year") && "past1year") ||
      (search.includes("selectDates") && "selectDates")
  );

  const [alertMsg, setAlertMsg] = useState(false);
  const [startDate, setStartDate] = useState(
    params.from ? new Date(params.from) : null
  );
  const [endDate, setEndDate] = useState(
    params.to ? new Date(params.to) : null
  );

  let { id } = useParams();

  useEffect(() => {
    // if (window.innerWidth >= 1024) {
    if (currentTab === "selectDates") {
      contentBodyRef.current.style.height = "59vh";
    } else {
      contentBodyRef.current.style.height = "65vh";
    }
    // }
  });

  useEffect(() => {
    if (currentTab === "past30days") {
      getOneMonthData();
    }

    if (currentTab === "past1year") {
      getOneYearData();
    }

    if (currentTab === "all") {
      getData();
    }
    if (currentTab === "selectDates" && startDate && endDate) {
      getSelectData();
    }
  }, []);

  const getReport = () => {
    if (startDate === null || endDate === null) {
      setAlertMsg(true);
    } else {
      setIsLoading(true);
      getSelectData();
    }
  };

  const onToggle = () => {
    setAlertMsg(false);
  };

  const getOneMonthData = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/admin/revenue/client";

    setIsLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `token ${token}`,
        },

        params: {
          id: id,
          //   from: moment(new Date())
          //     .startOf("day")
          //     .utc()
          //     .subtract(30, "day")
          //     .format("YYYY-MM-DD HH:mm:ss"),

          //   to: moment(new Date())
          //     .endOf("day")
          //     .utc()
          //     .format("YYYY-MM-DD HH:mm:ss"),
          //   status: statuses,
        },

        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        setIsLoading(false);
        const data = response.data.results.map(handleReportData);
        setDetails(data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getOneYearData = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    const url = BASE_URL + "/admin/revenue/client";

    axios
      .get(url, {
        headers: {
          Authorization: `token ${token}`,
        },

        params: {
          id: id,
          //   from: moment(new Date())
          //     .startOf("day")
          //     .utc()
          //     .subtract(365, "day")
          //     .format("YYYY-MM-DD HH:mm:ss"),

          //   to: moment(new Date())
          //     .endOf("day")
          //     .utc()
          //     .format("YYYY-MM-DD HH:mm:ss"),
          //   status: statuses,
        },

        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        setIsLoading(false);
        const data = response.data.results.map(handleReportData);
        setDetails(data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getSelectData = () => {
    const token = localStorage.getItem("token");

    const url = BASE_URL + "/admin/revenue/client";
    setIsLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `token ${token}`,
        },

        params: {
          id: id,
          //   from: startDate
          //     ? moment(startDate)
          //         .startOf("day")
          //         .utc()
          //         .format("YYYY-MM-DD HH:mm:ss")
          //     : null,
          //   to: endDate
          //     ? moment(endDate).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
          //     : null,
        },

        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        setIsLoading(false);
        const data = response.data.results.map(handleReportData);
        setDetails(data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getData = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    const url = BASE_URL + "/admin/revenue/client";

    axios
      .get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
        params: {
          id: id,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        setIsLoading(false);

        const data = response.data.results.map(handleReportData);

        setDetails(data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className={classes.topFlex}>
          <div className={classes.goBack}>
            <svg
              className={classes.backIcon}
              onClick={() => history.goBack()}
              xmlns="http://www.w3.org/2000/svg"
              width="18.303"
              height="17.946"
              viewBox="0 0 18.303 17.946"
            >
              <g
                id="Group_6913"
                data-name="Group 6913"
                transform="translate(-18699.623 -13146.869)"
              >
                <path
                  id="Path_4322"
                  data-name="Path 4322"
                  d="M18708.893,13147.221l-8.564,8.677,8.564,8.564"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="1"
                />
                <path
                  id="Path_4323"
                  data-name="Path 4323"
                  d="M18717.926,13155.842h-16.957"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </div>

          <div className={classes.topTabs}>
            <ul
              className={`nav nav-pills ${classes.navPills}`}
              id="myTab"
              role="tablist"
            >
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={`${currentTab === "all" && "adminActiveTab"} ${
                    classes.navLink
                  }`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#all"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "all")}
                >
                  All
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={` ${
                    currentTab === "past30days" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#past30days"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "past30days")}
                >
                  Past 30 days
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={`${
                    currentTab === "past1year" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#past1year"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "past1year")}
                >
                  Past 1 year
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={` ${
                    currentTab === "selectDates" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#selectDates"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "selectDates")}
                >
                  Select dates
                </a>
              </li>
            </ul>
          </div>
        </div>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    setCurrentTab(tab);

    if (tab === "all") {
      history.replace({
        pathname: `/revenue-details/${id}`,
        search: "all",
      });
      getData();
    }

    if (tab === "past30days") {
      history.replace({
        pathname: `/revenue-details/${id}`,
        search: "past30days",
      });
      getOneMonthData();
    }

    if (tab === "past1year") {
      history.replace({
        pathname: `/revenue-details/${id}`,
        search: "past1year",
      });
      getOneYearData();
    }

    if (tab === "selectDates") {
      //   setDetails([]);
      history.replace({
        pathname: `/revenue-details/${id}`,
        search: "selectDates",
      });
    }
  };

  const columns = [
    {
      field: "charge_status",
      headerName: "Charge Status",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      headerClassName: "super-app-theme--admin-header",
      cellClassName: "super-app-theme--cell",
    },

    {
      field: "last_charge_attempt_dt",
      headerName: "Date & Time",
      type: "dateTime",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      headerClassName: "super-app-theme--admin-header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        return (
          <Moment local format="DD MMM YYYY HH:mm A">
            {params.value}
          </Moment>
        );
      },
    },

    {
      field: "payment_amount",
      headerName: "Payment Amount",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      headerClassName: "super-app-theme--admin-header",
      cellClassName: "super-app-theme--cell",
    },

    {
      field: "charge_attempts",
      headerName: "Charge Attempts",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      headerClassName: "super-app-theme--admin-header",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "stripe_balance_transaction_id",
      headerName: "Transaction Id",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--admin-header",
      cellClassName: "super-app-theme--cell",
    },
  ];

  return (
    <div className={classes.reportDetails}>
      <div className={`bcg ${classes.reportDetails__innerDiv}`}>
        {currentTab === "selectDates" && (
          <>
            <div className={classes.selectDates}>
              <DatePicker
                calendarClassName={classes.firstCalender}
                className={classes.firstInput}
                onChange={(date) => setStartDate(date)}
                selected={startDate}
                placeholderText="Start Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />
              <p className={classes.to}> To </p>

              <DatePicker
                className={classes.secondInput}
                calendarClassName={classes.secondCalender}
                onChange={(date) => setEndDate(date)}
                selected={endDate}
                placeholderText="End Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />

              <button onClick={getReport} className={classes.GetReports}>
                Get Report
              </button>
            </div>
          </>
        )}

        <div className={classes.contentDiv}>
          <div ref={contentBodyRef} className={classes.contentBody}>
            {isLoading && <ComponentLoader />}

            {!isLoading && (
              <DataGrid
                rows={details}
                onRowClick={(e) =>
                  history.push(
                    "/session/" + e.row.therapist_session + "/overview"
                  )
                }
                columns={columns}
                components={{
                  Toolbar: CustomToolbar,
                }}
                pageSize={50}
                disableColumnMenu
                disableSelectionOnClick
                rowHeight={80}
              />
            )}
          </div>
        </div>
      </div>

      <div className={classes.ReportFooterLink}>
        <FooterLink />
      </div>

      {alertMsg && (
        <AlertDialog
          msg="Please select starting and ending dates"
          onToggle={onToggle}
        />
      )}
    </div>
  );
};

export default RevenueDetails;
