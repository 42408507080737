import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./UpcomingSes.module.scss";
import { useSelector } from "react-redux";
import UpcomingSessionsList from "./UpcomingSessionsList/UpcomingSessionsList";
import NoSession from "./UpcomingSessionsList/NoSession";
import { BASE_URL } from "../../../../../shared/constants";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import ComponentLoader from "../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../shared/utility";

const UpcomingSessions = (props) => {
  const { user } = useSelector((state) => state.auth);

  const [newMessages, setNewMessages] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSessions(currentDate);
    getMessageNumber();
  }, []);

  const getMessageNumber = () => {
    const token = localStorage.getItem("token");
    const url2 = BASE_URL + "/messaging/new-messages";

    axios
      .get(url2, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setNewMessages(response.data.number_of_chats);
      })
      .catch((error) => {});
  };

  const getSessions = (date) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions";

    let statuses = ["confirmed"];
    if (props.user.type === "client")
      statuses = ["confirmed", "cancelled", "tentative"];
    setLoading(() => true);
    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          from: moment(new Date())
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),

          to: moment(new Date())
            .startOf("day")
            .utc()
            .add(90, "day")
            .format("YYYY-MM-DD HH:mm:ss"),
          status: statuses,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        setSessions(response.data.results);
        setLoading(() => false);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }

        if (error.response.data.error) {
          setLoading(false);
        }
      });
  };

  return (
    <div className={classes.UpcomingSessions}>
      <div className={classes.UpcomingSessions__innerDiv}>
        <div className={classes.upcomingSesParent}>
          <div className={classes.upcomingSesList}>
            {loading && <ComponentLoader />}
            {!loading && (
              <>
                {sessions.length === 0 && <NoSession user={user} />}
                {sessions.length > 0 && (
                  <div>
                    <h4 className={classes.upcomingSesTitle}>
                      My Upcoming session
                    </h4>

                    <UpcomingSessionsList
                      user={props.user}
                      sessions={sessions}
                      onCancel={() => getSessions(currentDate)}
                      alert={props.showAlert}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={classes.upcomingSesBottom}>
            <div className={`${classes.footerLinkDiv} ${classes.greenBg}`}>
              <Link to="/messages" className={classes.footerLinkContent}>
                <svg
                  className={classes.message}
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="27.78"
                  viewBox="0 0 35 27.78"
                >
                  <g
                    id="Group_79"
                    data-name="Group 79"
                    transform="translate(-90 -509.4)"
                  >
                    <g
                      id="Group_49"
                      data-name="Group 49"
                      transform="translate(90 509.4)"
                    >
                      <path
                        className={classes.pathOne}
                        id="Rectangle_27"
                        data-name="Rectangle 27"
                        d="M5.44,0H29.56A5.44,5.44,0,0,1,35,5.44V22.316a5.464,5.464,0,0,1-5.464,5.464H5.44A5.44,5.44,0,0,1,0,22.341V5.44A5.44,5.44,0,0,1,5.44,0Z"
                        transform="translate(0)"
                      />
                    </g>
                    <g
                      id="Group_50"
                      data-name="Group 50"
                      transform="translate(94.939 517.273)"
                    >
                      <path
                        className={classes.pathTwo}
                        id="Path_32"
                        data-name="Path 32"
                        d="M-1071.65,1595.567a4.938,4.938,0,0,1-3.085-1.084l-8.382-6.686a1.592,1.592,0,0,1-.252-2.238,1.593,1.593,0,0,1,2.238-.252l8.382,6.687a1.755,1.755,0,0,0,2.189.006l8.506-6.7a1.594,1.594,0,0,1,2.237.266,1.593,1.593,0,0,1-.266,2.236l-8.506,6.7A4.936,4.936,0,0,1-1071.65,1595.567Z"
                        transform="translate(1083.717 -1584.959)"
                      />
                    </g>
                  </g>
                </svg>

                <p className={classes.footerLinkText}>
                  You have {newMessages ? newMessages : "no"} new&nbsp;
                  {newMessages.length >= 1 ? "messages" : "message"}.
                </p>
              </Link>
            </div>

            {user?.type === "client" && (
              <div className={`${classes.footerLinkDiv} ${classes.redBg}`}>
                <Link to="/search" className={classes.footerLinkContent}>
                  <svg
                    className={classes.bookIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.477"
                    height="30.277"
                    viewBox="0 0 29.477 30.277"
                  >
                    <g
                      id="Group_85"
                      data-name="Group 85"
                      transform="translate(650.295 -1704.825)"
                    >
                      <path
                        className={classes.bookPathOne}
                        id="Path_49"
                        data-name="Path 49"
                        d="M-620.818,1718.8v15.163a2.7,2.7,0,0,1-2.7,2.7h-24.07a2.7,2.7,0,0,1-2.7-2.7V1718.8Z"
                        transform="translate(0 -1.569)"
                      />
                      <path
                        className={classes.bookPathTwo}
                        id="Path_50"
                        data-name="Path 50"
                        d="M-620.818,1710.343v3.931h-29.477v-3.931a2.7,2.7,0,0,1,2.7-2.7h3.031v-.986a1.829,1.829,0,0,1,1.83-1.828,1.827,1.827,0,0,1,1.293.536,1.816,1.816,0,0,1,.536,1.292v.986h11.054v-.986a1.829,1.829,0,0,1,1.829-1.828,1.825,1.825,0,0,1,1.294.536,1.824,1.824,0,0,1,.536,1.292v.986h2.669A2.7,2.7,0,0,1-620.818,1710.343Z"
                      />
                    </g>
                  </svg>

                  <p className={classes.footerLinkText}> Book Appointment </p>
                </Link>
              </div>
            )}

            <div className={`${classes.footerLinkDiv} ${classes.blueBg}`}>
              <a
                href="https://mindsum.freshdesk.com/support/solutions/80000201126"
                target="_blank"
                rel="noreferrer"
                className={classes.footerLinkContent}
              >
                <svg
                  className={classes.knowledgeIcon}
                  xmlns="http://www.w3.org/2000/svg"
                  width="30.746"
                  height="34.227"
                  viewBox="0 0 30.746 34.227"
                >
                  <g
                    id="Group_91"
                    data-name="Group 91"
                    transform="translate(541.57 -1704.834)"
                  >
                    <g id="Group_87" data-name="Group 87">
                      <rect
                        className={classes.knowledgeRect}
                        id="Rectangle_48"
                        data-name="Rectangle 48"
                        width="30.746"
                        height="34.227"
                        rx="3.082"
                        transform="translate(-541.57 1704.834)"
                      />
                    </g>
                    <g id="Group_88" data-name="Group 88">
                      <path
                        className={classes.firstLine}
                        id="Path_51"
                        data-name="Path 51"
                        d="M-526.869,1715.829h-5.9a1.391,1.391,0,0,1-1.391-1.391,1.391,1.391,0,0,1,1.391-1.392h5.9a1.391,1.391,0,0,1,1.391,1.392A1.391,1.391,0,0,1-526.869,1715.829Z"
                      />
                    </g>
                    <g id="Group_89" data-name="Group 89">
                      <path
                        className={classes.secondLine}
                        id="Path_52"
                        data-name="Path 52"
                        d="M-519.62,1723.339h-13.153a1.392,1.392,0,0,1-1.391-1.392,1.391,1.391,0,0,1,1.391-1.391h13.153a1.391,1.391,0,0,1,1.391,1.391A1.392,1.392,0,0,1-519.62,1723.339Z"
                      />
                    </g>
                    <g id="Group_90" data-name="Group 90">
                      <path
                        className={classes.thirdLine}
                        id="Path_53"
                        data-name="Path 53"
                        d="M-519.62,1731.245h-13.153a1.391,1.391,0,0,1-1.391-1.391,1.391,1.391,0,0,1,1.391-1.392h13.153a1.391,1.391,0,0,1,1.391,1.392A1.391,1.391,0,0,1-519.62,1731.245Z"
                      />
                    </g>
                  </g>
                </svg>

                <p className={classes.footerLinkText}> Knowledge base </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingSessions;
