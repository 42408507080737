import React from "react";
import Navbar from "../../Navbar/Navbar";
import classes from "./Layout.module.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Layout = (props) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.token !== null);

  if (
    "/login_/signup-as-client_/signup-as-therapist_/welcome_/about-you_/your-needs_/preview_/forgot-password_/reset-password_/verify-email_/skills-qualifications_/preferences_/pending-approval_/beat-the-stigma_/search_/therapist/:id/profile_/therapist/:id/book-session".includes(
      location.pathname
    ) &&
    location.pathname !== "/"
  ) {
    return <div className="container-fluid mx-0 px-0">{props.children}</div>;
  }

  return (
    <div
      style={{ overflowY: isAuthenticated === false ? "auto" : "" }}
      className={`container-fluid mx-0 px-0 ${classes.container}`}
    >
      <div className={classes.gradientBg}></div>

      <Navbar />
      {props.children}
    </div>
  );
};

export default Layout;
