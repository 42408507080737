import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    message: null
};

const verifyEmailStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        message: null
    });
};

const verifyEmailSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        message: action.message
    });
};

const verifyEmailFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        message: null
    });
};

const requestEmailVerificationCodeStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        message: null
    });
};

const requestEmailVerificationCodeSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        message: action.message
    });
};

const requestEmailVerificationCodeFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        message: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VERIFY_EMAIL_START: return verifyEmailStart(state, action);
        case actionTypes.VERIFY_EMAIL_SUCCESS: return verifyEmailSuccess(state, action);
        case actionTypes.VERIFY_EMAIL_FAIL: return verifyEmailFail(state, action);
        case actionTypes.REQUEST_EMAIL_VERIFICATION_CODE_START: return requestEmailVerificationCodeStart(state, action);
        case actionTypes.REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS: return requestEmailVerificationCodeSuccess(state, action);
        case actionTypes.REQUEST_EMAIL_VERIFICATION_CODE_FAIL: return requestEmailVerificationCodeFail(state, action);
        default: return state;
    }
};

export default reducer;
