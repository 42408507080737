import React, { useEffect } from "react";
import classes from "../Step1.module.scss";
import { useForm } from "react-hook-form";

const StepOneForm = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ reValidateMode: "onChange" });

  useEffect(() => {
    Object.keys(props.profile).map((e) => {
      setValue(e, props.profile[e]);

      if (e.toString() === "external_websites") {
        props.profile.external_websites.map((type) => {
          setValue(type["external_website_type"].id, type.url);
          return type;
        });
      }

      return e;
    });
  }, []);

  const onSubmit = (formData) => {
    const userProfile = {
      job_title: formData.job_title,
      mobile: formData.mobile,
      landline: formData.landline,
      address_line_1: formData.address_line_1,
      address_line_2: formData.address_line_2,
      address_line_3: formData.address_line_3,
      city_town: formData.city_town,
      post_code: formData.post_code,
      more_about_yourself: formData.more_about_yourself,
      external_websites: props.externalWebsiteTypes.map((type) => ({
        external_website_type_id: type.id,
        url: formData[type.id],
      })),
    };

    props.onUpdateUserProfile(userProfile);
  };

  return (
    <form className={classes.step1Form} onSubmit={handleSubmit(onSubmit)}>
      {props.type === "therapist" && (
        <div className={`${classes.contactDiv} ${classes.jobTitleDiv}`}>
          <div style={{ width: "100%" }} className={classes.contactOne}>
            <input
              {...register("job_title", { required: true })}
              type="text"
              className={`${classes.contactInput}`}
              id="jobTitleInput"
              name="job_title"
              placeholder=" "
            />
            <label htmlFor="jobTitleInput" className={classes.contactLabel}>
              What is your job title?
            </label>

            {errors?.job_title && errors.job_title.type === "required" && (
              <p
                className={`errorTxt ${classes.errorText}`}
                style={{ marginTop: "-30px" }}
              >
                Job Title is required
              </p>
            )}
          </div>
        </div>
      )}

      <div className={classes.contactDiv}>
        <div className={classes.contactOne}>
          <input
            {...register("mobile", {
              required: true,
              pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            })}
            type="tel"
            name="mobile"
            className={`${classes.contactInput}`}
            id="mobileInput"
            placeholder=" "
          />
          <label className={classes.contactLabel} htmlFor="mobile">
            Contact (Mobile)
          </label>

          {errors?.mobile && errors.mobile.type === "required" && (
            <p
              className={`errorTxt ${classes.errorText}`}
              style={{ marginTop: "-30px" }}
            >
              Contact (Mobile) is required
            </p>
          )}
          {errors?.mobile && errors.mobile.type === "pattern" && (
            <p
              className={`errorTxt ${classes.errorText}`}
              style={{ marginTop: "-30px" }}
            >
              Enter a valid contact number
            </p>
          )}
        </div>
        <div className={classes.contactOne}>
          <input
            {...register("landline", {
              required: false,
              pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            })}
            type="tel"
            className={classes.contactInput}
            id="landlineInput"
            name="landline"
            placeholder=" "
          />
          <label className={classes.contactLabel} htmlFor="landline">
            Contact (Landline)
          </label>
          {errors?.landline && errors.landline.type === "pattern" && (
            <p
              className={`errorTxt ${classes.errorText}`}
              style={{ marginTop: "-30px" }}
            >
              Enter a valid contact number
            </p>
          )}
        </div>
      </div>

      <div className={`${classes.contactDiv}`}>
        <div className={`${classes.addressDiv} ${classes.contactOne}`}>
          <input
            {...register("address_line_1", { required: true })}
            type="text"
            className={classes.addressInput}
            name="address_line_1"
            id="addressLine1Input"
            placeholder=" "
          />

          <label className={classes.addressLabel} htmlFor="address_line_1">
            Address Line 1
          </label>

          {errors?.address_line_1 && errors.address_line_1.type === "required" && (
            <p className={`errorTxt`} style={{ marginTop: "-30px" }}>
              Address is required
            </p>
          )}
        </div>
        <div className={`${classes.addressDiv} ${classes.contactOne}`}>
          <input
            {...register("address_line_2", { required: false })}
            type="text"
            className={classes.addressInput}
            name="address_line_2"
            id="addressLine2Input"
            placeholder=" "
          />
          <label className={classes.addressLabel} htmlFor="address_line_2">
            Address Line 2
          </label>
        </div>
      </div>

      <div className={classes.contactDiv}>
        <div className={`${classes.addressDiv} ${classes.contactOne}`}>
          <input
            {...register("address_line_3", { required: false })}
            type="text"
            className={classes.addressInput}
            name="address_line_3"
            id="addressLine3Input"
            placeholder=" "
          />
          <label className={classes.addressLabel} htmlFor="address_line_3">
            Address Line 3
          </label>
        </div>

        <div className={`${classes.addressDiv} ${classes.contactOne}`}>
          <input
            {...register("city_town", { required: true })}
            type="text"
            className={classes.addressInput}
            name="city_town"
            id="cityTownInput"
            placeholder=" "
          />
          <label className={classes.addressLabel} htmlFor="city_town">
            City/Town
          </label>

          {errors?.city_town && errors.city_town.type === "required" && (
            <p className={`errorTxt`} style={{ marginTop: "-30px" }}>
              City/Town is required
            </p>
          )}
        </div>
      </div>

      <div className={classes.contactDiv}>
        <div className={`${classes.addressDiv} ${classes.contactOne}`}>
          <input
            {...register("post_code", { required: true })}
            type="text"
            className={classes.addressInput}
            name="post_code"
            id="postCodeInput"
            placeholder=" "
          />
          <label className={classes.addressLabel} htmlFor="post_code">
            Postcode
          </label>

          {errors?.post_code && errors.post_code.type === "required" && (
            <p className={`errorTxt `} style={{ marginTop: "-30px" }}>
              Postcode is required
            </p>
          )}
        </div>

        <div className={`${classes.addressDiv} ${classes.contactOne}`}>
          <input
            // {...register("country", { required: true })}
            type="text"
            className={classes.addressInput}
            name="country"
            id="countryInput"
            disabled
            value="United Kingdom"
            placeholder=" "
          />
          <label className={classes.addressLabel} htmlFor="country">
            Country
          </label>

          {/* {errors?.post_code && errors.post_code.type === "required" && (
            <p className={`errorTxt `} style={{ marginTop: "-30px" }}>
              Country is required
            </p>
          )} */}
        </div>
      </div>

      <div
        className={`${classes.addressDiv} ${classes.bioTopPadding} `}
        style={{ paddingTop: "2%" }}
      >
        <label className={classes.textAreaLabel}>
          Tell Us About Yourself&nbsp;
          <small>
            (Your bio will help us provide right support & help better)
          </small>
        </label>

        <textarea
          {...register("more_about_yourself", {
            required: props.type === "therapist" ? true : false,
            maxLength: 1000,
          })}
          rows="8"
          className={classes.textArea}
          id="aboutYourselfInput"
          name="more_about_yourself"
        />
        {errors?.more_about_yourself &&
          errors.more_about_yourself.type === "required" && (
            <p className={`errorTxt`} style={{ marginTop: "5px" }}>
              Bio is required
            </p>
          )}

        {errors?.more_about_yourself &&
          errors.more_about_yourself.type === "maxLength" && (
            <p className={`errorTxt`} style={{ marginTop: "5px" }}>
              Max. length exceeded (Max 1000 characters)
            </p>
          )}
      </div>

      <div className={classes.socialLinks}>
        <p className={classes.socialLinksText}>Social Links (Optional)</p>

        {props.type === "therapist" && (
          <p className={classes.socialLinksRecomm}>
            We strongly recommend to add these links so that we can improve your
            profile visibility on search engines.
          </p>
        )}

        <div className={classes.socialInputDiv}>
          {props?.externalWebsiteTypes.map((type) => (
            <div key={type.id}>
              <input
                {...register(type.id, {
                  required: false,
                  pattern: /^(http|https):/,
                })}
                type="text"
                name={type.id}
                className={classes.socialInput}
                id={`${type.title}Input`}
                placeholder={`e.g. https://${type.title}.com/myprofile`}
              />

              {errors?.[type.id] && errors[type.id].type === "pattern" && (
                <p className={`errorTxt`} style={{ marginTop: "-7px" }}>
                  Please enter a valid URL
                </p>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={classes.step1BtnDiv}>
        <button type="submit" className={classes.step1Btn}>
          Next
        </button>
      </div>
    </form>
  );
};

export default StepOneForm;
