import * as actionTypes from './actionTypes';
import axios from 'axios';
import {BASE_URL} from "../../shared/constants";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user: user
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START
    }
};

export const updateUserSuccess = (user) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        user: user
    }
};

export const updateUserFail = (error) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        error: error
    }
};

export const authResetState = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.AUTH_RESET_STATE
        });
    }
};

export const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};




export const login = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const url = BASE_URL + '/signin';

        const requestPayload = {
            email: email,
            password: password
        };

        axios.post(url, requestPayload)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                const user = response.data.user;
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(authSuccess(response.data.token, user));
            })
            .catch(error => {
                dispatch(authFail(error.response.data.error));
            });
    }
};

export const signUp = (type, email, password, firstName, lastName, termsAgreed) => {
    return dispatch => {
        dispatch(authStart());
        const url = BASE_URL + '/signup';

        const requestPayload = {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            type: type,
            terms_agreed: termsAgreed
        };

        axios.post(url, requestPayload)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                const user = response.data.user;
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(authSuccess(response.data.token, user));
            })
            .catch(error => {
                dispatch(authFail(error.response.data.error));
            });
    }
};

export const logout = () => {
    return dispatch => {
        const url = BASE_URL + '/signout';
        const token = localStorage.getItem('token');
        if (!token) {
            localStorage.removeItem('user');
            dispatch(authLogout());
        } else {
            axios.get(url, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    dispatch(authLogout());
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        dispatch(authLogout());
                    }
                });
        }
    }
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        if (!token || !user) {
            dispatch(logout());
        } else {
            dispatch(authSuccess(token, user));
        }
    }
};

export const updateUser = (updatedUser) => {
    return dispatch => {
        dispatch(updateUserStart());
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        const url = BASE_URL + '/user/' + user.id;

        const requestPayload = {
            ...updatedUser
        };

        axios.put(url, requestPayload, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                const user = response.data;
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(updateUserSuccess(user));
            })
            .catch(error => {
                dispatch(updateUserFail(error));
            });
    }
};
