import React, { useEffect } from "react";
import classes from "../Search.module.scss";
import search from "../../../../../assets/icons/search_search.svg";
import { useHistory, useLocation } from "react-router-dom";

const SearchForm = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { term, setTerm, onFoucs, setRecomResults } = props;

  const onSubmit = (event) => {
    event.preventDefault();
    setRecomResults(() => []);
    props.onSearch(term);
  };

  useEffect(() => {
    if (location.search) {
      const search = location.search.replace("?", "");
      setTerm(search);
      onFoucs();
      props.onSearch(search);
    }
  }, []);

  const handleOnchange = ({ target }) => {
    setTerm(target.value);

    history.replace({
      pathname: "/search",
      search: target.value,
    });
    props.onSearch(target.value);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className={classes.searchInputDiv}>
        <input
          type="text"
          className={classes.searchInputDiv__input}
          id="inlineFormInputGroupUsername"
          placeholder="Enter therapy type, condition, city.."
          value={term}
          onFocus={onFoucs}
          onChange={handleOnchange}
          autoComplete="off"
        />

        <label style={{ marginTop: "15px" }}>
          <a style={{ textDecoration: "none", cursor: "pointer" }}>
            <div className="">
              <img className={classes.searchIcon} src={search} alt="" />
            </div>
          </a>
          <input type="submit" style={{ display: "none" }} />
        </label>
      </div>
    </form>
  );
};

export default SearchForm;
