import React from "react";
import ReactDOM from "react-dom";
import "./custom-bootstrap.scss";
import "bootstrap/dist/js/bootstrap.min";

import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import authReducer from "./store/reducers/auth";
import requestPasswordResetCodeReducer from "./store/reducers/requestPasswordResetCode";
import resetPasswordReducer from "./store/reducers/resetPassword";
import verifyEmailReducer from "./store/reducers/verifyEmail";
import aboutYouReducer from "./store/reducers/aboutYou";
import userProfileReducer from "./store/reducers/userProfile";
import thunk from "redux-thunk";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  requestPasswordResetCode: requestPasswordResetCodeReducer,
  resetPassword: resetPasswordReducer,
  verifyEmail: verifyEmailReducer,
  aboutYou: aboutYouReducer,
  userProfile: userProfileReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>{app}</React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
