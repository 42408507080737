import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import ReactGA from "react-ga";
import Login from "./components/pages/Login/Login";
import SignUp from "./components/pages/SignUp/SignUp";
import ForgotPassword from "./components/pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword/ResetPassword";
import VerifyEmail from "./components/pages/VerifyEmail/VerifyEmail";
import CreateProfile from "./components/pages/CreateProfile/CreateProfile";
import PendingProfileApproval from "./components/pages/PendingProfileApproval/PendingProfileApproval";
import LoggedIn from "./components/pages/LoggedIn/LoggedIn";
import Layout from "./components/hoc/Layout/Layout";
import Logout from "./components/pages/Logout/Logout";
import StopTheStigma from "./components/pages/stopTheStigma";
import CardDetails from "./components/pages/stopTheStigma/CardDetails";
import Search from "./components/pages/LoggedIn/Search/Search";
import ProfileTherapist from "./components/pages/LoggedIn/Profile/ProfileTherapist";
import SearchProfile from "./components/pages/LoggedIn/Profile/therapistProfile/SearchProfile";
import BookSession from "./components/pages/LoggedIn/BookSession/BookSession";
import TherapistProfileModal from "./components/UI/TherapistProfileModal/TherapistProfileModal";

const usePageViews = () => {
  let location = useLocation();

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("UA-195608527-2");
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
};

const App = (props) => {
  usePageViews();
  const { onTryAutoSignUp } = props;

  useEffect(() => {
    onTryAutoSignUp();
  }, [onTryAutoSignUp]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route
        path="/signup-as-client"
        render={(props) => <SignUp {...props} type="client" />}
      />
      <Route
        path="/signup-as-therapist"
        component={(props) => <SignUp {...props} type="therapist" />}
      />

      <Route path="/verify-email" component={VerifyEmail} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route
        path="/pending-profile-approval"
        component={PendingProfileApproval}
      />
      <Route path="/beat-the-stigma" component={StopTheStigma} />
      <Route
        path="/beat-the-stigma?question=question/:id"
        component={CardDetails}
      />

      <Route
        path="/search?therapist_profile=therapist_profile/:id"
        component={TherapistProfileModal}
      />

      <Route path="/create-profile" component={CreateProfile} />

      <Route path="/search" component={Search} />

      <Route path="/therapist/:id/profile" component={SearchProfile} />

      <Route path="/therapist/:id/book-session" component={BookSession} />

      <Redirect to="/login" />
    </Switch>
  );

  if (props.isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/beat-the-stigma" component={StopTheStigma} />
        <Route
          path="/beat-the-stigma?question=question/:id"
          component={CardDetails}
        />

        <Route
          path="/search?therapist_profile=therapist_profile/:id"
          component={TherapistProfileModal}
        />

        <Route path="/logout" component={Logout} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/" component={LoggedIn} />
      </Switch>
    );
  }

  return <Layout>{props.authStateChecked && routes}</Layout>;
};

const mapStateToProps = (state) => {
  return {
    authStateChecked: state.auth.authStateChecked,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignUp: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
