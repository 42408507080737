import classes from "./QNA.module.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../../shared/constants";

const color = ["#ffa88a", "#ff5f5f", "#62bfae", "#Ed6b64", "#618df0"];

const QNAPops = ({ data, onToggle, setSuccessPopup }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      answer: "",
    },
  });

  const [oldAnswer, setOldAnswer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const submitHandler = async (formData) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/answer/create";
    const d = {
      question: data.id,
      answer: formData.answer,
      user: user.id,
    };
    try {
      await axios.post(url, d, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setIsLoading(false);
      onToggle();
      setSuccessPopup("Your answer has been submitted successfully!");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getOldAnswer = async () => {
    setIsLoading(true);
    const response = await axios.get(`${BASE_URL}/answer/view/${data.id}`);
    setValue("answer", response.data.results[0].answer);
    setOldAnswer(response.data.results[0]);
    setIsLoading(false);
  };

  const handleUpdate = async (formData) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/answer/update/" + oldAnswer.id;

    try {
      await axios.put(
        url,
        {
          answer: formData.answer,
          user: user.id,
        },
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      setIsLoading(false);
      onToggle();
      setSuccessPopup("Your answer has been updated successfully!");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (data.answered) {
      getOldAnswer();
    }
  }, []);

  const handleCancel = () => {
    onToggle();
  };

  const onSubmit = async (formData) => {
    if (data.answered) {
      handleUpdate(formData);
    } else {
      submitHandler(formData);
    }
  };

  return (
    <div className={classes.popupDiv}>
      <div onClick={handleCancel} className={classes.closePopup}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="39.031"
          height="39.031"
          viewBox="0 0 39.031 39.031"
        >
          <path
            id="Path_14891"
            data-name="Path 14891"
            d="M820.831,831.908a19.516,19.516,0,1,0,19.516,19.516A19.516,19.516,0,0,0,820.831,831.908Zm7.671,26.184a.709.709,0,0,1-1,1l-6.668-6.668-6.669,6.669a.709.709,0,0,1-1-1l6.669-6.669-6.669-6.669a.709.709,0,0,1,1-1l6.669,6.669,6.669-6.669a.709.709,0,1,1,1,1l-6.669,6.669Z"
            transform="translate(-801.315 -831.908)"
            fill="#fff"
          />
        </svg>
      </div>
      <div className={classes.innerDiv}>
        <div className={classes.mainDiv}>
          <div className={classes.topBorder}>
            <span
              className={classes.topBorder__left}
              style={{
                background: color[Math.ceil(Math.random() * color.length) - 1],
              }}
            ></span>
            <span
              className={classes.topBorder__right}
              style={{
                background: color[Math.ceil(Math.random() * color.length) - 1],
              }}
            ></span>
          </div>

          <p className={classes.mainDiv__question}> {data.question} </p>

          <div className={classes.clientDetails}>
            {data.name ? (
              <p className={classes.clientName}> {data.name} </p>
            ) : (
              <p className={classes.clientName}> Anonymous </p>
            )}

            {data.age && (
              <p className={classes.clientName}> {data.age}&nbsp;yrs. </p>
            )}

            {data.city && <p className={classes.clientName}> {data.city} </p>}

            {data.email && (
              <p
                style={{ textTransform: "initial" }}
                className={classes.clientName}
              >
                {data.email}
              </p>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.mainDiv__textArea}>
              <textarea
                {...register("answer", {
                  required: true,
                  maxLength: 2000,
                })}
                name="answer"
                id="answer"
                rows="6"
                placeholder="Post your reply..."
              ></textarea>

              {errors?.answer && errors.answer.type === "required" && (
                <p className={`errorTxt`} style={{ marginTop: "5px" }}>
                  Answer is required
                </p>
              )}

              {errors?.answer && errors.answer.type === "maxLength" && (
                <p className={`errorTxt`} style={{ marginTop: "5px" }}>
                  Maximum 2000 characters allowed
                </p>
              )}
            </div>
            <div className={classes.mainDiv__btnDiv}>
              <button
                onClick={handleCancel}
                type="button"
                className={`${classes.submitBtn} ${classes.cancelBtn}`}
              >
                Cancel
              </button>
              <button type="submit" className={classes.submitBtn}>
                {data.answered ? "Update" : "Publish"}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.529"
                  height="15.131"
                  viewBox="0 0 19.529 15.131"
                >
                  <path
                    id="Path_229"
                    data-name="Path 229"
                    d="M354.032,292.792l-5-2.016c-.453-.156-.507-.482.211-.639l18.214-4.757c.991-.271.807.124.618.777L363.428,299.8c-.25.777-.412.8-1.05.228l-3.4-3.006-2.488,1.88c-.223.165-.474.15-.472-.125l.045-4.242a.1.1,0,0,1,.041-.075l8.856-6.506c.247-.18.232-.282-.111-.126l-10.48,4.939c-.178.081-.18.092-.336.026"
                    transform="translate(-348.695 -285.293)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </form>
          {isLoading && (
            <div className={classes.loaderOverlay}>
              <div className={`${classes.loader}`}>
                <span className={classes.loader__spin}></span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QNAPops;
