import React, { useEffect, useState } from "react";
import classes from "./NewEnquiries.module.scss";
import NewEnquiriesList from "./NewEnquiriesList/NewEnquiriesList";
import { BASE_URL } from "../../../../../shared/constants";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import ComponentLoader from "../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../shared/utility";

const NewEnquiries = (props) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions";
    setLoading(() => true);
    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          from: moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
          to: moment(new Date())
            .startOf("day")
            .utc()
            .add(1, "year")
            .format("YYYY-MM-DD HH:mm:ss"),
          status: "tentative",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        setSessions(response.data.results);

        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }

        if (error.response.data.error) {
          setLoading(false);
        }
      });
  };

  return (
    <div className={classes.newEnquiries}>
      <div className={` ${classes.newEnquiries__innerDiv}`}>
        {loading && <ComponentLoader />}
        {!loading && (
          <div>
            <NewEnquiriesList
              user={props.user}
              sessions={sessions}
              getSessions={getSessions}
              homeSession={props.getDataLength}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewEnquiries;
