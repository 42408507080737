import React from "react";
import { connect } from "react-redux";
import classes from "../ChatList.module.scss";
import therapistImg from "../../../../../../assets/images/profilePlaceholder.png";

const ChatsListItem = (props) => {
  const date = new Date(props.chat.update_dt);

  return (
    <div
      style={{ cursor: "pointer" }}
      className={`${classes.chatListItem} ${props.isActive && "activeChat"}`}
      onClick={() => props.onSelectChat(props.chat.id)}
    >
      <div className={classes.chatListItemDiv}>
        <div className={classes.therapistImgDiv}>
          {props?.chat?.participants.map((e) => {
            if (e.type !== props.user.type) {
              return (
                <img
                  key={e.id}
                  src={e.profile_image ? e.profile_image : therapistImg}
                  alt=""
                  className={classes.therapistImg}
                />
              );
            }

            return null;
          })}
        </div>

        <div className={classes.chatListProfileRow}>
          <div className={classes.chatListProfileRowOne}>
            <div className="">
              {props.chat.participants
                .filter((item) => item.id !== props.user.id)
                .map((item) => (
                  <span key={item.id} className={classes.profileName}>
                    {item.first_name} {item.last_name}
                  </span>
                ))}
            </div>

            <div
              className={`${props.isActive && "activeChat"} ${
                classes.messageDate
              }`}
            >
              {date.toDateString().substr(4, 3)} {date.getDate()}
            </div>
          </div>

          <div
            className={`${props.isActive && "activeChat"} ${
              classes.chatListProfileRowTwo
            }`}
          >
            {props.chat.last_message && props.chat.last_message.text}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ChatsListItem);
