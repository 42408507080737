import React from 'react';

const ProgressBar = props => {
    return (
        <div className="progress mt-1">
            <div
                className="progress-bar progress-bar-striped progress-bar-animated w-100"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"/>
        </div>
    );
};

export default ProgressBar;
