import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    message: null
};

const requestPasswordResetCodeStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        message: null
    });
};

const requestPasswordResetCodeSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        message: action.message
    });
};

const requestPasswordResetCodeFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        message: null
    });
};

const requestPasswordResetCodeResetState = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        message: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REQUEST_PASSWORD_RESET_CODE_START: return requestPasswordResetCodeStart(state, action);
        case actionTypes.REQUEST_PASSWORD_RESET_CODE_SUCCESS: return requestPasswordResetCodeSuccess(state, action);
        case actionTypes.REQUEST_PASSWORD_RESET_CODE_FAIL: return requestPasswordResetCodeFail(state, action);
        case actionTypes.REQUEST_PASSWORD_RESET_CODE_RESET_STATE: return requestPasswordResetCodeResetState(state, action);
        default: return state;
    }
};

export default reducer;
