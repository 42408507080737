import React, { useState } from "react";
import classes from "../UpcomingSes.module.scss";
import { useHistory } from "react-router-dom";
import imagePlaceHolder from "../../../../../../assets/images/profile-placeholder.png";

const RecommendResults = (props) => {
  const { user } = props;

  const history = useHistory();

  let therapies = "";

  user.therapy_types &&
    user.therapy_types.map((e) => {
      therapies = `${therapies} ${e.title},`;
      return null;
    });

  const shorten = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }

    return text;
  };

  const handleNavigate = () => {
    history.push("/" + user.user.type + "/" + user.user.id + "/profile");
  };

  return (
    <div className={classes.SearchResult} onClick={handleNavigate}>
      {user.user.profile_image ? (
        <img
          className={classes.SearchResult__img}
          src={user.user.profile_image}
          alt=""
        />
      ) : (
        <img
          className={classes.SearchResult__img}
          src={imagePlaceHolder}
          alt=""
        />
      )}

      <div className={classes.SearchResult__detail}>
        <div className={classes.left}>
          <div className={classes.first}>
            <p className={classes.first__namecity}>
              <span className={classes.first__namecity_name}>
                {user.user.first_name}&nbsp;
                {user.user.last_name}
              </span>
            </p>
            <p className={classes.first__jobtitle}>
              {window.innerWidth > 600 && shorten(user.job_title, 60)}
              {window.innerWidth <= 600 && shorten(user.job_title, 25)}
            </p>
          </div>

          <p className={classes.second}>
            <span className={classes.second__heading}>Expertise:&nbsp;</span>
            <span className={classes.second__type}>
              {shorten(therapies, 120)}
            </span>
          </p>
        </div>

        <div className={classes.right}>
          {user.city_town && (
            <p className={classes.right__city}> {user?.city_town} </p>
          )}

          <p className={classes.right__ratingText}> Rating </p>
          <p className={classes.right__ratingValue}>
            {user.rating === null ? (
              <span style={{ color: "#F3AB8E" }} className={classes.rating}>
                No Rating Yet
              </span>
            ) : (
              <span className={classes.rating}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.344"
                  height="11.8"
                  viewBox="0 0 12.344 11.8"
                >
                  <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M-603.712,1758.963l1.433,2.9a.611.611,0,0,0,.46.335l3.205.466a.612.612,0,0,1,.339,1.044l-2.319,2.261a.612.612,0,0,0-.176.541l.547,3.192a.612.612,0,0,1-.887.645l-2.867-1.508a.612.612,0,0,0-.57,0l-2.867,1.508a.612.612,0,0,1-.887-.645l.547-3.192a.612.612,0,0,0-.176-.541l-2.319-2.261a.612.612,0,0,1,.339-1.044l3.205-.466a.612.612,0,0,0,.461-.335l1.433-2.9A.612.612,0,0,1-603.712,1758.963Z"
                    transform="translate(610.433 -1758.622)"
                    fill="#f9ddd2"
                  />
                </svg>
                {user.rating}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecommendResults;
