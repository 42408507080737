import * as actionTypes from './actionTypes';
import axios from 'axios';
import {BASE_URL} from "../../shared/constants";

export const requestPasswordResetCodeStart = () => {
    return {
        type: actionTypes.REQUEST_PASSWORD_RESET_CODE_START
    }
};

export const requestPasswordResetCodeSuccess = (message) => {
    return {
        type: actionTypes.REQUEST_PASSWORD_RESET_CODE_SUCCESS,
        message: message
    }
};

export const requestPasswordResetCodeFail = (error) => {
    return {
        type: actionTypes.REQUEST_PASSWORD_RESET_CODE_FAIL,
        error: error
    }
};

export const requestPasswordResetCodeResetState = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.REQUEST_PASSWORD_RESET_CODE_RESET_STATE
        });
    }
};

export const requestPasswordResetCode = (email) => {
    return dispatch => {
        dispatch(requestPasswordResetCodeStart());
        const url = BASE_URL + '/request-password-reset-code';

        const requestPayload = {
            email: email
        };

        axios.post(url, requestPayload)
            .then(response => {
                dispatch(requestPasswordResetCodeSuccess(response.data.message));
            })
            .catch(error => {
                dispatch(requestPasswordResetCodeFail(error.response.data.error));
            });
    }
};
