import React from "react";

import { connect } from "react-redux";

import SearchProfile from "./therapistProfile/SearchProfile";
import TherapistProfile from "./therapistProfile/TherapistProfile";

const ProfileTherapist = (props) => {
  return props.user.type === "therapist" ? (
    <TherapistProfile />
  ) : (
    <SearchProfile />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    profile: state.userProfile.profile,
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(ProfileTherapist);
