import React, { Fragment, useEffect, useState, useRef } from "react";
import ProfileImage from "../../../UI/ProfileImageAndBasicInfo/ProfileImage/ProfileImage";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import classes from "./BookSession.module.scss";
import FooterLink from "../../../footerLink/FooterLink";
import { BASE_URL } from "../../../../shared/constants";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import {
  addDays,
  addMinutes,
  addMonths,
  setHours,
  setMinutes,
  setSeconds,
  getDay,
  format,
  addSeconds,
} from "date-fns";
import { clearAndLogout } from "../../../../shared/utility";
import ComponentLoader from "../../../UI/ComponentLoader";
import BookSessionSuccess from "../../../UI/BookSessionSuccess";
import BookingTerms from "../../../UI/BookingTerms";
import AlertDialog from "../../../UI/Alert/AlertDialog";
import moment from "moment";
import Moment from "react-moment";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const freeTerm = [
  "Free consultation call will last up to 30 minutes.",
  "You cannot avail more than 1 free consultation call with a particular therapist even if you cancel the booking.",
  "In Clinic & Home visits are not included in Free Initial Consultation.",
  "Bookings within 24 hrs are not allowed.",
  "You will receive a confirmation of your booking on your registered email address.",
  "Please note, this isn’t a crisis support consultation. If you need urgent help, please call 999 or go to your nearest A&E department.",
];

const paidTerm = [
  "The duration of a therapy session depends on the therapist you book. We encourage you to discuss such details during your free initial consultation.",
  "You will be charged full fee in case the appointment is cancelled within 24 hours of the scheduled appointment time.",
  "Bookings within 24 hours are not allowed.",
  "You will receive a confirmation of your booking on your registered email address.",
  "If you are booking an online session, you will see a Zoom link appear on your ‘session details’ page, 10 minutes before the session starts.",
  "Please note, this isn’t a crisis support consultation. If you need urgent help, please call 999 or go to your nearest A&E department.",
];

const BookSession = (props) => {
  const [startDateTime, setStartDateTime] = useState(null);
  const [sessionData, setSessionData] = useState({});
  const [selectTime, setSelectTime] = useState("");
  const [type, setType] = useState("");
  const [profile, setProfile] = useState(null);
  const [isCardAdded, setIsCardAdded] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  const [isAuthError, setIsAuthError] = useState("");
  const [bookingTerm, setBookingTerm] = useState(false);
  const [termAgree, setTermAgree] = useState(false);
  const [chatError, setChatError] = useState("");
  const [free_flg, setFree_flg] = useState([]);
  const [termError, setTermError] = useState(false);

  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const { user } = useSelector((state) => state.auth);

  const history = useHistory();

  let { id } = useParams();

  useEffect(() => {
   if(termAgree===true){
    bookSession();
   }
  }, [termAgree]);

  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);

  useEffect(() => {
    isAuthenticated === true ? getProfile() : getPublicProfile();
    isAuthenticated === true && freeSessionTaken();
  }, []);

  const getProfile = () => {
    setProfile(null);

    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/" + id + "/profile";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getPublicProfile = () => {
    setProfile(null);

    const url = BASE_URL + "/therapist/public/" + id + "/profile";

    axios
      .get(url)
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const freeSessionTaken = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/free/flg";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setFree_flg(response.data);
      })
      .catch((error) => {});

    return data;
  };

  const getChat = () => {
    setChatLoader(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/create-chat";

    const requestPayload = {
      participants: [id],
    };

    axios
      .post(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        history.push("/messages/" + response.data.id);
        setChatLoader(false);
      })
      .catch((error) => {
        setChatLoader(false);
        if (error.response.data.error === "invalid token") {
          clearAndLogout();
        }

        if (error.response.data.error.includes("Inactive")) {
          if (
            error.response.data.error.includes(
              `${user.first_name} ${user.last_name}`
            )
          ) {
            setChatError(
              "Your account is inactive. To make your account active, please go to the Profile Settings tab under Settings page"
            );
          } else {
            setChatError("Therapist is inactive.");
          }
          return;
        }

        if (error.response.data.error) {
          setLoading(false);
          setChatError(error.response.data.error);
        }
      });
  };

  const handleBookingTerm = () => {
    console.log('handleBookingTerm');
    setBookingTerm(true);
  };

  const handleCloseTerm = () => {
    if (termAgree === false) {
      setTermError(true);
    } else if (termAgree === true) {
      setTermError(false);
    }

    setBookingTerm(false);
  };

  const bookSession = () => {
    if (!type) {
      setError(() => "Please select atleast one Session Type");
      return;
    }

    if (startDateTime === null) {
      setError(() => "Please select date and time");
      return;
    }

    if (isAuthenticated === false) {
      setIsAuthError(() => "Please Sign up or Login to book the session");
      return;
    }

    if (termAgree === false) {
      handleBookingTerm();
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions";
    const time = selectTime.split(":");

    const requestPayload = {
      start: moment(setHours(setMinutes(startDateTime, time[1]), time[0])).utc(
        true
      ),
      end: moment(
        addMinutes(setHours(setMinutes(startDateTime, time[1]), time[0]), 60)
      ).utc(true),
      attendees: [id],
      type: type,
    };

    axios({
      method: "post",
      url: url,
      data: requestPayload,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        setLoading(false);

        if (response.status === 201) {
          setSessionData(() => response.data);
          setSuccess(true);
        }
        // else if (response.status !== 201) {
        //   if (
        //     response?.data?.error ===
        //     "Please enter the card details to book the session. \n                    Your card will only be charged after the session is completed"
        //   ) {
        //     setIsCardAdded(
        //       "Please enter the card details to book the session. Your card will only be charged after the session is completed."
        //     );
        //   }
        // }
        else {
          if (
            response?.data?.error ===
            "Please enter the card details to book the session. \n                    Your card will only be charged after the session is completed"
          ) {
            setIsCardAdded(
              "Please enter the card details to book the session. Your card will only be charged after the session is completed."
            );
            return;
          }

          setError(() => response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }

        if (
          error?.response?.data?.error ===
          "Permission denied: You account is inactive."
        ) {
          setError(
            "Your account is inactive. To make your account active, please go to the Profile Settings tab under Settings page"
          );
        }
        if (
          error?.response?.data?.error === "This time slot is not available"
        ) {
          setError("This time slot is not available");
        }

        if (error?.response?.data?.error) {
          setError(error?.response?.data?.error);
        }
      });
  };

  const handleTypeChange = ({ target: { name } }) => {
    setType(() => name);
  };

  const onToggle = () => {
    setError(() => "");
  };

  const onClose = () => {
    setSuccess(() => "");
  };

  const isAvailableDay = (date) => {
    let day = getDay(date);
    const allSlots = profile?.availability_day;
    const monday = allSlots?.find((e) => e.title === "Monday")?.slots?.length
      ? null
      : 1;
    const tuesday = allSlots?.find((e) => e.title === "Tuesday")?.slots?.length
      ? null
      : 2;
    const wednesday = allSlots?.find((e) => e.title === "Wednesday")?.slots
      ?.length
      ? null
      : 3;
    const thursday = allSlots?.find((e) => e.title === "Thursday")?.slots
      ?.length
      ? null
      : 4;
    const friday = allSlots?.find((e) => e.title === "Friday")?.slots?.length
      ? null
      : 5;
    const saturday = allSlots?.find((e) => e.title === "Saturday")?.slots
      ?.length
      ? null
      : 6;
    const sunday = allSlots?.find((e) => e.title === "Sunday")?.slots?.length
      ? null
      : 0;
    return (
      day !== monday &&
      day !== tuesday &&
      day !== wednesday &&
      day !== thursday &&
      day !== friday &&
      day !== saturday &&
      day !== sunday
    );
  };

  const seletedHours = [];

  profile?.availability_day
    ?.filter((e) => e.title === weekDays[getDay(startDateTime)])?.[0]
    ?.slots?.forEach((e) => {
      const sp = e.start_time?.split(":").map((e) => parseInt(e));

      for (
        let i = parseInt(e.start_time.slice(0, 2));
        i < parseInt(e.end_time.slice(0, 2));
        i++
      ) {
        seletedHours.push(moment(new Date().setHours(i, sp[1], sp[2])));
      }
    });

  const handleTimeSelect = (sh) => {
    setSelectTime(() => moment(sh.toDate()).format("HH:mm:ss"));
  };

  const handleCancel = () => {
    history.goBack();
  };

  const flgCheck = free_flg && free_flg.find((item) => item === id);
  return (
    <Fragment>
      {!profile && <ComponentLoader />}
      {chatLoader && <ComponentLoader />}

      {chatError && (
        <AlertDialog
          msg={chatError}
          onToggle={() => {
            chatError ===
            "This account is private so cannot accept booking requests and messages"
              ? handleCancel()
              : setChatError("");
          }}
        />
      )}

      {profile && (
        <div className={classes.bookSession}>
          <div
            style={{
              width:
                isAuthenticated === false && window.innerWidth > 600
                  ? "80%"
                  : "95%",
              maxWidth: isAuthenticated === false ? "1540px" : "",
              margin: isAuthenticated === false ? "70px auto 0 auto" : "",
            }}
            className={`bcg ${classes.bookSession__innerDiv}`}
          >
            <div className={classes.bookSessionContent}>
              <div className={classes.headDiv}>
                <div className={classes.backNavDiv}>
                  <svg
                    className={classes.backIcon}
                    onClick={() => history.goBack()}
                    style={{ cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.303"
                    height="17.946"
                    viewBox="0 0 18.303 17.946"
                  >
                    <g
                      id="Group_6913"
                      data-name="Group 6913"
                      transform="translate(-18699.623 -13146.869)"
                    >
                      <path
                        id="Path_4322"
                        data-name="Path 4322"
                        d="M18708.893,13147.221l-8.564,8.677,8.564,8.564"
                        fill="none"
                        stroke="#000"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_4323"
                        data-name="Path 4323"
                        d="M18717.926,13155.842h-16.957"
                        fill="none"
                        stroke="#000"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                  <h4
                    onClick={() => history.goBack()}
                    className={classes.backNavText}
                  >
                    Therapist Profile
                  </h4>
                </div>

                <div className={classes.BasicInfo}>
                  <div className={classes.BasicInfoDiv}>
                    <div className={classes.profileImageDiv}>
                      {profile.user.profile_image ? (
                        <ProfileImage src={profile?.user?.profile_image} />
                      ) : (
                        <ProfileImage />
                      )}
                    </div>

                    <p className={classes.nameDiv}>
                      {profile?.user?.first_name}&nbsp;
                      {profile?.user?.last_name}
                    </p>
                    <p className={classes.jobTitle}> {profile?.job_title} </p>
                  </div>
                </div>
              </div>

              <div className={classes.contentDiv}>
                <h3 className={classes.scheduleSession}>
                  {!flgCheck
                    ? "Schedule free initial consultation Session"
                    : "Schedule a PAID Session"}
                </h3>

                {!flgCheck ? (
                  <p className={classes.subInst}>
                    It’s important that you know what to expect when you take
                    sessions with a new therapist. When your free consultation
                    finishes, you’ll have an outline of the counselling process
                    so there are no surprises along the way!
                  </p>
                ) : (
                  <p className={classes.subInst}>
                    If you don’t see a time that works for you, please&nbsp;
                    <span
                      style={{ color: "#62BFAE", cursor: "pointer" }}
                      onClick={getChat}
                    >
                      contact
                    </span>
                    &nbsp;the therapist to schedule a different time.
                  </p>
                )}

                <div className={classes.datePickerDiv}>
                  <div className={classes.DatePicker}>
                    <DatePicker
                      calendarClassName={classes.bookSessionCal}
                      selected={startDateTime}
                      onChange={setStartDateTime}
                      disabledKeyboardNavigation
                      locale={"en-GB"}
                      timeIntervals={60}
                      minDate={addDays(new Date(), 1)}
                      maxDate={addMonths(new Date(), 1)}
                      filterDate={isAvailableDay}
                      includeTimes={seletedHours}
                      // excludeTimes={[
                      //     setHours(setMinutes(new Date(), 0), 10),
                      //     setHours(setMinutes(new Date(), 0), 13),
                      //     setHours(setMinutes(new Date(), 0), 14),
                      //     setHours(setMinutes(new Date(), 0), 17)
                      // ]}
                      inline
                      fixedHeight
                    />
                  </div>

                  <div className={classes.sessionTypeDiv}>
                    <h3 className={classes.sessionTypeTitle}>
                      Select Time Slot
                    </h3>

                    <div className={`${classes.timeSlotDiv}`}>
                      {seletedHours.length === 0 && (
                        <p className={classes.selectTimeMsg}>
                          Please select an available date.
                        </p>
                      )}
                      {seletedHours.map((sh, index) => {
                        const isSelected =
                          moment(sh.toDate()).format("HH:mm:ss") === selectTime;
                        return (
                          <div
                            key={index}
                            className={`${classes.timeSlot}`}
                            onClick={() => handleTimeSelect(sh)}
                            style={{
                              background: isSelected ? "#62bfae" : "",
                              color: isSelected ? "#fff" : "",
                              border: isSelected ? "none" : "",
                            }}
                          >
                            <Moment local format="hh:mm a">
                              {sh}
                            </Moment>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className={classes.sessionTypeDiv}>
                    <h3 className={classes.sessionTypeTitle}>
                      SELECT THE SESSION TYPE
                    </h3>
                    <div
                      className={`btn-group btn-group-toggle ${classes.sessionTypeOptions}`}
                      data-toggle="buttons"
                    >
                      <div
                        style={{
                          pointerEvents:
                            !profile.offers_online_sessions && "none",
                          opacity: !profile.offers_online_sessions && 0.5,
                        }}
                        className={classes.typeOptionDiv}
                      >
                        <label className={`btn ${classes.typeOption}`}>
                          <input
                            className={classes.optionInput}
                            disabled={
                              profile.offers_online_sessions ? false : true
                            }
                            type="radio"
                            name="video"
                            id="option1"
                            checked={type === "video" ? true : false}
                            onChange={handleTypeChange}
                          />
                          <span className={classes.sessionIconDiv}>
                            <svg
                              className={classes.sessionIcon}
                              xmlns="http://www.w3.org/2000/svg"
                              width="35.994"
                              height="20.568"
                              viewBox="0 0 35.994 20.568"
                            >
                              <path
                                id="Path_4376"
                                data-name="Path 4376"
                                d="M1639.466,873.274a1.286,1.286,0,0,1-1.971,1.086l-8.2-5.171v4.835a1.285,1.285,0,0,1-1.285,1.285h-23.249a1.285,1.285,0,0,1-1.286-1.285v-18a1.286,1.286,0,0,1,1.286-1.286h23.251a1.286,1.286,0,0,1,1.286,1.286v4.835l8.2-5.171a1.285,1.285,0,0,1,1.971,1.087Z"
                                transform="translate(-1603.473 -854.741)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                          <p className={`${classes.sessionType} activeType`}>
                            Video
                          </p>
                        </label>
                        {profile?.video_fee && profile?.video_fee > 0 && (
                          <div>
                          <p
                            style={{
                              color: type === "video" ? "#62bfae" : "#b4c0d3",
                            }}
                            className={classes.sessionFees}
                          >
                           <span style={!flgCheck?{textDecorationLine:'line-through'}:{}}>
                              {profile?.video_fee}&nbsp;{profile?.currency_code}
                            </span>     
                         
                          </p>
                          {!flgCheck &&
                          <p style={{ margin: "0" }}
                            className={classes.sessionFees} >
                            FREE
                          </p>
                          }
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          pointerEvents:
                            !profile.offers_phone_sessions && "none",
                          opacity: !profile.offers_phone_sessions && 0.5,
                        }}
                        className={classes.typeOptionDiv}
                      >
                        <label className={`btn ${classes.typeOption}`}>
                          <input
                            className={classes.optionInput}
                            disabled={
                              profile.offers_phone_sessions ? false : true
                            }
                            type="radio"
                            name="phone"
                            id="option2"
                            checked={type === "phone" ? true : false}
                            onChange={handleTypeChange}
                          />
                          <span className={classes.sessionIconDiv}>
                            <svg
                              className={classes.sessionIcon}
                              xmlns="http://www.w3.org/2000/svg"
                              width="27.912"
                              height="32.977"
                              viewBox="0 0 27.912 32.977"
                            >
                              <path
                                id="Path_4317"
                                data-name="Path 4317"
                                d="M957.811,327.692a8.388,8.388,0,0,1-2.436,3.786c-1.672,1.242-4.64.892-8.171-.765a32.237,32.237,0,0,1-16.9-21.978c-.7-3.844-.267-6.8,1.357-8.094a8.386,8.386,0,0,1,4.285-1.383.924.924,0,0,1,.968.473l2.882,5.7a1.641,1.641,0,0,1-.19,1.811q-.908,1.1-1.827,2.2a.184.184,0,0,0-.034.191,39.346,39.346,0,0,0,10.5,13.663.185.185,0,0,0,.194.015l2.595-1.2a1.642,1.642,0,0,1,1.8.282l4.764,4.248A.923.923,0,0,1,957.811,327.692Z"
                                transform="translate(-929.983 -299.245)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                          <p className={`${classes.sessionType} activeType`}>
                            Phone
                          </p>
                        </label>

                        {profile?.audio_fee && profile?.audio_fee > 0 && (
                          <div>
                          <p
                            style={{
                              color: type === "phone" ? "#62bfae" : "#b4c0d3",
                            }}
                            className={classes.sessionFees}
                          >
                            <span style={!flgCheck?{textDecorationLine:'line-through'}:{}}>
                            {profile?.audio_fee}&nbsp;{profile?.currency_code}
                            </span>
                            
                          </p>
                           {!flgCheck &&
                            <p style={{ margin: "0" }}
                              className={classes.sessionFees} >
                              FREE
                            </p>
                            }
                            </div>
                        )}
                      </div>

                      <div
                        style={{
                          pointerEvents: !flgCheck
                            ? "none"
                            : !profile.offers_in_clinic_sessions && "none",

                          opacity: !flgCheck
                            ? 0.5
                            : !profile.offers_in_clinic_sessions && 0.5,
                        }}
                        className={classes.typeOptionDiv}
                      >
                        <label className={`btn ${classes.typeOption}`}>
                          <input
                            className={classes.optionInput}
                            disabled={
                              profile.offers_in_clinic_sessions ? false : true
                            }
                            type="radio"
                            name="in_clinic"
                            id="option3"
                            checked={type === "in_clinic" ? true : false}
                            onChange={handleTypeChange}
                          />
                          <span className={classes.sessionIconDiv}>
                            <svg
                              className={classes.sessionIcon}
                              id="Group_7167"
                              data-name="Group 7167"
                              xmlns="http://www.w3.org/2000/svg"
                              width="36.624"
                              height="33.256"
                              viewBox="0 0 36.624 33.256"
                            >
                              <path
                                id="Path_4724"
                                data-name="Path 4724"
                                d="M3355.151,3420.112a6.368,6.368,0,1,0,6.369,6.368A6.375,6.375,0,0,0,3355.151,3420.112Zm4.32,8.253h-2.435v2.434h-3.771v-2.434h-2.435v-3.771h2.435v-2.435h3.771v2.435h2.435Z"
                                transform="translate(-3336.839 -3406.848)"
                                fill="#fff"
                              />
                              <path
                                id="Path_4725"
                                data-name="Path 4725"
                                d="M3101.543,3116.446l-17.786-11.214a.524.524,0,0,0-.56,0l-17.786,11.214a.525.525,0,0,0,.28.969h4.162v20.993H3097.1v-20.993h4.162A.525.525,0,0,0,3101.543,3116.446Zm-13.593,14.971a8,8,0,1,1,3.527-6.634A8,8,0,0,1,3087.951,3131.417Z"
                                transform="translate(-3065.165 -3105.151)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                          <p className={`${classes.sessionType} activeType`}>
                            Clinic
                          </p>
                        </label>

                        {profile?.clinic_fee && profile?.clinic_fee > 0 && (
                          <div>
                          <p
                            style={{
                              color:
                                type === "in_clinic" ? "#62bfae" : "#b4c0d3",
                            }}
                            className={classes.sessionFees}
                          >
                             <span style={!flgCheck?{textDecorationLine:'line-through'}:{}}>
                             {profile?.clinic_fee}&nbsp;{profile?.currency_code}
                             </span>
                           
                          </p>
                          {!flgCheck &&
                          <p style={{ margin: "0" }}
                            className={classes.sessionFees} >
                            FREE
                          </p>
                          }
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          pointerEvents: !flgCheck
                            ? "none"
                            : !profile.offers_home_visit_sessions && "none",

                          opacity: !flgCheck
                            ? 0.5
                            : !profile.offers_home_visit_sessions && 0.5,
                        }}
                        className={classes.typeOptionDiv}
                      >
                        <label className={`btn ${classes.typeOption}`}>
                          <input
                            className={classes.optionInput}
                            disabled={
                              profile.offers_home_visit_sessions ? false : true
                            }
                            type="radio"
                            name="home"
                            id="option4"
                            checked={type === "home" ? true : false}
                            onChange={handleTypeChange}
                          />
                          <span className={classes.sessionIconDiv}>
                            <svg
                              className={classes.sessionIcon}
                              xmlns="http://www.w3.org/2000/svg"
                              width="25.873"
                              height="37.996"
                              viewBox="0 0 25.873 37.996"
                            >
                              <g
                                id="Group_7168"
                                data-name="Group 7168"
                                transform="translate(0)"
                              >
                                <path
                                  id="Path_4726"
                                  data-name="Path 4726"
                                  d="M1121.88,1145.165h-2.19a1.345,1.345,0,0,0-1.41,1.265v.676h.932v-.676c0-.121.158-.33.478-.333h2.19c.318,0,.475.213.477.333v.676h.932v-.676A1.343,1.343,0,0,0,1121.88,1145.165Z"
                                  transform="translate(-1113.511 -1123.969)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_4727"
                                  data-name="Path 4727"
                                  d="M1141.464,1098.354a4.083,4.083,0,1,0-4.088-4.086A4.085,4.085,0,0,0,1141.464,1098.354Z"
                                  transform="translate(-1125.245 -1090.188)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_4728"
                                  data-name="Path 4728"
                                  d="M1139.868,1123.622l-6.177-9.3a1.97,1.97,0,0,0-1.192-.841l-2.172-.546-.207-.038-.032-.011-1.905-.4a2.346,2.346,0,0,0-.415-.04,2.027,2.027,0,0,0-1.464.627l-6.137,6.508a1.986,1.986,0,0,0-.547,1.379l0,3.315h4.016v-2.525l2.27-2.429-.247,6.206-.451,1.057-.106.224h1.394a3.386,3.386,0,0,1,3.381,3.382v.2l5.67,10.427a1.982,1.982,0,0,0,1.2.962,2.03,2.03,0,0,0,.567.082,1.968,1.968,0,0,0,.958-.249,2.02,2.02,0,0,0,.966-1.194,2.05,2.05,0,0,0,.079-.588,1.975,1.975,0,0,0-.246-.944l-6.2-12.808.443-5.079,3.215,4.849a2.008,2.008,0,1,0,3.343-2.224Z"
                                  transform="translate(-1114.333 -1103.863)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_4729"
                                  data-name="Path 4729"
                                  d="M1118.071,1152.278H1108.3a2.386,2.386,0,0,0-2.386,2.386v8.015a2.386,2.386,0,0,0,2.386,2.386h9.774a2.386,2.386,0,0,0,2.386-2.386v-8.015A2.385,2.385,0,0,0,1118.071,1152.278Zm.938,10.4a.94.94,0,0,1-.938.938H1108.3a.941.941,0,0,1-.939-.938v-8.015a.943.943,0,0,1,.939-.939h9.774a.942.942,0,0,1,.938.939Zm-4.5-7.386h-2.654v2.051h-2.051V1160h2.051v2.051h2.654V1160h2.05v-2.654h-2.05Z"
                                  transform="translate(-1105.911 -1128.34)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>
                          </span>
                          <p className={`${classes.sessionType} activeType`}>
                            Home
                          </p>
                        </label>

                        {profile?.home_fee && profile?.home_fee > 0 && (
                          <div>
                          <p
                            style={{
                              color: type === "home" ? "#62bfae" : "#b4c0d3",
                            }}
                            className={classes.sessionFees}
                          >
                             <span style={!flgCheck?{textDecorationLine:'line-through'}:{}}>
                             {profile?.home_fee}&nbsp;{profile?.currency_code}
                             </span>
                            
                          </p>
                           {!flgCheck &&
                            <p style={{ margin: "0" }}
                              className={classes.sessionFees} >
                              FREE
                            </p>
                            }
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.termsConditions}>
                  <p>
                    {termError
                      ? "Please accept"
                      : "By confirming, I agree to the"}
                    &nbsp;
                    <span
                      style={{
                        color: termAgree === true ? "#62BFAE" : "#FF5F5F",
                        cursor: "pointer",
                      }}
                      onClick={handleBookingTerm}
                    >
                      {!flgCheck
                        ? "Mindsum Free Initial Consultation Booking Terms."
                        : "Mindsum Booking Terms."}
                    </span>
                    &nbsp;
                    {termError && "to confirm booking."}
                  </p>
                </div>

                {loading ? (
                  <div className={`${classes.loader} ${classes.btnDiv}`}>
                    <span className={classes.loader__spin}></span>
                  </div>
                ) : (
                  <div className={classes.btnDiv}>
                    <button className={classes.submitBtn} onClick={bookSession}>
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={classes.bookSessionFooter}>
            <FooterLink />
          </div>

          {success && (
            <BookSessionSuccess data={sessionData} onToggle={onClose} />
          )}

          {bookingTerm && (
            <BookingTerms
              termHead={
                !flgCheck
                  ? "Mindsum Free Initial Consultation Booking Terms"
                  : "Mindsum Booking Terms"
              }
              termData={!flgCheck ? freeTerm : paidTerm}
              onClose={handleCloseTerm}
              setTermAgree={setTermAgree}
            />
          )}

          {isCardAdded && (
            <AlertDialog
              msg={isCardAdded}
              paymentCTA="Click here to add the card."
              onToggle={() => setIsCardAdded("")}
            />
          )}

          {isAuthError && (
            <AlertDialog
              msg={isAuthError}
              signupCTA="Sign up"
              loginCTA="Login"
              onToggle={() => setIsAuthError("")}
            />
          )}

          {error && <AlertDialog msg={error} onToggle={onToggle} />}
        </div>
      )}
    </Fragment>
  );
};

export default BookSession;