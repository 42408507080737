import React, { useEffect, useState } from "react";
import linkedIn from "../../../../../assets/icons/linkedInIcon.svg";
import twitter from "../../../../../assets/icons/twitterIcon.svg";
import facebook from "../../../../../assets/icons/facebookIcon.svg";
import website from "../../../../../assets/icons/website2.svg";
import classes from "../ProfileClient.module.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getExternalWebsiteTypes,
  updateUserProfile,
} from "../../../../../store/actions";

const PersonalInfoForm = ({ profile, getProfile }) => {
  const dispatch = useDispatch();
  const externalWebsiteTypes = useSelector(
    (state) => state.aboutYou.externalWebsiteTypes
  );
  const [edit, setEdit] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ reValidateMode: "onChange" });

  useEffect(() => {
    Object.keys(profile).map((e) => {
      setValue(e, profile[e]);

      if (e.toString() === "external_websites") {
        profile.external_websites.map((type) => {
          setValue(type["external_website_type"].id, type?.url);
          return null;
        });
      }
      return null;
    });
  }, []);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleCancle = () => {
    Object.keys(profile).map((e) => {
      setValue(e, profile[e]);

      if (e.toString() === "external_websites") {
        profile.external_websites.map((type) => {
          setValue(type["external_website_type"].id, type?.url);
          return null;
        });
      }
      return null;
    });
    getProfile();
    handleEdit();
  };

  useEffect(() => {
    dispatch(getExternalWebsiteTypes());
  }, []);

  const onSubmit = (formData) => {
    const userProfile = {
      job_title: formData.job_title,
      mobile: formData.mobile,
      landline: formData.landline,
      address_line_1: formData.address_line_1,
      address_line_2: formData.address_line_2,
      address_line_3: formData.address_line_3,
      city_town: formData.city_town,
      post_code: formData.post_code,
      more_about_yourself: formData.more_about_yourself,
      external_websites: externalWebsiteTypes.map((type) => ({
        external_website_type_id: type.id,
        url: formData[type.id] ? formData[type.id] : "",
      })),
    };

    dispatch(updateUserProfile(userProfile));
    handleEdit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.contactInfo}>
        <div className={classes.profileClient__title}>
          <h3 className={classes.profileClient__heading}>
            Personal Information
          </h3>
          <div>
            {edit && (
              <div onClick={handleEdit} className={classes.editProfile}>
                Edit
              </div>
            )}
            {!edit && (
              <div className={classes.saveButton}>
                <button type="submit" className={classes.editProfile}>
                  Save
                </button>
                <div
                  onClick={handleCancle}
                  className={`${classes.editProfile} ${classes.cancelBtn}`}
                >
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.basicInfo__addressDiv}>
          <p className={classes.addInputLabel}>Job Title</p>
          <input
            {...register("job_title", { required: true })}
            type="text"
            id="jobTitleInput"
            name="job_title"
            className={classes.profileInput}
            disabled={edit}
          />

          {errors?.job_title && errors?.job_title?.type === "required" && (
            <p className="errorTxt"> Job Title is required</p>
          )}
        </div>

        <div className={classes.basicInfo__addressDiv}>
          <p style={{ marginBottom: "3px" }} className={classes.addInputLabel}>
            Tell Us About Yourself
          </p>
          <p className={classes.addInputLabel}>
            (We strongly recommend to write something about yourself so that we
            can improve your profile visibility on search engines.)
          </p>
          <textarea
            {...register("more_about_yourself", {
              required: true,
              maxLength: 1000,
            })}
            type="text"
            rows="5"
            className={classes.profileInput}
            name="more_about_yourself"
            disabled={edit}
          />
          {errors?.more_about_yourself &&
            errors.more_about_yourself.type === "required" && (
              <p className="errorTxt"> Bio is required</p>
            )}

          {errors?.more_about_yourself &&
            errors.more_about_yourself.type === "maxLength" && (
              <p className="errorTxt">
                Max length exceeded (Max 1000 characters)
              </p>
            )}
        </div>

        <div className={classes.contactDiv}>
          <div className={classes.contactInputDiv}>
            <p className={classes.addInputLabel}>Contact (Mobile)</p>
            <input
              {...register("mobile", {
                required: true,
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              })}
              type="tel"
              className={classes.profileInput}
              name="mobile"
              disabled={edit}
            />
            {errors?.mobile && errors.mobile.type === "required" && (
              <p className="errorTxt">Contact (Mobile) is required</p>
            )}

            {errors?.mobile && errors.mobile.type === "pattern" && (
              <p className="errorTxt"> Enter a valid contact number </p>
            )}
          </div>

          <div className={classes.contactInputDiv}>
            <p className={classes.addInputLabel}>Contact (Landline)</p>
            <input
              {...register("landline", {
                required: false,
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              })}
              type="tel"
              name="landline"
              className={classes.profileInput}
              disabled={edit}
            />

            {errors?.landline && errors.landline.type === "pattern" && (
              <p className="errorTxt"> Enter a valid contact number </p>
            )}
          </div>
        </div>

        <div className={classes.addressDivFlex}>
          <div className={classes.addressDiv}>
            <p className={classes.addInputLabel}>Address Line 1</p>
            <input
              {...register("address_line_1", { required: true })}
              type="text"
              className={classes.profileInput}
              name="address_line_1"
              disabled={edit}
            />
            {errors?.address_line_1 &&
              errors.address_line_1.type === "required" && (
                <p className="errorTxt"> Address is required</p>
              )}
          </div>

          <div className={classes.addressDiv}>
            <p className={classes.addInputLabel}>Address Line 2</p>
            <input
              {...register("address_line_2", { required: false })}
              type="text"
              className={classes.profileInput}
              name="address_line_2"
              disabled={edit}
            />
          </div>
        </div>

        <div className={classes.addressDivFlex}>
          <div className={classes.addressDiv}>
            <p className={classes.addInputLabel}>Address Line 3</p>
            <input
              {...register("address_line_3", { required: false })}
              type="text"
              className={classes.profileInput}
              name="address_line_3"
              disabled={edit}
            />
          </div>

          <div className={classes.addressDiv}>
            <p className={classes.addInputLabel}> City/Town</p>
            <input
              {...register("city_town", { required: true })}
              type="text"
              className={classes.profileInput}
              name="city_town"
              disabled={edit}
            />
            {errors?.city_town && errors.city_town.type === "required" && (
              <p className="errorTxt"> Address is required</p>
            )}
          </div>
        </div>

        <div className={classes.addressDivFlex}>
          <div className={classes.addressDiv}>
            <p className={classes.addInputLabel}> Postcode</p>
            <input
              {...register("post_code", { required: true })}
              type="text"
              className={classes.profileInput}
              name="post_code"
              disabled={edit}
            />
            {errors?.post_code && errors.post_code.type === "required" && (
              <p className="errorTxt"> Postcode is required</p>
            )}
          </div>

          <div className={classes.addressDiv}>
            <p className={classes.addInputLabel}> Country </p>
            <input
              // {...register("country", { required: true })}
              type="text"
              className={classes.profileInput}
              name="country"
              value="United Kingdom"
              disabled
            />
            {/* {errors?.country && errors.country.type === "required" && (
              <p className="errorTxt"> Country is required</p>
            )} */}
          </div>
        </div>

        <p style={{ fontSize: "18px" }} className={classes.addInputLabel}>
          Social Links
        </p>

        <p style={{ fontSize: "14px", lineHeight: "130%" }}>
          We strongly recommend to add these links so that we can improve your
          profile visibility on search engines.
        </p>

        <div className={classes.extWebsiteGrid}>
          {externalWebsiteTypes?.map((type) => (
            <div key={type.id} className={classes.extWebsiteDiv}>
              <div className={classes.socialIcon}>
                {type.title === "Website" && <img src={website} alt="" />}
                {type.title === "Facebook" && <img src={facebook} alt="" />}
                {type.title === "LinkedIn" && <img src={linkedIn} alt="" />}
                {type.title === "Twitter" && <img src={twitter} alt="" />}
              </div>

              <input
                {...register(type.id, {
                  required: false,
                  pattern: /^(http|https):/,
                })}
                type="text"
                name={type.id}
                disabled={edit}
                className={classes.profileInput}
                id={`${type.title}Input`}
                placeholder={`e.g. https://${type.title}.com/myprofile`}
              />
              {errors?.[type.id] && errors[type.id].type === "pattern" && (
                <p className="errorTxt">Please Enter a Valid URL</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </form>
  );
};

export default React.memo(PersonalInfoForm);
