import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "../UpcomingSes.module.scss";
import RecommendResults from "./RecommendResults";
import axios from "axios";
import qs from "qs";
import { BASE_URL } from "../../../../../../shared/constants";
import ComponentLoader from "../../../../../UI/ComponentLoader";

const NoSession = ({ user }) => {
  const profile = useSelector((state) => state.userProfile.profile);

  const [topThree, setTopThree] = useState([]);
  const [noData, setNoDate] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getRecommendedTherapists = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${BASE_URL}/recommend_weighatge`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        params: {
          limit: 3,
        },

        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });

      setTopThree(res.data.results);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error?.response?.status === 500) {
        setIsProfileComplete(true);
      }

      if (error?.response?.data?.error === "No Match Found") {
        setNoDate(true);
      }
      console.log(error.response.data.error);
    }
  };

  useEffect(() => {
    if (profile) {
      getRecommendedTherapists();
    }
  }, [profile]);

  return user.type === "client" ? (
    <>
      <div className={classes.noSession}>
        <div className={classes.noSession__firstCol}>
          <h3 className={classes.UpcomingHeading}>My Upcoming Session</h3>

          <p className={classes.upcomingNoSession}>
            You have no upcoming sessions. To book a session with a therapist or
            mental healthcare provider, please select one of the therapist and
            you can book a session from their profile page.
          </p>

          <Link to="/search" className={classes.viewTherapists}>
            View Therapists
          </Link>
        </div>
        <div className={classes.noSession__secondCol}>
          <h3 className={classes.recommendedTherapist}>
            Recommended Therapists
          </h3>

          {isProfileComplete ? (
            <div className={classes.recommInstructionDiv}>
              <p className={classes.completeProfileText}>
                Please complete your profile for better recommendations
              </p>
              <Link to={`client/${user.id}/profile`}>Complete Profile</Link>
            </div>
          ) : (
            <div className={classes.recommInstructionDiv}>
              <p className={classes.recommInstruction}>
                We have found three best matches based on your needs and
                preferences. Want to search other therapists on our platform?
                Click search on the top bar.
              </p>

              <Link to="/search" className={classes.recommViewMore}>
                View More
              </Link>
            </div>
          )}

          <div className={classes.recommContent}>
            {topThree.length > 0 &&
              topThree
                .slice(0, 3)
                .map((item) => <RecommendResults key={item.id} user={item} />)}

            {noData && (
              <p
                style={{
                  padding: "10px 3%",
                  fontSize: "20px",
                  color: "#ff5f5f",
                }}
              >
                No Match Found
              </p>
            )}
          </div>
        </div>

        {isLoading && <ComponentLoader />}
      </div>
    </>
  ) : (
    <div className={classes.noSession}>
      <div className={classes.noSession__firstCol}>
        <h3 className={classes.UpcomingHeading}>Upcoming Session</h3>

        <p className={classes.upcomingNoSession}>
          You have no upcoming sessions.
        </p>
      </div>
    </div>
  );
};

export default NoSession;
