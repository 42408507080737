import React, { useState, useEffect } from "react";
import classes from "../ProfileClient.module.scss";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import Moment from "react-moment";
import TimeInput from "../../../../UI/TimeInputPopup/TimeInput";
import { useDispatch, useSelector } from "react-redux";
import { deleteAvailabilitySlot } from "../../../../../store/actions";
import moment from "moment";
import ComponentLoader from "../../../../UI/ComponentLoader";

const weekDays = [
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
  { id: 7, day: "Sunday" },
];

const Availability = ({ getProfile }) => {
  const profile = useSelector((state) => state.userProfile.profile);
  const dispatch = useDispatch();
  const [isShowPopup, setIsShowPopup] = useState("");
  const [availabilityError, setAvailabilityError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePopup = (day) => {
    setIsShowPopup((prev) => day);
  };

  const handleCancel = () => {
    setIsShowPopup((prev) => "");
  };

  const handleSlotDelete = async (slot, day) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const url =
      BASE_URL + "/therapist/availability-delete/" + slot.id + "/" + day;
    const availability = profile.availability_day.map((wday) => {
      if (wday.title === day) {
        return {
          ...wday,
          slots: wday.slots.filter((sl) => sl.id !== slot.id),
        };
      }

      return wday;
    });

    try {
      dispatch(
        deleteAvailabilitySlot({ ...profile, availability_day: availability })
      );

      await axios.delete(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      getProfile();
    } catch (err) {
      setIsLoading(false);
      getProfile();

      window.alert(JSON.stringify(err));
      console.log("err", JSON.stringify(err));
    }
  };

  useEffect(() => {
    if (profile?.availability_day.every((e) => e?.is_available === false)) {
      setAvailabilityError(() => "Please add your availability");
    } else {
      setAvailabilityError(() => "");
    }
  }, [getProfile]);

  const getFormatedTime = (time) => {
    const st = time?.split(":").map((e) => parseInt(e));

    return moment(new Date().setHours(...st));
  };

  return (
    <div className={classes.preferences}>
      <div className={classes.profileClient__title}>
        <h3 className={classes.profileClient__heading}> Availability </h3>
      </div>

      <div className={classes.preferences__content}>
        <div
          className={`row ml-2 mt-2 mb-3 ${classes.therapyTypeOptions} ${classes.availabilityOptions}`}
        >
          {weekDays.map((weday) => {
            return (
              <div
                key={weday.id}
                className={`${classes.therapyTypeWrap} ${classes.rowFlex} mb-1 ml-2`}
              >
                <label className={`${classes.weekDaysLabel}`}>
                  {weday.day}
                </label>
                <div className={classes.selectedTimeRange}>
                  {profile?.availability_day
                    ?.filter((e) => e.title === weday.day)?.[0]
                    ?.["slots"]?.map((s) => {
                      return (
                        <div key={s.id} className={classes.selectedTimesDiv}>
                          <p className={classes.selectedTimes}>
                            <Moment local format="hh:mm a">
                              {getFormatedTime(s?.start_time)}
                            </Moment>
                            <Moment local format="hh:mm a">
                              {getFormatedTime(s?.end_time)}
                            </Moment>
                          </p>
                          <button
                            type="button"
                            onClick={() => handleSlotDelete(s, weday.day)}
                            className={classes.availableAddBtn}
                          >
                            <svg
                              className={classes.svgIcon}
                              xmlns="http://www.w3.org/2000/svg"
                              width="409.898"
                              height="517.767"
                              viewBox="0 0 409.898 517.767"
                            >
                              <path
                                id="Path_3"
                                data-name="Path 3"
                                d="M-1103.2-248.771H-1211.07v-21.573a32.394,32.394,0,0,0-32.361-32.361h-64.72a32.394,32.394,0,0,0-32.361,32.361v21.573H-1448.38a32.393,32.393,0,0,0-32.36,32.361,32.335,32.335,0,0,0,27.247,31.842l43.4,371.023a32.35,32.35,0,0,0,32.134,28.607h204.345a32.349,32.349,0,0,0,32.134-28.607l43.406-371.023a32.335,32.335,0,0,0,27.237-31.842A32.393,32.393,0,0,0-1103.2-248.771Zm-215.736-21.573a10.8,10.8,0,0,1,10.787-10.787h64.72a10.8,10.8,0,0,1,10.787,10.787v21.573h-86.294ZM-1360.6-163.587a10.832,10.832,0,0,1,11.542,9.967l22.965,315.093a10.794,10.794,0,0,1-9.967,11.552c-.27.011-.539.022-.8.022a10.791,10.791,0,0,1-10.755-10l-22.965-315.093A10.787,10.787,0,0,1-1360.6-163.587Zm74.019,325.847V-152.833a10.791,10.791,0,0,1,10.787-10.787A10.791,10.791,0,0,1-1265-152.833V162.26a10.791,10.791,0,0,1-10.787,10.787A10.791,10.791,0,0,1-1286.578,162.26Zm105.571-314.3-22.966,315.093a10.79,10.79,0,0,1-10.754,10c-.259,0-.529-.011-.8-.022a10.8,10.8,0,0,1-9.967-11.552l22.965-315.093a10.851,10.851,0,0,1,11.542-9.967A10.787,10.787,0,0,1-1181.007-152.045Z"
                                transform="translate(1480.74 302.705)"
                                fill="#ff5f5f"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                </div>
                <button
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => handlePopup(weday.day)}
                  type="button"
                  className={classes.availableAddBtn}
                >
                  <svg
                    className={classes.svgIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    width="820.056"
                    height="768.179"
                    viewBox="0 0 820.056 768.179"
                  >
                    <g
                      id="Group_1"
                      data-name="Group 1"
                      transform="translate(-147 -156)"
                    >
                      <rect
                        id="Rectangle_1"
                        data-name="Rectangle 1"
                        width="816.056"
                        height="764.179"
                        rx="76.512"
                        transform="translate(149 158)"
                        fill="#fff"
                        stroke="#fff"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                      />
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M-2026.65-93.654H-2235.7V-302.7h-99.664V-93.654h-209.051V6.01h209.051V215.062h99.664V6.01h209.051Z"
                        transform="translate(2842.561 583.911)"
                        fill="#62bfae"
                      />
                      <g id="Group_2" data-name="Group 2">
                        <path
                          id="Path_4"
                          data-name="Path 4"
                          d="M-2026.65-93.654H-2235.7V-302.7h-99.664V-93.654h-209.051V6.01h209.051V215.062h99.664V6.01h209.051Z"
                          transform="translate(2830.561 583.822)"
                          fill="#62bfae"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            );
          })}

          {isShowPopup && (
            <TimeInput
              day={isShowPopup}
              handleCancel={handleCancel}
              getProfile={getProfile}
            />
          )}
        </div>

        {availabilityError && (
          <p style={{ paddingLeft: "2%" }} className="errorTxt">
            {availabilityError}
          </p>
        )}
      </div>

      {isLoading && <ComponentLoader />}
    </div>
  );
};

export default React.memo(Availability);
