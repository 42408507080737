import React, { useState, useEffect } from "react";
import classes from "./StepTwoTherapistForm.module.scss";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";

const StepTwoTherapistForm = (props) => {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm({ reValidateMode: "onChange" });

  const getTherapistSkills = async (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/skills";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getTherapistProfessionalBodies = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/professional-bodies";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getTherapistCounsellingAreas = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/counselling-areas";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  const getTherapistTherapyTypes = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/therapy-types";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  useEffect(() => {
    Object.keys(props.profile).map((e) => {
      setValue(e, props.profile[e]);

      if (e.toString() === "skills") {
        setValue(
          "skills",
          props.profile.skills.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      if (e.toString() === "professional_bodies") {
        setValue(
          "professional_bodies",
          props.profile.professional_bodies.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      if (e.toString() === "counselling_areas") {
        setValue(
          "counselling_areas",
          props.profile.counselling_areas.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      if (e.toString() === "therapy_types") {
        setValue(
          "therapy_types",
          props.profile.therapy_types.map((e) => ({
            ...e,
            label: e.title,
            value: e.title,
          }))
        );
      }

      return e;
    });
  }, []);

  const onSubmit = (formData) => {
    const userProfile = {
      skills: formData.skills,
      professional_bodies: formData.professional_bodies,
      counselling_areas: formData.counselling_areas,
      therapy_types: formData.therapy_types,
      qualifications_training_experience:
        formData.qualifications_training_experience,
    };
    props.onUpdateUserProfile(userProfile);
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#F6F5F9",
      border: "none",
      outline: "none",
      marginLeft: "15px",
      width: "90%",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "12px",
    }),
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: "15px",
        width: "90%",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#62BFAE",
        color: "white",
        borderRadius: "15px",
        padding: "3px 4px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
      fontFamily: "L-SBold",
      fontSize: "10px",
      marginRight: "10px",
      textTransform: "uppercase",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#62BFAE",
      backgroundColor: "#D0ECE6",
      borderRadius: "50%",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#fff",
      },
    }),
  };

  return (
    <div className={classes.formDiv2}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${classes.formTwo} ${classes.formTwoTherapist}`}
      >
        <div className="form-group">
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>
            <label
              htmlFor="skillsInput"
              className={`mb-0 ${classes.labelOne} `}
            >
              Add your skills
            </label>
          </div>

          <small className="form-text text-muted mb-2 ml-3">
            Type and select from the list a minimum of three skills
          </small>

          <div className={classes.controllerDiv}>
            <Controller
              name="skills"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistSkills}
                    placeholder="e.g. Critical thinking"
                    closeMenuOnSelect={true}
                    defaultOptions
                    isMulti
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.skills && errors.skills?.type === "required" && (
              <p style={{ paddingLeft: "4%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>
        </div>

        <div className="form-group mt-4">
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>
            <label
              htmlFor="qualificationsInput"
              className={`mb-0 ${classes.labelOne} `}
            >
              Your qualifications, training and experience
            </label>
          </div>

          <small className="form-text text-muted mb-2 ml-3">
            Please list down qualifications, training and experience
          </small>

          <textarea
            {...register("qualifications_training_experience", {
              required: false,
              maxLength: 1000,
            })}
            className={`${classes.therapistTextArea}`}
            id="qualificationsInput"
            rows="8"
            name="qualifications_training_experience"
          />
          {errors?.qualifications_training_experience &&
            errors.qualifications_training_experience.type === "required" && (
              <p className="errorTxt" style={{ paddingLeft: "4%" }}>
                Your qualifications, training and experience is required
              </p>
            )}

          {errors?.qualifications_training_experience &&
            errors.qualifications_training_experience.type === "maxLength" && (
              <p className={`errorTxt`} style={{ paddingLeft: "4%" }}>
                Max. length exceeded (Max 1000 characters)
              </p>
            )}
        </div>

        <div className="form-group mt-3">
          <div className={classes.titleFlex} style={{ paddingBottom: "8px" }}>
            <div className={classes.listStyle}> </div>
            <label
              htmlFor="professionalBodyInput"
              className={`mb-0 ${classes.labelOne} `}
            >
              Your memberships with professional bodies
            </label>
          </div>

          <div className={classes.controllerDiv}>
            <Controller
              name="professional_bodies"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistProfessionalBodies}
                    placeholder="e.g. BACP - Individual Member"
                    closeMenuOnSelect={true}
                    defaultOptions
                    isMulti
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.professional_bodies &&
              errors.professional_bodies?.type === "required" && (
                <p style={{ paddingLeft: "4%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>
        </div>

        <div className="form-group mt-4">
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>
            <label
              htmlFor="counsellingAreasInput"
              className={`mb-0 ${classes.labelOne} `}
            >
              Counselling areas you deal with
            </label>
          </div>

          <small className="form-text text-muted mb-2 ml-3">
            Type and select from the list
          </small>

          <div className={classes.controllerDiv}>
            <Controller
              name="counselling_areas"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistCounsellingAreas}
                    placeholder="e.g. Bipolar disorder"
                    closeMenuOnSelect={true}
                    defaultOptions
                    isMulti
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.counselling_areas &&
              errors.counselling_areas?.type === "required" && (
                <p style={{ paddingLeft: "4%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>
        </div>

        <div className="form-group mt-4">
          <div className={classes.titleFlex}>
            <div className={classes.listStyle}> </div>
            <label
              htmlFor="skillsInput"
              className={`mb-0 ${classes.labelOne} `}
            >
              Therapies offered
            </label>
          </div>

          <small className="form-text text-muted mb-2 ml-3">
            Type and select from the list
          </small>

          <div className={classes.controllerDiv}>
            <Controller
              name="therapy_types"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={getTherapistTherapyTypes}
                    placeholder="e.g. Cognitive behavioral therapies"
                    closeMenuOnSelect={true}
                    defaultOptions
                    isMulti
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.therapy_types &&
              errors.therapy_types?.type === "required" && (
                <p style={{ paddingLeft: "4%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>
        </div>

        <button type="submit" className={classes.nextBtn}>
          Next
        </button>
      </form>
    </div>
  );
};

export default StepTwoTherapistForm;
