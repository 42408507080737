import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import ComponentLoader from "../../../../../../UI/ComponentLoader";

const BankAccountSetupForm = (props) => {
  const [holderName, setHolderName] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    stripe
      .createToken("bank_account", {
        country: "GB",
        currency: "gbp",
        routing_number: sortCode.toString(),
        account_number: accountNumber.toString(),
        account_holder_name: holderName,
        account_holder_type: "individual",
      })
      .then((result) => {
        if (result.error) {
          console.log(result.error);
          // alert(result.error);
        } else {
          setLoading(false);
          props.setExternalAccount(result.token.id);
          props.getAccountLink();
        }
      });
  };

  return (
    <div
      className="modal fade"
      id="addBankAccountModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="addBankAccountModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addBankAccountModalLabel">
              Set bank account details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <form>
                  <div className="form-group">
                    <label>Holder name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={holderName}
                      onChange={(event) => setHolderName(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Sort code</label>
                    <input
                      type="number"
                      className="form-control"
                      value={sortCode}
                      onChange={(event) => setSortCode(event.target.value)}
                    />
                    <small className="text-muted">Must be 6 digits long</small>
                  </div>
                  <div className="form-group">
                    <label>Account number</label>
                    <input
                      type="number"
                      className="form-control"
                      value={accountNumber}
                      onChange={(event) => setAccountNumber(event.target.value)}
                    />
                    <small className="text-muted">Must be 8 digits long</small>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>

            {loading ? (
              <ComponentLoader />
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountSetupForm;
