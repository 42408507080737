import React from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NavbarItem = props => {
    return (
        <NavLink
            className="nav-link"
            to={props.link}
            exact={true}>
            <li className="nav-item active">
                <FontAwesomeIcon icon={props.icon} className="mr-2"/>
                {props.children}
            </li>
        </NavLink>
    );
};

export default NavbarItem;
