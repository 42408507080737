import React from "react";
import { Link } from "react-router-dom";
import classes from "./Alert.module.scss";

const AlertDialog = (props) => {
  return (
    <div className={classes.alertDialog}>
      <div className={classes.innerDiv}>
        <div onClick={props.onToggle} className={classes.closeAlert}>
          <svg
            className={classes.closeIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="25.731"
            height="25.731"
            viewBox="0 0 25.731 25.731"
          >
            <g
              id="Group_226"
              data-name="Group 226"
              transform="translate(2559.582 -8520.719) rotate(45)"
            >
              <line
                id="Line_21"
                data-name="Line 21"
                y2="30.389"
                transform="translate(4233.354 7819.761)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <line
                id="Line_22"
                data-name="Line 22"
                x2="30.389"
                transform="translate(4218.16 7834.955)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </g>
          </svg>
        </div>

        <p className={classes.errorTxt}>{props.msg}</p>

        <div className={classes.alertSignupContainer}>
          {props.signupCTA && (
            <div style={{ color: "#f3ab8e" }} className={classes.alertSignup}>
              <Link to="/signup-as-client" className={classes.alertSignupBtn}>
                {props.signupCTA}
              </Link>
            </div>
          )}

          {props.loginCTA && (
            <div className={classes.alertSignup}>
              <Link to="/login" className={classes.alertSignupBtn}>
                {props.loginCTA}
              </Link>
            </div>
          )}

          {props.paymentCTA && (
            <div className={classes.alertPayment}>
              <Link to="/settings/payment">{props.paymentCTA}</Link>
            </div>
          )}

          {props.verifyCTA && (
            <div className={classes.alertPayment}>
              <Link to="/verify-email">{props.verifyCTA}</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;
