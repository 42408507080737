import React, { Fragment } from "react";
import StepTwoTherapistForm from "./StepTwoForm/StepTwoTherapistForm";
import StepTwoClientForm from "./StepTwoForm/StepTwoClientForm";
import classes from "../StepOne/Step1.module.scss";
import FooterLink from "../../../footerLink/FooterLink";
import logo from "../../../../assets/images/mindsum-logo-new.svg";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";

const StepTwo = (props) => {
  if (props.navigate) {
    props.onResetNavigate();
    if (props.type.toString() === "therapist")
      return <Redirect to="/preferences" />;
    else return <Redirect to="/preview" />;
  }

  let content = null;
  if (props.profile) {
    let form = (
      <StepTwoClientForm
        profile={props.profile}
        onUpdateUserProfile={props.onUpdateUserProfile}
      />
    );
    if (props.type.toString() === "therapist")
      form = (
        <StepTwoTherapistForm
          profile={props.profile}
          onUpdateUserProfile={props.onUpdateUserProfile}
        />
      );

    content = (
      <Fragment>
        <div className={classes.step1Nav}>
          {props.type.toString() === "client" ? (
            <div className={classes.firstPart}>
              <span className={classes.spanText}>ABOUT &#62;</span>

              <span className={`${classes.spanText} ${classes.active}`}>
                NEEDS &#62;
              </span>

              <span className={classes.spanText}>PREVIEW </span>
            </div>
          ) : (
            <div className={classes.firstPart}>
              <span className={classes.spanText}>ABOUT &#62;</span>

              <span className={`${classes.spanText} ${classes.active}`}>
                SKILLS &#62;
              </span>

              <span className={`${classes.spanText}`}>PREFERENCES &#62;</span>

              <span className={classes.spanText}>PREVIEW </span>
            </div>
          )}

          <div className={classes.secondPart}>
            <Link className={classes.navLogo} to="/">
              <img src={logo} alt="logo" className={classes.ImgFluid} />
            </Link>
          </div>
          <div className={classes.thirdPart}>
            {props.isAuthenticated &&
              (!props.user.is_signup_complete ||
                !props.user.is_profile_approved) && (
                <ul className="navbar-nav">
                  <Link to="logout">Logout</Link>
                </ul>
              )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={classes.form2Div}>{form}</div>

          <div
            style={{
              paddingTop: "4%",
            }}
          >
            <FooterLink />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <>
      <div className={classes.stepOneDiv}>
        <div className={classes.mainCard}>{content}</div>
      </div>
      <div className={classes.coverBg}> </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.auth.user["type"],
    profile: state.userProfile.profile,
    navigate: state.userProfile.navigate,
    user: state.auth.user,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUserProfile: (userProfile) =>
      dispatch(actions.updateUserProfile(userProfile)),
    onResetNavigate: () => dispatch(actions.resetNavigate()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
