import * as actionTypes from "./actionTypes";
import axios from "axios";
import { BASE_URL } from "../../shared/constants";

export const getExternalWebsiteTypesStart = () => {
  return {
    type: actionTypes.GET_EXTERNAL_WEBSITE_TYPES_START,
  };
};

export const getExternalWebsiteTypesSuccess = (externalWebsiteTypes) => {
  return {
    type: actionTypes.GET_EXTERNAL_WEBSITE_TYPES_SUCCESS,
    externalWebsiteTypes: externalWebsiteTypes,
  };
};

export const getExternalWebsiteTypesFail = (error) => {
  return {
    type: actionTypes.GET_EXTERNAL_WEBSITE_TYPES_FAIL,
    error: error,
  };
};

export const getExternalWebsiteTypes = (email) => {
  return (dispatch) => {
    dispatch(getExternalWebsiteTypesStart());
    const url = BASE_URL + "/external-website-types";
    const token = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getExternalWebsiteTypesSuccess(response.data.results));
      })
      .catch((error) => {
        dispatch(getExternalWebsiteTypesFail(error.response.data.error));
      });
  };
};
