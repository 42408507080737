const LOCALHOST_URL = "http://localhost:8000/api";
const TEST_URL = "https://mindsum-296321.appspot.com/api";
const LIVE_URL = "https://mindsum-310622.appspot.com/api";

const SEARCH_TEST = "https://RWE3XB1GHW-dsn.algolia.net/1/indexes";
const SEARCH_LIVE = "https://6DVUDUIO18-dsn.algolia.net/1/indexes";
const SEARCH_TEST_KEY = "2070ab2f89fdeed5f44398c3811d0704";
const SEARCH_LIVE_KEY = "43d5471c6385208c22632de91bad0c50";
const SEARCH_ID_TEST = "RWE3XB1GHW";
const SEARCH_ID_LIVE = "6DVUDUIO18";

const SEARCH_INDEX_DEV = "dev_mindsum_therapist";
const SEARCH_INDEX_DEV_ASEC = "dev_mindsum_therapist_asec";
const SEARCH_INDEX_DEV_DESC = "dev_mindsum_therapist_desc";

const SEARCH_INDEX_LIVE = "live_mindsum_therapist";
const SEARCH_INDEX_LIVE_ASEC = "live_mindsum_therapist_asec";
const SEARCH_INDEX_LIVE_DESC = "live_mindsum_therapist_desc";

const TEST_STRIPE_PK =
  "pk_test_51GxxkRGM2mxZtqvCkNIaxIRgfzr4v8XsTgvYiwlbIxccZLkjMzy2EMKkGRZsODSyLeQvqVvvdXWVm9XZS8AXU2Vi00VTThO7yb";
const LIVE_STRIPE_PK =
  "pk_live_51GxxkRGM2mxZtqvCQZfL1ZzZL6HW75fMAOYs2SGmmYvDLFhdXqTZSq8etBifIkkDucXIhOynJHZs8mDA7hGYyOhk00HFsLMcHT";

let BASE_URL;
let STRIPE_PK;
let SEARCH_URL;
let SEARCH_KEY;
let SEARCH_ID;
let SEARCH_INDEX_MAIN;
let SEARCH_INDEX_MAIN_ASEC;
let SEARCH_INDEX_MAIN_DESC;

if (process.env.REACT_APP_ENV === "live") {
  BASE_URL = LIVE_URL;
  STRIPE_PK = LIVE_STRIPE_PK;
  SEARCH_URL = SEARCH_LIVE;
  SEARCH_KEY = SEARCH_LIVE_KEY;
  SEARCH_ID = SEARCH_ID_LIVE;
  SEARCH_INDEX_MAIN = SEARCH_INDEX_LIVE;
  SEARCH_INDEX_MAIN_ASEC = SEARCH_INDEX_LIVE_ASEC;
  SEARCH_INDEX_MAIN_DESC = SEARCH_INDEX_LIVE_DESC;
} else if (process.env.REACT_APP_ENV === "local") {
  BASE_URL = LOCALHOST_URL;
  STRIPE_PK = TEST_STRIPE_PK;
  SEARCH_URL = SEARCH_TEST;
  SEARCH_KEY = SEARCH_TEST_KEY;
  SEARCH_ID = SEARCH_ID_TEST;
  SEARCH_INDEX_MAIN = SEARCH_INDEX_DEV;
  SEARCH_INDEX_MAIN_ASEC = SEARCH_INDEX_DEV_ASEC;
  SEARCH_INDEX_MAIN_DESC = SEARCH_INDEX_DEV_DESC;
} else {
  BASE_URL = TEST_URL;
  STRIPE_PK = TEST_STRIPE_PK;
  SEARCH_URL = SEARCH_TEST;
  SEARCH_KEY = SEARCH_TEST_KEY;
  SEARCH_ID = SEARCH_ID_TEST;
  SEARCH_INDEX_MAIN = SEARCH_INDEX_DEV;
  SEARCH_INDEX_MAIN_ASEC = SEARCH_INDEX_DEV_ASEC;
  SEARCH_INDEX_MAIN_DESC = SEARCH_INDEX_DEV_DESC;
}

export {
  BASE_URL,
  STRIPE_PK,
  SEARCH_URL,
  SEARCH_KEY,
  SEARCH_ID,
  SEARCH_INDEX_MAIN,
  SEARCH_INDEX_MAIN_ASEC,
  SEARCH_INDEX_MAIN_DESC,
};
