import React, { useState, useEffect } from "react";
import classes from "./StepThreeForm.module.scss";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import AlertDialog from "../../../../UI/Alert/AlertDialog";

const StepThreeForm = (props) => {
  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    trigger,
    setValue,
    clearErrors,
    register,
  } = useForm({ reValidateMode: "onChange" });

  const [defaultFee, setDefaultFee] = useState(
    props.profile.default_session_fee
  );

  const [clinicFee, setClinicFee] = useState(props.profile.clinic_fee);
  const [homeFee, setHomeFee] = useState(props.profile.home_fee);
  const [audioFee, setAudioFee] = useState(props.profile.audio_fee);
  const [videoFee, setVideoFee] = useState(props.profile.video_fee);
  // const [availabilityErrror, setAvailabilityError] = useState(false);

  const [currencyCode, setCurrencyCode] = useState(
    props.profile.currency_code ? props.profile.currency_code : "GBP"
  );

  const [businessType, setBusinessType] = useState(
    props.profile.business_type ? Number(props.profile.business_type) : 1
  );

  const [businessName, setBusinessName] = useState(
    props.profile.business_name ? props.profile.business_name : ""
  );

  const [vatRegistered, setVatRegistered] = useState(
    props.profile.is_vat_registered
  );
  const [vatRegistrationNumber, setVatRegistrationNumber] = useState(
    props.profile.vat_registration_number
      ? props.profile.vat_registration_number
      : ""
  );

  const [therapySessionsPrefer, setTherapySessionsPrefer] = useState({
    offers_in_clinic_sessions: props.profile.offers_in_clinic_sessions,
    offers_home_visit_sessions: props.profile.offers_home_visit_sessions,
    offers_online_sessions: props.profile.offers_online_sessions,
    offers_phone_sessions: props.profile.offers_phone_sessions,
  });

  const onTherapySessionPrefChange = ({ target: { name, checked } }) => {
    setTherapySessionsPrefer((prev) => ({ ...prev, [name]: checked }));
    if (errors?.therapySessionsPrefer) {
      clearErrors("therapySessionsPrefer");
    }
  };

  const handleDefaultSessionFee = (event) => {
    setDefaultFee(event.target.value);
    if (errors?.default_session_fee) {
      clearErrors("default_session_fee");
    }
  };

  const [clientServe, setClientServe] = useState({
    serves_adults: props.profile.serves_adults,
    serves_children: props.profile.serves_children,
    serves_couples: props.profile.serves_couples,
    serves_young_people: props.profile.serves_young_people,
  });

  const onClientServeChange = ({ target: { name, checked } }) => {
    setClientServe((prev) => ({ ...prev, [name]: checked }));
    if (errors?.clientServe) {
      clearErrors("clientServe");
    }
  };

  const [availableOn, setAvailableOn] = useState({
    available_on_monday:
      props.profile.available_on_monday === null
        ? false
        : props.profile.available_on_monday,
    available_on_tuesday:
      props.profile.available_on_tuesday === null
        ? false
        : props.profile.available_on_tuesday,
    available_on_wednesday:
      props.profile.available_on_wednesday === null
        ? false
        : props.profile.available_on_wednesday,
    available_on_thursday:
      props.profile.available_on_thursday === null
        ? false
        : props.profile.available_on_thursday,
    available_on_friday:
      props.profile.available_on_friday === null
        ? false
        : props.profile.available_on_friday,
    available_on_saturday:
      props.profile.available_on_saturday === null
        ? false
        : props.profile.available_on_saturday,
    available_on_sunday:
      props.profile.available_on_sunday === null
        ? false
        : props.profile.available_on_sunday,
  });

  const onAvailableOnChange = ({ target: { name, checked } }) => {
    setAvailableOn((prev) => ({ ...prev, [name]: checked }));
    if (errors?.availableOn) {
      clearErrors("availableOn");
    }
  };

  const getLanguages = (term) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/language/list";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          q: term.trim(),
          limit: 100,
        },
      })
      .then((response) => {
        return response.data.results.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }));
      })
      .catch((error) => {});

    return data;
  };

  useEffect(() => {
    if (props?.profile?.language.length > 0) {
      setValue(
        "language",
        props.profile.language.map((e) => ({
          ...e,
          label: e.title,
          value: e.title,
        }))
      );
    }
  }, []);

  const onSubmit = (formData) => {
    let therapyCheckedPrefer = 0;
    let clientServeChecked = 0;
    let availableOnCheck = 0;

    Object.keys(therapySessionsPrefer).map((e) => {
      if (therapySessionsPrefer[e] === false) {
        therapyCheckedPrefer++;
      }
      return e;
    });

    Object.keys(clientServe).map((e) => {
      if (clientServe[e] === false) {
        clientServeChecked++;
      }
      return e;
    });

    Object.keys(availableOn).map((e) => {
      if (availableOn[e] === false) {
        availableOnCheck++;
      }
      return e;
    });

    if (therapyCheckedPrefer === 4) {
      setError("therapySessionsPrefer", { type: "required" });
    }

    if (clientServeChecked === 4) {
      setError("clientServe", { type: "required" });
    }

    if (
      props?.profile?.availability_day.every((e) => e?.is_available === false)
    ) {
      props.setAvailabilityError(() => true);
      return;
    }

    if (
      formData.language === undefined ||
      formData.language === [] ||
      props?.profile?.language === []
    ) {
      setError("language", { type: "required" });
      return;
    }

    if (!defaultFee || defaultFee <= 0) {
      setError("default_session_fee", { type: "required" });
      return;
    }

    if (availableOnCheck === 7) {
      setError("availableOn", { type: "required" });
    } else if (
      therapyCheckedPrefer <= 3 &&
      clientServeChecked <= 3 &&
      availableOnCheck <= 6
    ) {
      const userProfile = {
        language: formData.language,
        offers_in_clinic_sessions:
          therapySessionsPrefer.offers_in_clinic_sessions,
        offers_home_visit_sessions:
          therapySessionsPrefer.offers_home_visit_sessions,
        offers_online_sessions: therapySessionsPrefer.offers_online_sessions,
        offers_phone_sessions: therapySessionsPrefer.offers_phone_sessions,
        serves_children: clientServe.serves_children,
        serves_young_people: clientServe.serves_young_people,
        serves_adults: clientServe.serves_adults,
        serves_couples: clientServe.serves_couples,
        available_on_friday: availableOn.available_on_friday,
        available_on_monday: availableOn.available_on_monday,
        available_on_saturday: availableOn.available_on_saturday,
        available_on_sunday: availableOn.available_on_sunday,
        available_on_thursday: availableOn.available_on_thursday,
        available_on_tuesday: availableOn.available_on_tuesday,
        available_on_wednesday: availableOn.available_on_wednesday,
        clinic_fee: clinicFee,
        home_fee: homeFee,
        audio_fee: audioFee,
        video_fee: videoFee,
        default_session_fee: formData.default_session_fee,
        business_type: formData.businessType,
        business_name: formData.businessName,
        is_vat_registered: formData.vatRegistered,
        vat_registration_number: formData.vatRegistrationNumber,
      };
      props.onUpdateUserProfile(userProfile);
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f0ebe7",
      border: "none",
      outline: "none",
      marginLeft: "19px",
      marginLeft: "15px",
      width: "98%",
      borderRadius: "0px",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "14px",
      color: "#000",
    }),
    menu: (styles) => {
      return {
        ...styles,
        marginLeft: "15px",
        width: "98%",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#62BFAE",
        color: "white",
        borderRadius: "15px",
        padding: "3px 4px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
      fontFamily: "L-SBold",
      fontSize: "10px",
      marginRight: "10px",
      textTransform: "uppercase",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#62BFAE",
      backgroundColor: "#D0ECE6",
      borderRadius: "50%",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#fff",
      },
    }),
  };

  return (
    <div className={classes.stepThreeForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mt-4">
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Types of sessions you offer</span>
          </p>

          <div
            style={{ gridTemplateColumns: "1fr", gap: "8px", width: "80%" }}
            className={`row ml-2 mt-2 ${classes.therapyTypeOptions}`}
          >
            <div
              className={`${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  id="inClinicSwitch"
                  name="offers_in_clinic_sessions"
                  className={classes.styledCheckbox}
                  checked={therapySessionsPrefer.offers_in_clinic_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="inClinicSwitch"
                >
                  Clinic
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_in_clinic_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  type="number"
                  id="clinicFeeInput"
                  required={
                    therapySessionsPrefer.offers_in_clinic_sessions === true
                      ? true
                      : false
                  }
                  placeholder="Enter Clinic Fee"
                  value={
                    therapySessionsPrefer.offers_in_clinic_sessions === true &&
                    clinicFee &&
                    clinicFee > 0 &&
                    clinicFee
                  }
                  onChange={(event) => setClinicFee(event.target.value)}
                />
                <div className={classes.typeSessionFee_currency}>
                  {currencyCode}
                </div>
              </div>
            </div>

            <div
              className={` ${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  id="homeVisitsSwitch"
                  name="offers_home_visit_sessions"
                  className={classes.styledCheckbox}
                  checked={therapySessionsPrefer.offers_home_visit_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="homeVisitsSwitch"
                >
                  Home
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_home_visit_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  required={
                    therapySessionsPrefer.offers_home_visit_sessions === true
                      ? true
                      : false
                  }
                  type="number"
                  placeholder="Enter home Fee"
                  value={
                    therapySessionsPrefer.offers_home_visit_sessions === true &&
                    homeFee &&
                    homeFee > 0 &&
                    homeFee
                  }
                  onChange={(event) => setHomeFee(event.target.value)}
                />
                <div className={classes.typeSessionFee_currency}>
                  {currencyCode}
                </div>
              </div>
            </div>

            <div
              className={` ${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  className={classes.styledCheckbox}
                  id="onlineSwitch"
                  name="offers_online_sessions"
                  checked={therapySessionsPrefer.offers_online_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="onlineSwitch"
                >
                  Video
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_online_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  required={
                    therapySessionsPrefer.offers_online_sessions === true
                      ? true
                      : false
                  }
                  type="number"
                  placeholder="Enter video Fee"
                  value={
                    therapySessionsPrefer.offers_online_sessions === true &&
                    videoFee &&
                    videoFee > 0 &&
                    videoFee
                  }
                  onChange={(event) => setVideoFee(event.target.value)}
                />
                <div className={classes.typeSessionFee_currency}>
                  {currencyCode}
                </div>
              </div>
            </div>

            <div
              className={` ${classes.therapyTypeWrapTwo} ${classes.therapyTypeWrap}`}
            >
              <div>
                <input
                  type="checkbox"
                  className={classes.styledCheckbox}
                  id="phoneSwitch"
                  name="offers_phone_sessions"
                  checked={therapySessionsPrefer.offers_phone_sessions}
                  onChange={onTherapySessionPrefChange}
                />
                <label
                  className={`${classes.subLabel} ${classes.topLabel}`}
                  htmlFor="phoneSwitch"
                >
                  Phone
                </label>
              </div>

              <div
                style={{
                  display:
                    therapySessionsPrefer.offers_phone_sessions === true
                      ? "flex"
                      : "none",
                }}
                className={classes.typeSessionFee}
              >
                <input
                  required={
                    therapySessionsPrefer.offers_phone_sessions === true
                      ? true
                      : false
                  }
                  type="number"
                  placeholder="Enter audio Fee"
                  value={
                    therapySessionsPrefer.offers_phone_sessions === true &&
                    audioFee &&
                    audioFee > 0 &&
                    audioFee
                  }
                  onChange={(event) => setAudioFee(event.target.value)}
                />
                <div className={classes.typeSessionFee_currency}>
                  {currencyCode}
                </div>
              </div>
            </div>
          </div>
          {errors?.therapySessionsPrefer &&
            errors.therapySessionsPrefer?.type === "required" && (
              <p style={{ paddingLeft: "4%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
        </div>

        <div className="form-group">
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Types of clients you serve</span>
          </p>

          <div
            style={{ gap: "8px" }}
            className={`row ml-2 mt-2 ${classes.therapyTypeOptions}`}
          >
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                id="serves_children"
                name="serves_children"
                className={classes.styledCheckbox}
                checked={clientServe.serves_children}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_children"
              >
                Children
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                id="serves_young_people"
                name="serves_young_people"
                className={classes.styledCheckbox}
                checked={clientServe.serves_young_people}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_young_people"
              >
                Young people
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                className={classes.styledCheckbox}
                id="serves_adults"
                name="serves_adults"
                checked={clientServe.serves_adults}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_adults"
              >
                Adults
              </label>
            </div>

            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="checkbox"
                className={classes.styledCheckbox}
                id="serves_couples"
                name="serves_couples"
                checked={clientServe.serves_couples}
                onChange={onClientServeChange}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="serves_couples"
              >
                Couples
              </label>
            </div>
          </div>
          {errors?.clientServe && errors.clientServe?.type === "required" && (
            <p style={{ paddingLeft: "4%" }} className="errorTxt">
              Please select at least one option
            </p>
          )}
        </div>

        <div className="form-group mt-4">
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Languages you can speak?</span>
          </p>
          <small className="form-text text-muted mb-2 ml-3">
            Type and select from the list
          </small>

          <div className={classes.controllerDiv}>
            <Controller
              name="language"
              // rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    required
                    loadOptions={getLanguages}
                    placeholder="e.g. English"
                    closeMenuOnSelect={true}
                    defaultOptions
                    isMulti
                    styles={colourStyles}
                  />
                );
              }}
            />
            {errors?.language && errors.language?.type === "required" && (
              <p style={{ paddingLeft: "4%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>
        </div>

        <div className="form-group mt-4">
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>
              What is your default session fee? &nbsp;
              <span className={classes.defaultSessInfo}>
                <svg
                  fill="#000000"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                >
                  <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                </svg>

                <small>This will be shown on your profile card</small>
              </span>
            </span>
          </p>

          <div className={classes.sessionFeeDiv}>
            <input
              {...register("default_session_fee", {
                // required: true,
              })}
              type="number"
              id="defaultSessionFeeInput"
              className={classes.feeInput}
              name="default_session_fee"
              placeholder="Default session fee"
              value={defaultFee && defaultFee > 0 && defaultFee}
              onChange={handleDefaultSessionFee}
            />

            <div className="input-group-prepend">
              <div className={`input-group-text ${classes.currencyCodeDiv}`}>
                {currencyCode}
              </div>
            </div>
          </div>
          {errors?.default_session_fee &&
            errors.default_session_fee?.type === "required" && (
              <p style={{ paddingLeft: "3%" }} className="errorTxt">
                Default session fee is required
              </p>
            )}
        </div>

        <div className="form-group">
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Your business type</span>
          </p>

          <div
            className={`row ml-2 mt-2 ${classes.therapyTypeOptions} ${classes.twoRow}`}
          >
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="privateLimitedRadio"
                name="business_type1"
                className={classes.styledCheckbox}
                checked={businessType === 1}
                onChange={() => setBusinessType(1)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="privateLimitedRadio"
              >
                Private limited
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="soleProprietorshipRadio"
                name="business_type2"
                className={classes.styledCheckbox}
                checked={businessType === 2}
                onChange={() => setBusinessType(2)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="soleProprietorshipRadio"
              >
                Sole Proprietorship
              </label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Your business name</span>
          </p>

          <div className={classes.sessionFeeDiv}>
            <input
              {...register("businessName", {
                required: false,
              })}
              type="text"
              className={classes.feeInput}
              id="businessNameInput"
              placeholder=""
              value={businessName}
              onChange={(event) => setBusinessName(event.target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <p className={classes.listingTitle}>
            <span className={classes.mRight}>•</span>
            <span>Are you VAT registered?</span>
          </p>

          <div className={`row ml-2 mt-2 ${classes.therapyTypeOptions}`}>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="vatRegisteredNoRadio"
                name="vatRegisteredNoRadio"
                className={classes.styledCheckbox}
                checked={!vatRegistered}
                onChange={() => setVatRegistered(false)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="vatRegisteredNoRadio"
              >
                No
              </label>
            </div>
            <div className={` ${classes.therapyTypeWrap}`}>
              <input
                type="radio"
                id="vatRegisteredYesRadio"
                name="vatRegisteredYesRadio"
                className={classes.styledCheckbox}
                checked={vatRegistered}
                onChange={() => setVatRegistered(true)}
              />
              <label
                className={`${classes.subLabel} ${classes.topLabel}`}
                htmlFor="vatRegisteredYesRadio"
              >
                Yes
              </label>
            </div>
          </div>

          {vatRegistered && (
            <div className={classes.sessionFeeDiv}>
              <input
                {...register("vatRegistrationNumber", {
                  required: false,
                })}
                type="text"
                className={`${classes.feeInput} mb-2 mt-2`}
                id="vatRegNumberInput"
                placeholder="VAT registration number"
                value={vatRegistrationNumber}
                onChange={(event) =>
                  setVatRegistrationNumber(event.target.value)
                }
              />
            </div>
          )}
        </div>
        <button className={classes.stepThreeNext}> Next </button>
      </form>
    </div>
  );
};

export default StepThreeForm;
