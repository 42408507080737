import React from "react";
import MyClient from "./MyClient/MyClient";

const MyClients = (props) => {
  return (
    <div
      style={{ overflowY: "auto", height: "100%", paddingBottom: "100px" }}
      className="row my-3"
    >
      <div className="col">
        <MyClient />
      </div>
    </div>
  );
};

export default MyClients;
