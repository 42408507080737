import { Fragment, useState } from "react";
import classes from "./FP.module.scss";
import logo from "../../../assets/images/mindsum-logo-new.svg";
import svgImg2 from "../../../assets/images/fp2.svg";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Alert from "../../UI/Alert/Alert";
import ProgressBar from "../../UI/ProgressBar/ProgressBar";
import FooterLink from "../../footerLink/FooterLink";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  const onChangeHandler = (email) => {
    if (props.error) {
      props.onResetState();
    }
    setEmail(email);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    props.onRequestPasswordResetCode(email);
  };

  const form = (
    <ForgotPasswordForm
      onSubmit={submitHandler}
      email={email}
      onChangeHandler={onChangeHandler}
      loading={props.loading}
    />
  );

  let content = form;

  if (props.error) {
    content = (
      <Fragment>
        <div>
          <Alert level="danger" message={props.error} />
        </div>
        {form}
      </Fragment>
    );
  } else if (props.message) {
    content = (
      <Fragment>
        <div className={classes.svgImgDiv}>
          <img src={svgImg2} className={classes.svgImg} alt="" />
          <p className={classes.checkMail}> Check your mail </p>
          <div style={{ padding: "20px 0px" }}>
            <Alert level="success" message={props.message} />
          </div>
          <button className="btn btn-secondary" onClick={props.onResetState}>
            Request again
          </button>
        </div>
      </Fragment>
    );
  } else if (props.loading) {
    content = <ProgressBar />;
  }

  return (
    <>
      <div className={classes.forgotPasswordLogo}>
        <a href="https://www.mindsum.app/">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className={`${classes.forgotPassword}`}>
        <div className="">
          <div className={classes.topComp}>
            <h3 className={classes.fpHeader}>Forgot password?</h3>
            {content}
          </div>
        </div>
        <div className={classes.bottomComp}>
          <FooterLink />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.requestPasswordResetCode.loading,
    error: state.requestPasswordResetCode.error,
    message: state.requestPasswordResetCode.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestPasswordResetCode: (email) =>
      dispatch(actions.requestPasswordResetCode(email)),
    onResetState: () => dispatch(actions.requestPasswordResetCodeResetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
