import * as actionTypes from "./actionTypes";
import axios from "axios";
import { BASE_URL } from "../../shared/constants";

export const verifyEmailStart = () => {
  return {
    type: actionTypes.VERIFY_EMAIL_START,
  };
};

export const verifyEmailSuccess = (message) => {
  return {
    type: actionTypes.VERIFY_EMAIL_SUCCESS,
    message: message,
  };
};

export const verifyEmailFail = (error) => {
  return {
    type: actionTypes.VERIFY_EMAIL_FAIL,
    error: error,
  };
};

export const requestEmailVerificationCodeStart = () => {
  return {
    type: actionTypes.REQUEST_EMAIL_VERIFICATION_CODE_START,
  };
};

export const requestEmailVerificationCodeSuccess = (message) => {
  return {
    type: actionTypes.REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS,
    message: message,
  };
};

export const requestEmailVerificationCodeFail = (error) => {
  return {
    type: actionTypes.REQUEST_EMAIL_VERIFICATION_CODE_FAIL,
    error: error,
  };
};

export const requestEmailVerificationCode = () => {
  return (dispatch) => {
    dispatch(requestEmailVerificationCodeStart());
    const url = BASE_URL + "/request-email-verification-code";
    const token = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(requestEmailVerificationCodeSuccess(response.data.message));
      })
      .catch((error) => {
        dispatch(requestEmailVerificationCodeFail(error.response.data.error));
      });
  };
};

export const verifyEmail = (code) => {
  return (dispatch) => {
    dispatch(verifyEmailStart());
    const url = BASE_URL + "/verify-email/" + code;
    axios
      .get(url)
      .then((response) => {
        dispatch(verifyEmailSuccess(response.data.message));
      })
      .catch((error) => {
        dispatch(verifyEmailFail(error.response.data.error));
      });
  };
};
