import React, { Fragment, useState } from "react";
import ProgressBar from "../../../UI/ProgressBar/ProgressBar";
import svgImg3 from "../../../../assets/images/fp3.svg";
import eyeIcon from "../../../../assets/icons/eyeIcon.svg";
import eyeHidden from "../../../../assets/icons/eyeHidden.svg";
import classes from "../RP.module.scss";

const ResetPasswordForm = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalid, setInvalid] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const invalidClass = invalid && "is-invalid";

  const onChangePasswordInputHandler = (event) => {
    setInvalid(false);
    setPassword(event.target.value);
    props.onChangeHandler();
  };

  const onChangeConfirmPasswordInputHandler = (event) => {
    setInvalid(false);
    setConfirmPassword(event.target.value);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const equal = password === confirmPassword;
    setInvalid(!equal);
    if (equal) props.onSubmit(password);
  };

  return (
    <Fragment>
      <div className={classes.svgImgDiv2}>
        <img src={svgImg3} className={classes.svgImg} alt="" />
        <p className={classes.rptext}>Please enter your new password</p>
        <p className={`${classes.rptext} ${classes.rptext2}`}>
          Your new password must be different from previously used passwords.
        </p>
      </div>
      <form onSubmit={onSubmitHandler} className={classes.resetPasswordForm}>
        <div className={classes.resetPasswordField}>
          <input
            type={passwordShown ? "text" : "password"}
            className={`${classes.resetPasswordInput} ${invalidClass}`}
            id="passwordInput"
            placeholder="New password"
            onChange={onChangePasswordInputHandler}
          />
          {passwordShown ? (
            <img
              onClick={togglePasswordVisibility}
              className={classes.signupEye}
              src={eyeIcon}
              alt=""
            />
          ) : (
            <img
              onClick={togglePasswordVisibility}
              className={classes.signupEye}
              src={eyeHidden}
              alt="/"
            />
          )}
        </div>
        <div className={classes.resetPasswordField}>
          <input
            type={passwordShown ? "text" : "password"}
            className={`${classes.resetPasswordInput} ${invalidClass}`}
            id="confirmPasswordInput"
            placeholder="Confirm password"
            onChange={onChangeConfirmPasswordInputHandler}
          />
          {passwordShown ? (
            <img
              onClick={togglePasswordVisibility}
              className={classes.signupEye}
              src={eyeIcon}
              alt=""
            />
          ) : (
            <img
              onClick={togglePasswordVisibility}
              className={classes.signupEye}
              src={eyeHidden}
              alt="/"
            />
          )}
          {invalid && (
            <div className="invalid-feedback">Passwords do not match</div>
          )}
        </div>
        {props.loading ? (
          <ProgressBar />
        ) : (
          <button type="submit" className={classes.rpBtn}>
            Reset password
          </button>
        )}
        <small>v0.0.92 </small>
      </form>
    </Fragment>
  );
};

export default ResetPasswordForm;
