import React, { Fragment, useState, useEffect } from "react";
import logo from "../../assets/images/mindsum-logo-new.svg";
import classes from "./Navbar.module.scss";
import ProfileImage from "../UI/ProfileImageAndBasicInfo/ProfileImage/ProfileImage";
import profileImg from "../../assets/images/profilePlaceholder.svg";
import NavbarItem from "./NavbarItem/NavbarItem";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/actions";

const Navbar = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { isAuthenticated, user, profile } = useSelector((state) => ({
    isAuthenticated: state.auth.token !== null,
    user: state.auth.user,
    profile: state.userProfile.profile,
  }));
  const dispatch = useDispatch();

  const handleClick = () => setOpenMenu(!openMenu);

  const closeMenu = () => setOpenMenu(false);

  useEffect(() => {
    if (profile === null && isAuthenticated) {
      dispatch(getUserProfile());
    }
  }, [user]);

  return (
    <Fragment>
      <div className={classes.navHeight}>
        <nav className={`navbar-expand  ${classes.navFlex} navbar`}>
          <Link className={classes.navLogo} to="/">
            <img src={logo} alt="logo" className={classes.ImgFluid} />
            <svg
              className={` ${classes.mobileLogo}`}
              xmlns="http://www.w3.org/2000/svg"
              width="44.463"
              height="34.113"
              viewBox="0 0 44.463 34.113"
            >
              <g
                id="Group_6969"
                data-name="Group 6969"
                transform="translate(-565.722 751.781)"
              >
                <rect
                  id="Rectangle_1"
                  data-name="Rectangle 1"
                  width="6.546"
                  height="11.795"
                  rx="3.273"
                  transform="translate(565.722 -744.063)"
                  fill="#428dff"
                />
                <rect
                  id="Rectangle_2"
                  data-name="Rectangle 2"
                  width="6.546"
                  height="21.82"
                  rx="3.273"
                  transform="translate(575.219 -749.789)"
                  fill="#62bfae"
                />
                <rect
                  id="Rectangle_3"
                  data-name="Rectangle 3"
                  width="6.546"
                  height="31.012"
                  rx="3.273"
                  transform="translate(594.181 -748.681)"
                  fill="#ffa88a"
                />
                <rect
                  id="Rectangle_4"
                  data-name="Rectangle 4"
                  width="6.546"
                  height="14.374"
                  rx="3.273"
                  transform="translate(603.639 -739.785)"
                  fill="#ff5f5f"
                />
                <rect
                  id="Rectangle_5"
                  data-name="Rectangle 5"
                  width="6.546"
                  height="29.14"
                  rx="3.273"
                  transform="translate(584.663 -751.781)"
                  fill="#ffdcd0"
                />
              </g>
            </svg>
          </Link>

          {isAuthenticated &&
            (!user.is_signup_complete || !user.is_profile_approved) &&
            user.type !== "admin" && (
              <ul className="navbar-nav ml-auto">
                <NavbarItem link="/logout" icon={faSignOutAlt}>
                  Log out
                </NavbarItem>
              </ul>
            )}

          {isAuthenticated && user.type === "admin" && (
            <ul
              className={`navbar-nav ml-auto flex-row ${classes.navListRight}`}
            >
              <li className={classes.menuDiv}>
                <div onClick={handleClick} className={classes.hamIconDiv}>
                  <svg
                    className={classes.hamIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.743"
                    height="21"
                    viewBox="0 0 31.743 21"
                  >
                    <g
                      id="Group_6980"
                      data-name="Group 6980"
                      transform="translate(-370.548 -38.164)"
                    >
                      <path
                        id="Path_4493"
                        data-name="Path 4493"
                        d="M-4229.9-10340.337h28.743"
                        transform="translate(4601.947 10380)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Path_4494"
                        data-name="Path 4494"
                        d="M-4229.9-10340.337h28.743"
                        transform="translate(4601.947 10389)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Path_4495"
                        data-name="Path 4495"
                        d="M-4229.9-10340.337h28.743"
                        transform="translate(4601.947 10398)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="3"
                      />
                    </g>
                  </svg>
                </div>

                {openMenu && (
                  <div className={`${classes.openedMenu}`}>
                    <div className={classes.firstRow}>
                      <div className={classes.openProfileDiv}>
                        <div className={classes.openedProfile}>
                          Welcome {user?.first_name}!
                        </div>

                        {user?.type === "therapist" ||
                          (user?.type === "admin" && (
                            <Link to="/logout">
                              <div
                                onClick={closeMenu}
                                className={classes.logoutBtn}
                              >
                                Logout
                              </div>
                            </Link>
                          ))}
                      </div>
                    </div>
                    <div className={classes.secondRow}>
                      <div
                        onClick={closeMenu}
                        className={classes.closeMenuIcon}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25.151"
                          height="25.151"
                          viewBox="0 0 28.151 28.151"
                        >
                          <path
                            id="Path_4501"
                            data-name="Path 4501"
                            d="M3292.127-3628.091a1.268,1.268,0,0,1-.028,1.793,1.268,1.268,0,0,1-1.765,0l-11.925-11.925-11.925,11.925a1.268,1.268,0,0,1-1.793-.028,1.268,1.268,0,0,1,0-1.765l11.925-11.925-11.925-11.925a1.268,1.268,0,0,1,.028-1.793,1.268,1.268,0,0,1,1.765,0l11.925,11.925,11.925-11.925a1.268,1.268,0,0,1,1.793.028,1.268,1.268,0,0,1,0,1.765l-11.925,11.925Z"
                            transform="translate(-3264.333 3654.091)"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                      <div className={classes.pageLinks}>
                        <a
                          className={classes.footerLink2}
                          href="https://www.mindsum.app/about"
                          rel="noreferrer"
                          target="_blank"
                        >
                          About
                        </a>
                        <a
                          className={classes.footerLink2}
                          href="https://mindsum.freshdesk.com/support/solutions/80000201126"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Help
                        </a>
                        <a
                          className={classes.footerLink2}
                          href={
                            user?.type === "therapist"
                              ? "https://www.mindsum.app/terms-for-therapists"
                              : "https://www.mindsum.app/terms-for-clients"
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          terms
                        </a>
                        <a
                          className={classes.footerLink2}
                          href="https://www.mindsum.app/privacy-cookie-policy"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          )}

          {isAuthenticated &&
            user.is_signup_complete &&
            user.is_profile_approved && (
              <ul
                className={`navbar-nav ml-auto flex-row ${classes.navListRight}`}
              >
                {user.type === "client" && (
                  <li className="">
                    <Link to="/search" className="nav-link">
                      <div className={classes.searchIconDiv}>
                        <span className={classes.searchText}>
                          View & Search Therapists
                        </span>
                        <svg
                          className={classes.searchIcon}
                          xmlns="http://www.w3.org/2000/svg"
                          width="27.063"
                          height="26"
                          viewBox="0 0 27.063 26"
                        >
                          <g
                            id="Group_18"
                            data-name="Group 18"
                            transform="translate(0)"
                          >
                            <path
                              id="Path_17"
                              data-name="Path 17"
                              d="M-2768.01,1617.546h0a1.675,1.675,0,0,1-2.369,0l-6.093-6.094a11.684,11.684,0,0,0,2.216-2.522l6.247,6.247A1.675,1.675,0,0,1-2768.01,1617.546Z"
                              transform="translate(2794.582 -1592.036)"
                              fill="#f3ddd2"
                            />
                            <g
                              id="Group_17"
                              data-name="Group 17"
                              transform="translate(0)"
                            >
                              <path
                                id="Path_18"
                                data-name="Path 18"
                                d="M-2861.566,1540.786a10.542,10.542,0,0,1-10.53-10.53,10.541,10.541,0,0,1,10.53-10.529,10.541,10.541,0,0,1,10.53,10.529A10.541,10.541,0,0,1-2861.566,1540.786Zm0-18.029a7.508,7.508,0,0,0-7.5,7.5,7.508,7.508,0,0,0,7.5,7.5,7.508,7.508,0,0,0,7.5-7.5A7.508,7.508,0,0,0-2861.566,1522.757Z"
                                transform="translate(2872.096 -1519.727)"
                                fill="#f3ddd2"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </Link>
                  </li>
                )}

                <li className={classes.menuDiv}>
                  <div onClick={handleClick} className={classes.hamIconDiv}>
                    <svg
                      className={classes.hamIcon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.743"
                      height="21"
                      viewBox="0 0 31.743 21"
                    >
                      <g
                        id="Group_6980"
                        data-name="Group 6980"
                        transform="translate(-370.548 -38.164)"
                      >
                        <path
                          id="Path_4493"
                          data-name="Path 4493"
                          d="M-4229.9-10340.337h28.743"
                          transform="translate(4601.947 10380)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_4494"
                          data-name="Path 4494"
                          d="M-4229.9-10340.337h28.743"
                          transform="translate(4601.947 10389)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_4495"
                          data-name="Path 4495"
                          d="M-4229.9-10340.337h28.743"
                          transform="translate(4601.947 10398)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                      </g>
                    </svg>
                  </div>

                  {openMenu && (
                    <div className={`${classes.openedMenu}`}>
                      <div className={classes.firstRow}>
                        <div className={classes.openProfileDiv}>
                          <Link
                            to={"/" + user.type + "/" + user.id + "/profile"}
                            className=""
                          >
                            <div onClick={closeMenu}>
                              <ProfileImage
                                src={profile?.user?.profile_image}
                              />
                            </div>
                          </Link>

                          <div className={classes.openedProfile}>
                            Welcome {user?.first_name}!
                          </div>

                          {user.type === "therapist" && (
                            <Link to="/logout">
                              <div
                                onClick={closeMenu}
                                className={classes.logoutBtn}
                              >
                                Logout
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className={classes.secondRow}>
                        <div
                          onClick={closeMenu}
                          className={classes.closeMenuIcon}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25.151"
                            height="25.151"
                            viewBox="0 0 28.151 28.151"
                          >
                            <path
                              id="Path_4501"
                              data-name="Path 4501"
                              d="M3292.127-3628.091a1.268,1.268,0,0,1-.028,1.793,1.268,1.268,0,0,1-1.765,0l-11.925-11.925-11.925,11.925a1.268,1.268,0,0,1-1.793-.028,1.268,1.268,0,0,1,0-1.765l11.925-11.925-11.925-11.925a1.268,1.268,0,0,1,.028-1.793,1.268,1.268,0,0,1,1.765,0l11.925,11.925,11.925-11.925a1.268,1.268,0,0,1,1.793.028,1.268,1.268,0,0,1,0,1.765l-11.925,11.925Z"
                              transform="translate(-3264.333 3654.091)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                        <div className={classes.pageLinks}>
                          <a
                            className={classes.footerLink2}
                            href="https://www.mindsum.app/about"
                            rel="noreferrer"
                            target="_blank"
                          >
                            About
                          </a>
                          <a
                            className={classes.footerLink2}
                            href="https://mindsum.freshdesk.com/support/solutions/80000201126"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Help
                          </a>
                          <a
                            className={classes.footerLink2}
                            href={
                              user?.type === "therapist"
                                ? "https://www.mindsum.app/terms-for-therapists"
                                : "https://www.mindsum.app/terms-for-clients"
                            }
                            rel="noreferrer"
                            target="_blank"
                          >
                            terms
                          </a>
                          <a
                            className={classes.footerLink2}
                            href="https://www.mindsum.app/privacy-cookie-policy"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>

                          <a
                            className={classes.footerLink2}
                            href="https://my.mindsum.app/beat-the-stigma"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Beat the stigma
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </li>

                <li className={`${classes.profileDiv}`}>
                  <Link to={"/" + user.type + "/" + user.id + "/profile"}>
                    <img
                      className={classes.navProfileImage}
                      src={
                        profile?.user?.profile_image
                          ? profile?.user?.profile_image
                          : profileImg
                      }
                      alt=""
                    />
                  </Link>
                </li>
              </ul>
            )}
        </nav>
      </div>
    </Fragment>
  );
};

export default Navbar;
