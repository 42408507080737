import React, { useState, useCallback, useRef, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import SearchForm from "./SearchBar/SearchForm";
import GreyLogo from "../../../../assets/images/Mindsum_grey_logo.svg";
import { useSelector } from "react-redux";
import SEarchCard from "./SearchResults/SearchCard/SearchCard";
import SearchFilter from "./searchReccom/SearchFilter";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import qs from "qs";
import classes from "./Search.module.scss";
import {
  BASE_URL,
  SEARCH_URL,
  SEARCH_KEY,
  SEARCH_ID,
  SEARCH_INDEX_MAIN,
  SEARCH_INDEX_MAIN_ASEC,
  SEARCH_INDEX_MAIN_DESC,
} from "../../../../shared/constants";
import NoTherapistFound from "../../../../assets/icons/no_therapist_found.svg";
import AlertDialog from "../../../UI/Alert/AlertDialog";
import ComponentLoader from "../../../UI/ComponentLoader";
import TherapistProfileModal from "../../../UI/TherapistProfileModal/TherapistProfileModal";
import { clearAndLogout } from "../../../../shared/utility";

const Search = (props) => {
  const profile = useSelector((state) => state.userProfile.profile);

  const filterRef = useRef(null);
  const filterButtonRef = useRef(null);

  const [searchResults, setSearchResults] = useState([]);
  const [freeFlg, setFreeFlg] = useState([]);
  const [term, setTerm] = useState("");
  const [focus, setFocus] = useState(false);
  const [loadin, setLoading] = useState(false);
  const cancelToken = useRef(undefined);
  const [showError, setShowError] = useState("");
  const [facets, setFacets] = useState({});
  const [page, setPage] = useState(0);
  const [nbPages, setNbPages] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [resultCount, setResultCount] = useState("");
  const [showProfile, setShowProfile] = useState(true);

  const [sortBy, setSortBy] = useState({
    value: "All",
    label: "All",
  });

  const options = [
    { value: "High Session fee", label: "Session Fee : High to Low " },
    { value: "Low Session fee", label: "Session Fee : Low to High" },
    { value: "All", label: "All" },
  ];

  const history = useHistory();
  const location = useLocation();

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "0",
      borderWidth: "1px",
      borderColor: "#e6eaea",
      borderLeft: "none",
      backgroundColor: "#f4f7f6",
      boxShadow: "none",
      height: "100%",
      width: "220px",
      fontFamily: "L-Medium",
      cursor: "pointer",

      "&:hover": {
        borderColor: "none",
      },
    }),

    indicatorSeparator: (base, state) => ({
      display: "none",
      color: "#62BFAE",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#62BFAE",
    }),

    option: (base, state) => ({
      ...base,
      outline: "none",
      border: "none",
      color: state.isSelected ? "#fff" : "#62BFAE",
      backgroundColor: state.isSelected ? "#62BFAE" : "#fff",
      backgroundColor: "#62BFAE",
      color: "#fff",
      cursor: "pointer",
      fontSize: "15px",
      fontFamily: "L-Regular",

      "&:hover": {
        color: "#62BFAE",
        backgroundColor: "#fff",
      },
    }),

    singleValue: (base, state) => ({
      ...base,
      color: "#62BFAE",
      fontFamily: "L-Bold",
      fontSize: "15px",
    }),
  };

  const handleClickOutside = (event) => {
    if (window.innerWidth <= 1300) {
      if (
        !filterRef?.current?.contains(event.target) &&
        !filterButtonRef?.current?.contains(event.target)
      ) {
        setShowFilter(false);
      }
    }
  };

  const handleSetLoading = useCallback(
    (bool) => {
      setLoading(() => bool);
    },
    [loadin]
  );

  const publicSearch = async (query) => {
    setIsLoading(true);
    query = query?.trim();
    handleSetLoading(true);
    const url = `${SEARCH_URL}/${SEARCH_INDEX_MAIN}`;
    const desc_url = `${SEARCH_URL}/${SEARCH_INDEX_MAIN_DESC}`;
    const asce_url = `${SEARCH_URL}/${SEARCH_INDEX_MAIN_ASEC}`;

    //Check if there are any prevxious pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.current?.cancel("Operation cancelled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken.current = axios.CancelToken.source();

    try {
      const result = await axios
        .get(
          (sortBy.value === "All" && url) ||
            (sortBy.value === "High Session fee" && desc_url) ||
            (sortBy.value === "Low Session fee" && asce_url),
          {
            headers: {
              "X-Algolia-Application-Id": `${SEARCH_ID}`,
              "X-Algolia-API-Key": `${SEARCH_KEY}`,
            },
            params: {
              query: query,
              page: page,
              facets: "*",
            },
            cancelToken: cancelToken.current.token,
          }
        )
        .then((response) => response.data);

      setSearchResults((oldResults) => [...oldResults, ...result.hits]);
      setFacets(result.facets);
      setNbPages(result.nbPages);
      setPage(result.page);
      setResultCount(result.nbHits);
      handleSetLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }

      if (
        error?.response?.data?.error ===
        "Permission denied: You account is inactive."
      ) {
        setShowError("Your account is inactive.");
      }
    }
  };

  const freeSessionTaken = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist/free/flg";

    const data = axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setFreeFlg(response.data);
      })
      .catch((error) => {});

    return data;
  };

  const scrollToEnd = () => {
    if (page <= nbPages) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    publicSearch(location.search.replace("?", ""));
    // publicSearch(location.search);

    if (props.isAuthenticated === true) {
      freeSessionTaken();
    }
  }, [page]);

  useEffect(() => {
    if (location.search === "") {
      setShowProfile(false);
    }
  }, []);

  useEffect(() => {
    publicSearch();
  }, [sortBy]);

  useEffect(() => {
    if (sortBy.value) {
      setTerm(() => "");
      setSearchResults([]);
      setPage(0);
      // publicSearch();
    }
  }, [sortBy.value]);

  useEffect(() => {
    if (location.search && !location.search.includes("therapist_profile=")) {
      setShowProfile(false);
      setPage(() => 0);
      setSearchResults([]);
      const search = location.search.replace("?", "");
      setTerm(search);
      publicSearch(search);
    }

    if (location.search === "") {
      // publicSearch();
    }
  }, [location.search]);

  const handleShowFilter = () => {
    if (window.innerWidth <= 1300) {
      setShowFilter((prev) => !prev);
    }
  };

  const onFoucs = () => {
    if (window.innerWidth > 320) {
      setFocus(true);
    }
  };

  const handleSetTerm = useCallback(
    (query) => {
      setTerm(() => query);
      setSearchResults(() => []);
    },
    [term]
  );

  const handleCancel = () => {
    setShowError("");
  };

  const handleProfileClose = () => {
    setShowProfile(false);
    history.goBack();

    if (term.includes("therapist_profile=")) {
      setTerm(() => "");
      publicSearch();
    }

    // else {
    //   if (termTwo.includes("therapist_profile=")) {
    //     setTermTwo(() => "");
    //     getSuggestedTherapists();
    //   }
    // }
  };

  return (
    <>
      <div
        style={{ height: props.isAuthenticated === false ? "100vh" : "" }}
        className={`${classes.search} ${classes.searchChangeBg}`}
        onClick={handleClickOutside}
      >
        <div
          onClick={() => history.push("/")}
          style={{ display: props.isAuthenticated === false ? "none" : "flex" }}
          className={classes.search__close}
        >
          <svg
            className={classes.backIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="18.303"
            height="17.946"
            viewBox="0 0 18.303 17.946"
          >
            <g
              id="Group_6913"
              data-name="Group 6913"
              transform="translate(-18699.623 -13146.869)"
            >
              <path
                id="Path_4322"
                data-name="Path 4322"
                d="M18708.893,13147.221l-8.564,8.677,8.564,8.564"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
              />
              <path
                id="Path_4323"
                data-name="Path 4323"
                d="M18717.926,13155.842h-16.957"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
              />
            </g>
          </svg>
        </div>

        <div
          style={{ padding: props.isAuthenticated === false ? "2.5%" : "" }}
          className={`${classes.searchFilter} ${classes.searchFilterSecond}`}
        >
          <div className={classes.searchRecomm}>
            {props.isAuthenticated === false && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a
                  href="https://www.mindsum.app/"
                  target="_blank"
                  style={{
                    textAlign: "left",
                    width: "100%",
                    display: "block",
                  }}
                >
                  <img src={GreyLogo} alt="Mindsum" />
                </a>
              </div>
            )}

            <div className={classes.SearchFilter}>
              <p className={classes.SearchFilter__title}>
                Filter Your Search Results
              </p>

              {!showFilter ? (
                <p
                  ref={filterButtonRef}
                  onClick={handleShowFilter}
                  className={classes.viewFilters}
                >
                  FILTERS
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.578"
                    height="15"
                    viewBox="0 0 25.578 15"
                  >
                    <g
                      id="Group_10787"
                      data-name="Group 10787"
                      transform="translate(-176.84 -94)"
                    >
                      <g
                        id="Group_10779"
                        data-name="Group 10779"
                        transform="translate(53 -1.693)"
                      >
                        <path
                          id="Path_1866"
                          data-name="Path 1866"
                          d="M8550.143,6173.339h25.578"
                          transform="translate(-8426.303 -6074.276)"
                          fill="none"
                          stroke="#62bfae"
                          strokeWidth="2"
                        />
                        <rect
                          id="Rectangle_1192"
                          data-name="Rectangle 1192"
                          width="6.741"
                          height="6.741"
                          transform="translate(128.935 95.693)"
                          fill="#62bfae"
                        />
                      </g>
                      <g
                        id="Group_10780"
                        data-name="Group 10780"
                        transform="translate(53 -1.337)"
                      >
                        <path
                          id="Path_1867"
                          data-name="Path 1867"
                          d="M8550.143,6173.339h25.578"
                          transform="translate(-8426.303 -6066.373)"
                          fill="none"
                          stroke="#62bfae"
                          strokeWidth="2"
                        />
                        <rect
                          id="Rectangle_1193"
                          data-name="Rectangle 1193"
                          width="6.741"
                          height="6.741"
                          transform="translate(137.935 103.596)"
                          fill="#62bfae"
                        />
                      </g>
                    </g>
                  </svg>
                </p>
              ) : (
                <p onClick={handleShowFilter} className={classes.viewFilters}>
                  CLOSE FILTERS
                </p>
              )}

              {showFilter && (
                <div ref={filterRef}>
                  <SearchFilter
                    term={term}
                    setTerm={handleSetTerm}
                    onSearch={publicSearch}
                    setShowFilter={setShowFilter}
                    setRecomResults={setSearchResults}
                    setResultCount={setResultCount}
                    facets={facets}
                    setPage={setPage}
                    page={page}
                  />
                </div>
              )}

              <div className={classes.filterDesktop}>
                <SearchFilter
                  term={term}
                  setTerm={handleSetTerm}
                  onSearch={publicSearch}
                  setShowFilter={setShowFilter}
                  setRecomResults={setSearchResults}
                  setResultCount={setResultCount}
                  facets={facets}
                  setPage={setPage}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${classes.searchContainer} ${classes.searchContainerSecond}`}
        >
          <div className={classes.recomProfileListsMain}>
            <div className={classes.searchHead}>
              <SearchForm
                onFoucs={onFoucs}
                term={term}
                setTerm={handleSetTerm}
                onSearch={publicSearch}
                setRecomResults={setSearchResults}
              />

              <div className={classes.sortByFilter}>
                <div className={classes.sortBy}>Sort By :</div>
                <Select
                  styles={colourStyles}
                  defaultValue={sortBy}
                  options={options}
                  onChange={(e) => setSortBy(e)}
                />
              </div>
              {/* <br />  */}
            </div>

            <div>
              {term && (
                <div>
                  {searchResults.length === 0 && (
                    <p className={classes.loadingText}>
                      {loadin
                        ? `Loading Results in ${term}`
                        : `"${term}" - Showing ${searchResults.length}/${resultCount} Therapist Found`}
                    </p>
                  )}

                  {searchResults.length > 0 && (
                    <div>
                      <p className={classes.loadingText}>
                        {loadin
                          ? "Loading..."
                          : `"${term}" - Showing ${searchResults.length}/${resultCount} Therapist Found`}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>

            {!term && resultCount && (
              <p className={classes.loadingText}>{resultCount} Results Found</p>
            )}

            <div
              style={{
                borderTop: " none",
              }}
              className={classes.recomProfileLists}
            >
              {searchResults.length > 0 && (
                <>
                  {searchResults.map((e) => (
                    <SEarchCard
                      key={e.id}
                      {...e}
                      freeFlg={freeFlg.find((item) => item === e.id)}
                      setShowProfile={setShowProfile}
                    />
                  ))}

                  {page < nbPages - 1 && (
                    <div onClick={scrollToEnd} className={classes.loadMore}>
                      <div style={{ width: "100%", height: "100%" }}>
                        <lottie-player
                          className={classes.lottie}
                          src="https://kurage-assets.s3.ap-south-1.amazonaws.com/mindsum/Load_more_animation.json"
                          style={{ width: "100%", height: "100%" }}
                          autoplay
                          loop
                          mode="normal"
                        ></lottie-player>
                      </div>

                      <p className={classes.loadMore__text}>Load More</p>
                    </div>
                  )}
                </>
              )}

              {!isLoading && !loadin && searchResults.length <= 0 && (
                <div className={classes.nothingResContainer}>
                  <img src={NoTherapistFound} alt="" />

                  <p className={classes.nothingRes}>
                    There are no therapist that matches your search. Try other
                    keywords or remove some filters to get resul
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {showProfile && <TherapistProfileModal onClose={handleProfileClose} />}

        {showError && <AlertDialog msg={showError} onToggle={handleCancel} />}
      </div>

      {isLoading && <ComponentLoader />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(Search);
