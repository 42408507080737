import React from "react";
import classes from "./Reports.module.scss";
import Balance from "./Balance/Balance";
import BasicStats from "./BasicStats/BasicStats";
import FooterLink from "../../../footerLink/FooterLink";

const Reports = (props) => {
  return (
    <div className={classes.reports}>
      <div className={`bcg ${classes.reports__innerDiv}`}>
        <Balance />
        <BasicStats />
      </div>
      <div className={classes.reports__footer}>
        <FooterLink />
      </div>
    </div>
  );
};

export default Reports;
