import React, { Fragment, useEffect, useState, useRef, Children } from "react";
import classes from "./SessionOverview.module.scss";
import PropTypes from "prop-types";
import FooterLink from "../../../footerLink/FooterLink";
import calIcon from "../../../../assets/icons/sessionOverview/calender.svg";
import clockIcon from "../../../../assets/icons/sessionOverview/clock.svg";
import currency from "../../../../assets/icons/sessionOverview/currency.svg";
import star from "../../../../assets/icons/sessionOverview/star.svg";
import timer from "../../../../assets/icons/sessionOverview/timer.svg";
import treatment from "../../../../assets/icons/sessionOverview/treatment.svg";
import videoCall from "../../../../assets/icons/sessionOverview/videoCall.svg";
import user from "../../../../assets/icons/sessionOverview/sessionUser.svg";
import status from "../../../../assets/icons/sessionOverview/status.svg";
import ratingModal from "../../../../assets/images/rating-modal-head.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import { BASE_URL } from "../../../../shared/constants";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import ComponentLoader from "../../../UI/ComponentLoader";
import Moment from "react-moment";
import { timeBetweenDates } from "../../../../shared/utility";
import { connect } from "react-redux";
import Rating from "@material-ui/lab/Rating";

import RatingIcon from "../../../../assets/icons/rating.svg";

import AlertDialog from "../../../UI/Alert/AlertDialog";
import { clearAndLogout } from "../../../../shared/utility";

const SessionOverview = (props) => {
  const history = useHistory();
  const location = useLocation();

  const ratingiconRef = useRef(null);
  const pathRef = useRef(null);

  const [sessionOverview, setSessionOverview] = useState({
    loading: false,
    session: null,
    selfAttendee: null,
    canAcceptDecline: false,
    canCancel: false,
    canAddToGoogleCalendar: false,
    canRate: false,
  });

  // const [rating, setRating] = useState({
  //   relationship: 0,
  //   goals: 0,
  //   qualification: 0,
  //   delivery: 0,
  //   overall: 0,
  // });

  const [relationship, setRelationship] = useState(0);
  const [goals, setGoals] = useState(0);
  const [qualification, setQualification] = useState(0);
  const [delivery, setDelivery] = useState(0);
  const [overall, setOverall] = useState(0);

  const [hover, setHover] = React.useState(-1);

  const [publicNotes, setPublicNotes] = useState(null);
  const [privateNotes, setPrivateNotes] = useState(null);
  const [editPublicNotesMode, setEditPublicNotesMode] = useState(false);
  const [editPrivateNotesMode, setEditPrivateNotesMode] = useState(false);
  const [currentSelected, setCurrentSelected] = useState("therapist");
  const [showAlert, setShowAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    getSession();
  }, []);

  const refreshSessionOverview = (sess) => {
    const slfAtt =
      sess.therapist.attendee.id === props.user.id
        ? sess.therapist
        : sess.client;

    const hasStarted = new Date(sess.start) <= new Date();
    setSessionOverview({
      loading: false,
      session: sess,
      selfAttendee: slfAtt,
      canAcceptDecline:
        !hasStarted &&
        sess.status === "tentative" &&
        slfAtt.response_status === "needsAction",
      canCancel:
        !hasStarted &&
        (sess.status === "confirmed" ||
          (sess.status === "tentative" &&
            slfAtt.response_status === "accepted")),
      canAddToGoogleCalendar:
        !hasStarted &&
        sess.status === "confirmed" &&
        slfAtt.response_status === "accepted",
      canRate:
        sess.status === "ended" &&
        slfAtt.attendee.type === "client" &&
        !slfAtt.rating,
    });

    if (sess.therapist.attendee.id === props.user.id) {
      setPrivateNotes(() => sess.therapist.private_notes);
      setPublicNotes(() => sess.therapist.public_notes);
    } else {
      setPrivateNotes(() => sess.client.private_notes);
      setPublicNotes(() => sess.client.public_notes);
    }
  };

  const getSession = () => {
    setSessionOverview({
      loading: true,
      session: null,
      selfAttendee: null,
      canAcceptDecline: false,
      canCancel: false,
      canAddToGoogleCalendar: false,
      canRate: false,
    });

    const token = localStorage.getItem("token");
    const url2 = BASE_URL + "/admin/session/" + id;
    const url = BASE_URL + "/therapist-sessions/" + id;

    axios
      .get(props.user.type === "admin" ? url2 : url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        refreshSessionOverview(response.data);
      })
      .catch((error) => {
        setSessionOverview({ loading: false });
        if (error.response.status === 404) history.push("/");
        else if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getChat = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/create-chat";

    const requestPayload = {
      participants:
        props.user.type === "client"
          ? [sessionOverview?.session.therapist.attendee.id]
          : [sessionOverview?.session.client.attendee.id],
    };

    axios
      .post(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        history.push("/messages/" + response.data.id);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data.error === "invalid token") {
          clearAndLogout();
        }

        if (error.response.data.error) {
          setIsLoading(false);
          setShowAlert(
            `This ${
              props.user.type === "client" ? "therapist" : "client"
            } has turned off their messaging feature`
          );
        }
      });
  };

  const updateSession = (requestPayload) => {
    setSessionOverview({
      loading: true,
      session: null,
      selfAttendee: null,
      canAcceptDecline: false,
      canCancel: false,
      canAddToGoogleCalendar: false,
      canRate: false,
    });

    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions/" + session.id;

    axios
      .patch(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        refreshSessionOverview(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
        getSession();
      });
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    const requestPayload = {
      status: "cancelled",
    };
    updateSession(requestPayload);
  };

  const onSetRating = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/rating";
    const requestPayload = {
      session: id,
      relationship: relationship,
      goals: goals,
      qualification: qualification,
      delivery: delivery,
      overall: overall,
    };

    try {
      await axios.post(url, requestPayload, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      setIsLoading(false);
      setShowAlert("Thank you for your valuable feedback");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }

    // console.log(requestPayload);
    updateSessionAttendee(requestPayload);
  };

  const acceptDeclineEnquiry = (responseStatus) => {
    if (responseStatus === "declined") {
      if (window.confirm("Are you sure you want to decline this enquiry?")) {
        const requestPayload = {
          response_status: responseStatus,
        };
        updateSessionAttendee(requestPayload);
      }
    } else {
      const requestPayload = {
        response_status: responseStatus,
      };
      updateSessionAttendee(requestPayload);
    }
  };

  const updateSessionAttendee = (requestPayload) => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions/attendee/" + selfAttendee.id;

    axios
      .patch(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        getSession();
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const {
    loading,
    session,
    selfAttendee,
    canAcceptDecline,
    canCancel,
    canAddToGoogleCalendar,
    canRate,
  } = sessionOverview;

  const onCancelEditPublicNotes = (initialNotes) => {
    toggleEditPublicNotesMode();
    setPublicNotes(initialNotes);
  };

  const onCancelEditPrivateNotes = (initialNotes) => {
    toggleEditPrivateNotesMode();
    setPrivateNotes(initialNotes);
  };

  const onSavePublicNotes = () => {
    toggleEditPublicNotesMode();
    const requestPayload = {
      public_notes: publicNotes,
    };
    updateSessionAttendee(requestPayload);
  };

  const onSavePrivateNotes = () => {
    toggleEditPrivateNotesMode();
    const requestPayload = {
      private_notes: privateNotes,
    };
    updateSessionAttendee(requestPayload);
  };

  const toggleEditPublicNotesMode = () => {
    setEditPublicNotesMode((prevValue) => !prevValue);
  };

  const toggleEditPrivateNotesMode = () => {
    setEditPrivateNotesMode((prevValue) => !prevValue);
  };

  const handleCancel = () => {
    setShowAlert("");
  };

  const customIcons = {
    1: {
      icon: (
        <svg
          className={classes.ratingIconSvg}
          id="Component_106_3"
          data-name="Component 106 – 3"
          xmlns="http://www.w3.org/2000/svg"
          width="47.583"
          height="47.583"
          viewBox="0 0 47.583 47.583"
        >
          <path
            ref={pathRef}
            className="ratingSvgPath"
            id="Path_14896"
            data-name="Path 14896"
            d="M47.583,23.791A23.792,23.792,0,1,1,23.792,0,23.791,23.791,0,0,1,47.583,23.791"
            fill="#eff2f8"
          />
          <line
            className="ratingSvgStroke"
            id="Line_1664"
            data-name="Line 1664"
            y1="3.241"
            x2="8.256"
            transform="translate(27.573 14.613)"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <line
            className="ratingSvgStroke"
            id="Line_1665"
            data-name="Line 1665"
            x1="8.256"
            y1="3.241"
            transform="translate(11.754 14.613)"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            className="ratingSvgStroke"
            id="Path_14897"
            data-name="Path 14897"
            d="M13.177,34.521a13.387,13.387,0,0,1,21.228,0"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            className="ratingSvgStroke"
            id="Path_14898"
            data-name="Path 14898"
            d="M34.969,21.867a3.127,3.127,0,0,1-5.766.016"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            className="ratingSvgStroke"
            id="Path_14899"
            data-name="Path 14899"
            d="M18.38,21.867a3.127,3.127,0,0,1-5.766.016"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <rect
            id="Rectangle_1089"
            data-name="Rectangle 1089"
            width="47.583"
            height="47.583"
            fill="none"
          />
        </svg>
      ),
      label: "Very Dissatisfied",
    },
    2: {
      icon: (
        <svg
          className={classes.ratingIconSvg}
          id="Component_107_3"
          data-name="Component 107 – 3"
          xmlns="http://www.w3.org/2000/svg"
          width="47.583"
          height="47.582"
          viewBox="0 0 47.583 47.582"
        >
          <path
            className="ratingSvgPath"
            id="Path_14900"
            data-name="Path 14900"
            d="M47.583,23.791A23.792,23.792,0,1,1,23.792,0,23.791,23.791,0,0,1,47.583,23.791"
            fill="#eff2f8"
          />
          <path
            className="ratingSvgStroke"
            id="Path_14901"
            data-name="Path 14901"
            d="M17.457,31.512a13.406,13.406,0,0,1,12.669,0"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            className="ratingSvgStroke"
            id="Path_14902"
            data-name="Path 14902"
            d="M35.342,18.8a3.3,3.3,0,1,1-6.6,0"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            className="ratingSvgStroke"
            id="Path_14903"
            data-name="Path 14903"
            d="M18.838,18.8a3.3,3.3,0,1,1-6.6,0"
            fill="none"
            stroke="#b5bfd0"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </svg>
      ),
      label: "Dissatisfied",
    },
    3: {
      icon: (
        <svg
          className={classes.ratingIconSvg}
          xmlns="http://www.w3.org/2000/svg"
          width="47.583"
          height="47.583"
          viewBox="0 0 47.583 47.583"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_1091"
                data-name="Rectangle 1091"
                width="47.583"
                height="47.583"
                transform="translate(0 0)"
                fill="none"
              />
            </clipPath>
          </defs>
          <g
            id="Group_10791"
            data-name="Group 10791"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              className="ratingSvgPath"
              id="Path_14904"
              data-name="Path 14904"
              d="M47.583,23.791A23.792,23.792,0,1,1,23.792,0,23.791,23.791,0,0,1,47.583,23.791"
              fill="#eff2f8"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14905"
              data-name="Path 14905"
              d="M33.371,22.6a2.483,2.483,0,1,1-2.483-2.483A2.483,2.483,0,0,1,33.371,22.6"
              fill="#b5bfd0"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14906"
              data-name="Path 14906"
              d="M19.179,22.6A2.484,2.484,0,1,1,16.7,20.117,2.483,2.483,0,0,1,19.179,22.6"
              fill="#b5bfd0"
            />
            <line
              className="ratingSvgStroke"
              id="Line_1666"
              data-name="Line 1666"
              x2="16.513"
              transform="translate(15.535 31.821)"
              fill="none"
              stroke="#b5bfd0"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </svg>
      ),
      label: "Neutral",
    },
    4: {
      icon: (
        <svg
          className={classes.ratingIconSvg}
          xmlns="http://www.w3.org/2000/svg"
          width="47.583"
          height="47.583"
          viewBox="0 0 47.583 47.583"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_1092"
                data-name="Rectangle 1092"
                width="47.583"
                height="47.583"
                transform="translate(0 0)"
                fill="none"
              />
            </clipPath>
          </defs>
          <g
            id="Group_10793"
            data-name="Group 10793"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              className="ratingSvgPath"
              id="Path_14907"
              data-name="Path 14907"
              d="M47.583,23.791A23.792,23.792,0,1,1,23.792,0,23.791,23.791,0,0,1,47.583,23.791"
              fill="#eff2f8"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14908"
              data-name="Path 14908"
              d="M32.515,29.815a13.387,13.387,0,0,1-17.448,0"
              fill="none"
              stroke="#b5bfd0"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14909"
              data-name="Path 14909"
              d="M29.775,21.211a2.7,2.7,0,0,1,5.162,0"
              fill="none"
              stroke="#b5bfd0"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14910"
              data-name="Path 14910"
              d="M12.645,21.211a2.7,2.7,0,0,1,5.162,0"
              fill="none"
              stroke="#b5bfd0"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </svg>
      ),
      label: "Satisfied",
    },
    5: {
      icon: (
        <svg
          className={classes.ratingIconSvg}
          xmlns="http://www.w3.org/2000/svg"
          width="47.583"
          height="47.583"
          viewBox="0 0 47.583 47.583"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_1093"
                data-name="Rectangle 1093"
                width="47.583"
                height="47.583"
                transform="translate(0 0)"
                fill="none"
              />
            </clipPath>
          </defs>
          <g
            id="Group_10795"
            data-name="Group 10795"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              className="ratingSvgPath"
              id="Path_14911"
              data-name="Path 14911"
              d="M47.583,23.791A23.792,23.792,0,1,1,23.792,0,23.791,23.791,0,0,1,47.583,23.791"
              fill="#eff2f8"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14912"
              data-name="Path 14912"
              d="M12.8,27.062a10.992,10.992,0,1,0,21.983,0Z"
              fill="#b5bfd0"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14913"
              data-name="Path 14913"
              d="M28.777,20.632a3.565,3.565,0,0,1,6.912,0"
              fill="none"
              stroke="#b5bfd0"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              className="ratingSvgStroke"
              id="Path_14914"
              data-name="Path 14914"
              d="M11.893,20.632a3.566,3.566,0,0,1,6.913,0"
              fill="none"
              stroke="#b5bfd0"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </svg>
      ),
      label: "Very Satisfied",
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return (
      <span
        ref={ratingiconRef}
        className={`${classes.ratingIconDiv} ratingIcon`}
        style={{ margin: "0 15px", width: "38px", height: "38px" }}
        {...other}
      >
        {customIcons[value].icon}
      </span>
    );
  }

  // IconContainer.propTypes = {
  //   value: PropTypes.number.isRequired,
  // };

  return (
    <Fragment>
      {loading && <ComponentLoader />}
      {isLoading && <ComponentLoader />}
      {showAlert && <AlertDialog msg={showAlert} onToggle={handleCancel} />}
      {session && (
        <div className={classes.sessionOverview}>
          <div className={`bcg ${classes.sessionOverview__innerDiv}`}>
            <div className={classes.sessionOverviewTitleDiv}>
              <svg
                onClick={() => history.goBack()}
                className={classes.backArrow}
                xmlns="http://www.w3.org/2000/svg"
                width="18.303"
                height="17.946"
                viewBox="0 0 18.303 17.946"
              >
                <g
                  id="Group_7099"
                  data-name="Group 7099"
                  transform="translate(-18699.623 -13146.869)"
                >
                  <path
                    id="Path_4322"
                    data-name="Path 4322"
                    d="M18708.893,13147.221l-8.564,8.677,8.564,8.564"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_4323"
                    data-name="Path 4323"
                    d="M18717.926,13155.842h-16.957"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                  />
                </g>
              </svg>

              <h4 className={classes.sessionOverviewTitle}>Session Overview</h4>
            </div>
            <div className={classes.sessionOverviewContent}>
              <div className={classes.firstCol}>
                <h5 className={classes.AppointmentTitle}>
                  <span>Appointment Details</span>
                </h5>

                <div className={classes.detailsDiv}>
                  <div className={classes.firstItemFlex}>
                    <div>
                      {session.status !== "tentative" && (
                        <div className={classes.detailOne}>
                          <div className={classes.iconBg}>
                            <img
                              src={status}
                              className={classes.sessionIcon}
                              alt=""
                            />
                          </div>
                          <p className={classes.sessionTitle}>
                            {session.status}
                          </p>
                        </div>
                      )}
                      {session.status === "tentative" && (
                        <div className={classes.detailOne}>
                          <div className={classes.iconBg}>
                            <img
                              src={status}
                              className={classes.sessionIcon}
                              alt=""
                            />
                          </div>
                          <p className={classes.sessionTitle}>
                            Awaiting Confirmation
                          </p>
                        </div>
                      )}
                    </div>

                    {canCancel && (
                      <div
                        className={`${classes.detailOne} ${classes.cancelBtn}`}
                      >
                        <button
                          className={`${classes.cancelSesBtn}`}
                          onClick={onCancelClick}
                        >
                          Cancel Session
                        </button>
                      </div>
                    )}
                  </div>

                  {canAcceptDecline && (
                    <div
                      className={`${classes.detailOne} ${classes.cancelBtn} ${classes.accDec} `}
                    >
                      <button
                        className="btn btn-primary  float-right"
                        onClick={() => acceptDeclineEnquiry("accepted")}
                      >
                        Accept
                      </button>
                      <button
                        className={`btn ml-3 btn-danger ${classes.cancelSesBtn}`}
                        onClick={() => acceptDeclineEnquiry("declined")}
                      >
                        Decline
                      </button>
                    </div>
                  )}

                  <div className={classes.detailOne}>
                    <div className={classes.iconBg}>
                      <img
                        src={calIcon}
                        className={classes.sessionIcon}
                        alt=""
                      />
                    </div>
                    <p className={classes.sessionTitle}>
                      <Moment local format="DD MMM YYYY">
                        {session.start}
                      </Moment>
                    </p>
                  </div>

                  <div className={classes.detailOne}>
                    <div className={classes.iconBg}>
                      <img
                        src={clockIcon}
                        className={classes.sessionIcon}
                        alt=""
                      />
                    </div>
                    <p className={classes.sessionTitle}>
                      <Moment local format="HH:mm A">
                        {session.start}
                      </Moment>
                    </p>
                  </div>

                  <div className={classes.detailOne}>
                    <div className={classes.iconBg}>
                      <img src={timer} className={classes.sessionIcon} alt="" />
                    </div>
                    <p className={classes.sessionTitle}>
                      {timeBetweenDates(
                        Date.parse(session.start),
                        Date.parse(session.end)
                      )}
                    </p>
                  </div>

                  {session.type === "video" && (
                    <div className={classes.detailOne}>
                      <div className={classes.iconBg}>
                        <img
                          src={videoCall}
                          className={classes.sessionIcon}
                          alt=""
                        />
                      </div>
                      <p className={classes.sessionTitle}>Video</p>
                    </div>
                  )}

                  {session.type === "home" && (
                    <div className={classes.detailOne}>
                      <div className={classes.iconBg}>
                        <svg
                          className={classes.sessionIcon}
                          xmlns="http://www.w3.org/2000/svg"
                          width="25.873"
                          height="32.996"
                          viewBox="0 0 25.873 37.996"
                        >
                          <g
                            id="Group_7168"
                            data-name="Group 7168"
                            transform="translate(0)"
                          >
                            <path
                              id="Path_4726"
                              data-name="Path 4726"
                              d="M1121.88,1145.165h-2.19a1.345,1.345,0,0,0-1.41,1.265v.676h.932v-.676c0-.121.158-.33.478-.333h2.19c.318,0,.475.213.477.333v.676h.932v-.676A1.343,1.343,0,0,0,1121.88,1145.165Z"
                              transform="translate(-1113.511 -1123.969)"
                              fill="#62bfae"
                            />
                            <path
                              id="Path_4727"
                              data-name="Path 4727"
                              d="M1141.464,1098.354a4.083,4.083,0,1,0-4.088-4.086A4.085,4.085,0,0,0,1141.464,1098.354Z"
                              transform="translate(-1125.245 -1090.188)"
                              fill="#62bfae"
                            />
                            <path
                              id="Path_4728"
                              data-name="Path 4728"
                              d="M1139.868,1123.622l-6.177-9.3a1.97,1.97,0,0,0-1.192-.841l-2.172-.546-.207-.038-.032-.011-1.905-.4a2.346,2.346,0,0,0-.415-.04,2.027,2.027,0,0,0-1.464.627l-6.137,6.508a1.986,1.986,0,0,0-.547,1.379l0,3.315h4.016v-2.525l2.27-2.429-.247,6.206-.451,1.057-.106.224h1.394a3.386,3.386,0,0,1,3.381,3.382v.2l5.67,10.427a1.982,1.982,0,0,0,1.2.962,2.03,2.03,0,0,0,.567.082,1.968,1.968,0,0,0,.958-.249,2.02,2.02,0,0,0,.966-1.194,2.05,2.05,0,0,0,.079-.588,1.975,1.975,0,0,0-.246-.944l-6.2-12.808.443-5.079,3.215,4.849a2.008,2.008,0,1,0,3.343-2.224Z"
                              transform="translate(-1114.333 -1103.863)"
                              fill="#62bfae"
                            />
                            <path
                              id="Path_4729"
                              data-name="Path 4729"
                              d="M1118.071,1152.278H1108.3a2.386,2.386,0,0,0-2.386,2.386v8.015a2.386,2.386,0,0,0,2.386,2.386h9.774a2.386,2.386,0,0,0,2.386-2.386v-8.015A2.385,2.385,0,0,0,1118.071,1152.278Zm.938,10.4a.94.94,0,0,1-.938.938H1108.3a.941.941,0,0,1-.939-.938v-8.015a.943.943,0,0,1,.939-.939h9.774a.942.942,0,0,1,.938.939Zm-4.5-7.386h-2.654v2.051h-2.051V1160h2.051v2.051h2.654V1160h2.05v-2.654h-2.05Z"
                              transform="translate(-1105.911 -1128.34)"
                              fill="#62bfae"
                            />
                          </g>
                        </svg>
                      </div>
                      <p className={classes.sessionTitle}> Home </p>
                    </div>
                  )}

                  {session.type === "in_clinic" && (
                    <div className={classes.detailOne}>
                      <div className={classes.iconBg}>
                        <svg
                          className={classes.sessionIcon}
                          id="Group_7167"
                          data-name="Group 7167"
                          xmlns="http://www.w3.org/2000/svg"
                          width="27.624"
                          height="28.256"
                          viewBox="0 0 36.624 33.256"
                        >
                          <path
                            id="Path_4724"
                            data-name="Path 4724"
                            d="M3355.151,3420.112a6.368,6.368,0,1,0,6.369,6.368A6.375,6.375,0,0,0,3355.151,3420.112Zm4.32,8.253h-2.435v2.434h-3.771v-2.434h-2.435v-3.771h2.435v-2.435h3.771v2.435h2.435Z"
                            transform="translate(-3336.839 -3406.848)"
                            fill="#62bfae"
                          />
                          <path
                            id="Path_4725"
                            data-name="Path 4725"
                            d="M3101.543,3116.446l-17.786-11.214a.524.524,0,0,0-.56,0l-17.786,11.214a.525.525,0,0,0,.28.969h4.162v20.993H3097.1v-20.993h4.162A.525.525,0,0,0,3101.543,3116.446Zm-13.593,14.971a8,8,0,1,1,3.527-6.634A8,8,0,0,1,3087.951,3131.417Z"
                            transform="translate(-3065.165 -3105.151)"
                            fill="#62bfae"
                          />
                        </svg>
                      </div>
                      <p className={classes.sessionTitle}> Clinic </p>
                    </div>
                  )}

                  {session.type === "phone" && (
                    <div className={classes.detailOne}>
                      <div className={classes.iconBg}>
                        <FontAwesomeIcon
                          icon={faPhone}
                          className={classes.FontAwesomeIcon}
                        />
                      </div>
                      <p className={classes.sessionTitle}> Phone </p>
                    </div>
                  )}

                  {session.status === "confirmed" &&
                    session.zoom_session &&
                    session.type === "video" && (
                      <p>
                        <a
                          className="btn btn-warning"
                          href={session.zoom_session.join_url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Join video session
                        </a>
                        <span className="d-block mt-3">
                          Passcode: {session.zoom_session.password}
                        </span>
                      </p>
                    )}

                  {session.status === "confirmed" &&
                    !session.zoom_session &&
                    session.type === "video" && (
                      <p className={classes.videoLink}>
                        <small className="text-muted">
                          Video session link will be available 10 minutes before
                          the session starts
                        </small>
                      </p>
                    )}

                  <div className={classes.firstItemFlex}>
                    <div className={classes.detailOne}>
                      <div className={classes.iconBg}>
                        <img
                          src={user}
                          className={classes.sessionIcon}
                          alt=""
                        />
                      </div>

                      {props?.user?.type === "client" && (
                        <p className={classes.sessionTitle}>
                          <Link
                            to={
                              `/therapist/` +
                              session.therapist.attendee.id +
                              `/profile`
                            }
                          >
                            <span>
                              {session?.therapist.attendee.first_name}
                            </span>
                            &nbsp;
                            <span>{session.therapist.attendee.last_name}</span>
                          </Link>
                        </p>
                      )}

                      {props?.user?.type !== "client" && (
                        <p className={classes.sessionTitle}>
                          <Link
                            to={
                              `/client/` +
                              sessionOverview?.session.client.attendee.id +
                              `/profile`
                            }
                          >
                            <span>
                              {
                                sessionOverview?.session?.client.attendee
                                  ?.first_name
                              }
                            </span>
                            &nbsp;
                            <span>
                              {
                                sessionOverview?.session?.client.attendee
                                  ?.last_name
                              }
                            </span>
                          </Link>
                        </p>
                      )}
                    </div>

                    {props.user.type !== "admin" && (
                      <button
                        className={classes.sendMsgBtn}
                        type="button"
                        onClick={getChat}
                      >
                        Send a message
                      </button>
                    )}
                  </div>

                  <div className={classes.detailOne}>
                    <div className={classes.iconBg}>
                      <img
                        src={currency}
                        className={classes.sessionIcon}
                        alt=""
                      />
                    </div>
                    <p className={classes.sessionTitle}>
                      {session.fee} {session.currency_code}
                    </p>
                  </div>

                  {session.rating && (
                    <div className={classes.detailOne}>
                      <div className={classes.iconBg}>
                        <img
                          src={star}
                          className={classes.sessionIcon}
                          alt=""
                        />
                      </div>
                      <p className={classes.sessionTitle}>{session.rating}</p>
                    </div>
                  )}

                  <div className={classes.detailOne}>
                    <div className={classes.iconBg}>
                      <img
                        src={treatment}
                        className={classes.sessionIcon}
                        alt=""
                      />
                    </div>
                    <p className={classes.sessionTitle}>{session.title} </p>
                  </div>

                  {canAddToGoogleCalendar && (
                    <div className={`${classes.detailOne} `}>
                      <div className={classes.iconBg}>
                        <img
                          src={calIcon}
                          className={classes.sessionIcon}
                          alt=""
                        />
                      </div>
                      <a
                        href={session.google_cal_link}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#62BFAE" }}
                        className={classes.sessionTitle}
                      >
                        Add to Google Calendar
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.secondCol}>
                <div className={classes.AppointmentTitle}>
                  <a
                    href="#"
                    style={{
                      textDecoration:
                        currentSelected === "therapist" && "underline",
                    }}
                    onClick={() => setCurrentSelected(() => "therapist")}
                    className={classes.notesTab}
                  >
                    Therapist Notes
                  </a>
                  <a
                    id="publicNotesTab"
                    style={{
                      textDecoration:
                        currentSelected === "client" && "underline",
                    }}
                    className={classes.notesTab}
                    onClick={() => setCurrentSelected(() => "client")}
                  >
                    Client Notes
                  </a>

                  {canRate &&
                    session.rating === null &&
                    props.user.type !== "admin" && (
                      <button
                        className={classes.notesTab}
                        data-toggle="modal"
                        data-target="#ratingModal"
                      >
                        <svg
                          className={classes.starIcon}
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.181"
                          height="18.336"
                          viewBox="0 0 19.181 18.336"
                        >
                          <path
                            id="Path_4708"
                            data-name="Path 4708"
                            d="M-599.99,1759.152l2.227,4.513a.95.95,0,0,0,.715.52l4.98.724a.951.951,0,0,1,.527,1.622l-3.6,3.513a.95.95,0,0,0-.273.841l.85,4.96a.951.951,0,0,1-1.379,1l-4.454-2.343a.95.95,0,0,0-.885,0l-4.454,2.343a.951.951,0,0,1-1.379-1l.851-4.96a.951.951,0,0,0-.273-.841l-3.6-3.513a.951.951,0,0,1,.527-1.622l4.98-.724a.951.951,0,0,0,.716-.52l2.227-4.513A.951.951,0,0,1-599.99,1759.152Z"
                            transform="translate(610.433 -1758.622)"
                            fill="#fff"
                          />
                        </svg>
                        Rate this session
                      </button>
                    )}
                </div>

                <div className={`${classes.notesDiv}`}>
                  <div className={classes.notesDivContent}>
                    {currentSelected === "therapist" && (
                      <div className={`card-body ${classes.cardBody}`}>
                        {session.therapist.id !== selfAttendee.id &&
                          ((session.therapist.public_notes && (
                            <textarea
                              disabled={true}
                              className={`form-control my-3 ${classes.sessionOverviewTextArea}`}
                              id="therapistPublicNotesInput"
                              rows="10"
                              value={session.therapist.public_notes}
                              onChange={(event) =>
                                setPublicNotes(event.target.value)
                              }
                            />
                          )) ||
                            "[ No therapist notes ]")}
                        {session.therapist.id === selfAttendee.id && (
                          <Fragment>
                            <ul
                              className={`nav nav-tabs ${classes.bBottom}`}
                              id="notesTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link active"
                                  id="publicNotesTab"
                                  data-toggle="tab"
                                  href="#publicNotes"
                                  role="tab"
                                  aria-controls="publicNotesTab"
                                  aria-selected="true"
                                >
                                  Public notes
                                </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  id="privateNotesTab"
                                  data-toggle="tab"
                                  href="#privateNotes"
                                  role="tab"
                                  aria-controls="privateNotesTab"
                                  aria-selected="false"
                                >
                                  Private notes
                                </a>
                              </li>
                            </ul>
                            <div
                              className={`tab-content ${classes.tabContent}`}
                              id="notesTabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="publicNotes"
                                role="tabpanel"
                                aria-labelledby="publicNotesTab"
                              >
                                <div className="row">
                                  <div className="col">
                                    {!(
                                      publicNotes ||
                                      session.therapist.public_notes ||
                                      editPublicNotesMode
                                    ) && (
                                      <p className="my-3">
                                        {"[ No public notes ]"}
                                      </p>
                                    )}

                                    <textarea
                                      className={`form-control my-3 ${classes.sessionOverviewTextArea}`}
                                      id="privateNotesInput"
                                      rows="8"
                                      placeholder="Create Public Notes – 
                                      Visible to you and your client only"
                                      value={publicNotes}
                                      onChange={(event) =>
                                        setPublicNotes(event.target.value)
                                      }
                                    />

                                    <Fragment>
                                      <button
                                        disabled={
                                          publicNotes ===
                                          session.therapist.public_notes
                                            ? true
                                            : false
                                        }
                                        className={`btn float-right ml-3 disableSaveBtn`}
                                        onClick={onSavePublicNotes}
                                      >
                                        Save
                                      </button>
                                      <button
                                        disabled={
                                          publicNotes ===
                                          session.therapist.public_notes
                                            ? true
                                            : false
                                        }
                                        className="btn btn-outline-danger float-right"
                                        onClick={() =>
                                          onCancelEditPublicNotes(
                                            session.therapist.public_notes ===
                                              null
                                              ? ""
                                              : session.therapist.public_notes
                                          )
                                        }
                                      >
                                        Cancel
                                      </button>
                                    </Fragment>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="privateNotes"
                                role="tabpanel"
                                aria-labelledby="privateNotesTab"
                              >
                                <div className="row">
                                  <div className="col">
                                    {!(
                                      privateNotes ||
                                      session.therapist.private_notes ||
                                      editPrivateNotesMode
                                    ) && (
                                      <p className="my-3">
                                        {"[ No private notes ]"}
                                      </p>
                                    )}

                                    <textarea
                                      className={`form-control my-3 ${classes.sessionOverviewTextArea}`}
                                      id="privateNotesInput"
                                      rows="8"
                                      placeholder="Create Private Notes"
                                      value={privateNotes}
                                      onChange={(event) =>
                                        setPrivateNotes(event.target.value)
                                      }
                                    />

                                    <Fragment>
                                      <button
                                        disabled={
                                          privateNotes ===
                                          session.therapist.private_notes
                                            ? true
                                            : false
                                        }
                                        className={`btn float-right ml-3 disableSaveBtn`}
                                        onClick={onSavePrivateNotes}
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="btn btn-outline-danger float-right"
                                        onClick={() =>
                                          onCancelEditPrivateNotes(
                                            session.therapist.private_notes ===
                                              null
                                              ? ""
                                              : session.therapist.private_notes
                                          )
                                        }
                                      >
                                        Cancel
                                      </button>
                                    </Fragment>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    )}
                    {currentSelected === "client" && (
                      <div className={`card-body ${classes.cardBody}`}>
                        {session.client.id !== selfAttendee.id &&
                          ((session.client.public_notes && (
                            <textarea
                              disabled={true}
                              className={`form-control my-3 ${classes.sessionOverviewTextArea}`}
                              id="clientPublicNotesInput"
                              rows="10"
                              value={session.client.public_notes}
                              onChange={(event) =>
                                setPublicNotes(event.target.value)
                              }
                            />
                          )) ||
                            "[ No client notes ]")}
                        {session.client.id === selfAttendee.id && (
                          <Fragment>
                            <ul
                              className={`nav nav-tabs ${classes.bBottom}`}
                              id="notesTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link active"
                                  id="publicNotesTab"
                                  data-toggle="tab"
                                  href="#publicNotes"
                                  role="tab"
                                  aria-controls="publicNotesTab"
                                  aria-selected="true"
                                >
                                  Public notes
                                </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  id="privateNotesTab"
                                  data-toggle="tab"
                                  href="#privateNotes"
                                  role="tab"
                                  aria-controls="privateNotesTab"
                                  aria-selected="false"
                                >
                                  Private notes
                                </a>
                              </li>
                            </ul>
                            <div
                              className={`tab-content ${classes.tabContent}`}
                              id="notesTabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="publicNotes"
                                role="tabpanel"
                                aria-labelledby="publicNotesTab"
                              >
                                <textarea
                                  className={`form-control my-3 ${classes.sessionOverviewTextArea}`}
                                  id="privateNotesInput"
                                  placeholder="Create Public Notes – 
                                      Visible to you and your therapist only"
                                  rows="8"
                                  value={publicNotes}
                                  onChange={(event) =>
                                    setPublicNotes(event.target.value)
                                  }
                                />

                                <Fragment>
                                  <button
                                    disabled={
                                      publicNotes ===
                                      session.client.public_notes
                                        ? true
                                        : false
                                    }
                                    className={`btn float-right ml-3 disableSaveBtn`}
                                    onClick={onSavePublicNotes}
                                  >
                                    Save
                                  </button>
                                  <button
                                    disabled={
                                      publicNotes ===
                                      session.client.public_notes
                                        ? true
                                        : false
                                    }
                                    className="btn btn-outline-danger float-right"
                                    onClick={() =>
                                      onCancelEditPublicNotes(
                                        session.client.public_notes === null
                                          ? ""
                                          : session.client.public_notes
                                      )
                                    }
                                  >
                                    Cancel
                                  </button>
                                </Fragment>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="privateNotes"
                                role="tabpanel"
                                aria-labelledby="privateNotesTab"
                              >
                                <textarea
                                  className={`form-control my-3 ${classes.sessionOverviewTextArea}`}
                                  id="privateNotesInput"
                                  placeholder="Create Private Notes"
                                  rows="8"
                                  value={privateNotes}
                                  onChange={(event) =>
                                    setPrivateNotes(event.target.value)
                                  }
                                />

                                <Fragment>
                                  <button
                                    disabled={
                                      privateNotes ===
                                      session.client.private_notes
                                        ? true
                                        : false
                                    }
                                    className={`btn float-right ml-3 disableSaveBtn`}
                                    onClick={onSavePrivateNotes}
                                  >
                                    Save
                                  </button>
                                  <button
                                    disabled={
                                      privateNotes ===
                                      session.client.private_notes
                                        ? true
                                        : false
                                    }
                                    className="btn btn-outline-danger float-right"
                                    onClick={() =>
                                      onCancelEditPrivateNotes(
                                        session.client.private_notes === null
                                          ? ""
                                          : session.client.private_notes
                                      )
                                    }
                                  >
                                    Cancel
                                  </button>
                                </Fragment>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    )}
                    <div
                      className={`modal fade ${classes.ratingModalDiv}`}
                      id="ratingModal"
                      data-backdrop="static"
                      data-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="ratingModalLabel"
                      aria-hidden="true"
                    >
                      <div
                        className={`modal-dialog modal-dialog-centered ${classes.modalDialog}`}
                      >
                        <div
                          className={`modal-content ${classes.ratingModalCard}`}
                        >
                          <button
                            type="button"
                            className={`close ${classes.closeBtn}`}
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <svg
                              className={classes.ratingClose}
                              xmlns="http://www.w3.org/2000/svg"
                              width="39.031"
                              height="39.031"
                              viewBox="0 0 39.031 39.031"
                            >
                              <path
                                id="Path_4294"
                                data-name="Path 4294"
                                d="M820.831,831.908a19.516,19.516,0,1,0,19.516,19.516A19.516,19.516,0,0,0,820.831,831.908Zm7.671,26.184a.709.709,0,0,1-1,1l-6.668-6.668-6.669,6.669a.709.709,0,0,1-1-1l6.669-6.669-6.669-6.669a.709.709,0,0,1,1-1l6.669,6.669,6.669-6.669a.709.709,0,1,1,1,1l-6.669,6.669Z"
                                transform="translate(-801.315 -831.908)"
                                fill="#49aa98"
                              />
                            </svg>
                          </button>

                          <div className={`modal-body ${classes.modalBody}`}>
                            <div className={classes.ratingModalHead}>
                              <div
                                className={classes.ratingModalHead__imageDiv}
                              >
                                <img
                                  src={ratingModal}
                                  className={classes.ratingImg}
                                  alt=""
                                />
                              </div>

                              <p className={classes.ratingModalHead__topTitle}>
                                Thank you for taking the session!
                              </p>
                              <h3
                                className={classes.ratingModalHead__topHeading}
                              >
                                Help us improve the service by rating
                                responsibly.
                              </h3>
                              <p className={classes.ratingModalHead__topDesc}>
                                We at Mindsum, take feedbacks and quality
                                service very seriously. We don’t keep therapists
                                providing average care for long because we know
                                how important it is to provide you with the best
                                therapy possible!
                              </p>
                            </div>

                            <div className={classes.ratingModalBody}>
                              <div className={classes.ratingModalBody__goal}>
                                <h4
                                  className={
                                    classes.ratingModalBody__goal__title
                                  }
                                >
                                  I felt heard, understood, and respected.
                                </h4>

                                <Rating
                                  name="relationship-rating"
                                  defaultValue={selfAttendee.relationship}
                                  // defaultValue={2}
                                  // precision={1}
                                  className={classes.Rating}
                                  IconContainerComponent={IconContainer}
                                  onChange={(event, newValue) => {
                                    setRelationship(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                />
                              </div>

                              <div className={classes.ratingModalBody__goal}>
                                <h4
                                  className={
                                    classes.ratingModalBody__goal__title
                                  }
                                >
                                  We worked on and talked about what I wanted to
                                  work on and talk about.
                                </h4>

                                <Rating
                                  name="goals-rating"
                                  defaultValue={selfAttendee.goals}
                                  // defaultValue={2}
                                  // precision={1}
                                  className={classes.Rating}
                                  IconContainerComponent={IconContainer}
                                  onChange={(event, newValue) => {
                                    setGoals(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                />
                              </div>

                              <div className={classes.ratingModalBody__goal}>
                                <h4
                                  className={
                                    classes.ratingModalBody__goal__title
                                  }
                                >
                                  The therapist’s expertise and approach are a
                                  good fit for me.
                                </h4>

                                <Rating
                                  name="qualifications-rating"
                                  defaultValue={selfAttendee.qualification}
                                  // defaultValue={2}
                                  // precision={1}
                                  className={classes.Rating}
                                  IconContainerComponent={IconContainer}
                                  onChange={(event, newValue) => {
                                    setQualification(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                />
                              </div>

                              <div className={classes.ratingModalBody__goal}>
                                <h4
                                  className={
                                    classes.ratingModalBody__goal__title
                                  }
                                >
                                  Audio and video call quality was good.
                                </h4>

                                <Rating
                                  name="delivery-rating"
                                  defaultValue={selfAttendee.delivery}
                                  // defaultValue={2}
                                  // precision={1}
                                  className={classes.Rating}
                                  IconContainerComponent={IconContainer}
                                  onChange={(event, newValue) => {
                                    setDelivery(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                />
                              </div>

                              <div className={classes.ratingModalBody__goal}>
                                <h4
                                  className={
                                    classes.ratingModalBody__goal__title
                                  }
                                >
                                  Overall, today’s session was right for me.
                                </h4>

                                <Rating
                                  name="overall-rating"
                                  defaultValue={selfAttendee.overall}
                                  // defaultValue={2}
                                  // precision={1}
                                  className={classes.Rating}
                                  IconContainerComponent={IconContainer}
                                  onChange={(event, newValue) => {
                                    setOverall(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                />
                              </div>

                              <div
                                className={`modal-footer ${classes.modalFooter}`}
                              >
                                <button
                                  type="button"
                                  className={classes.SubmitBtn}
                                  data-dismiss="modal"
                                  onClick={onSetRating}
                                >
                                  Submit
                                </button>
                              </div>

                              {/* <Rating
                              style={{
                                color: "white",
                              }}
                              name="half-rating"
                              defaultValue={selfAttendee.rating}
                              precision={0.5}
                              size="large"
                              className={classes.Rating}
                              // IconContainerComponent={IconContainer}
                              onChange={(event) =>
                                setRating(event.target.value)
                              }
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              
                            /> */}

                              {/* <div
                                className={`modal-footer ${classes.modalFooter}`}
                              >
                                <button
                                  type="button"
                                  className={classes.SubmitBtn}
                                  data-dismiss="modal"
                                  onClick={onSetRating}
                                >
                                  Submit
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sessionOverviewFooter}>
            <FooterLink />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(SessionOverview);
