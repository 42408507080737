import classes from "../QNA/QNA.module.scss";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import qs from "qs";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useHistory, useLocation } from "react-router";
import Moment from "react-moment";
import moment from "moment";

import { BASE_URL } from "../../../../../shared/constants";
import FooterLink from "../../../../footerLink/FooterLink";
import ComponentLoader from "../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../shared/utility";

const statuses = [
  "available_to_transfer",
  "pending",
  "cancelled",
  "refunded",
  "transferred",
];

const columns = [
  {
    field: "first_name",
    headerName: "First Name",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`adminSessionFee`}>
            {params.getValue(params.id, "first_name") || "-"}
          </p>
        </div>
      );
    },
  },

  {
    field: "last_name",
    headerName: "Last Name",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`adminSessionFee`}>
            {params.getValue(params.id, "last_name") || "-"}
          </p>
        </div>
      );
    },
  },

  {
    field: "number_of_session",
    headerName: "Total Session",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`adminSessionFee`}>
            {params.getValue(params.id, "number_of_session") || "-"}
          </p>
        </div>
      );
    },
  },
  {
    field: "total_fee_paid",
    headerName: "Total Fee Paid",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`adminSessionFee`}>
            {params.getValue(params.id, "total_fee_paid") ? (
              <> &#163;{params.getValue(params.id, "total_fee_paid")} </>
            ) : (
              "-"
            )}
          </p>
        </div>
      );
    },
  },
  {
    field: "paid_to_therapist",
    headerName: "Paid to Therapist",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    headerClassName: "super-app-theme--admin-header",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <div className={`questionClient`}>
          <p className={`adminSessionFee`}>
            {params.getValue(params.id, "paid_to_therapist") ? (
              <> &#163;{params.getValue(params.id, "paid_to_therapist")} </>
            ) : (
              "-"
            )}
          </p>
        </div>
      );
    },
  },
];

const Revenue = () => {
  const contentBodyRef = useRef(null);
  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);
  const history = useHistory();
  const { search } = useLocation();

  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const [questions, setQuestions] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const [currentTab, setCurrentTab] = useState(
    (search.includes("all") && "all") ||
      (search.includes("past30days") && "past30days") ||
      (search.includes("past1year") && "past1year") ||
      (search.includes("selectDates") && "selectDates")
  );

  const [startDate, setStartDate] = useState(
    params.from ? new Date(params.from) : null
  );
  const [endDate, setEndDate] = useState(
    params.to ? new Date(params.to) : null
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentTab === "selectDates") {
      contentBodyRef.current.style.height = "59vh";
    } else {
      contentBodyRef.current.style.height = "65vh";
    }
  });

  useEffect(() => {
    if (currentTab === "past30days") {
      getOneMonthData();
    }

    if (currentTab === "past1year") {
      getOneYearData();
    }

    if (currentTab === "all") {
      getAll();
    }
    if (currentTab === "selectDates" && startDate && endDate) {
      getSelectData();
    }
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className={classes.topFlex}>
          <div className={classes.topTabs}>
            <ul
              className={`nav nav-pills ${classes.navPills}`}
              id="myTab"
              role="tablist"
            >
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={`${currentTab === "all" && "adminActiveTab"} ${
                    classes.navLink
                  }`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#all"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "all")}
                >
                  All
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={` ${
                    currentTab === "past30days" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#past30days"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "past30days")}
                >
                  Past 30 days
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={`${
                    currentTab === "past1year" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#past1year"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "past1year")}
                >
                  Past 1 year
                </a>
              </li>
              <li
                className={`nav-item ${classes.navPillsList}`}
                role="presentation"
              >
                <a
                  className={` ${
                    currentTab === "selectDates" && "adminActiveTab"
                  } ${classes.navLink}`}
                  id="all-tab"
                  data-toggle="tab"
                  href="#selectDates"
                  role="tab"
                  onClick={(event) => handleTabClick(event, "selectDates")}
                >
                  Select dates
                </a>
              </li>
            </ul>
          </div>
        </div>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    setCurrentTab(tab);

    if (tab === "all") {
      history.replace({
        pathname: "/revenue",
        search: "all",
      });
      getAll();
    }

    if (tab === "past30days") {
      history.replace({
        pathname: "/revenue",
        search: "past30days",
      });
      getOneMonthData();
    }

    if (tab === "past1year") {
      history.replace({
        pathname: "/revenue",
        search: "past1year",
      });
      getOneYearData();
    }

    if (tab === "selectDates") {
      setQuestions({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
      history.replace({
        pathname: "/revenue",
        search: "selectDates",
      });
    }
  };

  const getAll = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/revenue`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },

        params: {
          ordering: "-signup_datetime",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });

      setQuestions(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const getOneMonthData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/revenue`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
        params: {
          from: moment(new Date())
            .startOf("day")
            .utc()
            .subtract(30, "day")
            .format("YYYY-MM-DD HH:mm:ss"),

          to: moment(new Date())
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),

          // start__date__range: `${moment(new Date())
          //   .startOf("day")
          //   .utc()
          //   .subtract(30, "day")
          //   .format("YYYY-MM-DD")},${moment(new Date())
          //   .endOf("day")
          //   .utc()
          //   .format("YYYY-MM-DD")}`,
          // ordering: "-start",
          // status: statuses,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      const filteredData = {
        ...response.data,
        results: response.data.results.map((item) => {
          return {
            ...item,
            client:
              item?.client?.attendee?.first_name +
              " " +
              item?.client?.attendee?.last_name,
            therapist:
              item?.therapist?.attendee?.first_name +
              " " +
              item?.therapist?.attendee?.last_name,
          };
        }),
      };

      setQuestions(filteredData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const getOneYearData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/revenue`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
        params: {
          from: moment(new Date())
            .startOf("day")
            .utc()
            .subtract(365, "day")
            .format("YYYY-MM-DD HH:mm:ss"),

          to: moment(new Date())
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          ordering: "-signup_datetime",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });

      setQuestions(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const getSelectData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/admin/revenue`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
        params: {
          from: startDate
            ? moment(startDate)
                .startOf("day")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
          to: endDate
            ? moment(endDate).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
            : null,
          ordering: "-signup_datetime",
        },
      });

      setQuestions(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.data?.error === "Invalid token.") {
        clearAndLogout();
      }
    }
  };

  const onPageChange = async (page) => {
    setLoading(() => true);
    try {
      const response = await axios.get(
        page > currentPage ? questions.next : questions.previous,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const filteredData = {
        ...response.data,
        results: response.data.results.map((item) => {
          return {
            ...item,
            client:
              item?.client?.attendee?.first_name +
              " " +
              item?.client?.attendee?.last_name,
            therapist:
              item?.therapist?.attendee?.first_name +
              " " +
              item?.therapist?.attendee?.last_name,
          };
        }),
      };
      setQuestions(filteredData);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRowClick = (e, tab) => {
    // e.preventDefault();

    if (currentTab === "all") {
      history.push({
        pathname: `/revenue-details/${e.row.id}`,
        search: `tab=all`,
      });
    }

    if (currentTab === "past30days") {
      history.push({
        pathname: `/revenue-details/${e.row.id}`,
        search: `tab=past30days`,
      });
    }

    if (currentTab === "past1year") {
      history.push({
        pathname: `/revenue-details/${e.row.id}`,
        search: `tab=past1year`,
      });
    }

    if (currentTab === "selectDates") {
      history.push({
        pathname: `/revenue-details/${e.row.id}`,
        search: `tab=selectDates&from=${startDate}&to=${endDate}`,
      });
    }
  };

  return (
    <div className={classes.qna}>
      <div className={`bcg ${classes.qna__innerDiv}`}>
        {currentTab === "selectDates" && (
          <>
            <div className={classes.selectDates}>
              <DatePicker
                calendarClassName={classes.firstCalender}
                className={classes.firstInput}
                onChange={(date) => setStartDate(date)}
                selected={startDate}
                placeholderText="Start Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />
              <p className={classes.to}> To </p>

              <DatePicker
                className={classes.secondInput}
                calendarClassName={classes.secondCalender}
                onChange={(date) => setEndDate(date)}
                selected={endDate}
                placeholderText="End Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />

              <button onClick={getSelectData} className={classes.GetReports}>
                Get Report
              </button>
            </div>
          </>
        )}

        <div className={classes.contentDiv}>
          <div ref={contentBodyRef} className={classes.contentBody}>
            <DataGrid
              onRowClick={handleRowClick}
              rows={questions.results}
              columns={columns}
              components={{
                Toolbar: CustomToolbar,
              }}
              pageSize={50}
              rowCount={questions.count}
              pagination
              paginationMode="server"
              loading={loading}
              onPageChange={onPageChange}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              disableColumnMenu
              rowHeight={80}
            />
          </div>
        </div>
      </div>
      <div className={classes.footerLink}>
        <FooterLink />
      </div>

      {isLoading && <ComponentLoader />}
    </div>
  );
};

export default Revenue;
