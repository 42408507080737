import React, { Fragment, useEffect, useState } from "react";
import classes from "./CP.module.scss";
import { BASE_URL } from "../../../../../../shared/constants";
import axios from "axios";
import PaymentMethodsList from "./PaymentMethodsList/PaymentMethodsList";
import ComponentLoader from "../../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../../shared/utility";
import AlertDialog from "../../../../../UI/Alert/AlertDialog";

const ClientPaymentSettings = (props) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultMethod, setDefaultMethod] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState("");

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/payment/payment-methods";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setPaymentMethods(response.data.payment_methods);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }

        if (
          error?.response?.data?.error ===
          "Permission denied: Please, verify your email to perform this action"
        ) {
          setIsEmailVerified("Verify your email to add payment method");
        }
      });
  };

  const setPaymentMethodAsDefault = (paymentMethodId) => {
    setDefaultMethod(true);
    const token = localStorage.getItem("token");
    const url =
      BASE_URL + `/payment/payment-methods/${paymentMethodId}/set-as-default`;

    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setDefaultMethod(false);
        setPaymentMethods(response.data.payment_methods);
      })
      .catch((error) => {
        alert(error.response.data.error);
      });
  };

  const deletePaymentMethod = (paymentMethodId) => {
    setDeleteCard(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + `/payment/payment-methods/${paymentMethodId}`;

    axios
      .delete(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setDeleteCard(false);
        setPaymentMethods(response.data.payment_methods);
      })
      .catch((error) => {
        alert(error.response.data.error);
      });
  };

  return (
    <Fragment>
      {loading && <ComponentLoader />}
      {!loading && (
        <Fragment>
          <div className={classes.addPaymentDiv}>
            <PaymentMethodsList
              paymentMethods={paymentMethods}
              setAsDefault={setPaymentMethodAsDefault}
              delete={deletePaymentMethod}
              getPaymentMethods={getPaymentMethods}
              defaultMethod={defaultMethod}
              deleteCard={deleteCard}
            />
          </div>
        </Fragment>
      )}

      {isEmailVerified && (
        <AlertDialog
          msg={isEmailVerified}
          onToggle={() => setIsEmailVerified("")}
          verifyCTA="Verify Email"
        />
      )}
    </Fragment>
  );
};

export default ClientPaymentSettings;
