import * as actionTypes from "./actionTypes";
import axios from "axios";
import { BASE_URL } from "../../shared/constants";

export const getUserProfileStart = () => {
  return {
    type: actionTypes.GET_USER_PROFILE_START,
  };
};

export const getUserProfileSuccess = (profile) => {
  return {
    type: actionTypes.GET_USER_PROFILE_SUCCESS,
    profile: profile,
  };
};

export const getUserProfileFail = (error) => {
  return {
    type: actionTypes.GET_USER_PROFILE_FAIL,
    error: error,
  };
};

export const updateUserProfileStart = () => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_START,
  };
};

export const updateUserProfileSuccess = (profile) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_SUCCESS,
    profile: profile,
  };
};

export const updateUserProfileFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_FAIL,
    error: error,
  };
};

export const updateUserProfileResetNavigate = () => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_RESET_NAVIGATE,
  };
};

export const addUserExternalWebsiteStart = () => {
  return {
    type: actionTypes.ADD_USER_EXTERNAL_WEBSITE_START,
  };
};

export const addUserExternalWebsiteSuccess = (profile) => {
  return {
    type: actionTypes.ADD_USER_EXTERNAL_WEBSITE_SUCCESS,
    profile: profile,
  };
};

export const addUserExternalWebsiteFail = (error) => {
  return {
    type: actionTypes.ADD_USER_EXTERNAL_WEBSITE_FAIL,
    error: error,
  };
};

export const addAvailabilitySlot = (profile) => {
  return {
    type: actionTypes.ADD_AVAILABILITY_SLOT,
    profile: profile,
  };
};

export const deleteAvailabilitySlot = (profile) => {
  return {
    type: actionTypes.DELETE_AVAILABILITY_SLOT,
    profile: profile,
  };
};

export const getUserProfile = () => {
  return (dispatch) => {
    dispatch(getUserProfileStart());
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const url = BASE_URL + "/" + user["type"] + "/" + user["id"] + "/profile";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getUserProfileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUserProfileFail(error.response.data.error));
      });
  };
};

export const updateUserProfile = (userProfile) => {
  return (dispatch) => {
    dispatch(updateUserProfileStart());
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const url = BASE_URL + "/" + user["type"] + "/" + user["id"] + "/profile";

    const requestPayload = {
      ...userProfile,
    };

    axios
      .put(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(updateUserProfileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateUserProfileFail(error.response.data.error));
      });
  };
};

export const resetNavigate = () => {
  return (dispatch) => {
    dispatch(updateUserProfileResetNavigate());
  };
};

export const addUserExternalWebsite = (externalWebsite) => {
  return (dispatch) => {
    dispatch(addUserExternalWebsiteStart());
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const url = BASE_URL + "/" + user["type"] + "/external-websites/add";

    const requestPayload = {
      ...externalWebsite,
    };

    axios
      .post(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(addUserExternalWebsiteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addUserExternalWebsiteFail(error.response.data.error));
      });
  };
};
