import React from "react";

const Alert = ({ customClass, ...props }) => {
  const contextualClass = "alert-" + props.level;

  return (
    <div
      style={{
        marginTop: "-25px",
        padding: "5px",
        textAlign: "center",
        lineHeight: "100%",
      }}
      className={`alert ${contextualClass} ${customClass}`}
      role="alert"
    >
      {props.message}
    </div>
  );
};

export default Alert;
