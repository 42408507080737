import React from "react";
import classes from "./ComponentLoader.module.scss";

const ComponentLoader = () => {
  return (
    <div className={classes.loader}>
      <span className={classes.loader__spin}></span>
    </div>
  );
};

export default ComponentLoader;
