import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classes from "./SearchCard.module.scss";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import imagePlaceHolder from "../../../../../../assets/images/profile-placeholder.png";

const SEarchCard = ({
  id = "",
  type = "",
  user = {},
  first_name = "",
  last_name = "",
  profile_image = "",
  job_title = "",
  city_town = "",
  rating = "",
  counselling_areas = [],
  default_session_fee = "",
  offers_home_visit_sessions = "",
  offers_in_clinic_sessions = "",
  offers_online_sessions = "",
  offers_phone_sessions = "",
  setShowProfile,
  freeFlg,
}) => {
  const history = useHistory();
  const location = useLocation();

  const isAuthenticated = useSelector((state) => state.auth.token !== null);

  let counsellingArea = "";

  counselling_areas.map((e) => {
    counsellingArea = `${counsellingArea} ${e.title},`;
    return null;
  });

  const shorten = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }

    return text;
  };

  // console.log("freeFlg", freeFlg);

  // const handleNavigate = () => {
  //   if (user.type) {
  //     history.push("/" + user.type + "/" + user.id + "/profile");
  //   }

  //   if (type) {
  //     history.push("/" + type + "/" + id + "/profile");
  //   }
  // };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     freeSessionTaken();
  //   }
  // }, []);

  const handleNavigate = () => {
    if (user.type) {
      history.push(`/search?therapist_profile=${user.id}`);
    }
    if (type) {
      history.push(`/search?therapist_profile=${id}`);
    }
    setShowProfile(true);
  };

  return (
    <div className={classes.searchCard}>
      <div className={classes.searchCard__topDetails}>
        <div className={classes.profileImgDiv}>
          {profile_image || user.profile_image ? (
            <img
              className={classes.ProfileImg}
              src={user.profile_image ? user.profile_image : profile_image}
              alt=""
            />
          ) : (
            <img className={classes.ProfileImg} src={imagePlaceHolder} alt="" />
          )}
        </div>

        <div className={classes.profileName}>
          {user && (
            <h4 className={classes.name}>
              {user?.first_name} {user?.last_name}
            </h4>
          )}

          <h4 className={classes.name}>
            {first_name} {last_name}
          </h4>
          <p className={classes.job}>{shorten(job_title, 25)}</p>
          <p className={classes.city_town}>{city_town}</p>
        </div>
      </div>

      <div className={classes.searchCard__middleDetails}>
        {default_session_fee > 0 && (
          <p className={classes.sessionFee}>
            &#163;{default_session_fee}/session
          </p>
        )}

        {default_session_fee === 0 && <p className={classes.sessionFee}></p>}

        {rating === null && (
          <p className={classes.sessionFee}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.344"
              height="11.8"
              viewBox="0 0 12.344 11.8"
            >
              <path
                id="Path_63"
                data-name="Path 63"
                d="M-603.712,1758.963l1.433,2.9a.611.611,0,0,0,.46.335l3.205.466a.612.612,0,0,1,.339,1.044l-2.319,2.261a.612.612,0,0,0-.176.541l.547,3.192a.612.612,0,0,1-.887.645l-2.867-1.508a.612.612,0,0,0-.57,0l-2.867,1.508a.612.612,0,0,1-.887-.645l.547-3.192a.612.612,0,0,0-.176-.541l-2.319-2.261a.612.612,0,0,1,.339-1.044l3.205-.466a.612.612,0,0,0,.461-.335l1.433-2.9A.612.612,0,0,1-603.712,1758.963Z"
                transform="translate(610.433 -1758.622)"
                fill="#62bfae"
              />
            </svg>
            <span> No Rating Yet </span>
          </p>
        )}

        {rating && rating !== null && (
          <p className={classes.sessionFee}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.344"
              height="11.8"
              viewBox="0 0 12.344 11.8"
            >
              <path
                id="Path_63"
                data-name="Path 63"
                d="M-603.712,1758.963l1.433,2.9a.611.611,0,0,0,.46.335l3.205.466a.612.612,0,0,1,.339,1.044l-2.319,2.261a.612.612,0,0,0-.176.541l.547,3.192a.612.612,0,0,1-.887.645l-2.867-1.508a.612.612,0,0,0-.57,0l-2.867,1.508a.612.612,0,0,1-.887-.645l.547-3.192a.612.612,0,0,0-.176-.541l-2.319-2.261a.612.612,0,0,1,.339-1.044l3.205-.466a.612.612,0,0,0,.461-.335l1.433-2.9A.612.612,0,0,1-603.712,1758.963Z"
                transform="translate(610.433 -1758.622)"
                fill="#62bfae"
              />
            </svg>
            <span> {rating} </span>
          </p>
        )}
      </div>

      <p className={classes.consellingArea}>
        <span className={classes.consellingArea__title}>
          Counselling Areas:&nbsp;
        </span>
        <span className={classes.consellingArea__dataItem}>
          {shorten(counsellingArea, 65)}
        </span>
      </p>

      <div className={`${classes.consellingArea} ${classes.typeSession} `}>
        <p style={{ margin: "0" }} className={classes.consellingArea__title}>
          Types of session offered:&nbsp;
        </p>

        <p className={classes.typeSessionList}>
          {offers_phone_sessions === true && (
            <span className={classes.typeSessionItem}>
              <span>•</span> Phone
            </span>
          )}

          {offers_online_sessions === true && (
            <span className={classes.typeSessionItem}>
              <span>•</span> Video
            </span>
          )}

          {offers_in_clinic_sessions === true && (
            <span className={classes.typeSessionItem}>
              <span>•</span> In Clinic
            </span>
          )}

          {offers_home_visit_sessions === true && (
            <span className={classes.typeSessionItem}>
              <span>•</span> Home Visit
            </span>
          )}
        </p>
      </div>

      <div className={classes.cardBtnDiv}>
        <p onClick={handleNavigate} className={classes.viewProfile}>
          view profile
        </p>

        {!freeFlg ? (
          <div>
            {user.type && (
              <Link
                to={{
                  pathname: `/therapist/${user.id}/book-session`,
                }}
              >
                <p className={classes.cardBookSess}>
                  Book Free Initial Consultation
                </p>
              </Link>
            )}

            {type && (
              <Link
                to={{
                  pathname: `/therapist/${id}/book-session`,
                }}
              >
                <p className={classes.cardBookSess}>
                  Book Free Initial Consultation
                </p>
              </Link>
            )}
          </div>
        ) : (
          <div>
            {user.type && (
              <Link
                to={{
                  pathname: `/therapist/${user.id}/book-session`,
                }}
              >
                <p className={classes.cardBookSess}>
                  {isAuthenticated
                    ? "Book Session"
                    : " Book Free Initial Consultation"}
                </p>
              </Link>
            )}

            {type && (
              <Link
                to={{
                  pathname: `/therapist/${id}/book-session`,
                }}
              >
                <p className={classes.cardBookSess}>
                  {isAuthenticated
                    ? "Book Session"
                    : " Book Free Initial Consultation"}
                </p>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SEarchCard;
