import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    token: null,
    user: null,
    error: null,
    loading: false,
    authStateChecked: false
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        user: action.user,
        error: null,
        loading: false,
        authStateChecked: true
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateUserStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
};

const updateUserSuccess = (state, action) => {
    return updateObject(state, {
        user: action.user,
        error: null,
        loading: false,
    });
};

const updateUserFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const authResetState = (state, action) => {
    return updateObject(state, {
        token: null,
        user: null,
        error: null,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        user: null,
        authStateChecked: true
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_RESET_STATE: return authResetState(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.UPDATE_USER_START: return updateUserStart(state, action);
        case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess(state, action);
        case actionTypes.UPDATE_USER_FAIL: return updateUserFail(state, action);
        default: return state;
    }
};

export default reducer;
