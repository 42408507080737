import React, { useState } from "react";
import MyClientOverview from "./MyClientOverview/MyClientOverview";
import MyClientConversation from "./MyClientConversation/MyClientConversation";

const MyClient = (props) => {
  const [currentTab, setCurrentTab] = useState("overview");

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    setCurrentTab(tab);
  };

  return (
    <div className="row mt-3">
      <div className="col-md-2">
        <div className="nav flex-column nav-pills">
          <a
            className={`nav-link ${currentTab === "overview" && "active"}`}
            href="#"
            onClick={(event) => handleTabClick(event, "overview")}
          >
            Overview
          </a>
          <a
            className={`nav-link ${currentTab === "messages" && "active"}`}
            href="#"
            onClick={(event) => handleTabClick(event, "messages")}
          >
            Conversation
          </a>
          <a
            className={`nav-link ${currentTab === "documents" && "active"}`}
            href="#"
            onClick={(event) => handleTabClick(event, "documents")}
          >
            Documents
          </a>
          <a
            className={`nav-link ${currentTab === "dispute" && "active"}`}
            href="#"
            onClick={(event) => handleTabClick(event, "dispute")}
          >
            Raise a dispute
          </a>
        </div>
      </div>
      <div className="col">
        <div className="row mb-4">
          <div className="col"></div>
        </div>
        <div className="tab-content flex-fill">
          <div className={`tab-pane ${currentTab === "overview" && "active"}`}>
            <MyClientOverview />
          </div>
          <div className={`tab-pane ${currentTab === "messages" && "active"}`}>
            <MyClientConversation />
          </div>
          <div className={`tab-pane ${currentTab === "documents" && "active"}`}>
            <h4>Documents</h4>
          </div>
          <div className={`tab-pane ${currentTab === "dispute" && "active"}`}>
            <h4>Raise a dispute</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyClient;
