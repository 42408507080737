import React, { Fragment, useEffect, useState } from "react";
import ChatsListItem from "./ChatsListItem/ChatsListItem";
import axios from "axios";
import { Link } from "react-router-dom";
import classes from "./ChatList.module.scss";
import { BASE_URL } from "../../../../../shared/constants";
import ComponentLoader from "../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../shared/utility";

const ChatsList = (props) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getChats();
  }, []);

  const getChats = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/chats";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setChats(response.data.results);
        setLoading(false);

        if (!props.activeChatId && window.innerWidth > 600) {
          props.onSelectChat(response.data.results[0].id);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };
  return (
    <Fragment>
      {loading && <ComponentLoader />}
      {!loading && (
        <>
          {chats.length === 0 && (
            <div className={classes.noMessageDiv}>
              <p className={classes.noMessageText}>No Chats available</p>
              <p className={classes.noMessageSubText}>
                To start conversation with therapist, go to their profile.
              </p>

              <Link to="/search" className={classes.viewTherapist}>
                View Therapist
              </Link>
            </div>
          )}

          {chats.length > 0 && (
            <div
              className="list-group"
              style={{ borderTop: "1px solid #f5d4c7" }}
            >
              {chats.map((item) => (
                <div key={item.id}>
                  <ChatsListItem
                    isActive={props.activeChatId === item.id}
                    chat={item}
                    onSelectChat={props.onSelectChat}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};

export default ChatsList;
