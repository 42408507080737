import classes from "./AdminHome.module.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import FooterLink from "../../../../footerLink/FooterLink";
import ComponentLoader from "../../../../UI/ComponentLoader";

const AdminHome = () => {
  const [allCounts, setAllCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getAllCounts = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      setAllCounts(response.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCounts();
  }, []);

  return (
    <div className={classes.adminHome}>
      <div className={`bcg ${classes.adminHome__innerDiv}`}>
        <div className={classes.gridContainer}>
          <div className={classes.gridItem}>
            <div className={classes.gridItem__titleDiv}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35.726"
                height="37"
                viewBox="0 0 35.726 37"
              >
                <g
                  id="Group_10767"
                  data-name="Group 10767"
                  transform="translate(8870 -10778.001)"
                >
                  <path
                    id="Rectangle_1044"
                    data-name="Rectangle 1044"
                    d="M14.462,0h6.8A14.462,14.462,0,0,1,35.726,14.462v0a3.678,3.678,0,0,1-3.678,3.678H3.678A3.678,3.678,0,0,1,0,14.462v0A14.462,14.462,0,0,1,14.462,0Z"
                    transform="translate(-8870 10796.861)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_1123"
                    data-name="Ellipse 1123"
                    cx="8.5"
                    cy="8.5"
                    r="8.5"
                    transform="translate(-8860.137 10778.001)"
                    fill="#fff"
                  />
                </g>
              </svg>

              <h1 className={classes.gridItem__title}> Clients </h1>
            </div>

            <div className={classes.gridItem__content}>
              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Total </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.client_total}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Active </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.client_active}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Inactive </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.client_inactive}
                </p>
              </div>
            </div>
            <Link to="/clients?all" className={classes.gridItem__link}>
              View Details
            </Link>
          </div>

          <div className={classes.gridItem}>
            <div className={classes.gridItem__titleDiv}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.433"
                height="36.38"
                viewBox="0 0 40.433 36.38"
              >
                <g
                  id="Group_10765"
                  data-name="Group 10765"
                  transform="translate(9138 -10833.5)"
                >
                  <path
                    id="Path_14861"
                    data-name="Path 14861"
                    d="M2030.933,2376.664c0,4.478-3.333,10.043-8.066,14.715a13.143,13.143,0,0,0-9.047-3.591h-6.206a13.14,13.14,0,0,0-9.33,3.866c-.071.071-.141.142-.21.215-4.4-4.223-7.573-9.4-7.573-15.206,0-14.659,15.415-16.172,20.216-8.845C2018.3,2359.733,2030.933,2364.535,2030.933,2376.664Z"
                    transform="translate(-11128.5 8469.968)"
                    fill="#fff"
                  />
                  <path
                    id="Path_14872"
                    data-name="Path 14872"
                    d="M6.191,0A6.191,6.191,0,1,1,0,6.191,6.191,6.191,0,0,1,6.191,0Z"
                    transform="translate(-9123.976 10841.709)"
                    fill="#f9ddd2"
                  />
                  <path
                    id="Path_14862"
                    data-name="Path 14862"
                    d="M2045.263,2463.111a36.44,36.44,0,0,1-13.16,8.534,47.936,47.936,0,0,1-11.632-8.044c.068-.073.139-.144.21-.214a13.137,13.137,0,0,1,9.33-3.866h6.206A13.141,13.141,0,0,1,2045.263,2463.111Z"
                    transform="translate(-11150.896 8398.235)"
                    fill="#f9ddd2"
                  />
                </g>
              </svg>

              <h1 className={classes.gridItem__title}> Therapists </h1>
            </div>
            <div className={classes.gridItem__content}>
              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}>Total</p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.therapist_total}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Active </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.therapist_active}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Inactive </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.therapist_inactive}
                </p>
              </div>
            </div>
            <Link to="/therapists?all" className={classes.gridItem__link}>
              View Details
            </Link>
          </div>

          <div className={classes.gridItem}>
            <div className={classes.gridItem__titleDiv}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                viewBox="0 0 31 31"
              >
                <g
                  id="Group_10773"
                  data-name="Group 10773"
                  transform="translate(-94.186 -758.186)"
                >
                  <circle
                    id="Ellipse_18"
                    data-name="Ellipse 18"
                    cx="6"
                    cy="6"
                    r="6"
                    transform="translate(94.186 758.186)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_20"
                    data-name="Ellipse 20"
                    cx="6"
                    cy="6"
                    r="6"
                    transform="translate(94.186 777.186)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_19"
                    data-name="Ellipse 19"
                    cx="6"
                    cy="6"
                    r="6"
                    transform="translate(113.186 758.186)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_21"
                    data-name="Ellipse 21"
                    cx="6"
                    cy="6"
                    r="6"
                    transform="translate(113.186 777.186)"
                    fill="#fff"
                  />
                </g>
              </svg>

              <h1 className={classes.gridItem__title}> Sessions </h1>
            </div>
            <div className={classes.gridItem__content}>
              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Total </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.session_total}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Upcoming </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.session_upcoming}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Completed </p>
                <p className={classes.contentDiv__data}>
                  {allCounts?.session_completed}
                </p>
              </div>
            </div>
            <Link to="/sessions?all" className={classes.gridItem__link}>
              View Details
            </Link>
          </div>

          <div className={classes.gridItem}>
            <div className={classes.gridItem__titleDiv}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.433"
                height="39.214"
                viewBox="0 0 40.433 39.214"
              >
                <g
                  id="Group_10772"
                  data-name="Group 10772"
                  transform="translate(-90 -562.844)"
                >
                  <path
                    id="Path_14863"
                    data-name="Path 14863"
                    d="M2117.933,1378.071V1390.2a3.38,3.38,0,0,0-4.8.984l-6.347,10.152a2.384,2.384,0,0,1-2.946.937l-14.534-6.121a1.874,1.874,0,0,0-2.31.724l-9.494,14.982v-20.52l.624-.79a2.5,2.5,0,0,1,3.072-.686l4.454,2.228,5.125-9.133a2.235,2.235,0,0,1,3.956.113l3.746,7.67a2.3,2.3,0,0,0,3.852.448l11.444-14a2.2,2.2,0,0,1,3.363-.047Z"
                    transform="translate(-1987.5 -813.543)"
                    fill="#f9ddd2"
                  />
                  <path
                    id="Path_14864"
                    data-name="Path 14864"
                    d="M2117.933,1419.139v22.432a2.963,2.963,0,0,1-2.965,2.965h-34.5a2.963,2.963,0,0,1-2.965-2.965v-.774l9.494-14.982a1.875,1.875,0,0,1,2.31-.724l14.534,6.121a2.384,2.384,0,0,0,2.946-.937l6.347-10.152A3.38,3.38,0,0,1,2117.933,1419.139Z"
                    transform="translate(-1987.5 -842.479)"
                    fill="#fff"
                  />
                </g>
              </svg>

              <h1 className={classes.gridItem__title}> Revenue </h1>
            </div>
            <div className={classes.gridItem__content}>
              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Total </p>
                <p className={classes.contentDiv__data}>
                  &#163;{allCounts?.total_revenue}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}>Paid</p>
                <p className={classes.contentDiv__data}>
                  &#163;{allCounts?.paid_to_therapist}
                </p>
              </div>

              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}>Commision </p>
                <p className={classes.contentDiv__data}>
                  &#163;{allCounts?.commission_earned}
                </p>
              </div>
            </div>
            <Link to="/revenue?all" className={classes.gridItem__link}>
              View Details
            </Link>
          </div>

          <div className={classes.gridItem}>
            <div className={classes.gridItem__titleDiv}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                viewBox="0 0 37 37"
              >
                <g
                  id="Group_10774"
                  data-name="Group 10774"
                  transform="translate(1107.345 -1644.783)"
                >
                  <g
                    id="Group_42"
                    data-name="Group 42"
                    transform="translate(-1107.345 1644.783)"
                  >
                    <rect
                      id="Rectangle_26"
                      data-name="Rectangle 26"
                      width="37"
                      height="37"
                      rx="5.432"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Group_44"
                    data-name="Group 44"
                    transform="translate(-1094.241 1652.577)"
                  >
                    <g id="Group_43" data-name="Group 43">
                      <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M-1092.177,1662.234a6.765,6.765,0,0,1-1.81-.246,1.265,1.265,0,0,1-.883-1.557,1.266,1.266,0,0,1,1.557-.883,4.336,4.336,0,0,0,4.855-1.94,4.334,4.334,0,0,0-1.474-5.941,4.335,4.335,0,0,0-5.939,1.474,4.342,4.342,0,0,0-.375.793,1.267,1.267,0,0,1-1.614.772,1.267,1.267,0,0,1-.773-1.615,6.962,6.962,0,0,1,.594-1.257,6.867,6.867,0,0,1,9.415-2.335,6.867,6.867,0,0,1,2.336,9.415A6.886,6.886,0,0,1-1092.177,1662.234Z"
                        transform="translate(1098.706 -1648.517)"
                        fill="#f9ddd2"
                      />
                    </g>
                    <circle
                      id="Ellipse_26"
                      data-name="Ellipse 26"
                      cx="2.169"
                      cy="2.169"
                      r="2.169"
                      transform="translate(2.838 17.076)"
                      fill="#f9ddd2"
                    />
                  </g>
                </g>
              </svg>

              <h1 className={classes.gridItem__title}> Queries </h1>
            </div>
            <div className={classes.gridItem__content}>
              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Total </p>

                <p className={classes.contentDiv__data}>
                  {allCounts?.total_question}
                </p>
              </div>
              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Answered </p>

                <p className={classes.contentDiv__data}>
                  {allCounts?.question_is_answered}
                </p>
              </div>
              <div className={classes.contentDiv}>
                <p className={classes.contentDiv__heading}> Pending </p>

                <p className={classes.contentDiv__data}>
                  {allCounts?.question_is_not_answered}
                </p>
              </div>
            </div>
            <Link to="/questions?all" className={classes.gridItem__link}>
              View Details
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.footerLink}>
        <FooterLink />
      </div>

      {isLoading && <ComponentLoader />}
    </div>
  );
};

export default AdminHome;
