import moment from "moment";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const splitArray = (array, size) => {
  const tmp = [];
  for (let i = 0; i < array.length; i += size) {
    if (array.length > i + size) tmp.push(array.slice(i, i + size));
    else tmp.push(array.slice(i, array.length));
  }
  return tmp;
};

export const timeBetweenDates = (startDate, endDate) => {
  const diffInSeconds = (endDate - startDate) / 1000;
  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds - hours * 3600) / 60);
  const hoursStr =
    hours > 0 ? (hours > 1 ? hours + " hours" : hours + " hour") : "";
  const minutesStr =
    minutes > 0
      ? minutes > 1
        ? minutes + " minutes"
        : minutes + " minute"
      : "";
  return hoursStr + " " + minutesStr;
};

export const parseUtcDateStrToLocal = (utcDateStr) => {
  const utcDate = new Date(utcDateStr);
  return moment(utcDate).local();
};

export const clearAndLogout = () => {
  localStorage.clear();
  window.location.reload();
};

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};
