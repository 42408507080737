import React, { Fragment, useEffect, useState } from "react";
import Message from "./Message/Message";
import ProgressBar from "../../../../UI/ProgressBar/ProgressBar";
import classes from "./Chat.module.scss";
import { connect } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import { clearAndLogout } from "../../../../../shared/utility";
import { useHistory } from "react-router-dom";
import AlertDialog from "../../../../UI/Alert/AlertDialog";

const Chat = (props) => {
  const [messages, setMessages] = useState([]);
  const [nextPage, setNextPage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [userDetail, setUserDetail] = useState("");
  const [showAlert, setShowAlert] = useState("");

  const history = useHistory();

  useEffect(() => {
    getMessages();

    const intervalId = setInterval(getMessages, 5000);
    getUserDetail();

    return () => {
      clearInterval(intervalId);
    };
  }, [props.id]);

  const getUserDetail = async () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/chats";
    await axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const userData = response?.data.results
          ?.filter((e) => e.id === props.id)[0]
          .participants.filter((e) => e.id !== props.user.id)[0];
        setUserDetail(userData);
      })

      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getMessages = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/chat/" + props.id + "/messages";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setMessages(response.data.results);
        setNextPage(response.data.next);
        setLoading(false);
        setSendingMessage(false);
        markAllAsSeen();
      })
      .catch((error) => {
        setLoading(false);
        setSendingMessage(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getNextData = () => {
    const token = localStorage.getItem("token");
    setLoading(() => true);
    axios
      .get(nextPage, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setMessages((prev) => [...prev, ...response.data.results]);
        setNextPage(response.data.next);
        setLoading(false);
        setSendingMessage(false);
        markAllAsSeen();
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const markAllAsSeen = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/chat/" + props.id + "/mark-all-as-seen";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const sendMessage = (event) => {
    event.preventDefault();
    setSendingMessage(true);

    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/chat/write-message";

    const requestPayload = {
      chat: props.id,
      text: newMessage,
    };

    axios
      .post(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setNewMessage("");
        getMessages();
      })
      .catch((error) => {
        setSendingMessage(false);
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }

        if (error.response.data.error.includes("Inactive")) {
          if (
            error.response.data.error.includes(
              props.user.first_name + " " + props.user.last_name
            )
          ) {
            setShowAlert(
              "Your account is inactive. To make your account active, please go to the Profile Settings tab under Settings page"
            );
          } else {
            setShowAlert(
              `${
                props.user.type === "client" ? "Therapist" : "Client"
              } is inactive.`
            );
            setShowAlert("Therapist is inactive.");
          }
          return;
        }

        if (error?.response?.data?.error) {
          setShowAlert(error?.response?.data?.error);
        }
      });
  };

  const handleCancel = () => {
    setShowAlert("");
  };

  return (
    <Fragment>
      <div className={classes.ChatDiv}>
        <div className={classes.ChatProfile}>
          {userDetail && (
            <p className={classes.chatProfileName}>
              <span
                className={classes.goBackBtn}
                onClick={() => history.goBack()}
              >
                <svg
                  className={classes.sendIcon}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.303"
                  height="17.946"
                  viewBox="0 0 18.303 17.946"
                >
                  <g
                    id="Group_7099"
                    data-name="Group 7099"
                    transform="translate(-18699.623 -13146.869)"
                  >
                    <path
                      id="Path_4322"
                      data-name="Path 4322"
                      d="M18708.893,13147.221l-8.564,8.677,8.564,8.564"
                      fill="none"
                      stroke="#000"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_4323"
                      data-name="Path 4323"
                      d="M18717.926,13155.842h-16.957"
                      fill="none"
                      stroke="#000"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </span>
              {userDetail.first_name}&nbsp;{userDetail.last_name}
            </p>
          )}
        </div>
        <div className={`${classes.Chat}`}>
          {nextPage && (
            <div onClick={getNextData} className="loadMoreBtn">
              Load More
            </div>
          )}
          {loading && <ProgressBar />}
          {!loading &&
            messages.map((item) => (
              <Message
                key={item.id}
                message={item}
                ownMessage={props.user.id === item.author.id}
              />
            ))}
        </div>
        <div className={classes.ChatInputDiv}>
          <div className={classes.chatInputAbs}>
            <form onSubmit={(event) => sendMessage(event)}>
              <textarea
                className={classes.chatTextarea}
                id="aboutYourselfInput"
                rows="1"
                placeholder="Write your message..."
                value={newMessage}
                onChange={(event) => setNewMessage(event.target.value)}
              />

              {sendingMessage && <ProgressBar />}
              {!sendingMessage && (
                <button className={classes.chatSendBtn} type="submit">
                  Send
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.529"
                    height="15.131"
                    viewBox="0 0 19.529 15.131"
                  >
                    <path
                      id="Path_229"
                      data-name="Path 229"
                      d="M354.032,292.792l-5-2.016c-.453-.156-.507-.482.211-.639l18.214-4.757c.991-.271.807.124.618.777L363.428,299.8c-.25.777-.412.8-1.05.228l-3.4-3.006-2.488,1.88c-.223.165-.474.15-.472-.125l.045-4.242a.1.1,0,0,1,.041-.075l8.856-6.506c.247-.18.232-.282-.111-.126l-10.48,4.939c-.178.081-.18.092-.336.026"
                      transform="translate(-348.695 -285.293)"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </form>
          </div>
        </div>

        {showAlert && <AlertDialog msg={showAlert} onToggle={handleCancel} />}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Chat);
