import React from "react";
import classes from "./SidebarItem.module.scss";
import { NavLink } from "react-router-dom";

const SidebarItem = ({ className, ...props }) => {
  const activeClass = props.active ? classes.active : "";

  return (
    <div
      className={`${classes.SidebarItem} ${classes.BottomBorder} ${activeClass} ${className}`}
    >
      <NavLink
        className={classes.SidebarLink}
        to={props.link}
        exact={props.exact}
        activeClassName={classes.active}
      >
        <img
          src={props.icon}
          icon={props.icon}
          alt=""
          className={`${classes.SidebarItemIcon} ${classes.active}`}
        />
        <p className={`${classes.SidebarItemTitle} mt-1 mb-0 text-uppercase`}>
          {props.title}
          {props.badgeText && (
            <span className="badge badge-danger">{props.badgeText}</span>
          )}
        </p>
      </NavLink>
    </div>
  );
};

export default SidebarItem;
