import classes from "./BookingTerms.module.scss";

const BookingTerms = ({ onClose, setTermAgree, termHead, termData }) => {
  const handleAcceptTerm = () => {
    setTermAgree(true);
    onClose();
  };

  return (
    <div className={classes.bookingTermsModal}>
      <div className={classes.bookingTermsModal__content}>
        <div className={classes.bookingClose} onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39.031"
            height="39.031"
            viewBox="0 0 39.031 39.031"
          >
            <g
              id="Group_10918"
              data-name="Group 10918"
              transform="translate(-924.999 -209)"
            >
              <path
                id="Path_4294"
                data-name="Path 4294"
                d="M820.831,831.908a19.516,19.516,0,1,0,19.516,19.516A19.516,19.516,0,0,0,820.831,831.908Zm7.671,26.184a.709.709,0,0,1-1,1l-6.668-6.668-6.669,6.669a.709.709,0,0,1-1-1l6.669-6.669-6.669-6.669a.709.709,0,0,1,1-1l6.669,6.669,6.669-6.669a.709.709,0,1,1,1,1l-6.669,6.669Z"
                transform="translate(123.684 -622.908)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>

        <div className={classes.bookingTerms}>
          <h2 className={classes.bookingTerms__head}> {termHead} </h2>
          <div className={classes.bookingTerms__body}>
            {termData.map((term, index) => {
              return (
                <p key={index}>
                  •&nbsp;<span>{term}</span>
                </p>
              );
            })}
          </div>

          <p className={classes.acceptBtn} onClick={handleAcceptTerm}>
            Accept and Book
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingTerms;
