import React from "react";
import TextOnCircleBackground from "../../../UI/TextOnCircleBackground/TextOnCircleBackground";
import logo from "../../../../assets/images/mindsum-logo.svg";
import heroImg from "../../../../assets/images/profileBuilderImg.jpg";
import classes from "../CreateProfile.module.scss";
import FooterLink from "../../../footerLink/FooterLink";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const StepZeroTherapist = (props) => {
  return (
    <div className={classes.createProfile}>
      <div className={`${classes.firstDiv} ${classes.reduceTopPadding}`}>
        <Link to="/" className={classes.logoDiv}>
          <img src={logo} className={classes.mindsumLogo} alt="" />
        </Link>
        <div className={classes.introDiv}>
          <h3 className={classes.name}>Welcome {props.firstName} </h3>
          <p className={classes.intro}>
            Mindsum is the platform where the best therapists connect with
            people seeking therapies. To ensure this quality we review all
            profiles before they are approved. The more details you provide
            about yourself, your skills and experience, the better we will be
            able to connect you with the clients.
          </p>
          <div className={classes.intro}>
            <p>
              Here is an overview of what information we will need from you.
              It's as easy as 1,2,3!
            </p>
            <div className="row mt-md-3 justify-content-center">
              <div className=" col-md-3 text-center mt-2">
                <TextOnCircleBackground text="1" active />
                <p className="mt-2">About you</p>
              </div>
              <div className=" col-md-3 text-center mt-2">
                <TextOnCircleBackground text="2" active />
                <p className="mt-2">Skills & qualification</p>
              </div>
              <div className=" col-md-3 text-center mt-2">
                <TextOnCircleBackground text="3" active />
                <p className="mt-2">Preferences</p>
              </div>
            </div>
          </div>

          <div className={classes.proceedText}>
            <Link to="/about-you" className={classes.proceedBtn}>
              <span className={classes.proceed}>Proceed</span>
            </Link>
          </div>

          {props.isAuthenticated &&
            (!props.user.is_signup_complete ||
              !props.user.is_profile_approved) && (
              <p style={{ fontSize: "20px", margin: "0" }}>
                <Link to="logout">Logout</Link>
              </p>
            )}
        </div>
        <div className={classes.createProfileFooter}>
          <FooterLink />
        </div>
      </div>

      <div className={classes.secondDiv}>
        <img src={heroImg} className={classes.heroImg} alt="" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.auth.user.first_name,
    user: state.auth.user,
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(StepZeroTherapist);
