import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import qs from "qs";
import ComponentLoader from "../../UI/ComponentLoader";
import ProfileImage from "../../UI/ProfileImageAndBasicInfo/ProfileImage/ProfileImage";
import { clearAndLogout } from "../../../shared/utility";
import { BASE_URL } from "../../../shared/constants";
import classes from "./TherapistModal.module.scss";
import AlertDialog from "../Alert/AlertDialog";

const weekDays = [
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
  { id: 7, day: "Sunday" },
];

const TherapistProfileModal = (props) => {
  const { user, isAuthenticated } = useSelector((state) => ({
    user: state.auth.user,
    isAuthenticated: state.auth.token !== null,
  }));

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [chatError, setChatError] = useState("");

  const { search } = useLocation();

  const params = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    isAuthenticated === true ? getProfile() : getPublicProfile();
  }, []);

  const getProfile = () => {
    setIsLoading(true);
    setProfile(null);

    const token = localStorage.getItem("token");
    const url =
      BASE_URL + "/therapist/" + params.therapist_profile + "/profile";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
        if (error?.response?.data?.error === "This profile is private") {
          setIsLoading(false);

          setChatError(
            "This account is private so cannot accept booking requests and messages"
          );
        }
      });
  };

  const getPublicProfile = () => {
    setIsLoading(true);
    setProfile(null);

    const url =
      BASE_URL + "/therapist/public/" + params.therapist_profile + "/profile";

    axios
      .get(url)
      .then((response) => {
        setProfile(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
        if (error?.response?.data?.error === "This profile is private") {
          setIsLoading(false);

          setChatError(
            "This account is private so cannot accept booking requests and messages"
          );
        }
      });
  };

  const getChat = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/messaging/create-chat";

    const requestPayload = {
      participants: [params.therapist_profile],
    };

    axios
      .post(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        history.push("/messages/" + response.data.id);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.data.error === "invalid token") {
          clearAndLogout();
        }

        if (error.response.data.error.includes("Inactive")) {
          setIsLoading(false);
          if (
            error.response.data.error.includes(
              `${user.first_name} ${user.last_name}`
            )
          ) {
            setChatError(
              "Your account is inactive. To make your account active, please go to the Profile Settings tab under Settings page"
            );
          } else {
            setChatError("Therapist is inactive.");
          }
          return;
        }

        if (error.response.data.error) {
          setIsLoading(false);
          setChatError(error.response.data.error);
        }
      });
  };

  const getFormatedTime = (time) => {
    const st = time?.split(":").map((e) => parseInt(e));

    return moment(new Date().setHours(...st));
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className={classes.TherapistModal}>
      {chatError && (
        <AlertDialog
          msg={chatError}
          onToggle={() => {
            chatError ===
            "This account is private so cannot accept booking requests and messages"
              ? handleCancel()
              : setChatError("");
          }}
        />
      )}

      <div className={classes.TherapistModal__content}>
        <div onClick={props.onClose} className={classes.closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.731"
            height="25.731"
            viewBox="0 0 25.731 25.731"
          >
            <g
              id="Group_226"
              data-name="Group 226"
              transform="translate(2559.582 -8520.719) rotate(45)"
            >
              <line
                id="Line_21"
                data-name="Line 21"
                y2="30.389"
                transform="translate(4233.354 7819.761)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <line
                id="Line_22"
                data-name="Line 22"
                x2="30.389"
                transform="translate(4218.16 7834.955)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </g>
          </svg>
        </div>

        <div className={classes.TherapistModal__content__info}>
          <div className={classes.basicInfo}>
            <h3
              className={`${classes.mainTitle} ${classes.therapistMainTitle}`}
            >
              Basic Information
            </h3>

            <div className={classes.basicInfo__content}>
              <div className={classes.firstRow}>
                <div className={classes.firstRow__colOne}>
                  <div className={classes.profileDiv}>
                    <div className={classes.therapistProfile}>
                      {profile?.user?.profile_image ? (
                        <ProfileImage src={profile?.user?.profile_image} />
                      ) : (
                        <ProfileImage />
                      )}
                    </div>
                    <div className={classes.nameDiv}>
                      <p className={classes.nameText}>
                        <span> {profile?.user.first_name} </span>
                        <span> {profile?.user.last_name} </span>
                      </p>
                      <p className={classes.jobTitle}>{profile?.job_title}</p>

                      <p className={classes.jobTitle}>
                        <span style={{ marginRight: "8px" }}>
                          {profile?.currency_code}
                        </span>
                        <span>{profile?.default_session_fee} / session</span>
                      </p>
                    </div>
                  </div>
                  <div className={classes.addressDiv}>
                    <p className={classes.addressText}>{profile?.city_town}</p>

                    {profile?.rating && (
                      <p className={classes.addressText}>
                        {profile?.rating && profile?.rating}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.181"
                          height="13.336"
                          viewBox="0 0 19.181 18.336"
                        >
                          <path
                            id="Path_4708"
                            data-name="Path 4708"
                            d="M-599.99,1759.152l2.227,4.513a.95.95,0,0,0,.715.52l4.98.724a.951.951,0,0,1,.527,1.622l-3.6,3.513a.95.95,0,0,0-.273.841l.85,4.96a.951.951,0,0,1-1.379,1l-4.454-2.343a.95.95,0,0,0-.885,0l-4.454,2.343a.951.951,0,0,1-1.379-1l.851-4.96a.951.951,0,0,0-.273-.841l-3.6-3.513a.951.951,0,0,1,.527-1.622l4.98-.724a.951.951,0,0,0,.716-.52l2.227-4.513A.951.951,0,0,1-599.99,1759.152Z"
                            transform="translate(610.433 -1758.622)"
                            fill="#62BFAE"
                          />
                        </svg>
                      </p>
                    )}
                  </div>
                </div>

                {isAuthenticated === false && (
                  <div className={classes.firstRow__colTwo}>
                    <Link
                      to={
                        "/therapist/" +
                        params.therapist_profile +
                        "/book-session"
                      }
                    >
                      <div className={classes.bookSessionLink}>
                        Book a session
                      </div>
                    </Link>

                    <button
                      onClick={() => history.push("/login")}
                      className={classes.sessionMessage}
                    >
                      Message
                    </button>
                  </div>
                )}

                {user?.type === "client" && (
                  <div className={classes.firstRow__colTwo}>
                    <Link
                      to={
                        "/therapist/" +
                        params.therapist_profile +
                        "/book-session"
                      }
                    >
                      <div className={classes.bookSessionLink}>
                        Book a session
                      </div>
                    </Link>

                    <button
                      className={classes.sessionMessage}
                      onClick={getChat}
                    >
                      Message
                    </button>
                  </div>
                )}
              </div>
              <div className={classes.secondRow}>
                <div className={classes.secondRow__header}>
                  <h4 className={classes.BioText}>Bio</h4>
                  <div className={classes.socialLinks}>
                    {profile?.external_websites.map((item) => {
                      return (
                        <a
                          key={item?.id}
                          target="_blank"
                          href={item?.url}
                          rel="noreferrer"
                          className={classes.socialLinkItem}
                        >
                          {item?.external_website_type.title === "Twitter" && (
                            <div className={classes.socialLinkDiv}>
                              <svg
                                className={classes.socialIcon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.235"
                                height="11.569"
                                viewBox="0 0 14.235 11.569"
                              >
                                <path
                                  id="twitter"
                                  d="M4.559,7.745a8.253,8.253,0,0,0,8.31-8.31q0-.19-.008-.377a5.943,5.943,0,0,0,1.457-1.512,5.827,5.827,0,0,1-1.677.46A2.932,2.932,0,0,0,13.924-3.61,5.858,5.858,0,0,1,12.07-2.9a2.917,2.917,0,0,0-2.132-.923A2.921,2.921,0,0,0,7.017-.9a2.918,2.918,0,0,0,.076.666A8.292,8.292,0,0,1,1.073-3.29a2.914,2.914,0,0,0-.4,1.468A2.918,2.918,0,0,0,1.977.609,2.9,2.9,0,0,1,.654.244c0,.012,0,.024,0,.037A2.921,2.921,0,0,0,3,3.145a2.931,2.931,0,0,1-.77.1,2.9,2.9,0,0,1-.549-.053A2.924,2.924,0,0,0,4.406,5.223,5.859,5.859,0,0,1,.779,6.473a5.944,5.944,0,0,1-.7-.04A8.267,8.267,0,0,0,4.559,7.745"
                                  transform="translate(-0.082 3.824)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          )}
                          {item?.external_website_type.title === "Facebook" && (
                            <div className={classes.socialLinkDiv}>
                              <svg
                                className={classes.socialIcon}
                                id="facebook-app-symbol"
                                xmlns="http://www.w3.org/2000/svg"
                                width="7.896"
                                height="15.207"
                                viewBox="0 0 7.896 15.207"
                              >
                                <path
                                  id="f_1_"
                                  d="M42.416,15.207V8.271h2.327l.349-2.7H42.416V3.841c0-.783.216-1.316,1.34-1.316h1.431V.106A19.4,19.4,0,0,0,43.1,0a3.257,3.257,0,0,0-3.477,3.573V5.567H37.29v2.7h2.334v6.936Z"
                                  transform="translate(-37.29)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          )}
                          {item?.external_website_type.title === "LinkedIn" && (
                            <div className={classes.socialLinkDiv}>
                              <svg
                                className={classes.socialIcon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.335"
                                height="11.782"
                                viewBox="0 0 12.335 11.782"
                              >
                                <g
                                  id="Group_6021"
                                  data-name="Group 6021"
                                  transform="translate(0 0)"
                                >
                                  <path
                                    id="Path_1830"
                                    data-name="Path 1830"
                                    d="M31.362,30.861a1.271,1.271,0,0,1-.4.979,1.513,1.513,0,0,1-1.085.393h-.018a1.446,1.446,0,0,1-1.059-.393,1.313,1.313,0,0,1-.4-.979,1.293,1.293,0,0,1,.413-.983,1.509,1.509,0,0,1,1.075-.389,1.464,1.464,0,0,1,1.067.389,1.333,1.333,0,0,1,.412.983Zm-.171,2.455v7.953H28.545V33.316Zm9.526,3.394v4.558H38.079V37.016a2.322,2.322,0,0,0-.325-1.32,1.142,1.142,0,0,0-1.016-.477,1.3,1.3,0,0,0-.846.277,1.706,1.706,0,0,0-.509.685,1.917,1.917,0,0,0-.089.65v4.439h-2.64q.016-3.2.017-5.192T32.662,33.7l-.008-.385h2.64v1.156h-.017a3.609,3.609,0,0,1,.33-.449,3.454,3.454,0,0,1,.453-.417,2.081,2.081,0,0,1,.7-.349,3.2,3.2,0,0,1,.919-.124,2.85,2.85,0,0,1,2.206.91,3.818,3.818,0,0,1,.835,2.668Z"
                                    transform="translate(-28.394 -29.487)"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            </div>
                          )}
                          {item?.external_website_type.title === "Website" && (
                            <div className={classes.socialLinkDiv}>
                              <svg
                                className={classes.socialIcon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.013"
                                height="12.932"
                                viewBox="0 0 13.013 12.932"
                              >
                                <g
                                  id="Group_6904"
                                  data-name="Group 6904"
                                  transform="translate(0 0)"
                                >
                                  <path
                                    id="Path_4313"
                                    data-name="Path 4313"
                                    d="M979.518,995.629a.848.848,0,0,1-.074.094q-.651.653-1.3,1.3a1.907,1.907,0,0,1-.829.511,1.352,1.352,0,0,1-1.755-1.081,1.584,1.584,0,0,1,.4-1.3c.043-.053.09-.1.139-.151.823-.824,1.651-1.644,2.47-2.472a1.587,1.587,0,0,1,2.268-.118.78.78,0,0,0,1.1-.071.808.808,0,0,0-.028-1.132,2.893,2.893,0,0,0-2.153-.85,3.312,3.312,0,0,0-2.343,1.021q-1.237,1.234-2.471,2.471a3.443,3.443,0,0,0-.952,1.679,2.6,2.6,0,0,0,.12,1.788,2.965,2.965,0,0,0,3.245,1.871,3.4,3.4,0,0,0,1.919-1q1.1-1.1,2.2-2.2a.653.653,0,0,0,.048-.058A2.707,2.707,0,0,1,979.518,995.629Z"
                                    transform="translate(-973.888 -986.296)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_4314"
                                    data-name="Path 4314"
                                    d="M1000.85,975.949a3.091,3.091,0,0,0-5.053-.979c-.824.723-1.556,1.546-2.332,2.32a.117.117,0,0,0-.017.029,2.726,2.726,0,0,1,1.991.316c.021-.025.049-.064.082-.1.431-.432.856-.869,1.3-1.291a2.433,2.433,0,0,1,.552-.408,1.367,1.367,0,0,1,2.038.982,1.561,1.561,0,0,1-.483,1.373q-1.288,1.309-2.6,2.6a1.554,1.554,0,0,1-2.18.073.786.786,0,0,0-1.117.047.807.807,0,0,0,.023,1.141,3.089,3.089,0,0,0,.5.4,3.133,3.133,0,0,0,3.761-.366,31.078,31.078,0,0,0,2.816-2.795C1001.512,977.629,1001.095,976.537,1000.85,975.949Z"
                                    transform="translate(-988.093 -974.05)"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            </div>
                          )}
                        </a>
                      );
                    })}
                  </div>
                </div>

                <div className={classes.secondRow__body}>
                  <p className={classes.bio}>{profile?.more_about_yourself}</p>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.skills}>
            <h3 className={`${classes.mainTitle} ${classes.changeBg}`}>
              Skills & Qualifications
            </h3>

            <div className={classes.skills__content}>
              <div className={classes.skillsDiv}>
                <h4 className={classes.skillsTitle}> Skills </h4>
                <div className={classes.skillsDataDiv}>
                  {profile?.skills.map((item) => {
                    return (
                      <div key={item.id} className={classes.skillsData}>
                        <span>•</span> <span>{item?.title}</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                className={`${classes.skillsDiv} ${classes.qualificationsDiv}`}
              >
                <h4 className={classes.skillsTitle}>
                  Qualifications, Training & Experience
                </h4>

                <p className={classes.qualificationsData}>
                  {profile?.qualifications_training_experience}
                </p>
              </div>

              <div
                className={`${classes.skillsDiv} ${classes.qualificationsDiv}`}
              >
                <h4 className={classes.skillsTitle}>Memberships</h4>

                <div className={classes.membershipData}>
                  {profile?.professional_bodies.map((item) => {
                    return (
                      <p key={item.id}>
                        <span>•</span> <span>{item?.title}</span>
                      </p>
                    );
                  })}
                </div>
              </div>

              <div
                className={`${classes.skillsDiv} ${classes.qualificationsDiv}`}
              >
                <h4 className={classes.skillsTitle}>COUNSELLING AREAS</h4>

                <div
                  className={`${classes.membershipData} ${classes.gridColumn}`}
                >
                  {profile?.counselling_areas.map((item) => {
                    return (
                      <p key={item.id}>
                        <span>•</span> <span>{item?.title}</span>
                      </p>
                    );
                  })}
                </div>
              </div>

              <div className={`${classes.skillsDiv} ${classes.therapyOffered}`}>
                <h4 className={classes.skillsTitle}> THERAPIES OFFERED </h4>
                <div className={classes.skillsDataDiv}>
                  {profile?.therapy_types.map((item) => {
                    return (
                      <div key={item.id} className={classes.skillsData}>
                        <span>•</span> <span>{item?.title}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={classes.preferences}>
            <h3 className={`${classes.mainTitle} ${classes.changeBgBlue}`}>
              Preferences
            </h3>

            <div className={classes.preferences__content}>
              <div className={classes.sessionTypes}>
                <h4 className={classes.sessionTypesTitle}>LANGUAGES KNOWN</h4>
                <div className={`${classes.sessionDataDiv}`}>
                  {profile?.language.map((item) => {
                    return (
                      <p key={item.id} className={classes.sessionData}>
                        <span>•</span>&nbsp;
                        <span>{item.title}</span>
                      </p>
                    );
                  })}
                </div>
              </div>

              <div
                className={`${classes.sessionTypes} ${classes.clientServed}`}
              >
                <h4 className={classes.sessionTypesTitle}>
                  TYPES OF SESSIONS OFFERED
                </h4>
                <div
                  className={`${classes.sessionDataDiv} ${classes.availDataDiv}`}
                >
                  {profile?.offers_in_clinic_sessions && (
                    <p className={classes.sessionData}>
                      <span>•</span>
                      <span> Clinic </span>
                      {profile?.clinic_fee && profile?.clinic_fee > 0 && (
                        <span className={classes.individualSessFee}>
                          {profile?.clinic_fee}&nbsp;{profile?.currency_code}
                        </span>
                      )}
                    </p>
                  )}

                  {profile?.offers_home_visit_sessions && (
                    <p className={classes.sessionData}>
                      <span>•</span>
                      <span> Home </span>
                      {profile?.home_fee && profile?.home_fee > 0 && (
                        <span className={classes.individualSessFee}>
                          {profile?.home_fee}&nbsp;{profile?.currency_code}
                        </span>
                      )}
                    </p>
                  )}

                  {profile?.offers_online_sessions && (
                    <p className={classes.sessionData}>
                      <span>•</span>
                      <span> Video </span>
                      {profile?.video_fee && profile?.video_fee > 0 && (
                        <span className={classes.individualSessFee}>
                          {profile?.video_fee}&nbsp;{profile?.currency_code}
                        </span>
                      )}
                    </p>
                  )}

                  {profile?.offers_phone_sessions && (
                    <p className={classes.sessionData}>
                      <span>•</span>
                      <span> Phone </span>
                      {profile?.audio_fee && profile?.audio_fee > 0 && (
                        <span className={classes.individualSessFee}>
                          {profile?.audio_fee}&nbsp;{profile?.currency_code}
                        </span>
                      )}
                    </p>
                  )}
                </div>
              </div>

              <div
                className={`${classes.sessionTypes} ${classes.clientServed}`}
              >
                <h4 className={classes.sessionTypesTitle}>
                  TYPES OF CLIENTS SERVED
                </h4>
                <div className={classes.sessionDataDiv}>
                  {profile?.serves_children && (
                    <p className={classes.sessionData}>
                      <span>•</span> <span> Children </span>
                    </p>
                  )}

                  {profile?.serves_young_people && (
                    <p className={classes.sessionData}>
                      <span>•</span> <span> Young People </span>
                    </p>
                  )}

                  {profile?.serves_adults && (
                    <p className={classes.sessionData}>
                      <span>•</span> <span> Adults </span>
                    </p>
                  )}

                  {profile?.serves_couples && (
                    <p className={classes.sessionData}>
                      <span>•</span> <span> Couples </span>
                    </p>
                  )}
                </div>
              </div>

              <div
                className={`${classes.sessionTypes} ${classes.availability}`}
              >
                <h4 className={classes.sessionTypesTitle}>AVAILABILITY</h4>
                <div
                  className={`${classes.sessionDataDiv} ${classes.availDataDiv}`}
                >
                  {weekDays?.map((weday) => {
                    return (
                      <div
                        key={weday.id}
                        className={`${classes.therapyTypeWrap} ${classes.rowFlex} mb-1`}
                      >
                        <label className={`${classes.weekDaysLabel}`}>
                          <span>•</span>
                          <span> {weday.day} </span>
                        </label>
                        <div className={classes.selectedTimeRange}>
                          {profile?.availability_day
                            .filter((e) => e.title === weday.day)?.[0]
                            ?.["slots"]?.map((s) => {
                              return (
                                <div
                                  key={s.id}
                                  className={classes.selectedTimesDiv}
                                >
                                  <p className={classes.selectedTimes}>
                                    <Moment local format="hh:mm a">
                                      {getFormatedTime(s?.start_time)}
                                    </Moment>
                                    <Moment local format="hh:mm a">
                                      {getFormatedTime(s?.end_time)}
                                    </Moment>
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {isAuthenticated === false && (
                <div className={classes.footerBtn}>
                  <Link
                    to={
                      "/therapist/" + params.therapist_profile + "/book-session"
                    }
                  >
                    <div className={classes.bookSessionLink}>
                      Book a session
                    </div>
                  </Link>

                  <button
                    onClick={() => history.push("/login")}
                    className={classes.sessionMessage}
                  >
                    Message
                  </button>
                </div>
              )}

              {user?.type === "client" && (
                <div className={classes.footerBtn}>
                  <Link
                    to={
                      "/therapist/" + params.therapist_profile + "/book-session"
                    }
                  >
                    <div className={classes.bookSessionLink}>
                      Book a session
                    </div>
                  </Link>

                  <button className={classes.sessionMessage} onClick={getChat}>
                    Message
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoading && <ComponentLoader />}
    </div>
  );
};

export default TherapistProfileModal;
