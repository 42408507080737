import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import classes from "./MySesTable.module.scss";
import { BASE_URL } from "../../../../../shared/constants";
import MySessionsTableRow from "./MySessionsTableRow/MySessionsTableRow";
import TherapistTable from "../MySessionsTable/MySessionsTableRow/TherapistTable";
import { useSelector } from "react-redux";
import ComponentLoader from "../../../../UI/ComponentLoader";
import { clearAndLogout } from "../../../../../shared/utility";

const MySessionsTable = ({ loading, pastSessions, next, getNextData }) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className={classes.sessionTable}>
      <div className={classes.sessionTable__head}>
        {user?.type !== "therapist" && (
          <div className={classes.sessionTable__head__rowOne}>
            <span>Therapist</span>
            <span className={classes.sessionStatus}>Session Status</span>
            <span>Date & time</span>
            <span>Session Type</span>
            <span className={classes.sessionFee}>Fee paid</span>
          </div>
        )}

        {user?.type === "therapist" && (
          <div className={classes.sessionTable__head__rowOne}>
            <span>Client</span>
            <span className={classes.sessionStatus}>Session Status</span>
            <span>Date & time</span>
            <span>Session Type</span>
            <span className={classes.sessionFee}>Fee paid</span>
          </div>
        )}
      </div>

      <div className={classes.sessionTable__body}>
        {loading && <ComponentLoader />}
        {!loading && (
          <div className={classes.sessionTable__body__row}>
            {pastSessions.length === 0 && (
              <div className={classes.noSessionDiv}>
                <p className={classes.noSessionText}>No Past Session</p>

                {user?.type !== "therapist" && (
                  <Link to="/search" className={classes.bookSessionBtn}>
                    Book Session
                  </Link>
                )}
              </div>
            )}

            {pastSessions.length > 0 && (
              <>
                {pastSessions.map((e) => {
                  return (
                    <div key={e.id} style={{ width: "100%" }}>
                      {user?.type !== "therapist" ? (
                        <MySessionsTableRow data={e} />
                      ) : (
                        <TherapistTable data={e} />
                      )}
                    </div>
                  );
                })}
              </>
            )}
            {next && (
              <div onClick={getNextData} className="loadMoreBtn">
                Load More
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MySessionsTable;
