import React, { useRef, useState } from "react";
import classes from "../UpcomingSes.module.scss";
import Slider from "react-slick";
import UpcomingSessionsListItem from "./UpcomingSessionsListItem/UpcomingSessionsListItem";
import AlertMessage from "../../../../../UI/Alert/AlertMessage";
import { BASE_URL } from "../../../../../../shared/constants";
import axios from "axios";
import { clearAndLogout } from "../../../../../../shared/utility";

const UpcomingSessionsList = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [id, setId] = useState("");

  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const cancelSession = (event) => {
    event.stopPropagation();

    const requestPayload = {
      status: "cancelled",
    };

    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions/" + id;

    axios
      .patch(url, requestPayload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setShowAlert(false);
        props.onCancel();
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
        alert(error.response.data.error);
      });
  };

  const handleNo = () => {
    setShowAlert(false);
  };

  const handleShowAleart = (id) => {
    setShowAlert(() => true);
    setId(() => id);
  };

  return (
    <div className={classes.UpcomingSessionsList}>
      <div
        className={classes.leftArrrow}
        onClick={() => sliderRef.current.slickPrev()}
      >
        <svg
          className={classes.leftA}
          xmlns="http://www.w3.org/2000/svg"
          width="19.824"
          height="37.042"
          viewBox="0 0 19.824 37.042"
        >
          <path
            id="Path_4394"
            data-name="Path 4394"
            d="M18718.031,13147.223l-17.7,17.934,17.7,17.7"
            transform="translate(-18698.918 -13146.521)"
            fill="none"
            stroke="#62bfae"
            strokeWidth="2"
          />
        </svg>
      </div>

      <div className={classes.sliderDiv}>
        <Slider ref={sliderRef} {...settings}>
          {props.sessions?.map((item) => {
            return (
              <div key={item.id} style={{ outline: "none" }}>
                <UpcomingSessionsListItem
                  session={item}
                  user={
                    props.user.type === "therapist"
                      ? item.client
                      : item.therapist
                  }
                  handleShowAleart={handleShowAleart}
                  onCancel={props.onCancel}
                  onCancelSession={cancelSession}
                />
              </div>
            );
          })}
        </Slider>
      </div>

      <div
        className={classes.rightArrrow}
        onClick={() => sliderRef.current.slickNext()}
      >
        <svg
          className={classes.rightA}
          xmlns="http://www.w3.org/2000/svg"
          width="19.824"
          height="37.042"
          viewBox="0 0 19.824 37.042"
        >
          <path
            id="Path_4395"
            data-name="Path 4395"
            d="M18700.328,13147.223l17.7,17.934-17.7,17.7"
            transform="translate(-18699.617 -13146.521)"
            fill="none"
            stroke="#62bfae"
            strokeWidth="2"
          />
        </svg>
      </div>

      {showAlert && (
        <AlertMessage
          msg="Are you sure you wish to cancel this session?"
          handleYes={cancelSession}
          handleNo={handleNo}
        />
      )}
    </div>
  );
};

export default UpcomingSessionsList;
