import React, { useState, useEffect } from "react";
import StepOneForm from "./StepOneForm/StepOneForm";
import classes from "./Step1.module.scss";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/mindsum-logo-new.svg";
import FooterLink from "../../../footerLink/FooterLink";
import { Redirect } from "react-router-dom";
import * as actions from "../../../../store/actions";
import { connect, useDispatch } from "react-redux";
import ComponentLoader from "../../../UI/ComponentLoader";
import ProfileImage from "../../../UI/ProfileImageAndBasicInfo/ProfileImage/ProfileImage";
import axios from "axios";
import { BASE_URL } from "../../../../shared/constants";

const StepOne = (props) => {
  const [loading, setLoading] = useState(false);

  const { onGetExternalWebsiteTypes } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    onGetExternalWebsiteTypes();
  }, [onGetExternalWebsiteTypes]);

  if (props.navigate) {
    props.onResetNavigate();
    if (props.user.type.toString() === "therapist")
      return <Redirect to="/skills-qualifications" />;
    else return <Redirect to="/your-needs" />;
  }

  const handleProfileImageChange = async (event) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const url = BASE_URL + `/user/` + props.user.id;

    const formData = new FormData();
    formData.append(
      "profile_image",
      event.target.files[0],
      `${props.user.id}_${event.target.files[0].name}`
    );
    formData.append("first_name", props.user.first_name);
    formData.append("last_name", props.user.last_name);

    await axios
      .put(url, formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .catch((err) => {});

    dispatch(actions.getUserProfile());
  };

  let content = null;
  if (props.profile && props.externalWebsiteTypes) {
    content = (
      <>
        <div className={classes.stepOneDiv}>
          <div className={classes.mainCard}>
            <div className={classes.step1Nav}>
              {props.user.type.toString() === "client" ? (
                <div className={classes.firstPart}>
                  <span className={`${classes.spanText} ${classes.active}`}>
                    ABOUT &#62;
                  </span>

                  <span className={classes.spanText}>NEEDS &#62; </span>

                  <span className={classes.spanText}>PREVIEW </span>
                </div>
              ) : (
                <div className={classes.firstPart}>
                  <span className={`${classes.spanText} ${classes.active}`}>
                    ABOUT &#62;
                  </span>

                  <span className={classes.spanText}> SKILLS &#62; </span>
                  <span className={classes.spanText}> PREFERENCES &#62; </span>

                  <span className={classes.spanText}>PREVIEW </span>
                </div>
              )}

              <div className={classes.secondPart}>
                <Link className={classes.navLogo} to="/">
                  <img src={logo} alt="logo" className={classes.ImgFluid} />
                </Link>
              </div>
              <div className={classes.thirdPart}>
                {props.isAuthenticated &&
                  (!props.user.is_signup_complete ||
                    !props.user.is_profile_approved) && (
                    <ul className="navbar-nav">
                      <Link to="logout">Logout</Link>
                    </ul>
                  )}
              </div>
            </div>
            <div className={classes.formDiv}>
              <div className="row" style={{ marginBottom: "40px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="col"
                >
                  {loading ? (
                    <div className={`${classes.loader} ${classes.btnDiv}`}>
                      <span className={classes.loader__spin}></span>
                    </div>
                  ) : (
                    <label
                      htmlFor="profileimg"
                      className={classes.clientAvatar}
                    >
                      <ProfileImage src={props?.profile?.user?.profile_image} />
                      <input
                        style={{ display: "none" }}
                        id="profileimg"
                        name="profileimg"
                        type="file"
                        onChange={handleProfileImageChange}
                      />

                      <div className={classes.editHover}> Edit </div>
                    </label>
                  )}

                  <div className={classes.clientName}>
                    {props?.user?.first_name}&nbsp;{props?.user?.last_name}
                  </div>

                  <div className={classes.clientEmail}>
                    {props?.user?.email}
                  </div>

                  <div>{props?.profile?.job_title}</div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col">
                  <StepOneForm
                    type={props.user.type}
                    profile={props.profile}
                    externalWebsiteTypes={props.externalWebsiteTypes}
                    onUpdateUserProfile={props.onUpdateUserProfile}
                  />
                </div>
              </div>
              <div className={classes.aboutYouFooter}>
                <FooterLink />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.coverBg}></div>
      </>
    );
  } else {
    content = <ComponentLoader />;
  }

  return content;
};

const mapStateToProps = (state) => {
  return {
    externalWebsiteTypes: state.aboutYou.externalWebsiteTypes,
    user: state.auth.user,
    profile: state.userProfile.profile,
    navigate: state.userProfile.navigate,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetExternalWebsiteTypes: () =>
      dispatch(actions.getExternalWebsiteTypes()),
    onUpdateUserProfile: (userProfile) =>
      dispatch(actions.updateUserProfile(userProfile)),
    onResetNavigate: () => dispatch(actions.resetNavigate()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
