import React, { useState, useEffect } from "react";
import classes from "../Search.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import enGB from "date-fns/locale/en-GB";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import ComponentLoader from "../../../../UI/ComponentLoader";

const SearchFilter = (props) => {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm({ reValidateMode: "onChange" });

  const profile = useSelector((state) => state.userProfile.profile);
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const city =
    props?.facets["city_town"] && Object.entries(props?.facets["city_town"]);
  const cityFilter = city?.map(([key, value]) => {
    if (value) {
      return {
        value: key,
        label: key,
      };
    }

    return null;
  });

  const counsellingArea =
    props?.facets["counselling_areas.title"] &&
    Object.entries(props?.facets["counselling_areas.title"]);
  const counsellingFilter = counsellingArea?.map(([key, value]) => {
    if (value) {
      return {
        value: key,
        label: key,
      };
    }

    return null;
  });

  const therapyType =
    props?.facets["therapy_types.title"] &&
    Object.entries(props?.facets["therapy_types.title"]);
  const therapyTypeFilter = therapyType?.map(([key, value]) => {
    if (value) {
      return {
        value: key,
        label: key,
      };
    }

    return null;
  });

  const language =
    props?.facets["language.title"] &&
    Object.entries(props?.facets["language.title"]);
  const languageFilter = language?.map(([key, value]) => {
    if (value) {
      return {
        value: key,
        label: key,
      };
    }

    return null;
  });

  const sessionType =
    props?.facets["type_of_session"] &&
    Object.entries(props?.facets["type_of_session"]);
  const sessionFilter = sessionType?.map(([key, value]) => {
    if (value) {
      return {
        value: key,
        label: key,
      };
    }

    return null;
  });

  const clientType =
    props?.facets["type_of_client"] &&
    Object.entries(props?.facets["type_of_client"]);
  const clientFilter = clientType?.map(([key, value]) => {
    if (value) {
      return {
        value: key,
        label: key,
      };
    }

    return null;
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      marginTop: "10px",
      width: "100%",
      borderRadius: "2px",
      fontFamily: "L-Regular",
      fontSize: "20px",
      color: "#B4C0D3",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "20px",
      color: "#B4C0D3",
    }),
    menu: (styles) => {
      return {
        ...styles,

        width: "100%",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#62BFAE",
        color: "white",
        borderRadius: "15px",
        padding: "3px 4px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
      fontFamily: "L-SBold",
      fontSize: "10px",
      marginRight: "10px",
      textTransform: "uppercase",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#62BFAE",
      backgroundColor: "#D0ECE6",
      borderRadius: "50%",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#fff",
      },
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#62BFAE",
      fontSize: "30px",
    }),
  };

  const onSubmit = async (formData) => {
    const city_town =
      formData?.city_town &&
      formData?.city_town.map((e) => {
        return e.value;
      });

    const counselling =
      formData?.counselling_areas_looking_for &&
      formData?.counselling_areas_looking_for.map((e) => {
        return e.value;
      });

    const therapyType =
      formData?.therapy_types_looking_for &&
      formData?.therapy_types_looking_for.map((e) => {
        return e.value;
      });

    const language =
      formData?.language &&
      formData?.language.map((e) => {
        return e.value;
      });

    const typeOfSession =
      formData?.type_of_session &&
      formData?.type_of_session.map((e) => {
        return e.value;
      });

    const typeOfClient =
      formData?.type_of_client &&
      formData?.type_of_client.map((e) => {
        return e.value;
      });

    const updatedParams = [
      props.term,
      city_town && city_town.toString(),
      counselling && counselling.toString(),
      therapyType && therapyType.toString(),
      language && language.toString(),
      typeOfSession && typeOfSession.toString(),
      typeOfClient && typeOfClient.toString(),
    ];

    props.setTerm(updatedParams.join(","));
    // props.setPage(() => props.page);

    // history.replace({
    //   pathname: "/search",
    //   // search: updatedParams.join(","),
    // });

    props.onSearch(updatedParams.join(","));
    props.setTerm(location.search.replace("?", ""));
    props.setShowFilter(false);
  };

  const handleResetFilter = () => {
    reset({
      city_town: [],
      counselling_areas_looking_for: [],
      therapy_types_looking_for: [],
      language: [],
      type_of_session: [],
      type_of_client: [],
    });

    props.setTerm(location.search.replace("?", ""));
    // history.replace({
    //   pathname: "/search",
    //   search: "",
    // });
    props.setPage(0);
    props.onSearch(location.search.replace("?", ""));

    props.setShowFilter(false);
  };

  return (
    <div className={classes.SearchFilter}>
      {isLoading && <ComponentLoader />}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.SearchFilter__content}
      >
        <div className={classes.dropdownFilter}>
          <div className={classes.counsellingArea}>
            <p className={classes.listingTitle}>Find Therapist Near Me</p>

            <small className={classes.smallText}>
              (You can select multiple options)
            </small>

            <Controller
              name="city_town"
              rules={{ required: false }}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    styles={colourStyles}
                    options={cityFilter}
                    // onChange={handleCity}
                    placeholder="Manchester"
                    isMulti
                  />
                );
              }}
            />

            {errors?.cityFilter && errors.cityFilter.type === "required" && (
              <p style={{ paddingLeft: "3%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>

          <div className={classes.counsellingArea}>
            <p className={classes.listingTitle}>Counselling Area</p>

            <small className={classes.smallText}>
              (You can select multiple options)
            </small>

            <Controller
              name="counselling_areas_looking_for"
              rules={{ required: false }}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    styles={colourStyles}
                    options={counsellingFilter}
                    placeholder="Autism"
                    isMulti
                  />
                );
              }}
            />

            {errors?.counselling_areas_looking_for &&
              errors.counselling_areas_looking_for.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className={classes.counsellingArea}>
            <p className={classes.listingTitle}>Type Of Therapy</p>
            <small className={classes.smallText}>
              (You can select multiple options)
            </small>

            <Controller
              name="therapy_types_looking_for"
              rules={{ required: false }}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    styles={colourStyles}
                    options={therapyTypeFilter}
                    placeholder="Speech & Language"
                    isMulti
                  />
                );
              }}
            />
            {errors?.therapy_types_looking_for &&
              errors.therapy_types_looking_for.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className={classes.counsellingArea}>
            <p className={classes.listingTitle}> Languages </p>

            <small className={classes.smallText}>
              (You can select multiple options)
            </small>

            <Controller
              name="language"
              rules={{ required: false }}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    styles={colourStyles}
                    options={languageFilter}
                    placeholder="English"
                    isMulti
                  />
                );
              }}
            />
            {errors?.language && errors.language.type === "required" && (
              <p style={{ paddingLeft: "3%" }} className="errorTxt">
                Please select at least one option
              </p>
            )}
          </div>

          <div className={classes.counsellingArea}>
            <p className={classes.listingTitle}>Type Of Sessions</p>

            <small className={classes.smallText}>
              (You can select multiple options)
            </small>

            <Controller
              name="type_of_session"
              rules={{ required: false }}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    styles={colourStyles}
                    options={sessionFilter}
                    placeholder="offers_phone_sessions"
                    isMulti
                  />
                );
              }}
            />
            {errors?.type_of_session &&
              errors.type_of_session.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>

          <div className={classes.counsellingArea}>
            <p className={classes.listingTitle}>Type Of Clients</p>

            <small className={classes.smallText}>
              (You can select multiple options)
            </small>

            <Controller
              name="type_of_client"
              rules={{ required: false }}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    styles={colourStyles}
                    options={clientFilter}
                    placeholder="serves_children"
                    isMulti
                  />
                );
              }}
            />
            {errors?.type_of_client &&
              errors.type_of_client.type === "required" && (
                <p style={{ paddingLeft: "3%" }} className="errorTxt">
                  Please select at least one option
                </p>
              )}
          </div>
        </div>

        <div className={classes.btnDiv}>
          <div
            onClick={handleResetFilter}
            className={`${classes.clear} ${classes.submit}`}
          >
            Clear
          </div>
          <button type="submit" className={`${classes.submit}`}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default React.memo(SearchFilter);
