import React, { useEffect, useState, useRef } from "react";
import classes from "./stopStigma.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { copyToClipboard } from "../../../shared/utility";
import { BASE_URL } from "../../../shared/constants";
import axios from "axios";
import { Anchorme } from "react-anchorme";
import ComponentLoader from "../../UI/ComponentLoader";

const CardDetails = (props) => {
  const answerRef = useRef(null);

  const history = useHistory();
  const { search } = useLocation();

  const color = ["#ffa88a", "#ff5f5f", "#62bfae", "#Ed6b64", "#618df0"];
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const params = qs.parse(search, { ignoreQueryPrefix: true });

  const getCardData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/answer/view/${params.question}`
      );

      setCardData(response.data.results[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCardData();
  }, []);

  const handleBack = () => {
    props.onClose();
    history.push("/beat-the-stigma");
  };

  const shorten = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className={classes.cardDetails}>
      <div className={`${classes.cardDetails__innerDiv} `}>
        <div onClick={handleBack} className={classes.closeIcon}>
          <svg
            className={classes.closeIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="25.731"
            height="25.731"
            viewBox="0 0 25.731 25.731"
          >
            <g
              id="Group_226"
              data-name="Group 226"
              transform="translate(2559.582 -8520.719) rotate(45)"
            >
              <line
                id="Line_21"
                data-name="Line 21"
                y2="30.389"
                transform="translate(4233.354 7819.761)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <line
                id="Line_22"
                data-name="Line 22"
                x2="30.389"
                transform="translate(4218.16 7834.955)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </g>
          </svg>
        </div>

        <div className={classes.topBorder}>
          <span
            className={classes.topBorder__left}
            style={{
              background: color[Math.ceil(Math.random() * color.length) - 1],
            }}
          ></span>
          <span
            className={classes.topBorder__right}
            style={{
              background: color[Math.ceil(Math.random() * color.length) - 1],
            }}
          ></span>
        </div>

        <h3 className={classes.question}>{cardData?.question?.question}</h3>
        <div className={classes.user}>
          <div className={classes.user__left}>
            <p className={classes.name}> {cardData?.question?.name} </p>
            <p className={classes.name}> {cardData?.question?.age} </p>
            <p className={classes.name}> {cardData?.question?.city} </p>
          </div>

          <div className={classes.user__right}>
            <a
              className={classes.socialLinks}
              data-pgaction-redirection="0"
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/beat-the-stigma?question=${cardData?.question?.id}`}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.36"
                height="14.176"
                viewBox="0 0 7.36 14.176"
              >
                <g
                  id="Group_311"
                  data-name="Group 311"
                  transform="translate(7.36) rotate(90)"
                >
                  <path
                    id="f"
                    d="M14.175,2.583H7.709V.413L5.188.088v2.5H3.58c-.731,0-1.227-.2-1.227-1.249V0H.1A18.419,18.419,0,0,0,0,1.944,3.037,3.037,0,0,0,3.331,5.185H5.19V7.361H7.711V5.185h6.465Z"
                    fill="#62bfae"
                  />
                </g>
              </svg>
            </a>

            <a
              className={classes.socialLinks}
              title="Linkedin"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${
                window.location.origin
              }/beat-the-stigma?question=${
                cardData?.question?.id
              }&title=${shorten(
                cardData?.question?.question,
                50
              )}&summary=${shorten(cardData?.answer, 60)}&source=`}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.179"
                height="12.949"
                viewBox="0 0 14.179 12.949"
              >
                <g
                  id="Group_1"
                  data-name="Group 1"
                  transform="translate(14.179) rotate(90)"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M8.979,2.3V.46A.46.46,0,0,0,8.519,0H.461A.461.461,0,0,0,0,.46V2.3a.46.46,0,0,0,.461.46H8.519A.461.461,0,0,0,8.98,2.3Z"
                    transform="translate(3.969 11.416)"
                    fill="#62bfae"
                  />
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M2.556,2.3V.46A.459.459,0,0,0,2.1,0H.46A.459.459,0,0,0,0,.46V2.3a.46.46,0,0,0,.46.46H2.1a.461.461,0,0,0,.461-.46Z"
                    transform="translate(0 11.416)"
                    fill="#62bfae"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M1.17.694A2.815,2.815,0,0,0,.329,1.84,4.156,4.156,0,0,0,0,3.511,4.1,4.1,0,0,0,1.418,6.658c.189.171.15.3-.108.3H.7a.461.461,0,0,0-.462.46V9.063a.461.461,0,0,0,.462.46H8.751a.46.46,0,0,0,.46-.46V7.22a.46.46,0,0,0-.46-.46H5.95a9.018,9.018,0,0,1-2.4-.211,1.979,1.979,0,0,1-1.045-.782,2.215,2.215,0,0,1-.393-1.289,1.641,1.641,0,0,1,.275-.959,1.54,1.54,0,0,1,.772-.575,8.242,8.242,0,0,1,2.188-.177h3.4a.46.46,0,0,0,.46-.46V.464A.46.46,0,0,0,8.75,0H4.187A8.663,8.663,0,0,0,2.33.152,3.048,3.048,0,0,0,1.17.694Z"
                    transform="translate(3.737 0)"
                    fill="#62bfae"
                  />
                </g>
              </svg>
            </a>

            <a
              className={classes.socialLinks}
              text={cardData?.question?.question}
              title="Twitter"
              href={`https://twitter.com/intent/tweet?url=${
                window.location.origin
              }/beat-the-stigma?question=${
                cardData?.question?.id
              }&text=${shorten(cardData?.question?.question, 50)}`}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.345"
                height="11.996"
                viewBox="0 0 16.345 11.996"
              >
                <path
                  id="Path_1827"
                  data-name="Path 1827"
                  d="M47.784,53.849a8.417,8.417,0,0,1-.757,3.918c-.069.152-.141.3-.215.442a8.946,8.946,0,0,1-5.469,4.412q-.27.075-.532.131c-3.678.784-7.87-1.823-7.87-1.823a10.862,10.862,0,0,0,3.662,0c.8-.168,1.571-.451,2.366-.644-.456.112-.95-.284-1.3-.53a6.435,6.435,0,0,1-1.049-.929,6.672,6.672,0,0,1-1.446-6.493c.063-.2.2-.632.293-.9a8.833,8.833,0,0,0,6.246,3.132A3.1,3.1,0,0,1,43.871,51a3.026,3.026,0,0,1,3.038.851,4.877,4.877,0,0,0,1.926-.738c-.09.756-1.224,1.674-1.224,1.674a6.487,6.487,0,0,0,1.674-.468A5.666,5.666,0,0,1,47.784,53.849Z"
                  transform="translate(-32.94 -50.904)"
                  fill="#62bfae"
                />
              </svg>
            </a>

            <div
              className={classes.socialLinks}
              style={{ cursor: "pointer" }}
              onClick={() =>
                copyToClipboard(
                  `${window.location.origin}/beat-the-stigma?question=${cardData?.question?.id}`
                )
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.44"
                height="13.043"
                viewBox="0 0 13.44 13.043"
              >
                <g
                  id="Group_10640"
                  data-name="Group 10640"
                  transform="translate(0.961 0.75)"
                >
                  <path
                    id="Path_1033"
                    data-name="Path 1033"
                    d="M-764.611,612.473l1.51-1.526a2.717,2.717,0,0,1,3.843-.021h0a2.718,2.718,0,0,1,.021,3.843l-2.188,2.212a2.718,2.718,0,0,1-3.843.021h0c-.246-.243-.5-.524-.732-.755"
                    transform="translate(769.97 -610.141)"
                    fill="none"
                    stroke="#62bfae"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_1034"
                    data-name="Path 1034"
                    d="M-795.55,650.495l-1.471,1.487a2.717,2.717,0,0,1-3.843.021h0a2.718,2.718,0,0,1-.021-3.843l2.188-2.212a2.717,2.717,0,0,1,3.843-.021h0c.267.264.578.619.827.868"
                    transform="translate(801.67 -641.245)"
                    fill="none"
                    stroke="#62bfae"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <p className={classes.answer}>
          <Anchorme target="_blank" rel="noreferrer noopener" truncate={50}>
            {cardData?.answer}
          </Anchorme>
        </p>
      </div>

      {loading && <ComponentLoader />}
    </div>
  );
};

export default CardDetails;
