import React from 'react';
import classes from './TextOnCircleBackground.module.scss';

const TextOnCircleBackground = props => {
    return (
        <p className={`mx-auto ${classes.Circle} ${props.active && classes.active}`}>{props.text}</p>

    );
};

export default TextOnCircleBackground;
