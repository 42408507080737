import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import classes from "./LoggedIn.module.scss";

import Home from "./Home/Home";
import AdminHome from "./Admin/AdminHome/AdminHome";
import Sidebar from "./Sidebar/Sidebar";
import ProfileTherapist from "./Profile/ProfileTherapist";
import MyClients from "./MyClients/MyClients";
import ProfileClient from "./Profile/ProfileClient";
import Settings from "./Settings/Settings";
import MySessions from "./MySessions/MySessions";
import Chats from "./Chats/Chats";
import Search from "./Search/Search";
import BookSession from "./BookSession/BookSession";
import CreateProfile from "../CreateProfile/CreateProfile";
import PendingProfileApproval from "../PendingProfileApproval/PendingProfileApproval";
import SessionOverview from "./SessionOverview/SessionOverview";
import Reports from "./Reports/Reports";
import ReportDetail from "./Reports/Reports-detail/ReportDetail";
import RevenueDetails from "./Admin/Revenue/RevenueDetails";
import QNA from "./Admin/QNA";
import Clients from "./Admin/Clients";
import Therapists from "./Admin/Therapists";
import Sessions from "./Admin/Sessions";
import Revenue from "./Admin/Revenue";

const LoggedIn = (props) => {
  const history = useHistory();

  let routes = <Route path="/" component={CreateProfile} />;
  let content = routes;

  if (props.isAdmin) {
    routes = (
      <Switch>
        <Route path={props.match.url} exact component={AdminHome} />

        <Route path={props.match.url + "questions"} component={QNA} />
        <Route path={props.match.url + "clients"} component={Clients} />
        <Route path={props.match.url + "therapists"} component={Therapists} />
        <Route path={props.match.url + "sessions"} component={Sessions} />
        <Route path={props.match.url + "revenue"} component={Revenue} />

        <Route
          path={props.match.url + "revenue-details/:id"}
          component={RevenueDetails}
        />

        <Route
          path={props.match.url + "therapist/:id/profile"}
          component={ProfileTherapist}
        />
        <Route
          path={props.match.url + "client/:id/profile"}
          component={ProfileClient}
        />
        <Route path={props.match.url + "my-clients"} component={MyClients} />

        <Route
          path={props.match.url + "session/:id/overview"}
          component={SessionOverview}
        />

        <Redirect to="/" />
      </Switch>
    );

    content = (
      <div className={classes.mainDiv}>
        <div className={`row mx-0 ${classes.Main}`}>
          <div className={`col-xl-1 px-0 ${classes.sidebarDiv}`}>
            <Sidebar currentPath={history.location.pathname} />
          </div>

          <div className={`col ${classes.CurrentTabContent}`}>{routes}</div>
        </div>
      </div>
    );
  }

  if (props.isSignUpComplete) {
    routes = (
      <Switch>
        <Route path="/pending-approval" component={PendingProfileApproval} />
        <Redirect to="/pending-approval" />
      </Switch>
    );
    content = routes;

    if (props.isProfileApproved) {
      routes = (
        <Switch>
          <Route path={props.match.url} exact component={Home} />
          <Route
            path={props.match.url + "therapist/:id/profile"}
            component={ProfileTherapist}
          />
          <Route
            path={props.match.url + "client/:id/profile"}
            component={ProfileClient}
          />
          <Route path={props.match.url + "my-clients"} component={MyClients} />

          <Route path={props.match.url + "settings"} component={Settings} />

          <Route
            path={props.match.url + "my-sessions"}
            component={MySessions}
          />

          <Route
            path={props.match.url + "session/:id/overview"}
            component={SessionOverview}
          />

          <Route path={props.match.url + "messages/:id?"} component={Chats} />
          <Route path={props.match.url + "search"} component={Search} />
          <Route
            path={props.match.url + "therapist/:id/book-session"}
            component={BookSession}
          />
          <Route
            path={props.match.url + "reports/total-clients"}
            component={ReportDetail}
          />
          <Route path={props.match.url + "reports"} component={Reports} />

          <Redirect to="/" />
        </Switch>
      );

      content = (
        <div className={classes.mainDiv}>
          <div className={`row mx-0 ${classes.Main}`}>
            <div className={`col-xl-1 px-0 ${classes.sidebarDiv}`}>
              <Sidebar currentPath={history.location.pathname} />
            </div>

            <div className={`col ${classes.CurrentTabContent}`}>{routes}</div>
          </div>
        </div>
      );
    }
  }

  return content;
};

const mapStateToProps = (state) => {
  return {
    firstName: state.auth.user.first_name,
    isSignUpComplete: state.auth.user["is_signup_complete"],
    isProfileApproved: state.auth.user["is_profile_approved"],
    isAdmin: state.auth.user.type === "admin" ? true : false,
  };
};

export default connect(mapStateToProps)(LoggedIn);
