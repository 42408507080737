export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_RESET_STATE = "AUTH_RESET_STATE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const ADD_AVAILABILITY_SLOT = "ADD_AVAILABILITY_SLOT";
export const DELETE_AVAILABILITY_SLOT = "DELETE_AVAILABILITY_SLOT";

export const REQUEST_PASSWORD_RESET_CODE_START =
  "REQUEST_PASSWORD_RESET_CODE_START";
export const REQUEST_PASSWORD_RESET_CODE_SUCCESS =
  "REQUEST_PASSWORD_RESET_CODE_SUCCESS";
export const REQUEST_PASSWORD_RESET_CODE_FAIL =
  "REQUEST_PASSWORD_RESET_CODE_FAIL";
export const REQUEST_PASSWORD_RESET_CODE_RESET_STATE =
  "REQUEST_PASSWORD_RESET_CODE_RESET_STATE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_RESET_STATE = "RESET_PASSWORD_RESET_STATE";

export const REQUEST_EMAIL_VERIFICATION_CODE_START =
  "REQUEST_EMAIL_VERIFICATION_CODE_START";
export const REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS =
  "REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS";
export const REQUEST_EMAIL_VERIFICATION_CODE_FAIL =
  "REQUEST_EMAIL_VERIFICATION_CODE_FAIL";

export const VERIFY_EMAIL_START = "VERIFY_EMAIL_START";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";

export const GET_EXTERNAL_WEBSITE_TYPES_START =
  "GET_EXTERNAL_WEBSITE_TYPES_START";
export const GET_EXTERNAL_WEBSITE_TYPES_SUCCESS =
  "GET_EXTERNAL_WEBSITE_TYPES_SUCCESS";
export const GET_EXTERNAL_WEBSITE_TYPES_FAIL =
  "GET_EXTERNAL_WEBSITE_TYPES_FAIL";

export const GET_USER_PROFILE_START = "GET_USER_PROFILE_START";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const UPDATE_USER_PROFILE_START = "UPDATE_USER_PROFILE_START";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";
export const UPDATE_USER_PROFILE_RESET_NAVIGATE =
  "UPDATE_USER_PROFILE_RESET_NAVIGATE";

export const ADD_USER_EXTERNAL_WEBSITE_START =
  "ADD_USER_EXTERNAL_WEBSITE_START";
export const ADD_USER_EXTERNAL_WEBSITE_SUCCESS =
  "ADD_USER_EXTERNAL_WEBSITE_SUCCESS";
export const ADD_USER_EXTERNAL_WEBSITE_FAIL = "ADD_USER_EXTERNAL_WEBSITE_FAIL";
