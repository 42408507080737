import * as actionTypes from './actionTypes';
import axios from 'axios';
import {BASE_URL} from "../../shared/constants";

export const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START
    }
};

export const resetPasswordSuccess = (message) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        message: message
    }
};

export const resetPasswordFail = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        error: error
    }
};

export const resetPasswordResetState = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_PASSWORD_RESET_STATE
        });
    }
};

export const resetPassword = (password, code) => {
    return dispatch => {
        dispatch(resetPasswordStart());
        const url = BASE_URL + '/reset-password/' + code;

        const requestPayload = {
            password: password
        };

        axios.post(url, requestPayload)
            .then(response => {
                dispatch(resetPasswordSuccess(response.data.message));
            })
            .catch(error => {
                dispatch(resetPasswordFail(error.response.data.error));
            });
    }
};
