import React from "react";
import classes from "./UpcomingSessionsListItem.module.scss";
import ProfileImage from "../../../../../../UI/ProfileImageAndBasicInfo/ProfileImage/ProfileImage";
import Moment from "react-moment";
import { timeBetweenDates } from "../../../../../../../shared/utility";
import { useHistory } from "react-router-dom";

const UpcomingSessionsListItem = (props) => {
  const history = useHistory();

  const onClick = (event) => {
    history.push("/session/" + props.session.id + "/overview");
  };

  const onUserClick = (event) => {
    event.stopPropagation();
    history.push(
      "/" + props.user.attendee.type + "/" + props.user.attendee.id + "/profile"
    );
  };

  const Tentative = props.session.status === "tentative" && classes.Tentative;
  const Cancelled = props.session.status === "cancelled" && classes.Cancelled;
  const Confirmed = props.session.status === "confirmed" && classes.Confirmed;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className={`${classes.UpcomingSessionsListItem}`}>
        <div onClick={onClick} className={classes.firstCol}>
          <div className={classes.date}>
            <p className={classes.date__num}>
              <Moment local format="DD">
                {props.session.start}
              </Moment>
            </p>
            <p className={classes.date__month}>
              <Moment local format="MMM">
                {props.session.start}
              </Moment>
            </p>
          </div>
          <p className={classes.time}>
            <Moment local format="HH:mm A">
              {props.session.start}
            </Moment>
          </p>
          <p className={classes.duration}>
            {timeBetweenDates(
              Date.parse(props.session.start),
              Date.parse(props.session.end)
            )}
          </p>
        </div>
        <div
          className={`${classes.secondCol} ${Tentative} ${Cancelled} ${Confirmed}`}
        >
          <div className={classes.userBtn}>
            <div className={classes.profileImg}>
              <ProfileImage src={props.user.attendee.profile_image} />
            </div>
            <p className={classes.profileName}>
              {props.user.attendee.first_name} {props.user.attendee.last_name}
            </p>
            <p className={classes.viewProfile} onClick={onUserClick}>
              View Profile
            </p>
          </div>

          {props.session.status === "tentative" && (
            <div className={classes.awaitingApp}>
              <h4 className={classes.awaitingAppText}>Awaiting Confirmation</h4>
            </div>
          )}

          {props.session.status === "cancelled" && (
            <div className={`${classes.awaitingApp}`}>
              <h4
                className={`${classes.awaitingAppText}  ${classes.cancelledBg}`}
              >
                Cancelled
              </h4>
            </div>
          )}

          {props.session.status === "confirmed" && (
            <div className={classes.secondCol__footer}>
              {props.session.type === "video" && (
                <div className={classes.footerDiv}>
                  <div className={classes.videoIconDiv}>
                    <svg
                      className={classes.icon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="30.994"
                      height="20.568"
                      viewBox="0 0 35.994 20.568"
                    >
                      <path
                        id="Path_4376"
                        data-name="Path 4376"
                        d="M1639.466,873.274a1.286,1.286,0,0,1-1.971,1.086l-8.2-5.171v4.835a1.285,1.285,0,0,1-1.285,1.285h-23.249a1.285,1.285,0,0,1-1.286-1.285v-18a1.286,1.286,0,0,1,1.286-1.286h23.251a1.286,1.286,0,0,1,1.286,1.286v4.835l8.2-5.171a1.285,1.285,0,0,1,1.971,1.087Z"
                        transform="translate(-1603.473 -854.741)"
                        fill="#62BFAE"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className={classes.callSessionTitle}>Video</p>
                    <p className={classes.viewDetailsDiv}>
                      <span onClick={onClick} className={classes.viewDetails}>
                        View Details
                      </span>
                      |
                      <span
                        className={classes.cancel}
                        onClick={() => props.handleShowAleart(props.session.id)}
                      >
                        Cancel
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {props.session.type === "in_clinic" && (
                <div className={classes.footerDiv}>
                  <div className={classes.videoIconDiv}>
                    <svg
                      className={classes.icon}
                      id="Group_7167"
                      data-name="Group 7167"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32.624"
                      height="27.256"
                      viewBox="0 0 36.624 33.256"
                    >
                      <path
                        id="Path_4724"
                        data-name="Path 4724"
                        d="M3355.151,3420.112a6.368,6.368,0,1,0,6.369,6.368A6.375,6.375,0,0,0,3355.151,3420.112Zm4.32,8.253h-2.435v2.434h-3.771v-2.434h-2.435v-3.771h2.435v-2.435h3.771v2.435h2.435Z"
                        transform="translate(-3336.839 -3406.848)"
                        fill="#62BFAE"
                      />
                      <path
                        id="Path_4725"
                        data-name="Path 4725"
                        d="M3101.543,3116.446l-17.786-11.214a.524.524,0,0,0-.56,0l-17.786,11.214a.525.525,0,0,0,.28.969h4.162v20.993H3097.1v-20.993h4.162A.525.525,0,0,0,3101.543,3116.446Zm-13.593,14.971a8,8,0,1,1,3.527-6.634A8,8,0,0,1,3087.951,3131.417Z"
                        transform="translate(-3065.165 -3105.151)"
                        fill="#62BFAE"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className={classes.callSessionTitle}>Clinic</p>
                    <p className={classes.viewDetailsDiv}>
                      <span onClick={onClick} className={classes.viewDetails}>
                        View Details
                      </span>
                      |
                      <span
                        className={classes.cancel}
                        onClick={() => props.handleShowAleart(props.session.id)}
                      >
                        Cancel
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {props.session.type === "home" && (
                <div className={classes.footerDiv}>
                  <div className={classes.videoIconDiv}>
                    <svg
                      className={classes.icon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.873"
                      height="30.996"
                      viewBox="0 0 25.873 37.996"
                    >
                      <g
                        id="Group_7168"
                        data-name="Group 7168"
                        transform="translate(0)"
                      >
                        <path
                          id="Path_4726"
                          data-name="Path 4726"
                          d="M1121.88,1145.165h-2.19a1.345,1.345,0,0,0-1.41,1.265v.676h.932v-.676c0-.121.158-.33.478-.333h2.19c.318,0,.475.213.477.333v.676h.932v-.676A1.343,1.343,0,0,0,1121.88,1145.165Z"
                          transform="translate(-1113.511 -1123.969)"
                          fill="#62BFAE"
                        />
                        <path
                          id="Path_4727"
                          data-name="Path 4727"
                          d="M1141.464,1098.354a4.083,4.083,0,1,0-4.088-4.086A4.085,4.085,0,0,0,1141.464,1098.354Z"
                          transform="translate(-1125.245 -1090.188)"
                          fill="#62BFAE"
                        />
                        <path
                          id="Path_4728"
                          data-name="Path 4728"
                          d="M1139.868,1123.622l-6.177-9.3a1.97,1.97,0,0,0-1.192-.841l-2.172-.546-.207-.038-.032-.011-1.905-.4a2.346,2.346,0,0,0-.415-.04,2.027,2.027,0,0,0-1.464.627l-6.137,6.508a1.986,1.986,0,0,0-.547,1.379l0,3.315h4.016v-2.525l2.27-2.429-.247,6.206-.451,1.057-.106.224h1.394a3.386,3.386,0,0,1,3.381,3.382v.2l5.67,10.427a1.982,1.982,0,0,0,1.2.962,2.03,2.03,0,0,0,.567.082,1.968,1.968,0,0,0,.958-.249,2.02,2.02,0,0,0,.966-1.194,2.05,2.05,0,0,0,.079-.588,1.975,1.975,0,0,0-.246-.944l-6.2-12.808.443-5.079,3.215,4.849a2.008,2.008,0,1,0,3.343-2.224Z"
                          transform="translate(-1114.333 -1103.863)"
                          fill="#62BFAE"
                        />
                        <path
                          id="Path_4729"
                          data-name="Path 4729"
                          d="M1118.071,1152.278H1108.3a2.386,2.386,0,0,0-2.386,2.386v8.015a2.386,2.386,0,0,0,2.386,2.386h9.774a2.386,2.386,0,0,0,2.386-2.386v-8.015A2.385,2.385,0,0,0,1118.071,1152.278Zm.938,10.4a.94.94,0,0,1-.938.938H1108.3a.941.941,0,0,1-.939-.938v-8.015a.943.943,0,0,1,.939-.939h9.774a.942.942,0,0,1,.938.939Zm-4.5-7.386h-2.654v2.051h-2.051V1160h2.051v2.051h2.654V1160h2.05v-2.654h-2.05Z"
                          transform="translate(-1105.911 -1128.34)"
                          fill="#62BFAE"
                        />
                      </g>
                    </svg>
                  </div>
                  <div>
                    <p className={classes.callSessionTitle}>Home</p>
                    <p className={classes.viewDetailsDiv}>
                      <span onClick={onClick} className={classes.viewDetails}>
                        View Details
                      </span>
                      |
                      <span
                        className={classes.cancel}
                        onClick={() => props.handleShowAleart(props.session.id)}
                      >
                        Cancel
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {props.session.type === "phone" && (
                <div className={classes.footerDiv}>
                  <div className={classes.videoIconDiv}>
                    <svg
                      className={classes.icon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.912"
                      height="33.977"
                      viewBox="0 0 27.912 32.977"
                    >
                      <path
                        id="Path_4317"
                        data-name="Path 4317"
                        d="M957.811,327.692a8.388,8.388,0,0,1-2.436,3.786c-1.672,1.242-4.64.892-8.171-.765a32.237,32.237,0,0,1-16.9-21.978c-.7-3.844-.267-6.8,1.357-8.094a8.386,8.386,0,0,1,4.285-1.383.924.924,0,0,1,.968.473l2.882,5.7a1.641,1.641,0,0,1-.19,1.811q-.908,1.1-1.827,2.2a.184.184,0,0,0-.034.191,39.346,39.346,0,0,0,10.5,13.663.185.185,0,0,0,.194.015l2.595-1.2a1.642,1.642,0,0,1,1.8.282l4.764,4.248A.923.923,0,0,1,957.811,327.692Z"
                        transform="translate(-929.983 -299.245)"
                        fill="#62BFAE"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className={classes.callSessionTitle}> Phone </p>
                    <p className={classes.viewDetailsDiv}>
                      <span onClick={onClick} className={classes.viewDetails}>
                        View Details
                      </span>
                      |
                      <span
                        className={classes.cancel}
                        onClick={() => props.handleShowAleart(props.session.id)}
                      >
                        Cancel
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingSessionsListItem;
