import React, { useState } from "react";
import classes from "../Login.module.scss";
import ComponentLoader from "../../../UI/ComponentLoader";
import eyeIcon from "../../../../assets/icons/eyeIcon.svg";
import eyeHidden from "../../../../assets/icons/eyeHidden.svg";

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onEmailChangeHandler = (event) => {
    setEmail(event.target.value);
    props.onChangeHandler();
  };

  const onPasswordChangeHandler = (event) => {
    setPassword(event.target.value);
    props.onChangeHandler();
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    props.onSubmitHandler(email, password);
  };

  return (
    <form
      className={classes.loginForm}
      onSubmit={(event) => onSubmitHandler(event)}
    >
      <div className={classes.emailDiv}>
        <label> Email </label>
        <br />
        <input type="email" onChange={onEmailChangeHandler} id="emailInput" />
      </div>

      <div className={classes.emailDiv}>
        <label> Password </label>
        <br />
        <div className={classes.passwordDiv}>
          <input
            type={passwordShown ? "text" : "password"}
            onChange={onPasswordChangeHandler}
            id="passwordInput"
          />
          {passwordShown ? (
            <img
              onClick={togglePasswordVisibility}
              className={classes.eye}
              src={eyeIcon}
              alt=""
            />
          ) : (
            <img
              onClick={togglePasswordVisibility}
              className={classes.eye}
              src={eyeHidden}
              alt="/"
            />
          )}
        </div>
      </div>

      {!props.loading && (
        <button type="submit" className={classes.loginBtn}>
          Login
        </button>
      )}
      <div className={classes.progressBar}>
        {props.loading && <ComponentLoader />}
      </div>
    </form>
  );
};

export default LoginForm;
