import React from "react";
import classes from "./BookSessionSuccess.module.scss";
import BookSessionSuccess from "../../assets/icons/bookSessionSuccess.svg";
import { useHistory } from "react-router-dom";

const AlertDialog = (props) => {
  const histor = useHistory();
  return (
    <div className={classes.successPopup}>
      <div className={classes.innerDiv}>
        <div onClick={() => histor.push("/session/" + props.data.id + "/overview")} className={classes.closeAlert}>
          <svg
            className={classes.crossIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="25.731"
            height="25.731"
            viewBox="0 0 25.731 25.731"
          >
            <g
              id="Group_226"
              data-name="Group 226"
              transform="translate(2559.582 -8520.719) rotate(45)"
            >
              <line
                id="Line_21"
                data-name="Line 21"
                y2="30.389"
                transform="translate(4233.354 7819.761)"
                fill="none"
                stroke="#B6C0D1"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <line
                id="Line_22"
                data-name="Line 22"
                x2="30.389"
                transform="translate(4218.16 7834.955)"
                fill="none"
                stroke="#B6C0D1"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </g>
          </svg>
        </div>

        <div>
          <img
            className={classes.succesPopupSvg}
            src={BookSessionSuccess}
            alt=""
          />

          <h4 className={classes.thankyou}>Thank you!</h4>
        </div>

        <p className={classes.message}>
          You request for appointment has been submitted. You will receive a
          confirmation mail on your registered email address once it’s
          confirmed.
        </p>

        <button
          onClick={() => histor.push("/session/" + props.data.id + "/overview")}
          className={classes.sessionOverviewBtn}
        >
          Session overview
        </button>
      </div>
    </div>
  );
};

export default AlertDialog;
