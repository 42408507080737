import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import classes from "../ProfileClient.module.scss";
import FooterLink from "../../../../footerLink/FooterLink";
import ComponentLoader from "../../../../UI/ComponentLoader";
import BasicInfoForm from "../therapistProfileForm/BasicInfoForm";
import PersonalInfoForm from "../therapistProfileForm/PersonalInfoForm";
import PreferencesForm from "../therapistProfileForm/PreferencesForm";
import Availability from "../therapistProfileForm/Availability";
import { getUserProfile } from "../../../../../store/actions";

const ProfileClient = (props) => {
  const { profile, loading, error } = useSelector((state) => ({
    profile: state.userProfile.profile,
    loading: state.userProfile.loading,
    error: state.userProfile.error,
  }));
  const dispatch = useDispatch();

  const getProfile = () => {
    dispatch(getUserProfile());
  };

  return (
    <Fragment>
      {loading && <ComponentLoader />}
      {!loading && profile && (
        <div className={classes.profileClient}>
          <div className={`bcg ${classes.ProfileClientInnerDiv}`}>
            <div className={classes.profileClientDiv}>
              <BasicInfoForm getProfile={getProfile} profile={profile} />
              <PersonalInfoForm getProfile={getProfile} profile={profile} />

              <PreferencesForm getProfile={getProfile} profile={profile} />
              <Availability getProfile={getProfile} profile={profile} />
            </div>
          </div>

          <div className={classes.profileClient__footer}>
            <FooterLink />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProfileClient;
