import { useRef, useEffect } from "react";
import beatStigmaVid from "../../../assets/video/beat-stigma.mp4";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import beatStigmaPoster from "../../../assets/video/beat-stigma.jpg";
import PostLists from "./PostListings";

import classes from "./stopStigma.module.scss";

const StopTheStigma = () => {
  const firstSecRef = useRef(null);
  const secondSection = useRef(null);

  useEffect(() => {
    if (window.innerWidth >= 320) {
      const Scrollmagic = require("scrollmagic");
      ScrollMagicPluginGsap(Scrollmagic, TweenMax, TimelineMax);

      const controller = new Scrollmagic.Controller();

      const heroTimeline = new TimelineMax().fromTo(
        firstSecRef.current,
        {
          opacity: 1,
          y: "0%",
          duration: 5,
        },
        { opacity: 0.5, y: "-100%" }
      );

      const heroScene = new Scrollmagic.Scene({
        triggerElement: firstSecRef.current,
        triggerHook: "onLeave",
        duration: "60%",
      })
        .setTween(heroTimeline)
        .addTo(controller);

      return () => {
        heroScene.destroy(true);
      };
    }
  }, []);

  return (
    <div className={classes.parentSection}>
      <div ref={firstSecRef} className={classes.firstSection}>
        <div className={classes.stigmaDiv}>
          <h1 className={classes.stigmaDiv_title}> #Beat The Stigma </h1>
          <h3 className={classes.stigmaDiv_subTitle}>
            Get Your Query Answered By Qualified Therapists For Free
          </h3>
          <div className={classes.stigmaDiv_scrollDown}>
            <p className={classes.scrollDownText}>Scroll down</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="63.31"
              height="70.962"
              viewBox="0 0 63.31 70.962"
            >
              <g
                id="Group_10667"
                data-name="Group 10667"
                transform="translate(-11194.379 1482.987)"
              >
                <path
                  id="Path_14800"
                  data-name="Path 14800"
                  d="M11066.5-1478.34v62.11"
                  transform="translate(159 -4.646)"
                  fill="none"
                  stroke="#5DFFE1"
                  strokeWidth="6"
                />
                <path
                  id="Path_14801"
                  data-name="Path 14801"
                  d="M11196.374-1445.8l29.534,29.534,29.533-29.534"
                  transform="translate(0.126)"
                  fill="none"
                  stroke="#5DFFE1"
                  strokeWidth="6"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className={classes.videoDiv}>
          <video
            muted
            autoPlay
            loop
            src={beatStigmaVid}
            playsInline
            poster={beatStigmaPoster}
            className={classes.beatStigmaVid}
          ></video>
        </div>
      </div>
      <div ref={secondSection} className={classes.secondSection}>
        <PostLists />
      </div>
    </div>
  );
};

export default StopTheStigma;
