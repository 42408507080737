import React from "react";
import SignUpForm from "./SignUpForm/SignUpForm";
import classes from "../Login/Login.module.scss";
import clientImage from "../../../assets/images/loginImg.jpg";
import clientVideo from "../../../assets/video/Mindsum_login.mp4";
import logo from "../../../assets/images/mindsum-logo.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Alert from "../../UI/Alert/Alert";
import FooterLink from "../../footerLink/FooterLink";

const SignUp = (props) => {
  const onChangeHandler = () => {
    props.onResetState();
  };

  const onSubmitHandler = (
    email,
    password,
    firstName,
    lastName,
    termsAgreed
  ) => {
    props.onSignUp(
      props.type,
      email,
      password,
      firstName,
      lastName,
      termsAgreed
    );
  };

  let errorMessage = null;
  if (props.error && typeof props.error === "string") {
    errorMessage = <Alert level="danger" message={props.error} />;
  }

  return (
    <div className={classes.login}>
      <div className={classes.firstDiv}>
        <div className={`${classes.welcomeSec} ${classes.signup}`}>
          <a href="https://www.mindsum.app/">
            <img className={classes.mindsumLogo} src={logo} alt="" />
          </a>
          <h1 className={classes.welcome}> Sign Up </h1>
          <p className={classes.signIn}> Register to continue </p>
        </div>

        {errorMessage}

        <SignUpForm
          type={props.type}
          onSubmitHandler={onSubmitHandler}
          onChangeHandler={onChangeHandler}
          errors={props.error}
          loading={props.loading}
        />

        <p className={classes.alreadyReg}>
          Already Registered?
          {/* <Link
            to="/login"
            onClick={() => window.location.reload()}
            className="loginLink"
          >
            LOGIN
          </Link> */}
          <span
            onClick={() => {
              window.location.href = "/login";
            }}
            className={classes.loginLink}
          >
            Login
          </span>
        </p>
        <div className={classes.signupFooter}>
          <FooterLink className={classes.whiteText} />
        </div>
      </div>
      <div className={classes.secondDiv}>
        <video
          muted
          autoPlay
          loop
          src={clientVideo}
          playsInline
          poster={clientImage}
          className={classes.loginVideo}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignUp: (type, email, password, firstName, lastName, termsAgreed) =>
      dispatch(
        actions.signUp(type, email, password, firstName, lastName, termsAgreed)
      ),
    onResetState: () => dispatch(actions.authResetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
