const CheckBox = (props) => {
  return (
    <div className="container">
      <label className="switch">
        <input
          type="checkbox"
          id={props.id}
          className={props.className}
          checked={props.checked}
          onChange={props.onChange}
          {...props}
        />
        <div className="slider round"></div>
      </label>
    </div>
  );
};

export default CheckBox;
