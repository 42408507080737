import React, { Fragment, useState } from "react";
import classes from "../PreviewProfile/PreProfile.module.scss";
import ProfileImage from "../../../UI/ProfileImageAndBasicInfo/ProfileImage/ProfileImage";
import Moment from "react-moment";
import logo from "../../../../assets/images/mindsum-logo-new.svg";
import FooterLink from "../../../footerLink/FooterLink";
import { Link } from "react-router-dom";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { updateObject } from "../../../../shared/utility";
import ComponentLoader from "../../../UI/ComponentLoader";

const weekDays = [
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
  { id: 7, day: "Sunday" },
];

const PreviewTherapistProfile = (props) => {
  const [loading, setloading] = useState(false);

  const onSubmit = () => {
    setloading((prev) => true);
    const updatedUser = updateObject(props.user, {
      is_signup_complete: true,
    });
    props.onUpdateUser(updatedUser);
  };

  const shorten = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  const getFormatedTime = (time) => {
    const st = time?.split(":").map((e) => parseInt(e));

    return new Date().setHours(...st);
  };

  return loading ? (
    <ComponentLoader />
  ) : (
    <div className={`${classes.stepThree} ${classes.preview}`}>
      <div className={`${classes.stepThreeInner} ${classes.previewMain}`}>
        <div className={classes.step1Nav}>
          <div className={classes.firstPart}>
            <span className={`${classes.spanText}`}>ABOUT &#62;</span>

            <span className={`${classes.spanText}`}>SKILLS &#62;</span>

            <span className={`${classes.spanText}`}>Preferences &#62;</span>

            <span className={`${classes.spanText} ${classes.active}`}>
              PREVIEW
            </span>
          </div>
          <div className={classes.secondPart}>
            <Link className={classes.navLogo} to="/">
              <img src={logo} alt="logo" className={classes.ImgFluid} />
            </Link>
          </div>
          <div className={classes.thirdPart}>
            {props.isAuthenticated &&
              (!props.user.is_signup_complete ||
                !props.user.is_profile_approved) && (
                <ul className="navbar-nav">
                  <Link to="logout">Logout</Link>
                </ul>
              )}
          </div>
        </div>
        <div className={classes.stepThreeContent}>
          <div
            className={`${classes.therapistPreview} ${classes.previewContent}`}
          >
            <div className={classes.previewAbout}>
              <div className={classes.topHeader}>
                <h4 className={classes.aboutYouTitle}> About You </h4>
                <Link to="about-you" className={classes.editLink}>
                  <div> Edit </div>
                </Link>
              </div>
              <div className={classes.aboutYouContent}>
                <div className={classes.profileInfo}>
                  <ProfileImage src={props?.profile?.user?.profile_image} />
                  <div className={classes.previewNameDiv}>
                    <h3 className={classes.previewName}>
                      {props?.profile?.user?.first_name}&nbsp;
                      {props?.profile?.user?.last_name}
                    </h3>
                    <p className={classes.previewEmail}>
                      {props?.profile?.user?.email}
                    </p>
                  </div>

                  <div className={classes.previewSocialDiv}>
                    {props?.profile?.external_websites.map((item) => (
                      <Fragment key={item.external_website_type.id}>
                        {item.external_website_type.title === "LinkedIn" && (
                          <a
                            className="mr-1"
                            href={item.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <div className={classes.socialIconDiv}>
                              <svg
                                className={classes.socialIcon}
                                id="Group_6021"
                                data-name="Group 6021"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.043"
                                height="13.413"
                                viewBox="0 0 14.043 13.413"
                              >
                                <path
                                  id="Path_1830"
                                  data-name="Path 1830"
                                  d="M31.772,31.051a1.446,1.446,0,0,1-.461,1.114,1.722,1.722,0,0,1-1.235.448h-.021a1.647,1.647,0,0,1-1.206-.448,1.5,1.5,0,0,1-.455-1.114,1.472,1.472,0,0,1,.47-1.119,1.718,1.718,0,0,1,1.223-.443,1.667,1.667,0,0,1,1.215.443,1.518,1.518,0,0,1,.469,1.119Zm-.2,2.8V42.9H28.566V33.846ZM42.422,37.71V42.9h-3V38.058a2.644,2.644,0,0,0-.371-1.5,1.3,1.3,0,0,0-1.156-.543,1.475,1.475,0,0,0-.963.315,1.942,1.942,0,0,0-.579.78,2.183,2.183,0,0,0-.1.74V42.9H33.244q.018-3.646.019-5.911t-.009-2.7l-.009-.439H36.25v1.316h-.019a4.109,4.109,0,0,1,.376-.512,3.933,3.933,0,0,1,.515-.475,2.369,2.369,0,0,1,.8-.4,3.64,3.64,0,0,1,1.046-.141,3.244,3.244,0,0,1,2.511,1.036,4.347,4.347,0,0,1,.951,3.038Z"
                                  transform="translate(-28.394 -29.487)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </a>
                        )}
                        {item.external_website_type.title === "Twitter" && (
                          <a
                            key={item.external_website_type.id}
                            className="mr-1"
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className={classes.socialIconDiv}>
                              <svg
                                className={classes.socialIcon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.205"
                                height="13.17"
                                viewBox="0 0 16.205 13.17"
                              >
                                <path
                                  id="twitter"
                                  d="M5.178,9.346a9.4,9.4,0,0,0,9.46-9.46q0-.216-.009-.43a6.766,6.766,0,0,0,1.658-1.722,6.633,6.633,0,0,1-1.909.524A3.337,3.337,0,0,0,15.84-3.581a6.668,6.668,0,0,1-2.111.807A3.321,3.321,0,0,0,11.3-3.824,3.325,3.325,0,0,0,7.977-.5a3.322,3.322,0,0,0,.086.758A9.44,9.44,0,0,1,1.21-3.216,3.317,3.317,0,0,0,.76-1.544a3.322,3.322,0,0,0,1.48,2.767A3.3,3.3,0,0,1,.733.807c0,.014,0,.028,0,.042A3.326,3.326,0,0,0,3.4,4.109a3.337,3.337,0,0,1-.877.117,3.3,3.3,0,0,1-.625-.06A3.328,3.328,0,0,0,5,6.475,6.67,6.67,0,0,1,.875,7.9a6.766,6.766,0,0,1-.793-.046,9.412,9.412,0,0,0,5.1,1.494"
                                  transform="translate(-0.082 3.824)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </a>
                        )}
                        {item.external_website_type.title === "Facebook" && (
                          <a
                            key={item.external_website_type.id}
                            className="mr-1"
                            href={item.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <div className={classes.socialIconDiv}>
                              <svg
                                className={classes.socialIcon}
                                id="facebook-app-symbol"
                                xmlns="http://www.w3.org/2000/svg"
                                width="8.989"
                                height="17.312"
                                viewBox="0 0 8.989 17.312"
                              >
                                <path
                                  id="f_1_"
                                  d="M43.125,17.312v-7.9h2.649l.4-3.078H43.125V4.372c0-.891.246-1.5,1.525-1.5h1.629V.121A22.083,22.083,0,0,0,43.906,0a3.708,3.708,0,0,0-3.958,4.068v2.27H37.29V9.416h2.657v7.9Z"
                                  transform="translate(-37.29)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </a>
                        )}

                        {item.external_website_type.title === "Website" && (
                          <a
                            key={item.external_website_type.id}
                            className="mr-1"
                            href={item.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <div className={classes.socialIconDiv}>
                              <svg
                                className={classes.socialIcon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.013"
                                height="12.932"
                                viewBox="0 0 13.013 12.932"
                              >
                                <g
                                  id="Group_6904"
                                  data-name="Group 6904"
                                  transform="translate(0 0)"
                                >
                                  <path
                                    id="Path_4313"
                                    data-name="Path 4313"
                                    d="M979.518,995.629a.848.848,0,0,1-.074.094q-.651.653-1.3,1.3a1.907,1.907,0,0,1-.829.511,1.352,1.352,0,0,1-1.755-1.081,1.584,1.584,0,0,1,.4-1.3c.043-.053.09-.1.139-.151.823-.824,1.651-1.644,2.47-2.472a1.587,1.587,0,0,1,2.268-.118.78.78,0,0,0,1.1-.071.808.808,0,0,0-.028-1.132,2.893,2.893,0,0,0-2.153-.85,3.312,3.312,0,0,0-2.343,1.021q-1.237,1.234-2.471,2.471a3.443,3.443,0,0,0-.952,1.679,2.6,2.6,0,0,0,.12,1.788,2.965,2.965,0,0,0,3.245,1.871,3.4,3.4,0,0,0,1.919-1q1.1-1.1,2.2-2.2a.653.653,0,0,0,.048-.058A2.707,2.707,0,0,1,979.518,995.629Z"
                                    transform="translate(-973.888 -986.296)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_4314"
                                    data-name="Path 4314"
                                    d="M1000.85,975.949a3.091,3.091,0,0,0-5.053-.979c-.824.723-1.556,1.546-2.332,2.32a.117.117,0,0,0-.017.029,2.726,2.726,0,0,1,1.991.316c.021-.025.049-.064.082-.1.431-.432.856-.869,1.3-1.291a2.433,2.433,0,0,1,.552-.408,1.367,1.367,0,0,1,2.038.982,1.561,1.561,0,0,1-.483,1.373q-1.288,1.309-2.6,2.6a1.554,1.554,0,0,1-2.18.073.786.786,0,0,0-1.117.047.807.807,0,0,0,.023,1.141,3.089,3.089,0,0,0,.5.4,3.133,3.133,0,0,0,3.761-.366,31.078,31.078,0,0,0,2.816-2.795C1001.512,977.629,1001.095,976.537,1000.85,975.949Z"
                                    transform="translate(-988.093 -974.05)"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            </div>
                          </a>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>

                <div className={classes.profileBioDiv}>
                  <h6 className={classes.profileBioTitle}>
                    Tell Us About Yourself (Your bio will help us provide right
                    support & help better)
                  </h6>
                  <p className={classes.profileBio}>
                    {props.profile.more_about_yourself}
                  </p>
                </div>

                <div className={classes.contactInfo}>
                  <h5 className={classes.contactInfoTitle}>
                    Contact Information
                  </h5>
                  <div className={classes.contactFlex}>
                    <div className={classes.contactFirstDiv}>
                      <h6 className={classes.contactTitle}>Contact (Mobile)</h6>

                      <div className={classes.mobile}>
                        {props.profile.mobile}
                      </div>
                    </div>
                    <div className={classes.contactFirstDiv}>
                      <h6 className={classes.contactTitle}>
                        Contact (Landline)
                      </h6>

                      <div className={classes.mobile}>
                        {props.profile.landline}
                      </div>
                    </div>
                  </div>

                  <div className={classes.addressDivFlex}>
                    <div className={classes.addressDiv}>
                      <h6 className={classes.addressTitle}>Address Line 1</h6>
                      <div className={classes.address}>
                        {props.profile.address_line_1}
                      </div>
                    </div>
                    <div className={classes.addressDiv}>
                      <h6 className={classes.addressTitle}>Address Line 2</h6>
                      <div className={classes.address}>
                        {props.profile.address_line_2}
                      </div>
                    </div>
                  </div>

                  <div className={classes.addressDivFlex}>
                    <div className={classes.addressDiv}>
                      <h6 className={classes.addressTitle}>Address Line 3</h6>
                      <div className={classes.address}>
                        {props.profile.address_line_3}
                      </div>
                    </div>
                    <div className={classes.addressDiv}>
                      <h6 className={classes.addressTitle}> City/Town </h6>
                      <div className={classes.address}>
                        {props.profile.city_town}
                      </div>
                    </div>
                  </div>

                  <div className={classes.addressDivFlex}>
                    <div className={classes.addressDiv}>
                      <h6 className={classes.addressTitle}> Postcode </h6>
                      <div className={classes.address}>
                        {props.profile.post_code}
                      </div>
                    </div>
                    <div className={classes.addressDiv}>
                      <h6 className={classes.addressTitle}> Country </h6>
                      <div className={classes.address}>
                        {/* {props.profile.country} */}
                        United Kingdom
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${classes.skills_qualifications} ${classes.previewNeeds}`}
            >
              <div className={classes.topHeader}>
                <h4 className={classes.aboutYouTitle}>
                  Skills and qualifications
                </h4>
                <Link to="/skills-qualifications" className={classes.editLink}>
                  <div> Edit </div>
                </Link>
              </div>

              <div className={classes.yourNeedsContent}>
                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Your skills
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.skills.map((item) => (
                      <span
                        key={item.id}
                        className={`badge ${classes.therapySelectBg} p-2 mr-2 mb-2`}
                      >
                        {shorten(item.title, 25)}
                      </span>
                    ))}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Your qualifications, training and experience
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.qualifications_training_experience}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Your memberships with professional bodies
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.professional_bodies.map((item) => (
                      <span
                        key={item.id}
                        className={`badge ${classes.therapySelectBg} p-2 mr-2 mb-2`}
                      >
                        {shorten(item.title, 25)}
                      </span>
                    ))}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Counselling areas you deal with
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.counselling_areas.map((item) => (
                      <span
                        key={item.id}
                        className={`badge ${classes.therapySelectBg} p-2 mr-2 mb-2`}
                      >
                        {shorten(item.title, 25)}
                      </span>
                    ))}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Therapies offered
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.therapy_types.map((item) => (
                      <span
                        key={item.id}
                        className={`badge ${classes.therapySelectBg} p-2 mr-2 mb-2`}
                      >
                        {shorten(item.title, 25)}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${classes.preferences} ${classes.previewNeeds}`}>
              <div className={classes.topHeader}>
                <h4 className={classes.aboutYouTitle}>Preferences</h4>
                <Link to="preferences" className={classes.editLink}>
                  <div> Edit </div>
                </Link>
              </div>

              <div className={classes.yourNeedsContent}>
                <div className={classes.sessionDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}></div>
                    <span
                      className={`${classes.therapySession} ${classes.listStyleText}`}
                    >
                      Your availability
                    </span>
                  </div>

                  <div className={classes.sessionDiv}>
                    {weekDays.map((weday) => {
                      return (
                        <div
                          key={weday.id}
                          className={`${classes.selectedSessions} ${classes.rowFlex} mb-2`}
                        >
                          <label className={`${classes.weekDaysLabel}`}>
                            {weday.day}
                          </label>
                          <div className={classes.selectedTimeRange}>
                            {props.profile.availability_day
                              .filter((e) => e.title === weday.day)?.[0]
                              ?.["slots"]?.map((s) => {
                                return (
                                  <div
                                    key={s.id}
                                    className={classes.selectedTimesDiv}
                                  >
                                    <p className={classes.selectedTimes}>
                                      <Moment local format="hh:mm a">
                                        {getFormatedTime(s?.start_time)}
                                      </Moment>
                                      <Moment local format="hh:mm a">
                                        {getFormatedTime(s?.end_time)}
                                      </Moment>
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={classes.sessionDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}></div>
                    <span
                      className={`${classes.therapySession} ${classes.listStyleText}`}
                    >
                      Types of sessions you offer
                    </span>
                  </div>
                  <p className={classes.selectedSessions}>
                    {props?.profile?.offers_in_clinic_sessions && (
                      <>
                        <span
                          className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-3 `}
                        >
                          Clinic
                        </span>
                        {props?.profile?.clinic_fee > 0 && (
                          <span className={`mr-5 mb-3`}>
                            {props?.profile?.clinic_fee}&nbsp;
                            {props?.profile?.currency_code}
                          </span>
                        )}
                      </>
                    )}

                    {props?.profile?.offers_home_visit_sessions && (
                      <>
                        <span
                          className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-3`}
                        >
                          Home
                        </span>
                        {props?.profile?.home_fee > 0 && (
                          <span className={`mr-5 mb-3`}>
                            {props?.profile?.home_fee}&nbsp;
                            {props?.profile?.currency_code}
                          </span>
                        )}
                      </>
                    )}

                    {props?.profile?.offers_online_sessions && (
                      <>
                        <span
                          className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-3`}
                        >
                          Video
                        </span>
                        {props?.profile?.video_fee > 0 && (
                          <span className={`mr-5 mb-3`}>
                            {props?.profile?.video_fee}&nbsp;
                            {props?.profile?.currency_code}
                          </span>
                        )}
                      </>
                    )}
                    <br />
                    {props?.profile?.offers_phone_sessions && (
                      <>
                        <span
                          className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-3`}
                        >
                          Phone
                        </span>
                        {props?.profile?.audio_fee > 0 && (
                          <span className={`mr-5 mb-3`}>
                            {props?.profile?.audio_fee}&nbsp;
                            {props?.profile?.currency_code}
                          </span>
                        )}
                      </>
                    )}
                  </p>
                </div>

                <div className={classes.sessionDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}></div>
                    <span
                      className={`${classes.therapySession} ${classes.listStyleText}`}
                    >
                      Types of clients you serve
                    </span>
                  </div>
                  <p className={classes.selectedSessions}>
                    {props.profile.serves_children && (
                      <span
                        className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-2`}
                      >
                        children
                      </span>
                    )}
                    {props.profile.serves_young_people && (
                      <span
                        className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-2`}
                      >
                        Young people
                      </span>
                    )}
                    {props.profile.serves_adults && (
                      <span
                        className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-2`}
                      >
                        Adults
                      </span>
                    )}
                    {props.profile.serves_couples && (
                      <span
                        className={`badge ${classes.selectedSessionsBg} p-2 mr-2 mb-2`}
                      >
                        Couples
                      </span>
                    )}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Languages you can speak
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.language?.map((item) => (
                      <span
                        key={item.id}
                        className={`badge ${classes.therapySelectBg} p-2 mr-2 mb-2`}
                      >
                        {shorten(item.title, 25)}
                      </span>
                    ))}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Your default session fee
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.currency_code}&nbsp;
                    {props.profile.default_session_fee}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Your business name
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {props.profile.business_name}
                  </p>
                </div>

                <div className={classes.therapySelectDiv}>
                  <div className={classes.listStyleFlex}>
                    <div className={classes.listStyle}> </div>
                    <span
                      className={`${classes.therapySelect} ${classes.listStyleText} `}
                    >
                      Your business type
                    </span>
                  </div>

                  <p className={classes.selectedTherapies}>
                    {Number(props.profile.business_type) === 1
                      ? "Private Limited"
                      : "Sole Proprietorship"}
                  </p>
                </div>

                {props.profile.is_vat_registered && (
                  <div className={classes.therapySelectDiv}>
                    <div className={classes.listStyleFlex}>
                      <div className={classes.listStyle}> </div>
                      <span
                        className={`${classes.therapySelect} ${classes.listStyleText} `}
                      >
                        VAT registration number
                      </span>
                    </div>

                    <p className={classes.selectedTherapies}>
                      {props.profile.vat_registration_number}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className={classes.previewBtns}>
              <button
                className={`${classes.previewCompleteBtn}`}
                onClick={onSubmit}
              >
                Complete
              </button>
            </div>
          </div>
        </div>
        <div className={classes.stepThreeFooter}>
          <FooterLink />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    profile: state.userProfile.profile,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUser: (updatedUser) => dispatch(actions.updateUser(updatedUser)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewTherapistProfile);
