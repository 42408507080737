import React, { useEffect, useState } from "react";
import ChatsList from "./ChatsList/ChatsList";
import Chat from "./Chat/Chat";
import classes from "./Chats.module.scss";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import FooterLink from "../../../footerLink/FooterLink";

const Chats = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [activeChatId, setActiveChatId] = useState(id);

  useEffect(() => {
    setActiveChatId(id);
  }, [id]);

  const onSelectChat = (chatId) => {
    history.push("/messages/" + chatId);
  };

  return (
    <div className={classes.chatsSetting}>
      <div className={`bcg ${classes.chatsSetting__innerDiv}`}>
        <div className={classes.topHead}>
          <p className={classes.backArrow} onClick={() => history.goBack()}>
            <svg
              className={classes.backIcon}
              xmlns="http://www.w3.org/2000/svg"
              width="18.303"
              height="17.946"
              viewBox="0 0 18.303 17.946"
            >
              <g
                id="Group_6913"
                data-name="Group 6913"
                transform="translate(-18699.623 -13146.869)"
              >
                <path
                  id="Path_4322"
                  data-name="Path 4322"
                  d="M18708.893,13147.221l-8.564,8.677,8.564,8.564"
                  fill="none"
                  stroke="#000"
                  strokeWidth="1"
                />
                <path
                  id="Path_4323"
                  data-name="Path 4323"
                  d="M18717.926,13155.842h-16.957"
                  fill="none"
                  stroke="#000"
                  strokeWidth="1"
                />
              </g>
            </svg>
            BACK
          </p>
          <div className="settingsHead singleNavItem">
            <div
              className="navItem"
              style={{ width: "100%", background: "#62bfae" }}
            >
              <a
                className="navLink"
                style={{ color: "white", cursor: "pointer" }}
              >
                Messages
              </a>
            </div>
          </div>
        </div>

        <div className={classes.chats}>
          <div className={classes.chatsFirstCol}>
            {!activeChatId && window.innerWidth <= 600 && (
              <ChatsList
                activeChatId={activeChatId}
                onSelectChat={onSelectChat}
              />
            )}
            {window.innerWidth > 600 && (
              <ChatsList
                activeChatId={activeChatId}
                onSelectChat={onSelectChat}
              />
            )}
          </div>
          {activeChatId && (
            <div className={classes.chatsSecondCol}>
              <Chat id={activeChatId} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.chatsFooter}>
        <FooterLink />
      </div>
    </div>
  );
};

export default Chats;
