import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import ComponentLoader from "../../UI/ComponentLoader";
import { clearAndLogout } from "../../../shared/utility";

const Logout = (props) => {
  const { onLogout } = props;
  const histor = useHistory();

  useEffect(() => {
    onLogout();
    localStorage.removeItem("show");
    clearAndLogout();
  }, [onLogout]);

  useEffect(() => {
    if (props.token === null) {
      histor.push("/");
      localStorage.removeItem("show");
      clearAndLogout();
    }
  }, [props.token]);

  return <ComponentLoader />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
