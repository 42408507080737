import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { BASE_URL } from "../../../../../../../../shared/constants";
import axios from "axios";
import { clearAndLogout } from "../../../../../../../../shared/utility";
import AlertDialog from "../../../../../../../UI/Alert/AlertDialog";

const CardSetupForm = (props) => {
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(true);
  const [cardElementRef, setCardElementRef] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState("");

  const getSetupIntent = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/payment/setup-intent";

    return axios.get(url, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  };

  const setupCard = async (setupIntent) => {
    const result = await stripe.confirmCardSetup(setupIntent["client_secret"], {
      payment_method: {
        card: cardElementRef,
      },
    });

    cardElementRef.clear();
    if (result.error) {
      alert(result.error.message);
    } else {
      if (defaultPaymentMethod) {
        props.setAsDefault(result.setupIntent.payment_method);
      } else {
        props.getPaymentMethods();
      }
    }
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      cardElementRef.clear();
      return;
    }

    getSetupIntent()
      .then(async (response) => {
        const setupIntent = response.data;

        await setupCard(setupIntent);
      })
      .catch((error) => {
        cardElementRef.clear();
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }

        if (
          error?.response?.data?.error ===
          "Permission denied: Please, verify your email to perform this action"
        ) {
          setIsEmailVerified("Verify your email to add payment method");
        }
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="addCardModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="1"
        aria-labelledby="addCardModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addCardModalLabel">
                Add card
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <CardElement onReady={(e) => setCardElementRef(e)} />
                </div>
              </div>
              <div className="row pt-4">
                <div className="col">
                  <div className="form-group form-check mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="setDefaultInput"
                      checked={defaultPaymentMethod}
                      onChange={(event) =>
                        setDefaultPaymentMethod(event.target.checked)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="setDefaultInput"
                    >
                      Default payment method
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                disabled={!stripe}
                onClick={handleSubmit}
              >
                Save card
              </button>
            </div>
          </div>
        </div>
      </div>

      {isEmailVerified && (
        <AlertDialog
          msg={isEmailVerified}
          onToggle={() => setIsEmailVerified("")}
          verifyCTA="Verify Email"
        />
      )}
    </>
  );
};

export default CardSetupForm;
