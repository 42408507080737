import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    message: null
};

const resetPasswordStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        message: null
    });
};

const resetPasswordSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        message: action.message
    });
};

const resetPasswordFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        message: null
    });
};

const resetPasswordState = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        message: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_PASSWORD_START: return resetPasswordStart(state, action);
        case actionTypes.RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action);
        case actionTypes.RESET_PASSWORD_FAIL: return resetPasswordFail(state, action);
        case actionTypes.RESET_PASSWORD_RESET_STATE: return resetPasswordState(state, action);
        default: return state;
    }
};

export default reducer;
