import React, {Fragment} from 'react';
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MyClientOverview = props => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-3 col-xl-2">
                    <label className="font-weight-bold">First session</label>
                    <p>18 Feb 2020</p>
                    <label className="font-weight-bold">Last session</label>
                    <p>Ongoing</p>
                    <label className="font-weight-bold">Total sessions</label>
                    <p>20</p>
                    <label className="font-weight-bold">Agreed fee</label>
                    <p>£50 per session</p>
                    <label className="font-weight-bold">Total amount paid</label>
                    <p>£1000</p>
                    <label className="font-weight-bold">Primary condition</label>
                    <p>Bipolar disorder</p>
                    <label className="font-weight-bold">Age</label>
                    <p>14 Years</p>
                    <label className="font-weight-bold">Gender</label>
                    <p>Female</p>
                    <label className="font-weight-bold">Client ID</label>
                    <p>123456</p>
                    <label className="font-weight-bold">You rated this client</label>
                    <p>4.0 <FontAwesomeIcon icon={faStar} className="float-left mr-2 mt-1"/></p>
                </div>
                <div className="col-md-9 col-xl-8">
                    <p>About client: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <button className="btn btn-warning float-right mt-3">Rate this client</button>
                </div>
            </div>
        </Fragment>
    );
};

export default MyClientOverview;
