import React, { useState, useEffect } from "react";
import classes from "./MySessions.module.scss";
import { useHistory } from "react-router";
import { BASE_URL } from "../../../../shared/constants";
import axios from "axios";
import MySessionsTable from "./MySessionsTable/MySessionsTable";
import MySessionsTablePast from "./MySessionsTable/MySessionsTablePast";
import FooterLink from "../../../footerLink/FooterLink";
import { clearAndLogout } from "../../../../shared/utility";

const MySessions = (props) => {
  const history = useHistory();

  const [sessions, setSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [nextPage, setNextPage] = useState("");

  const [currentTab, setCurrentTab] = useState("upcoming");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.location.pathname === "/my-sessions" + "/upcoming") {
      setCurrentTab("upcoming");
    } else if (props.location.pathname === "/my-sessions" + "/past") {
      setCurrentTab("past");
    } else {
      history.replace("/my-sessions/upcoming");
    }
  }, []);

  useEffect(() => {
    getSessions();
  }, []);

  useEffect(() => {
    getNextData();
  }, []);

  const getSessions = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/therapist-sessions/sessions-list";

    setLoading(() => true);
    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const data = response.data.results.filter(
          (e) => new Date(e.end) > new Date()
        );
        const pastData = response.data.results.filter(
          (e) => new Date(e.end) < new Date()
        );

        setNextPage(response.data.next);
        setSessions(data);
        setPastSessions(pastData);
        setLoading(() => false);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const getNextData = () => {
    const token = localStorage.getItem("token");
    setLoading(() => true);
    axios
      .get(nextPage, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const data = response.data.results.filter(
          (e) => new Date(e.end) > new Date()
        );

        const pastData = response.data.results.filter(
          (e) => new Date(e.end) < new Date()
        );

        setSessions((prev) => [...prev, ...data]);
        setPastSessions((prev) => [...prev, ...pastData]);
        setNextPage(response.data.next);
        setLoading(() => false);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    history.replace("/my-sessions/" + tab);

    setCurrentTab(tab);
  };

  return (
    <div className={classes.mySessions}>
      <div className={`bcg ${classes.mySessions__innerDiv}`}>
        <div className={classes.mySessionTab}>
          <div className={`settingsHead`}>
            <div className="navItem">
              <a
                id="upcoming"
                style={{ cursor: "pointer" }}
                className={`navLink ${classes.active}  ${
                  currentTab === "upcoming" && "activeTab"
                }`}
                onClick={(event) => handleTabClick(event, "upcoming")}
              >
                Upcoming
              </a>
            </div>
            <div className=" navItem">
              <a
                id="past"
                style={{ cursor: "pointer" }}
                className={`navLink ${classes.active} ${
                  currentTab === "past" && "activeTab"
                }`}
                onClick={(event) => handleTabClick(event, "past")}
              >
                Past
              </a>
            </div>
          </div>
        </div>

        <div className={` tab-content ${classes.tabContent}`}>
          <div
            className={`tab-pane ${classes.mobScroll} ${
              currentTab === "upcoming" && "active"
            }`}
          >
            <MySessionsTable
              sessions={sessions}
              loading={loading}
              next={nextPage}
              getNextData={getNextData}
            />
          </div>

          <div
            className={`tab-pane ${classes.mobScroll} ${
              currentTab === "past" && "active"
            }`}
          >
            <MySessionsTablePast
              pastSessions={pastSessions}
              loading={loading}
              next={nextPage}
              getNextData={getNextData}
            />
          </div>
        </div>
      </div>
      <div className={classes.mySessions__footer}>
        <FooterLink />
      </div>
    </div>
  );
};

export default MySessions;
