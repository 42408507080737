import React from "react";
import classes from "./Alert.module.scss";

const AlertMessage = (props) => {
  return (
    <div className={classes.alertMessage}>
      <div className={classes.alertMessage__innerDiv}>
        <p className={classes.alertMessage__errorTxt}>{props.msg}</p>

        <div className={classes.btnDiv}>
          <button onClick={props.handleYes} className={classes.yesBtn}>
            Yes
          </button>
          <button onClick={props.handleNo} className={classes.noBtn}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;
