import React, { useEffect } from "react";
import StepZeroTherapist from "./StepZero/StepZeroTherapist";
import StepZeroClient from "./StepZero/StepZeroClient";
import { Redirect, Route, Switch } from "react-router-dom";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";
import PreviewTherapistProfile from "./PreviewProfile/PreviewTherapistProfile";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import PreviewClientProfile from "./PreviewProfile/PreviewClientProfile";
import ComponentLoader from "../../UI/ComponentLoader";

const CreateProfile = (props) => {
  const { onGetUserProfile } = props;

  useEffect(() => {
    onGetUserProfile();
  }, [onGetUserProfile]);

  let content = null;
  if (props.profile) {
    content = (
      <Switch>
        {props.type.toString() === "therapist" && (
          <Route path="/welcome" component={StepZeroTherapist} />
        )}
        {props.type.toString() === "client" && (
          <Route path="/welcome" component={StepZeroClient} />
        )}
        <Route path="/about-you" component={StepOne} />

        {props.type.toString() === "therapist" && (
          <Route path="/skills-qualifications" component={StepTwo} />
        )}
        {props.type.toString() === "client" && (
          <Route path="/your-needs" component={StepTwo} />
        )}
        <Route path="/preferences" component={StepThree} />

        {props.type.toString() === "therapist" && (
          <Route path="/preview" component={PreviewTherapistProfile} />
        )}
        {props.type.toString() === "client" && (
          <Route path="/preview" component={PreviewClientProfile} />
        )}
        <Redirect to="/welcome" />
      </Switch>
    );
  } else {
    content = <ComponentLoader />;
  }

  return content;
};

const mapStateToProps = (state) => {
  return {
    type: state.auth.user["type"],
    profile: state.userProfile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserProfile: () => dispatch(actions.getUserProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);
