import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./BasicStats.module.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import axios from "axios";
import { BASE_URL } from "../../../../../shared/constants";
import enGB from "date-fns/locale/en-GB";
import moment from "moment";
import { clearAndLogout } from "../../../../../shared/utility";
import ComponentLoader from "../../../../UI/ComponentLoader";
import AlertDialog from "../../../../UI/Alert/AlertDialog";

const BasicStats = (props) => {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);

  const [alertMsg, setAlertMsg] = useState(false);

  const [currentTab, setCurrentTab] = useState(
    (search.includes("all") && "all") ||
      (search.includes("past30days") && "past30days") ||
      (search.includes("past1year") && "past1year") ||
      (search.includes("selectDates") && "selectDates")
  );

  const [period, setPeriod] = useState(null);

  const [stats, setStats] = useState(null);
  const [calender, setCalender] = useState(false);

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setCalender(true);
    if (stats != null) {
      setStats(null);
    }

    if (currentTab === "selectDates") {
      if (period != null) {
        setPeriod(null);
      }
    } else {
      setPeriod(currentTab);
      setCalender(true);

      if (currentTab === period) {
        getBasicStats();
      }
    }
  }, [currentTab, period]);

  const getReport = () => {
    if (startDate === null || endDate === null) {
      setAlertMsg(true);
    } else {
      setLoading(true);
      getBasicStats();
    }
  };

  const onToggle = () => {
    setAlertMsg(false);
  };

  const handleTabClick = (event, tab) => {
    event.preventDefault();
    setCurrentTab(tab);

    if (tab === "all") {
      history.replace({
        pathname: "reports",
        search: "all",
      });
    }

    if (tab === "past30days") {
      history.replace({
        pathname: "reports",
        search: "past30days",
      });
    }

    if (tab === "past1year") {
      history.replace({
        pathname: "reports",
        search: "past1year",
      });
    }

    if (tab === "selectDates") {
      history.replace({
        pathname: "reports",
        search: `selectDates`,
      });
    }

    if (tab !== "selectDates") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const handleViewDetails = (event, tab) => {
    event.preventDefault();

    if (currentTab === "all") {
      history.push({
        pathname: "/reports/total-clients",
        search: `tab=all`,
      });
    }

    if (currentTab === "past30days") {
      history.push({
        pathname: "/reports/total-clients",
        search: `tab=past30days`,
      });
    }

    if (currentTab === "past1year") {
      history.push({
        pathname: "/reports/total-clients",
        search: `tab=past1year`,
      });
    }

    if (currentTab === "selectDates") {
      history.push({
        pathname: "/reports/total-clients",
        search: `tab=selectDates&from=${startDate}&to=${endDate}`,
      });
    }
  };

  const getBasicStats = () => {
    const token = localStorage.getItem("token");
    const url = BASE_URL + "/reports/therapist-basic-stats";

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          from: startDate
            ? moment(startDate)
                .startOf("day")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
          to: endDate
            ? moment(endDate).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
            : null,
          period: period === "all" ? null : period,
        },
      })
      .then((response) => {
        setLoading(false);
        setStats(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error === "Invalid token.") {
          clearAndLogout();
        }
      });
  };

  return (
    <div className={classes.basicStats}>
      <div className={`col ${classes.removePadding}`}>
        <ul
          className={`nav nav-pills ${classes.navPills}`}
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              className={`${currentTab === "all" && "activeLink"} ${
                classes.navLink
              }`}
              id="all-tab"
              data-toggle="tab"
              href="#all"
              role="tab"
              onClick={(event) => handleTabClick(event, "all")}
            >
              All
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className={` ${currentTab === "past30days" && "activeLink"} ${
                classes.navLink
              }`}
              id="all-tab"
              data-toggle="tab"
              href="#past30days"
              role="tab"
              onClick={(event) => handleTabClick(event, "past30days")}
            >
              Past 30 days
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className={`${currentTab === "past1year" && "activeLink"} ${
                classes.navLink
              }`}
              id="all-tab"
              data-toggle="tab"
              href="#past1year"
              role="tab"
              onClick={(event) => handleTabClick(event, "past1year")}
            >
              Past 1 year
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className={` ${currentTab === "selectDates" && "activeLink"} ${
                classes.navLink
              }`}
              id="all-tab"
              data-toggle="tab"
              href="#selectDates"
              role="tab"
              onClick={(event) => handleTabClick(event, "selectDates")}
            >
              Select dates
            </a>
          </li>
        </ul>

        {currentTab === "selectDates" && calender && (
          <>
            <div className={classes.selectDates}>
              <DatePicker
                calendarClassName={classes.firstCalender}
                className={classes.firstInput}
                onChange={(date) => setStartDate(date)}
                selected={startDate}
                placeholderText="Start Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />
              <p className={classes.to}> To </p>

              <DatePicker
                className={classes.secondInput}
                calendarClassName={classes.secondCalender}
                onChange={(date) => setEndDate(date)}
                selected={endDate}
                placeholderText="End Date"
                locale={"en-GB"}
                dateFormat="yyyy-MM-dd"
                fixedHeight
                withPortal
              />

              <button onClick={getReport} className={classes.GetReports}>
                Get Report
              </button>
            </div>
          </>
        )}
      </div>

      {stats && (
        <div>
          <div className={classes.statsDiv}>
            <div className={classes.statsFirstDiv}>
              <p className={classes.totalSession}>
                {stats.total_sessions_offered}
              </p>
              <label className={classes.statsLabel}>
                Total sessions offered
              </label>
            </div>

            <div className={classes.statsFirstDiv}>
              {stats.revenue_generated.length === 0 && (
                <p className={classes.totalSession}>0</p>
              )}

              {stats.revenue_generated.length > 0 && (
                <>
                  {stats.revenue_generated.map((revenue) => (
                    <p key={revenue.currency} className={classes.totalSession}>
                      {revenue.amount}&nbsp;{revenue.currency}
                    </p>
                  ))}
                </>
              )}

              <label className={classes.statsLabel}>Revenue generated</label>
            </div>
          </div>
          <div className={classes.reportsBtnDiv}>
            <div onClick={handleViewDetails} className={classes.viewDetailsBtn}>
              View Details
            </div>
          </div>
        </div>
      )}

      {alertMsg && (
        <AlertDialog
          msg="Please select starting and ending dates"
          onToggle={onToggle}
        />
      )}

      {loading && <ComponentLoader />}
    </div>
  );
};

export default BasicStats;
