import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASE_URL } from "../../../shared/constants";
import Card from "./Card";
import classes from "./stopStigma.module.scss";
import FooterLink from "../../footerLink/FooterLink";
import logo from "../../../assets/images/mindsum-logo.svg";
import AlertDialog from "../../UI/Alert/AlertDialog";
import CardDetails from "./CardDetails";
import ComponentLoader from "../../UI/ComponentLoader";

const color = ["#ffa88a", "#ff5f5f", "#62bfae", "#Ed6b64", "#618df0"];

const PostLists = () => {
  const headerRef = useRef(null);

  const location = useLocation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({ reValidateMode: "onChange" });

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successPopup, setSuccessPopup] = useState("");
  const [showCardDetail, setShowCardDetail] = useState(true);
  const [pageData, setPageData] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const handleForm = () => {
    setShowForm(true);
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    const userDetail = {
      question: formData.mental_health_query,
      name: formData.name,
      age: parseInt(formData.age),
      city: formData.city,
      email: formData.email,
    };

    try {
      const response = await axios.post(`${BASE_URL}/questions`, userDetail);

      setLoading(false);
      setShowForm(false);
      setSuccessPopup(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }

    reset();
  };

  const getAllQuestion = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/answer/list`);
      setPageData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.search === "") {
      setShowCardDetail(false);
    }
  }, []);

  useEffect(() => {
    getAllQuestion();
  }, []);

  const onToggle = () => {
    setSuccessPopup("");
  };

  const onClose = () => {
    setShowCardDetail(false);
  };

  return (
    <div className={classes.listsDiv}>
      <div ref={headerRef} className={classes.listsDiv_header}>
        <Link to="/">
          <img src={logo} alt="" className={classes.mindsumLogo} />
        </Link>

        <button
          onClick={handleForm}
          type="button"
          className={classes.postButton}
        >
          Post your query anonymously
        </button>
      </div>

      {showForm && (
        <div className={classes.formDiv}>
          <div onClick={() => setShowForm(false)} className={classes.hideForm}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39.031"
              height="39.031"
              viewBox="0 0 39.031 39.031"
            >
              <path
                id="Path_14799"
                data-name="Path 14799"
                d="M820.831,831.908a19.516,19.516,0,1,0,19.516,19.516A19.516,19.516,0,0,0,820.831,831.908Zm7.671,26.184a.709.709,0,0,1-1,1l-6.668-6.668-6.669,6.669a.709.709,0,0,1-1-1l6.669-6.669-6.669-6.669a.709.709,0,0,1,1-1l6.669,6.669,6.669-6.669a.709.709,0,1,1,1,1l-6.669,6.669Z"
                transform="translate(-801.315 -831.908)"
                fill="#fff"
              />
            </svg>
          </div>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.inputDiv}>
              <textarea
                {...register("mental_health_query", {
                  required: true,
                  maxLength: 250,
                })}
                rows="7"
                id="mental_health_query"
                name="mental_health_query"
                placeholder="Write your query related to mental health..."
              ></textarea>
              {errors?.mental_health_query &&
                errors.mental_health_query.type === "required" && (
                  <p className={`errorTxt`} style={{ marginTop: "5px" }}>
                    Question is required
                  </p>
                )}

              {errors?.mental_health_query &&
                errors.mental_health_query.type === "maxLength" && (
                  <p className={`errorTxt`} style={{ marginTop: "5px" }}>
                    Maximum 250 characters allowed
                  </p>
                )}
            </div>
            <div className={classes.inputDiv}>
              <input
                {...register("name", { required: false })}
                name="name"
                id="name"
                type="text"
                placeholder="Name (Optional)"
              />
            </div>

            <div className={classes.inputDiv}>
              <input
                {...register("age", { required: false })}
                name="age"
                id="age"
                type="text"
                placeholder="Age (Optional)"
              />
            </div>

            <div className={classes.inputDiv}>
              <input
                {...register("city", { required: false })}
                id="city"
                name="city"
                type="city"
                placeholder="City (Optional)"
              />
            </div>

            <div className={classes.inputDiv}>
              <input
                {...register("email", { required: false })}
                id="email"
                name="email"
                type="email"
                placeholder="Email (Optional)"
              />
            </div>

            <p className={classes.alertInfo}>
              We will use your email to notify you when your query gets
              published. A qualified therapist will answer your query within 5
              working days. It will be published on this page.
            </p>

            {loading ? (
              <div className={`${classes.loader} ${classes.btnDiv}`}>
                <span className={classes.loader__spin}></span>
              </div>
            ) : (
              <div className={classes.btnDiv}>
                <button className={classes.submitBtn} type="submit">
                  Submit
                </button>
              </div>
            )}
          </form>
        </div>
      )}

      <div className={classes.listsDiv_mainSection}>
        <h2 className={classes.welcomeHeading}>Welcome to your safe space</h2>
        <div className={classes.cardDiv}>
          {pageData.results.length === 0 && <ComponentLoader />}

          {pageData.results.length &&
            pageData.results.map((d) => {
              return (
                <div key={d.id}>
                  <Card
                    data={d}
                    firstcolor={
                      color[Math.ceil(Math.random() * color.length) - 1]
                    }
                    secondcolor={
                      color[Math.ceil(Math.random() * color.length) - 1]
                    }
                    setShowCardDetail={setShowCardDetail}
                  />
                </div>
              );
            })}
        </div>
        <div className={classes.footerDiv}>
          <FooterLink />
        </div>

        <a
          href="https://www.mindsum.app/"
          target="_blank"
          className={classes.getSupportDiv}
        >
          <div className={classes.redBg}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="179"
              height="179"
              viewBox="0 0 179 179"
            >
              <path
                id="Path_14798"
                data-name="Path 14798"
                d="M-4672.141,5747.03a89.156,89.156,0,0,0-47.061,11.918l-36.3-11.111a1.55,1.55,0,0,0-1.972,1.8l7.875,38.165a89.129,89.129,0,0,0-14.381,50.764c1.074,47.714,40.068,86.542,87.787,87.422a89.5,89.5,0,0,0,91.1-93.642C-4587.205,5785.707-4625.474,5748.231-4672.141,5747.03Z"
                transform="translate(4764 -5747)"
                fill="#e56161"
              />
            </svg>
          </div>
          <p className={classes.innerText}>Find your Therapist</p>
        </a>
      </div>

      {successPopup && (
        <AlertDialog
          msg="Your query has been submitted successfully!"
          onToggle={onToggle}
        />
      )}

      {showCardDetail && (
        <CardDetails onClose={onClose} setShowCardDetail={setShowCardDetail} />
      )}
    </div>
  );
};

export default PostLists;
