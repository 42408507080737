import React from "react";
import classes from "../CreateProfile/CreateProfile.module.scss";
import logo from "../../../assets/images/mindsum-logo.svg";
import heroImg from "../../../assets/images/profileBuilderImg.jpg";
import FooterLink from "../../footerLink/FooterLink";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const PendingProfileApproval = (props) => {
  return (
    <div className={classes.createProfile}>
      <div className={classes.firstDiv}>
        <Link to="/" className={classes.logoDiv}>
          <img src={logo} className={classes.mindsumLogo} alt="" />
        </Link>
        <div className={classes.introDiv}>
          <h3 className={classes.name} style={{ padding: "0 2%" }}>
            We are excited to see you join us!
          </h3>
          <p className={classes.intro}>
            Mindsum is platform where the best therapists connect with clients
            seeking mental health counselling and therapies. To protect this
            quality, we review all profiles before they're approved.
          </p>
          <p className={classes.intro}>
            We will send you an email once your profile is approved by our team.
            This should not take more than 2 working days.
          </p>
          <div className={classes.proceedText}>
            {props.isAuthenticated &&
              (!props.user.is_signup_complete ||
                !props.user.is_profile_approved) && (
                <Link to="logout" className={classes.proceedBtn}>
                  <span className={classes.proceed}>Logout</span>
                </Link>
              )}
          </div>
        </div>

        <div
          className={`${classes.createProfileFooter} ${classes.pendingFooterDiv}`}
        >
          <FooterLink />
        </div>
      </div>

      <div className={classes.secondDiv}>
        <img src={heroImg} className={classes.heroImg} alt="" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.token !== null,
  };
};
export default connect(mapStateToProps)(PendingProfileApproval);
